import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { SchemaService } from "../schema.service";

export const getCompareSchema = async ({
  id,
  version
}: {
  id?: string;
  version?: number;
}) => {
  if (!id || !version) {
    return null;
  }
  const schemaService = container.resolve(SchemaService);
  return schemaService.getSchemaVersion(id, version);
};

export const useCompareSchema = ({
  id,
  version
}: {
  id?: string;
  version?: number;
}) => {
  return useQuery({
    queryKey: [`COMPARE_SCHEMA`, id, version],
    queryFn: () => getCompareSchema({ id: id, version: version })
  });
};
