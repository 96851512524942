import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import DeleteModal from "../../components/modals/DeleteModal";
import useDeleteActiveTenant from "../../features/tenants/api/deleteTenant";
import { useTenantStore } from "../../features/tenants/store";

const DeleteTenantPage = () => {
  const deleteTenantMutation = useDeleteActiveTenant();
  const { activeTenant } = useTenantStore();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = "Delete tenant";

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={
          <Flex gap={"1em"}>
            <Text>{title} </Text>
            <Text fontWeight={500} as={"i"}>
              {activeTenant.name}
            </Text>
          </Flex>
        }
      >
        <Flex w={"2fr"}>
          <Button onClick={onOpen}>Delete Tenant</Button>
        </Flex>
      </ContentBox>
      <DeleteModal
        name={`tenant ${activeTenant.name}`}
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={() => {
          deleteTenantMutation.mutate();
          onClose();
        }}
      ></DeleteModal>
    </>
  );
};

export default DeleteTenantPage;
