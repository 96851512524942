import { ITenantUser } from "../features/tenants/types";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";

export const getUserOptions = (
  users: ITenantUser[] | undefined
): IEnterspeedIdentifiableSelectOption[] => {
  if (!users || users.length === 0) {
    return [];
  }

  return users
    .sort((a, b) =>
      `${a.firstName} ${a.lastName} (${a.emails?.[0]})` >
      `${b.firstName} ${b.lastName} (${b.emails?.[0]})`
        ? 1
        : -1
    )
    .map((user) => {
      return {
        id: user.id.idValue,
        label: `${user.firstName} ${user.lastName} (${user.emails?.[0]})`,
        value: user.id.idValue,
        title: `${user.firstName} ${user.lastName} (${user.emails?.[0]})`
      } as IEnterspeedIdentifiableSelectOption;
    });
};

export const getUserOptionById = (
  users: ITenantUser[] | undefined,
  id: string
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!users || users.length === 0) {
    return undefined;
  }

  const user = users.find((s) => s.id.idValue === id);
  return user
    ? {
        id: user.id.idValue,
        label: `${user.firstName} ${user.lastName} (${user.emails?.[0]})`,
        value: user.id.idValue
      }
    : undefined;
};
