import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import ViewsTable from "../features/views/components/ViewsTable";

const GeneratedViewsPage = () => {
  const title = "Generated views";
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Generated views let you see the views your schemas generate from your data sources. This is exactly how your content will look when delivered to your front-end."
      >
        <ViewsTable />
      </ContentBox>
    </>
  );
};

export default GeneratedViewsPage;
