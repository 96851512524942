import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useMixPanel from "../../mixpanel/useMixPanel";

const GettingStartedItemActionLink = ({
  href,
  description
}: {
  href: string;
  description: string;
}) => {
  const mixpanel = useMixPanel();
  const navigate = useNavigate();
  return (
    <Button
      variant={"ghost"}
      onClick={() => {
        mixpanel.track("gettingStartedAction", { type: "link", href });
        return navigate(href);
      }}
    >
      {description}
    </Button>
  );
};

export default GettingStartedItemActionLink;
