import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DOMAINS } from "../../../constants/queryKey";
import { DomainService } from "../domain.service";
import { IDomainCreatePayload } from "../types";

export const createDomain = ({
  payload
}: {
  payload: IDomainCreatePayload;
}) => {
  const domainService = container.resolve(DomainService);
  return domainService.createDomain(payload);
};

export const useCreateDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDomain,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DOMAINS] });
    }
  });
};
