import { inject, singleton } from "tsyringe";
import { IApiResponse } from "../types/api";
import { HttpService, IHttpOptions, IHttpService } from "./http.service";

@singleton()
export class ManagementClient implements IHttpService {
  constructor(
    @inject("managementHttpService")
    private readonly http: HttpService
  ) {}
  get<T>(
    url: string,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.http.get(url, options);
  }
  post<T, R = unknown>(
    url: string,
    data?: R | undefined,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.http.post(url, data, options);
  }
  put<T, R = unknown>(
    url: string,
    data?: R | undefined,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.http.put(url, data, options);
  }
  patch<T, R = unknown>(
    url: string,
    data: R,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.patch(url, data, options);
  }
  delete<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.http.delete(url, data, options);
  }
  options<T>(
    url: string,
    options?: IHttpOptions | undefined
  ): Promise<IApiResponse<T>> {
    return this.http.options(url, options);
  }
}
