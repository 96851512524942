import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../../components/table/DataTable";
import { SidePanelContext } from "../../../context/SidePanelContext";
import formatDateTime, {
  formatDateTimeWithMilliseconds
} from "../../../helpers/formatDateTime";

import { useSearchParams } from "react-router-dom";
import { getDisplayName } from "../helpers/fieldDisplayNames";
import { createConditionId } from "../helpers/initQueryConditionFromSearchParams";
import {
  IConditionEqual,
  IConditionNotEqual,
  IEnhancedLog,
  IFilterableFieldOption,
  IFrontendCondition,
  ILogsState,
  LogLevel,
  LogsAction,
  Operator
} from "../types";
import { isPlaceholder } from "../types/interactivePlaceholders";
import InterpolatedField from "./InterpolatedField";
import InterpolatedText from "./InterpolatedText";
import LogPanel from "./LogPanel";

const LogsTable = ({
  logsState,
  logsDispatch,
  filterFields,
  loading
}: {
  logsState: ILogsState;
  logsDispatch: React.Dispatch<LogsAction>;
  filterFields: IFilterableFieldOption[];
  loading: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const columnHelper = createColumnHelper<IEnhancedLog>();
  const [selectedRow, setSelectedRow] = useState<string>("");
  const { setOpen, setTitle, setContent, isClosing, setCustomStartWidth } =
    useContext(SidePanelContext);
  useEffect(() => {
    if (isClosing) {
      setSelectedRow("");
      setOpen(false);
    }
  }, [isClosing]);

  const toggleSorting = () => {
    logsDispatch({
      type: "set_sorting_direction",
      value:
        logsState.sortingDirection === "Descending" ? "Ascending" : "Descending"
    });
  };

  const handleOpenSidePanel = (row: IEnhancedLog) => {
    const filterValue = (operator: Operator, field: string, value: string) => {
      const condition = {
        id: createConditionId(),
        $type: operator,
        field: field,
        value: value
      } as IConditionEqual | IConditionNotEqual;
      const updatedConditions = [
        ...(logsState.condition?.items ?? []),
        condition
      ] as IFrontendCondition[];
      logsDispatch({
        type: "set_condition",
        value: updatedConditions
      });
    };

    const toggleColumn = (field: string) => {
      logsDispatch({
        type: "toggle_column",
        value: field
      });
    };

    setTitle("Log details");
    setContent(
      <LogPanel
        filterFields={filterFields}
        displayType={logsState.displayType}
        row={row}
        filterValue={filterValue}
        toggleColumn={toggleColumn}
      />
    );
    setOpen(true);
    setCustomStartWidth(40);
    setSelectedRow(row.id);
    const search = new URLSearchParams(searchParams);
    search.set("sidePanel", "logs");
    setSearchParams(search);
  };

  const systemColumns: ColumnDef<IEnhancedLog, string>[] = [
    columnHelper.accessor("timestamp", {
      cell: (info) => {
        return (
          <Tooltip label={formatDateTimeWithMilliseconds(info.getValue())}>
            {formatDateTime(info.getValue())}
          </Tooltip>
        );
      },
      meta: {
        width: 150,
        isNumeric: false
      },
      header: () => (
        <>
          <Tooltip label="Sort by time">
            <IconButton
              variant="ghost"
              size="xs"
              mr={1}
              aria-label="Sort by time"
              onClick={toggleSorting}
              icon={
                <Icon
                  as={FontAwesomeIcon}
                  icon={
                    logsState.sortingDirection === "Descending"
                      ? faChevronDown
                      : faChevronUp
                  }
                />
              }
            />
          </Tooltip>
          Timestamp
        </>
      ),
      enableSorting: false
    })
  ];

  const dynamicColumns: (
    | ColumnDef<IEnhancedLog, string>
    | ColumnDef<IEnhancedLog, LogLevel>
  )[] = [];
  logsState.selectedColumns.forEach((column) => {
    let columnDefinition:
      | ColumnDef<IEnhancedLog, string>
      | ColumnDef<IEnhancedLog, LogLevel>
      | null = null;
    if (column === "message") {
      columnDefinition = columnHelper.accessor("message", {
        cell: (info) => {
          const teaser: string =
            (info.row.original.properties.TeaserMessageTemplate as string) ??
            info.row.original.messageTemplate ??
            "";
          return (
            <InterpolatedText
              text={teaser}
              properties={info.row.original.properties}
              enhancements={info.row.original.enhancements}
              displayType={logsState.displayType}
            ></InterpolatedText>
          );
        },
        header: "Message",
        enableSorting: false
      });
    } else if (column.startsWith("properties.")) {
      const noPropertiesSuffix = column.replace("properties.", "");
      const isRecognizedPlaceholder = isPlaceholder(noPropertiesSuffix);

      columnDefinition = columnHelper.display({
        header: getDisplayName(column),
        cell: (_col) => {
          const valueAsString = _col.row.original.properties[
            noPropertiesSuffix
          ] as string;
          if (!isRecognizedPlaceholder) {
            return <>{valueAsString}</>;
          } else {
            return (
              <InterpolatedField
                displayType={logsState.displayType}
                key={Math.random()}
                input={noPropertiesSuffix}
                properties={_col.row.original.properties}
                enhancements={_col.row.original.enhancements}
                fallbackValue={valueAsString}
              ></InterpolatedField>
            );
          }
        }
      });
    } else {
      columnDefinition = columnHelper.display({
        header: getDisplayName(column),
        cell: (_col) => {
          return <>{(_col.row.original as never)[column]}</>;
        }
      });
    }

    if (columnDefinition) {
      dynamicColumns.push(columnDefinition);
    }
  });

  const columns = [...systemColumns, ...dynamicColumns];
  return (
    <>
      <DataTable
        fontSize="sm"
        displayStyle="compact"
        loading={loading}
        columns={columns}
        data={logsState.items}
        highlightedRow={selectedRow}
        onRowClickCallback={(row: IEnhancedLog) => handleOpenSidePanel(row)}
      />
    </>
  );
};
export default LogsTable;
