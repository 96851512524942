import {
  parseDomainId,
  parseEnvironmentClientId,
  parseEnvironmentId,
  parseExplicitRedirectRouteId,
  parseHandleRouteId,
  parseImplicitRedirectRouteId,
  parseMappingSchemaId,
  parseSourceEntityId,
  parseSourceGroupId,
  parseSourceId,
  parseUrlRouteId,
  parseViewId
} from "../../../helpers/ids";
import { isRouteKindViewAware } from "../../../helpers/routes";
import { IDomainResponse } from "../../domains/types";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { IEnvironmentResponse } from "../../environments/types";
import { IManagementClientResponse } from "../../management-clients/types";
import { ISchemaListResponse } from "../../schemas/types";
import { ISourceGroupsResponse } from "../../source-groups/types";
import { ITenantUser } from "../../tenants/types";
import { IEnhancedLog, ILog } from "../types";
import {
  Enhancement,
  EnhancementKey,
  isPlaceholder,
  Placeholder
} from "../types/interactivePlaceholders";
import {
  findEnvironmentByGuid,
  findMappingSchemaByAlias,
  findMappingSchemaByGuid,
  findSourceGroupByGuid,
  findSourceGroupBySourceGuid
} from "./interactivePlaceholders";

export const enhanceLogEntry = (
  log: ILog,
  schemas: ISchemaListResponse[],
  environments: IEnvironmentResponse[],
  sources: ISourceGroupsResponse[],
  environmentClients: IEnvironmentClientResponse[],
  tenantUsers: ITenantUser[],
  domains: IDomainResponse[],
  managementClients: IManagementClientResponse[]
): IEnhancedLog => {
  // Enhance based on properties
  let enhancements: Record<EnhancementKey, Enhancement> = {};
  Object.keys(log.properties).forEach((key) => {
    if (isPlaceholder(key)) {
      enhancements = remap(
        key,
        enhancements,
        log.properties,
        schemas,
        environments,
        sources,
        environmentClients,
        tenantUsers,
        domains,
        managementClients
      );
    }
  });
  return {
    ...log,
    properties: {
      ...log.properties,
      // we wan't to have log level as part of the properties, for display purposes
      Level: log.level,
      Type: log.type
    },
    enhancements: enhancements
  };
};

const getOrCreateSourceEnhancement = (
  sourceGuid: string,
  enhancements: Record<EnhancementKey, Enhancement>,
  sources: ISourceGroupsResponse[]
): Enhancement | undefined => {
  const enhancementKey: EnhancementKey = `Source|${sourceGuid}`;
  const existingEnhancement = enhancements[enhancementKey];
  if (existingEnhancement) {
    return existingEnhancement;
  }

  const sourceGroup = findSourceGroupBySourceGuid(sourceGuid, sources);
  if (!sourceGroup) {
    return undefined;
  }

  const source = sourceGroup.sources.find(
    (f) => f.source.id.sourceGuid === sourceGuid
  );
  if (!source) {
    return undefined;
  }

  return {
    key: enhancementKey,
    id: {
      sourceGuid: sourceGuid
    },
    sourceGroupName: sourceGroup.name,
    name: source.source.name
  };
};

const getOrCreateSchemaEnhancement = (
  identifier: string,
  enhancements: Record<EnhancementKey, Enhancement>,
  schemas: ISchemaListResponse[]
): Enhancement | undefined => {
  const enhancementKey: EnhancementKey = `MappingSchema|${identifier}`;
  const existingEnhancement = enhancements[enhancementKey];
  if (existingEnhancement) {
    return existingEnhancement;
  }

  const schema =
    findMappingSchemaByGuid(identifier, schemas) ??
    findMappingSchemaByAlias(identifier, schemas);
  if (!schema) {
    return undefined;
  }

  return {
    key: enhancementKey,
    id: {
      mappingSchemaGuid: schema.id.mappingSchemaGuid
    },
    name: schema.name
  };
};

const getOrCreateEnvironmentEnhancement = (
  environmentGuid: string,
  enhancements: Record<EnhancementKey, Enhancement>,
  environments: IEnvironmentResponse[]
): Enhancement | undefined => {
  const enhancementKey: EnhancementKey = `Environment|${environmentGuid}`;
  const existingEnhancement = enhancements[enhancementKey];
  if (existingEnhancement) {
    return existingEnhancement;
  }

  const environment = findEnvironmentByGuid(environmentGuid, environments);
  if (!environment) {
    return undefined;
  }

  return {
    key: enhancementKey,
    id: {
      environmentGuid: environmentGuid
    },
    name: environment.name
  };
};

const sourceEntityEnhancements = (
  id: string,
  sources: ISourceGroupsResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const sourceEntityId = parseSourceEntityId(id);
  if (!sourceEntityId) {
    return undefined;
  }

  const sourceEnhancement = getOrCreateSourceEnhancement(
    sourceEntityId.sourceGuid,
    enhancements,
    sources
  );

  if (!sourceEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[sourceEnhancement.key] = sourceEnhancement;
  output[`SourceEntity|${id}`] = {
    key: `SourceEntity|${id}`,
    id: sourceEntityId
  };
  return output;
};

const mappingSchemaEnhancements = (
  id: string,
  schemas: ISchemaListResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const mappingSchemaId = parseMappingSchemaId(id);
  if (!mappingSchemaId) {
    return undefined;
  }

  const schemaEnhancement = getOrCreateSchemaEnhancement(
    mappingSchemaId.mappingSchemaGuid,
    enhancements,
    schemas
  );

  if (!schemaEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[schemaEnhancement.key] = schemaEnhancement;
  return output;
};

const sourceGroupEnhancements = (
  id: string,
  sources: ISourceGroupsResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const sourceGroupId = parseSourceGroupId(id);
  if (!sourceGroupId) {
    return undefined;
  }

  const enhancementKey: EnhancementKey = `SourceGroup|${sourceGroupId.sourceGroupGuid}`;
  const existingEnhancement = enhancements[enhancementKey];
  if (existingEnhancement) {
    return existingEnhancement;
  }

  const sourceGroup = findSourceGroupByGuid(
    sourceGroupId.sourceGroupGuid,
    sources
  );
  if (!sourceGroup) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[enhancementKey] = {
    key: enhancementKey,
    id: {
      sourceGroupGuid: sourceGroupId.sourceGroupGuid
    },
    name: sourceGroup.name,
    type: sourceGroup.type
  };
  return output;
};

const sourceEnhancements = (
  id: string,
  sources: ISourceGroupsResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const sourceId = parseSourceId(id);
  if (!sourceId) {
    return undefined;
  }

  const sourceEnhancement = getOrCreateSourceEnhancement(
    sourceId.sourceGuid,
    enhancements,
    sources
  );

  if (!sourceEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[sourceEnhancement.key] = sourceEnhancement;
  return output;
};

const domainEnhancements = (
  id: string,
  domains: IDomainResponse[]
): Record<EnhancementKey, Enhancement> | undefined => {
  const domainId = parseDomainId(id);
  if (!domainId) {
    return undefined;
  }

  const domain = domains.find((f) => f.id.idValue === id);
  if (!domain) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  const enhancementKey: EnhancementKey = `Domain|${domainId.domainGuid}`;
  output[enhancementKey] = {
    key: enhancementKey,
    id: domainId,
    name: domain.name
  };
  return output;
};

const environmentEnhancements = (
  id: string,
  environments: IEnvironmentResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const environmentId = parseEnvironmentId(id);
  if (!environmentId) {
    return undefined;
  }

  const environmentEnhancement = getOrCreateEnvironmentEnhancement(
    environmentId.environmentGuid,
    enhancements,
    environments
  );

  if (!environmentEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[environmentEnhancement.key] = environmentEnhancement;
  return output;
};

const releaseEnhancements = (
  id: string,
  environmentIdAsString: string,
  environments: IEnvironmentResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const environmentId = parseEnvironmentId(environmentIdAsString);
  if (!environmentId) {
    return undefined;
  }

  const environmentEnhancement = getOrCreateEnvironmentEnhancement(
    environmentId.environmentGuid,
    enhancements,
    environments
  );

  if (!environmentEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[environmentEnhancement.key] = environmentEnhancement;

  const enhancementKey: EnhancementKey = `Release|${id}`;
  output[enhancementKey] = {
    key: enhancementKey,
    id: id,
    environmentId: environmentIdAsString
  };
  return output;
};

const environmentClientEnhancements = (
  id: string,
  environmentClients: IEnvironmentClientResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const environmentClientId = parseEnvironmentClientId(id);
  if (!environmentClientId) {
    return undefined;
  }

  const enhancementKey: EnhancementKey = `EnvironmentClient|${id}`;
  const existingEnhancement = enhancements[enhancementKey];
  if (existingEnhancement) {
    return existingEnhancement;
  }

  const environmentClient = environmentClients.find((f) => f.id.idValue === id);
  if (!environmentClient) {
    return undefined;
  }
  const output: Record<EnhancementKey, Enhancement> = {};
  output[enhancementKey] = {
    key: enhancementKey,
    id: environmentClientId,
    name: environmentClient.name,
    environmentName: environmentClient.environmentName
  };

  return output;
};

const actorEnhancements = (
  properties: Record<string, unknown>,
  tenantUsers: ITenantUser[],
  managementClients: IManagementClientResponse[]
): Record<EnhancementKey, Enhancement> | undefined => {
  type ActorType = "USER" | "MANAGEMENT_CLIENT";
  const actorId = properties.ActorId as string;
  const actorType = properties.ActorType as ActorType;
  const actorRetrieval: Record<
    ActorType,
    () => { displayName: string } | null
  > = {
    USER: () => {
      const user = tenantUsers.find((f) => f.id.idValue === actorId);
      return user
        ? {
            displayName: `${user.firstName} ${user.lastName} (${user.emails?.[0]})`
          }
        : null;
    },
    MANAGEMENT_CLIENT: () => {
      const managementClient = managementClients.find(
        (f) => f.id.idValue === actorId
      );
      return managementClient
        ? {
            displayName: managementClient.name
          }
        : null;
    }
  };
  const actor = actorRetrieval[actorType]?.();
  if (!actor) {
    return undefined;
  }

  const enhancementKey: EnhancementKey = `Actor|${actorId}`;
  const output: Record<EnhancementKey, Enhancement> = {};
  output[enhancementKey] = {
    key: enhancementKey,
    id: actorId,
    name: actor.displayName,
    type: actorType
  };

  return output;
};

const routeEnhancements = (
  routeIdAsString: string,
  schemas: ISchemaListResponse[],
  environments: IEnvironmentResponse[],
  sources: ISourceGroupsResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const routeId =
    parseUrlRouteId(routeIdAsString) ??
    parseHandleRouteId(routeIdAsString) ??
    parseImplicitRedirectRouteId(routeIdAsString) ??
    parseExplicitRedirectRouteId(routeIdAsString);

  if (!routeId) {
    return undefined;
  }

  if (!isRouteKindViewAware(routeId.kind)) {
    return undefined;
  }

  const environmentEnhancement = getOrCreateEnvironmentEnhancement(
    routeId.environmentGuid,
    enhancements,
    environments
  );
  const schemaEnhancement = getOrCreateSchemaEnhancement(
    routeId.schemaAlias,
    enhancements,
    schemas
  );
  const sourceEnhancement = getOrCreateSourceEnhancement(
    routeId.sourceGuid,
    enhancements,
    sources
  );
  if (!environmentEnhancement || !schemaEnhancement || !sourceEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[sourceEnhancement.key] = sourceEnhancement;
  output[schemaEnhancement.key] = schemaEnhancement;
  output[environmentEnhancement.key] = environmentEnhancement;
  output[`Route|${routeIdAsString}`] = {
    key: `Route|${routeIdAsString}`,
    id: routeId
  };
  return output;
};

const viewEnhancements = (
  id: string,
  schemas: ISchemaListResponse[],
  environments: IEnvironmentResponse[],
  sources: ISourceGroupsResponse[],
  enhancements: Record<EnhancementKey, Enhancement>
): Record<EnhancementKey, Enhancement> | undefined => {
  const viewId = parseViewId(id);
  if (!viewId) {
    return undefined;
  }

  const environmentEnhancement = getOrCreateEnvironmentEnhancement(
    viewId.environmentGuid,
    enhancements,
    environments
  );
  const schemaEnhancement = getOrCreateSchemaEnhancement(
    viewId.viewHandle,
    enhancements,
    schemas
  );

  const sourceEnhancement = getOrCreateSourceEnhancement(
    viewId.sourceGuid,
    enhancements,
    sources
  );
  if (!environmentEnhancement || !schemaEnhancement || !sourceEnhancement) {
    return undefined;
  }

  const output: Record<EnhancementKey, Enhancement> = {};
  output[sourceEnhancement.key] = sourceEnhancement;
  output[schemaEnhancement.key] = schemaEnhancement;
  output[environmentEnhancement.key] = environmentEnhancement;
  output[`View|${id}`] = {
    key: `View|${id}`,
    id: viewId
  };
  return output;
};

const remap = (
  placeholder: Placeholder,
  existingEnhancements: Record<EnhancementKey, Enhancement>,
  properties: Record<string, unknown>,
  schemas: ISchemaListResponse[],
  environments: IEnvironmentResponse[],
  sources: ISourceGroupsResponse[],
  environmentClients: IEnvironmentClientResponse[],
  tenantUsers: ITenantUser[],
  domains: IDomainResponse[],
  managementClients: IManagementClientResponse[]
): Record<EnhancementKey, Enhancement> => {
  let enhancements: Record<EnhancementKey, Enhancement> | undefined = undefined;
  switch (placeholder) {
    case "SourceEntity":
    case "SourceEntityId":
      enhancements = sourceEntityEnhancements(
        properties.SourceEntityId as string,
        sources,
        existingEnhancements
      );
      break;
    case "MappingSchema":
    case "MappingSchemaId":
    case "MappingSchemaVersion":
    case "MappingSchemaVersionId":
      enhancements = mappingSchemaEnhancements(
        properties.MappingSchemaId as string,
        schemas,
        existingEnhancements
      );
      break;
    case "View":
    case "ViewId":
      enhancements = viewEnhancements(
        properties.ViewId as string,
        schemas,
        environments,
        sources,
        existingEnhancements
      );
      break;
    case "Route":
    case "RouteId":
    case "ActiveRoute":
    case "ActiveRouteId":
      enhancements = routeEnhancements(
        properties.RouteId as string,
        schemas,
        environments,
        sources,
        existingEnhancements
      );
      break;
    case "SourceGroup":
    case "SourceGroupId":
      enhancements = sourceGroupEnhancements(
        properties.SourceGroupId as string,
        sources,
        existingEnhancements
      );
      break;
    case "Domain":
    case "DomainId":
      enhancements = domainEnhancements(properties.DomainId as string, domains);
      break;
    case "Source":
    case "SourceId":
      enhancements = sourceEnhancements(
        properties.SourceId as string,
        sources,
        existingEnhancements
      );
      break;
    case "Environment":
    case "EnvironmentId":
      enhancements = environmentEnhancements(
        properties.EnvironmentId as string,
        environments,
        existingEnhancements
      );
      break;
    case "Release":
    case "ReleaseId":
      enhancements = releaseEnhancements(
        properties.ReleaseId as string,
        properties.EnvironmentId as string,
        environments,
        existingEnhancements
      );
      break;
    case "EnvironmentClient":
    case "EnvironmentClientId":
      enhancements = environmentClientEnhancements(
        properties.EnvironmentClientId as string,
        environmentClients,
        existingEnhancements
      );
      break;
    case "Actor":
    case "ActorId":
      enhancements = actorEnhancements(
        properties,
        tenantUsers,
        managementClients
      );
      break;
  }

  return {
    ...existingEnhancements,
    ...enhancements
  };
};
