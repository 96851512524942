import { Button, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { INVITES } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import useTenantSwitcher from "../../tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../tenants/store";
import { InviteService } from "../invite.service";
import { IReceivedInvitation } from "../types";

const acceptInviteFn = ({ id }: IReceivedInvitation) => {
  const inviteService = container.resolve(InviteService);
  return inviteService.acceptInvite(id);
};

export const useAcceptInvite = () => {
  const queryClient = useQueryClient();

  const toast = useToast();

  const tenantSwitcher = useTenantSwitcher();

  const { availableTenants } = useTenantStore();

  return useMutation({
    mutationFn: acceptInviteFn,
    onSuccess: async (_, { tenant: { id: tenantId } }) => {
      await queryClient.invalidateQueries({ queryKey: [INVITES] });
      if (availableTenants.length) {
        const tenant = availableTenants.find((t) => t.id.idValue === tenantId);
        if (tenant) {
          toast({
            title: (
              <>
                <Text>Tenant</Text>
                <Text as="b">{tenant.name}</Text>
                <Text> is now available`</Text>
              </>
            ),
            description: (
              <Button
                onClick={() =>
                  tenantSwitcher.switch(tenant, { redirectToHome: true })
                }
              >
                Switch to tenant
              </Button>
            ),
            status: "success",
            ...defaultToast
          });
        }
      }
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error accepting invitation",
        status: "error",
        ...defaultToast
      });
    }
  });
};
