import {
  Badge,
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import { parseMappingSchemaId } from "../../../helpers/ids";
import { useSchemas } from "../api/getSchemas";
import { IFailedToCreateReleaseModal } from "../types";

const FailedToCreateReleaseModal = ({
  response,
  isOpen,
  onClose
}: IFailedToCreateReleaseModal) => {
  const { data: schemas } = useSchemas();
  const initialRef = useRef(null);

  const schemaLink = (schemaId: string) => {
    const gid = parseMappingSchemaId(schemaId);
    if (!gid) {
      <Text>{schemaId}</Text>;
    }

    const schema = schemas?.find((f) => f.id.idValue === schemaId);
    return (
      <Link
        as={RouterLink}
        color="brand.900"
        to={`/schemas/${gid?.mappingSchemaGuid ?? ""}`}
      >
        <Badge
          variant="outline"
          fontSize="x-small"
          fontWeight="normal"
          colorScheme="gray"
          borderRadius="md"
          mr="1.5"
        >
          schema
        </Badge>

        <Button variant="link">{schema ? schema.name : schemaId}</Button>
      </Link>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        size="4xl"
      >
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>{"Failed to create a release"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack divider={<StackDivider />}>
                {(response?.errors ?? []).map((schema, i) => {
                  return (
                    <Box mb="2" key={`error-${i}`}>
                      {schemaLink(schema.name)}
                      <Box fontSize="sm">
                        {Object.keys(schema.errors).length > 0 &&
                          Object.entries(schema.errors).map((m) => {
                            return (
                              <Box key={`issue-${m[0]}`}>
                                <Text as="span" fontWeight="500">
                                  {m[0]}
                                </Text>
                                {m[0] ? ": " : ""}
                                {m[1].join(", ")}
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
              <Flex pt={8} justifyContent="flex-end">
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  mr={3}
                  onClick={onClose}
                >
                  Close
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default FailedToCreateReleaseModal;
