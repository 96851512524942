"use client";

import {
  Flex,
  Icon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISchemaValidationErrorsBottomBarButtonProps } from "../types";

const SchemaValidationErrorsBottomBarButton = ({
  errors
}: ISchemaValidationErrorsBottomBarButtonProps) => {
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <Popover trigger="hover" arrowShadowColor="red.300">
      <PopoverTrigger>
        <Flex
          px="2"
          fontSize="xs"
          alignItems="center"
          bg="red.300"
          height="24px"
        >
          <Icon as={FontAwesomeIcon} icon={faCircleXmark} mr={1} />
          <Text cursor="default">
            {errors.length} error
            {errors.length !== 1 && "s"}
          </Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent bg="red.300" borderColor="red.300" fontSize="sm">
        <PopoverArrow bgColor="red.300" />
        <PopoverHeader fontWeight="bold" borderColor="black">
          Fix following error
          {errors.length !== 1 && "s"}
        </PopoverHeader>
        <PopoverBody>
          <UnorderedList>
            {errors.map((error, index) => (
              <ListItem key={index}>{`${error.description} ${
                error.location?.start
                  ? `at line ${error.location.start.line}`
                  : ""
              }`}</ListItem>
            ))}
          </UnorderedList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SchemaValidationErrorsBottomBarButton;
