import qs from "qs";
import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IPaginated } from "../../types/api";
import { IUserSearchPayload, IUserSearchResponse } from "./types";

@singleton()
export class UserService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async getUsers(payload: IUserSearchPayload, signal?: AbortSignal) {
    const { data } = await this.managementClient.get<
      IPaginated<IUserSearchResponse>
    >(`/api/v1/admin/users`, {
      params: {
        term: payload.term,
        first: payload.first,
        after: payload.after
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      signal
    });
    return data;
  }
}
