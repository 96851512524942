import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import {
  IEnvironmentClientCreatePayload,
  IEnvironmentClientEditPayload,
  IEnvironmentClientResponse,
  IEnvironmentClientResponseId
} from "./types";

@singleton()
export class EnvironmentClientService {
  public constructor(private readonly managementClient: ManagementClient) {}

  public async getAllEnvironmentClient() {
    const { data } = await this.managementClient.get<
      IEnvironmentClientResponse[]
    >("/api/v1/tenant/environment-clients");
    return data;
  }

  public async createEnvironmentClient(
    environmentId: string,
    payload: IEnvironmentClientCreatePayload
  ) {
    const { data } =
      await this.managementClient.post<IEnvironmentClientResponseId>(
        `/api/v1/tenant/environments/${environmentId}/clients/`,
        payload
      );
    return data;
  }

  public async deleteEnvironmentClient(
    environmentId: string,
    environmentClientId: string
  ) {
    const { data } = await this.managementClient.delete(
      `/api/v1/tenant/environments/${environmentId}/clients/${environmentClientId}`
    );
    return data;
  }

  public async editEnvironmentClient(
    environmentId: string,
    environmentClientId: string,
    payload: IEnvironmentClientEditPayload
  ) {
    const { data } =
      await this.managementClient.put<IEnvironmentClientResponseId>(
        `/api/v1/tenant/environments/${environmentId}/clients/${environmentClientId}`,
        payload
      );
    return data;
  }
}
