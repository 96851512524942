export interface IIdDisplayFormat {
  format(id: string): string;
  copyable: boolean;
}

export const fullIdValueDisplayFormat: IIdDisplayFormat = {
  format: function (id: string): string {
    return id;
  },
  copyable: true
};

export const simpleIdValueDisplayFormat: IIdDisplayFormat = {
  format: function (id: string): string {
    return `${id.substring(id.lastIndexOf("/") + 1).substring(0, 9)}..`;
  },
  copyable: false
};

export const environmentClientIdDisplayFormat: IIdDisplayFormat = {
  format: function (id: string): string {
    const regex =
      /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Client\/(?<EnvironmentClientGuid>[a-fA-F0-9-]+)$/;
    const environmentGuid = id.match(regex)?.groups?.EnvironmentGuid;
    if (!environmentGuid) {
      return simpleIdValueDisplayFormat.format(id);
    }
    return `${environmentGuid.substring(0, 9)}..`;
  },
  copyable: false
};
