import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";

@singleton()
export class DatasetsService {
  public static readonly defaultDatasetName = "demodatajs";

  public constructor(private readonly http: ManagementClient) {}

  public async importJsDemoDataset() {
    const { data } = await this.http.post("/api/v1/datasets/import", {
      name: DatasetsService.defaultDatasetName
    });
    return data;
  }
}
