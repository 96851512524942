import { Helmet } from "react-helmet-async";
import { isDevEnv } from "../helpers/env";

const MetaTitle = ({
  title,
  description
}: {
  title?: string;
  description?: string;
}) => {
  let metaTitle = "";
  metaTitle += isDevEnv() ? "[DEV] " : "";
  metaTitle += title ? `${title} - ` : "";
  metaTitle += "Enterspeed";

  const path = isDevEnv() ? "/favicon/dev/" : "/favicon/";

  return (
    <Helmet>
      <title>{`${metaTitle}`}</title>
      {description && <meta name="description" content={description} />}
      <link
        rel="icon"
        type="img/png"
        sizes="32x32"
        href={`${path}favicon-32x32.png`}
      ></link>
      <link
        rel="icon"
        type="img/png"
        sizes="16x16"
        href={`${path}favicon-16x16.png`}
      ></link>
      <link rel="icon" type="image/svg+xml" href={`${path}favicon.svg`}></link>
      <link
        rel="apple"
        sizes="180x180"
        href={`${path}apple-touch-icon.png`}
      ></link>
      <link rel="manifest" href={`${path}site.webmanifest`}></link>
    </Helmet>
  );
};

export default MetaTitle;
