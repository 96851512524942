import { createColumnHelper } from "@tanstack/react-table";

import StringListPopover from "../../../components/StringListPopover";
import { DataTable } from "../../../components/table/DataTable";
import { ISelectedRows } from "../../../types/dataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { useDomains } from "../../domains/api/getDomains";
import { IDomain } from "../../domains/types";

const EnvironmentClientDomainsTable = ({
  envClientDomains,
  tableCallback
}: {
  envClientDomains?: IDomain[];
  tableCallback: (ids: string[]) => void;
}) => {
  const columnHelper = createColumnHelper<IDomain>();

  const envClientDomainsIds = envClientDomains
    ? envClientDomains.map((item) => item.id)
    : [];

  const { data, isLoading } = useDomains();

  const tableData = data
    ? data?.map((item) => ({
        name: item.name,
        hostnames: item.hostnames,
        domainGuid: item.id.domainGuid,
        id: item.id.idValue
      }))
    : [];

  const columns = [
    columnHelper.accessor("id", {
      cell: (info) => info.getValue(),
      enableHiding: true
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("hostnames", {
      cell: (info) => <StringListPopover list={info.getValue()} />,
      header: "Hostnames"
    })
  ];
  const handleSelectedRowsCallback = (payload: ISelectedRows<IDomain>) => {
    const ids = payload.rows.map((row) => row.id);
    tableCallback(ids);
  };

  const preselectedRowSelection = envClientDomainsIds.reduce(
    (acc, id) => ({ ...acc, [id]: true }),
    {}
  );

  return (
    <DataTable
      columns={columns}
      loading={isLoading}
      data={tableData}
      enableRowSelection
      enableRowSelectionAll
      preselectedRowSelection={preselectedRowSelection}
      selectedRowsCallback={handleSelectedRowsCallback}
      emptyState={EmptyStateType.ENVIRONMENT_CLIENTS_NO_DOMAINS}
    />
  );
};

export default EnvironmentClientDomainsTable;
