import { Button, Text, ToastId, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import ReloadButton from "../../components/ReloadButton";
import { useInvites } from "../../features/account/api/getInvites";
import ReceivedInvitationsTable from "../../features/account/components/ReceivedInvitationsTable";
import { useAuthStore } from "../../features/auth/store";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../features/tenants/store";

const InvitesPage = () => {
  const title = "Received invitations";
  const navigate = useNavigate();

  const toastRef = useRef<ToastId>();

  const { data, isLoading, refetch, isRefetching } = useInvites();

  const { availableTenants } = useTenantStore();
  const { addTenant } = useAuthStore();

  const [tenantId, setTenantId] = useState<string | null>(null);

  const toast = useToast();

  const tenantSwitcher = useTenantSwitcher();

  useEffect(() => {
    if (!tenantId) {
      return;
    }
    addTenant(tenantId);
  }, [tenantId, addTenant]);

  useEffect(() => {
    if (tenantId && availableTenants.length === 0) {
      navigate("/");
    }
    if (availableTenants.length) {
      const tenant = availableTenants.find((t) => t.id.idValue === tenantId);

      if (tenant && toastRef) {
        toastRef.current = toast({
          title: "You have been added to tenant",
          description: (
            <Button
              onClick={() => {
                tenantSwitcher.switch(tenant);
                if (toastRef.current) {
                  toast.close(toastRef.current);
                }
              }}
            >
              {`Switch to `} <Text as="b">{tenant.name}</Text>
            </Button>
          )
        });
      }
    }
  }, [tenantId, availableTenants, tenantSwitcher, toast, navigate]);

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Invitations you have received to join other tenants."
      >
        <ReloadButton loading={isRefetching} onClick={refetch} />

        <ReceivedInvitationsTable
          data={data}
          isLoading={isLoading}
          acceptedTenantInvite={setTenantId}
        />
      </ContentBox>
    </>
  );
};

export default InvitesPage;
