import { Button, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { useState } from "react";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import TenantInviteUserModal from "../../features/tenants/components/TenantInviteUserModal";
import TenantSentInvitationsTable from "../../features/tenants/components/TenantSentInvitationsTable";
import TenantUsersTable from "../../features/tenants/components/TenantUsersTable";
import useAdminFeatures from "../../helpers/useAdminFeatures";

const UsersPage = () => {
  const title = "Users";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addExistingUser, setAddExistingUser] = useState<boolean>(false);
  const adminFeaturesEnabled = useAdminFeatures();
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <VStack spacing="6" w={"100%"}>
        <ContentBox
          title={title}
          help="Users connected to your tenant."
          headerRight={
            <HStack>
              {adminFeaturesEnabled && (
                <Button
                  variant="subtle"
                  onClick={() => {
                    setAddExistingUser(true);
                    onOpen();
                  }}
                >
                  Add existing user
                </Button>
              )}
              <Button
                variant="subtle"
                onClick={() => {
                  setAddExistingUser(false);
                  onOpen();
                }}
              >
                Invite user
              </Button>
            </HStack>
          }
        >
          <TenantInviteUserModal
            isOpen={isOpen}
            onClose={onClose}
            addExistingUser={addExistingUser}
          />
          <TenantUsersTable />
        </ContentBox>
        <ContentBox
          title="Sent invitations"
          help="Invitations you have sent for people to join your tenant."
        >
          <TenantSentInvitationsTable />
        </ContentBox>
      </VStack>
    </>
  );
};

export default UsersPage;
