import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Outlet } from "react-router-dom";

const DestinationsRoute = () => {
  const { destinations } = useFlags();

  if (!destinations) {
    return <Navigate to={"/"}></Navigate>;
  }
  return <Outlet></Outlet>;
};

export default DestinationsRoute;
