import { create } from "zustand";
import useMixPanel from "./mixpanel/useMixPanel";

interface HomeStore {
  removedTiles: string[];
  removeTile: (tile: string) => void;
  showTile: (tile: string) => void;
}

const useHomeStore = () => {
  const storageKey = "home-storage";
  const mixpanel = useMixPanel();
  const stored = localStorage.getItem(storageKey);
  const initialState = stored
    ? (JSON.parse(stored) as HomeStore)
    : {
        removedTiles: [],
        removeTile: () => {
          //
        },
        showTile: () => {
          //
        }
      };

  return create<HomeStore>((set) => ({
    ...initialState,
    removeTile: (tile) => {
      mixpanel.track(`Remove ${tile} tile`);
      set((state) => {
        const newState = {
          ...state,
          removedTiles: [...state.removedTiles, tile]
        };
        localStorage.setItem(storageKey, JSON.stringify(newState));
        return newState;
      });
    },
    showTile: (tile) => {
      mixpanel.track(`show ${tile} tile`);
      set((state) => {
        const newState = {
          ...state,
          removedTiles: state.removedTiles.filter((item) => item !== tile)
        };
        localStorage.setItem(storageKey, JSON.stringify(newState));
        return newState;
      });
    }
  }));
};

export default useHomeStore;
