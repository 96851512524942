import { IEnvironmentResponse } from "../features/environments/types";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";
import { ValueType } from "./valueType";

export const getEnvironmentOptions = (
  environments: IEnvironmentResponse[] | undefined,
  valueType?: ValueType | undefined | null
): IEnterspeedIdentifiableSelectOption[] => {
  if (!environments || environments.length === 0) {
    return [];
  }

  return environments
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((env) => {
      return {
        id: env.id.idValue,
        label: env.name,
        value: valueType === "FULL" ? env.id.idValue : env.id.environmentGuid
      } as IEnterspeedIdentifiableSelectOption;
    });
};

export const getEnvironmentOption = (
  environments: IEnvironmentResponse[] | undefined,
  identifierValue: string,
  valueType?: ValueType | undefined | null
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!environments || environments.length === 0) {
    return undefined;
  }

  const environment = environments.find((s) => {
    if (valueType === "FULL") {
      return s.id.idValue === identifierValue;
    }

    return s.id.environmentGuid === identifierValue;
  });
  return environment
    ? {
        id: environment.id.idValue,
        label: environment.name,
        value:
          valueType === "FULL"
            ? environment.id.idValue
            : environment.id.environmentGuid
      }
    : undefined;
};
