import { ReactNode } from "react";
import { useShallow } from "zustand/react/shallow";
import { SourceEntitiesMetricsDto } from "../../features/metrics/metrics.service";
import { useTenantStore } from "../../features/tenants/store";
import { ITenant } from "../../features/tenants/types";
import MetricsPlot from "./MetricsPlot";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";
import MetricsToolTip from "./MetricsToolTip";
import useGaugeData from "./useGaugeData";

const StatsSourceEntitiesTile = ({
  sourceEntitiesMetrics,
  isLoading,
  tenant,
  removeTileMenu
}: {
  sourceEntitiesMetrics?: SourceEntitiesMetricsDto;
  isLoading?: boolean;
  tenant?: ITenant;
  removeTileMenu?: ReactNode;
}) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );

  const usedTenant = tenant ?? activeTenant;

  const usage = sourceEntitiesMetrics?.totalCount ?? 0;
  const limit = usedTenant.plan?.sourceEntitiesCountLimit;

  const data = useGaugeData(
    { usage, limit },
    {
      valueFormat: ".2~s",
      exponentFormat: "B"
    }
  );

  const help = `The current total number of source entities ${
    limit ? "and the limit based on the tenant's plan" : ""
  } (updated every ~1 minute)`;

  const tooltipMetrics = [{ label: "Usage", value: usage }];

  if (limit) {
    tooltipMetrics.push({ label: "Limit", value: limit });
  }

  return (
    <MetricsTile
      title="Source entities"
      help={help}
      removeTileMenu={removeTileMenu}
    >
      <MetricsTileBody
        tooltipBody={
          <MetricsToolTip
            title="Total count of source entities"
            metrics={tooltipMetrics}
          ></MetricsToolTip>
        }
      >
        <MetricsPlot data={data} isLoading={isLoading}></MetricsPlot>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default StatsSourceEntitiesTile;
