import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { MANAGEMENT_CLIENTS } from "../../../constants/queryKey";
import { ManagementClientService } from "../management-client.service";

export const getManagementClients = async () => {
  const managementClientService = container.resolve(ManagementClientService);
  return managementClientService.getAllManagementClient();
};

export const useManagementClients = () => {
  return useQuery({
    queryKey: [MANAGEMENT_CLIENTS],
    queryFn: getManagementClients
  });
};
