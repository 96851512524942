import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const deleteDestinationEnvironmentConfigurationFn = (dto: {
  alias: string;
  environmentId: string;
}) => {
  const destinationService = container.resolve(DestinationsService);

  return destinationService.deleteEnvironmentConfigurationToDestinationConfiguration(
    dto
  );
};

const useDeleteDestinationEnvironmentConfiguration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDestinationEnvironmentConfigurationFn,
    onSuccess: (_data, { alias }) =>
      queryClient.invalidateQueries({ queryKey: [DESTINATIONS, alias] })
  });
};

export default useDeleteDestinationEnvironmentConfiguration;
