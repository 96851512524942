import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Code,
  Flex,
  Icon,
  StackDivider,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { faTerminal } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ConsoleEntryType,
  ISchemaDryRunConsoleEntry,
  ISchemaDryRunResultDto
} from "../types";

const TestConsoleEntries = ({
  testResult
}: {
  testResult: ISchemaDryRunResultDto;
}) => {
  const dividerBorderColor = useColorModeValue("gray.100", "gray.700");
  const warningBackgroundColor = useColorModeValue("yellow.100", "yellow.900");
  const errorBackgroundColor = useColorModeValue("red.100", "red.900");

  const getBackgroundColorByType = (type: ConsoleEntryType) => {
    switch (type) {
      case "warn":
        return warningBackgroundColor;
      case "error":
        return errorBackgroundColor;
      default:
        return undefined;
    }
  };

  const outputEntryLocation = (entry: ISchemaDryRunConsoleEntry) => {
    const parts = [entry.fileName];
    if (entry.lineNumber) {
      parts.push(entry.lineNumber?.toString());
    }

    return <Text as="span">{parts.join(":")}</Text>;
  };

  const outputEntryArguments = (entry: ISchemaDryRunConsoleEntry) => {
    const displayArgs = entry.args.map((arg) => {
      if (typeof arg === "object") {
        return (
          <>
            <Code
              display={"inline"}
              colorScheme="transparent"
              children={JSON.stringify(arg)}
            ></Code>{" "}
          </>
        );
      }

      return (
        <>
          <Text as="span">{arg?.toString()}</Text>{" "}
        </>
      );
    });

    return <>{displayArgs}</>;
  };

  return (
    <>
      {testResult.console && testResult.console.length > 0 && (
        <>
          <Accordion
            defaultIndex={0}
            allowToggle
            width="100%"
            fontSize="sm"
            mb={"15px"}
          >
            <AccordionItem>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Icon mr="10px" as={FontAwesomeIcon} icon={faTerminal} />
                  <Text as="span" mr="4px" fontWeight="semibold">
                    Console output
                  </Text>
                  ({testResult.console.length} messages)
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <VStack
                  divider={<StackDivider borderColor={dividerBorderColor} />}
                  maxHeight={"250px"}
                  overflowY={"auto"}
                  spacing={"1px"}
                >
                  {testResult.console?.map((entry, i) => {
                    return (
                      <Flex
                        rounded="md"
                        justifyContent={"space-between"}
                        px="1"
                        py="0.75"
                        fontSize={"sm"}
                        width="100%"
                        key={`console-entry-${i}`}
                        backgroundColor={getBackgroundColorByType(entry.type)}
                      >
                        <Box>{outputEntryArguments(entry)}</Box>
                        <Box>{outputEntryLocation(entry)}</Box>
                      </Flex>
                    );
                  })}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </>
  );
};
export default TestConsoleEntries;
