import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const destinationQueryFn = (continuationToken?: string) => {
  const destinationsService = container.resolve(DestinationsService);
  return destinationsService.getConfiguredDestinations(continuationToken);
};

const useConfiguredDestinations = () =>
  useInfiniteQuery({
    queryFn: ({ pageParam }: { pageParam?: string }) =>
      destinationQueryFn(pageParam),
    queryKey: [DESTINATIONS],
    getNextPageParam: (lastPage) => lastPage.continuationToken,
    initialPageParam: undefined
  });

export default useConfiguredDestinations;
