import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { SOURCE_GROUPS } from "../../../constants/queryKey";
import { useTenantStore } from "../../tenants/store";
import { SourceGroupService } from "../source-groups.service";

const getSourceGroupsFn = () => {
  const sourceGroupService = container.resolve(SourceGroupService);
  return sourceGroupService.getSourceGroups();
};

export const useSourceGroups = () => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({
      activeTenant
    }))
  );

  return useQuery({
    queryKey: [SOURCE_GROUPS],
    queryFn: getSourceGroupsFn,
    enabled: !!activeTenant.id
  });
};
