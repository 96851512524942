import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { BulkDeploy } from "../types/BulkDeploy";
import { BulkDeployModal } from "./BulkDeployModal";

export const BulkDeployPanelFooter = ({
  cancel,
  deployConfig,
  environmentGuid,
  onSubmit
}: {
  cancel: () => void;
  deployConfig: BulkDeploy[];
  environmentGuid: string;
  onSubmit: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex pt={8} justifyContent="flex-end">
        <Button
          colorScheme="gray"
          mr={3}
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onOpen}
          isDisabled={deployConfig.length === 0}
        >
          {deployConfig.length
            ? `Deploy ${deployConfig.length} changes`
            : `Deploy`}
        </Button>
        <BulkDeployModal
          deployConfig={deployConfig}
          environmentGuid={environmentGuid}
          onClose={onClose}
          isOpen={isOpen}
          onSubmit={onSubmit}
        ></BulkDeployModal>
      </Flex>
    </>
  );
};
