import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import Pagination from "../../components/Pagination";
import { USERS } from "../../constants/queryKey";
import { useUsers } from "../../features/users/api/getUsers";
import { UserList } from "../../features/users/components/UserList";
import { IUserTableItem } from "../../features/users/types";

const UserSearchPage = () => {
  const title = "Search for users";
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tableData, setTableData] = useState<IUserTableItem[]>([]);

  const queryClient = useQueryClient();

  const { fetchNextPage, data, isLoading, isFetchingNextPage } = useUsers({
    term: searchTerm,
    first: 5
  });

  const total = data?.pages[0].total ?? 0;

  useEffect(() => {
    if (data?.pages) {
      const newTableData = data?.pages.flatMap((pages) =>
        pages.results.map(
          ({ firstName, lastName, emails, tenants, id, tenantsMap }) =>
            ({
              name: `${firstName} ${lastName}`,
              email: emails.join(", "),
              tenantCount: Object.keys(tenants).length,
              id: id.idValue,
              tenantsMap
            } as IUserTableItem)
        )
      );
      setTableData(newTableData);
    }
  }, [data]);

  const handleSearch = useDebouncedCallback(async (term: string) => {
    await queryClient.cancelQueries({ queryKey: [USERS, searchTerm] });
    return setSearchTerm(term);
  }, 300);

  const handleLoadMore = async () => {
    const currentPage = data?.pages[data.pages.length - 1];

    if (currentPage) {
      await fetchNextPage();
    }
  };

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox title={title}>
        <Flex>
          <FormControl mr="4">
            <FormLabel color="gray.500" fontSize="xs">
              Search for users
            </FormLabel>
            <Input
              placeholder="Search by name, email or id (Nb. search is case-sensitive)"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </FormControl>
        </Flex>
        <UserList users={tableData} isLoading={isLoading} />
        {tableData.length > 0 && (
          <Pagination
            disabled={
              tableData.length === total || isLoading || isFetchingNextPage
            }
            current={tableData.length}
            total={total}
            loadMore={handleLoadMore}
            loading={isFetchingNextPage}
          />
        )}
      </ContentBox>
    </>
  );
};

export default UserSearchPage;
