import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import {
  SchemaActionModelSubmissionRequest,
  SchemaActionModelSubmissionResult,
  SchemaListModalType
} from "../types";
import CreateSchemaModal from "./CreateSchemaModal";
import DuplicateSchemaModal from "./DuplicateSchemaModal";

const ActionModal = ({
  type,
  id,
  name,
  isOpen,
  onClose,
  onSubmit,
  onSuccess
}: {
  type: SchemaListModalType;
  id: string;
  name: string;
  isOpen: boolean;
  onClose: (type: SchemaListModalType, id?: string, name?: string) => void;
  onSubmit: (
    request: SchemaActionModelSubmissionRequest
  ) => Promise<SchemaActionModelSubmissionResult>;
  onSuccess: (type: SchemaListModalType, id?: string, name?: string) => void;
}) => {
  switch (type) {
    case SchemaListModalType.CREATE:
      return (
        <CreateSchemaModal
          name={name}
          isOpen={isOpen}
          onSubmit={(request) =>
            onSubmit({
              modalType: SchemaListModalType.CREATE,
              ...request
            })
          }
          onSuccess={() => onSuccess(type, id, name)}
          onClose={() => onSuccess(type, id, name)}
        />
      );
    case SchemaListModalType.DELETE:
      return (
        <DeleteModal
          type="schema"
          name={name}
          isOpen={isOpen}
          onClose={() => onClose(type, id, name)}
          onSubmit={() =>
            onSubmit({
              modalType: SchemaListModalType.DELETE,
              schemaGuid: id
            })
          }
          onSuccess={() => onSuccess(type, id, name)}
        />
      );
    case SchemaListModalType.EDIT:
      return (
        <EditNameModal
          type="schema"
          name={name}
          isOpen={isOpen}
          onClose={() => onClose(type, id, name)}
          onSubmit={(e) =>
            onSubmit({
              modalType: SchemaListModalType.EDIT,
              schemaGuid: id,
              name: e.name
            })
          }
          onSuccess={() => onSuccess(type, id, name)}
        />
      );
    case SchemaListModalType.DUPLICATE:
      return (
        <DuplicateSchemaModal
          sourceSchemaName={name}
          isOpen={isOpen}
          onClose={() => onClose(type, id, name)}
          onSubmit={(request) =>
            onSubmit({
              modalType: SchemaListModalType.DUPLICATE,
              schemaGuid: id,
              ...request
            })
          }
          onSuccess={() => onSuccess(type, id, name)}
        />
      );
    default:
      return null;
  }
};

export default ActionModal;
