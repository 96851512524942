import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IInspectRoutePayload, IInspectRouteResponse } from "./types";

@singleton()
export class RouteService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async getInspectedRouted(
    payload: IInspectRoutePayload,
    continuationToken?: string
  ) {
    const { data, headers } =
      await this.managementClient.post<IInspectRouteResponse>(
        "/api/v1/tenant/routes/_inspect",
        payload,
        {
          headers: {
            "x-continuation-token": continuationToken
          }
        }
      );

    return {
      routes: data,
      continuationToken: headers["x-continuation-token"] as string
    };
  }
}
