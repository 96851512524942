import { Flex, Text } from "@chakra-ui/react";
import numeral from "numeral";

const MetricsToolTip = ({
  title,
  metrics
}: {
  title?: string;
  metrics: {
    label: string;
    value: number;
    format?: string;
    suffix?: string;
  }[];
}) => {
  return (
    <Flex direction={"column"}>
      <Text fontSize={"1em"} pb={"0.5em"}>
        {title}
      </Text>
      <Flex
        borderTop={"1px"}
        borderColor={"gray.300"}
        marginTop={"5px"}
        paddingTop={"5px"}
        display={"flex"}
        flexDirection={"column"}
        gap={1.5}
      >
        {metrics.map(({ label, value, format, suffix }) => (
          <Flex key={label}>
            <Flex justify={"space-between"} grow={1}>
              <Text fontWeight={500} fontSize={"s"}>
                {label}:
              </Text>
              <Text
                justifyContent={"flex-end"}
                fontWeight={400}
                fontSize={"s"}
                opacity={0.8}
              >{` ${numeral(value).format(format ?? "0,0")}${
                suffix ?? ""
              }`}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default MetricsToolTip;
