import { useShallow } from "zustand/react/shallow";
import { useAuthStore } from "../features/auth/store";

const useAdminFeatures = () => {
  const [adminFeaturesEnabled] = useAuthStore(
    useShallow(({ adminFeaturesEnabled }) => [adminFeaturesEnabled])
  );
  return adminFeaturesEnabled;
};

export default useAdminFeatures;
