import { Box, Flex, Tooltip } from "@chakra-ui/react";
import {
  fullIdValueDisplayFormat,
  IIdDisplayFormat
} from "../../helpers/idFormatters";
import useAdminFeatures from "../../helpers/useAdminFeatures";
import CopyableText from "./CopyableText";

const AdminTableRowId = ({
  id,
  formatter
}: {
  id: string;
  formatter?: IIdDisplayFormat;
}) => {
  const adminFeaturesEnabled = useAdminFeatures();

  if (!id) {
    return null;
  }

  const displayIdFormatter = formatter ?? fullIdValueDisplayFormat;
  const displayId = displayIdFormatter.format(id);

  return adminFeaturesEnabled ? (
    <Flex alignItems="center">
      {!displayIdFormatter.copyable ? (
        <Tooltip closeOnClick={false} label={id}>
          <Box ml="1" fontSize="xs" color="gray.500" fontStyle="italic">
            {displayId}
          </Box>
        </Tooltip>
      ) : (
        <CopyableText id={id} />
      )}
    </Flex>
  ) : (
    <></>
  );
};

export default AdminTableRowId;
