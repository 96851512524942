import { ReactNode } from "react";
import ISelectOption from "../../../types/selectInput";
import { IEnvironmentBase, IEnvironmentId } from "../../environments/types";
import { ITenantId } from "../../tenants/types";

export interface ISourceGroup {
  name: string;
  alias: string;
  numberOfSources: number;
  type: string;
  marginBottom?: number;
  children: ReactNode;
  gid: string;
  openModalCallback: (arg0: SourceGroupModalType) => void;
}

export enum SourceGroupModalType {
  DELETE_SOURCE_GROUP = "Delete source group",
  EDIT_SOURCE_GROUP = "Edit source group"
}

export enum DataSourceModalType {
  DELETE_ALL_ENTITIES = "Delete all entities",
  REGENERATE_API_KEY = "Regenerate API key"
}

export interface IDataSource {
  name: string;
  numberOfSourceEntities: number;
  environments: IEnvironmentBase[];
  type: string;
  accessKey: string;
  sourceId: ISourceId;
}

export interface ISourceGroupsCreateWithSources {
  group: ISourceGroupsCreatePayload;
  sources: ISourceBase[];
}

export interface ISourceGroupsEditWithSources {
  id: ISourceGroupId;
  group: ISourceGroupsCreatePayload;
  sources: ISourceBase[];
}

export interface ISourceGroupsCreatePayload {
  name: string;
  alias: string;
  type?: string;
}

export interface ISourceGroupsResponse {
  alias: string;
  id: ISourceGroupId;
  name: string;
  sources: ISourceInSourceGroup[];
  tenantId: ITenantId;
  type: string;
}

export interface ISourceInSourceGroup {
  entitiesInSource: number;
  environments: IEnvironmentBase[];
  source: ISource;
}

export interface ISourceGroupId {
  idValue: string;
  sourceGroupGuid: string;
}

export interface ISourceId {
  idValue: string;
  sourceGuid: string;
}
export interface ISource {
  accessKey: string;
  environments: IEnvironmentId[];
  id: ISourceId;
  name: string;
  sourceGroupId: ISourceGroupId;
  tenantId: ITenantId;
  type: string;
}

export interface ISourceItem {
  name: string;
  id: string;
  type: string;
  environments: ISelectOption[];
}

export interface ISourceBase {
  name: string;
  id?: string;
  type?: string;
  environmentIds?: string[];
}

export interface ISourceCreatePayload extends ISourceBase {
  sourceGroupId?: string;
}

export interface ISourceEditPayload extends ISourceCreatePayload {
  regenerateAccessKey?: boolean;
}
