import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import {
  getSchemaAliasOptions,
  getSchemaOptionById
} from "../../../helpers/getSchemaAliasOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { ISchemaListResponse } from "../../schemas/types";

const SingleValueConditionSchemaPicker = ({
  onSelect,
  value,
  schemas
}: {
  value: string | null;
  onSelect(value: string | null | undefined): void;
  schemas: ISchemaListResponse[] | undefined;
}) => {
  const schemaOptions = getSchemaAliasOptions(schemas, "FULL");
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const [selectedSchema, setSelectedSchema] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  useEffect(() => {
    if (!schemas) {
      return;
    }

    const dataSchemaOption = value
      ? getSchemaOptionById(schemas, value, "FULL")
      : undefined;
    setSelectedSchema(dataSchemaOption);
  }, [schemas, value]);

  const handleSchemaFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    setSelectedSchema(value);
    onSelect(value?.value as string | null);
  };

  return (
    <Select<
      IEnterspeedIdentifiableSelectOption,
      true,
      GroupBase<IEnterspeedIdentifiableSelectOption>
    >
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select schema"
      options={schemaOptions}
      components={new EnterspeedIdentifiableSelect()}
      value={selectedSchema}
      onChange={(value) => {
        return handleSchemaFilter(
          value as unknown as IEnterspeedIdentifiableSelectOption
        );
      }}
    />
  );
};

export default SingleValueConditionSchemaPicker;
