import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SCHEMAS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";
import { ISchemaDuplicatePayload } from "../types";

export const duplicateSchema = async ({
  payload
}: {
  payload: ISchemaDuplicatePayload;
}) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.duplicateSchema(payload);
};

export const useDuplicateSchema = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: duplicateSchema,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SCHEMAS] });
      toast({
        title: "Schema duplicated successfully",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      const errors = Object.values(response?.data["errors"] ?? {});
      toast({
        title: response?.data.detail ?? "Error duplicating schema",
        description: errors.length > 0 ? (errors[0] as string) : undefined,
        status: "error",
        ...defaultToast
      });
    }
  });
};
