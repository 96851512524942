import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { ScreenDetails, WindowWithScreenApi } from "./types";

export const useScreens = () => {
  const flags = useFlags();
  const { options } = flags.fullscreen as {
    enabled: boolean;
    options: string[];
  };

  const [screens, setScreens] = useState<ScreenDetails | null>(null);

  useEffect(() => {
    const getScreens = async () => {
      if (!options?.includes("screens")) {
        return;
      }

      const screenDetails = await (
        window as WindowWithScreenApi
      )?.getScreenDetails();
      return setScreens(screenDetails);
    };
    getScreens().catch(console.error);
  }, [options]);

  return screens;
};
