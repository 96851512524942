import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { ITenantInvite } from "../tenants/types";
import { IInviteId } from "./types";

@singleton()
export class InviteService {
  public constructor(private readonly http: ManagementClient) {}

  public async acceptInvite(id: string) {
    const { data } = await this.http.put<IInviteId>(
      `/api/v1/invites/${id}/_accept`
    );
    return data;
  }

  public async declineInvite(id: string) {
    const { data } = await this.http.put<IInviteId>(
      `/api/v1/invites/${id}/_decline`
    );
    return data;
  }

  public async getInvites() {
    const { data } = await this.http.get<ITenantInvite[]>(
      "/api/v1/invites/pending"
    );
    return data;
  }
}
