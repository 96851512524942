import { Box, VStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import CreateDomainModal from "../../features/domains/components/CreateDomainModal";
import DomainsTable from "../../features/domains/components/DomainsTable";
import CreateEnvironmentClientModal from "../../features/environment-clients/components/CreateEnvironmentClientModal";
import EnvironmentClientsTable from "../../features/environment-clients/components/EnvironmentClientsTable";
import CreateEnvironmentModal from "../../features/environments/components/CreateEnvironmentModal";
import EnvironmentsTable from "../../features/environments/components/EnvironmentsTable";
import UnconfiguredHostnames from "../../features/source-entities/components/UnconfiguredHostnames";

const EnvironmentSettingsPage = () => {
  const title = "Environments";
  const flags = useFlags();
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <VStack spacing="6" w={"100%"}>
        <ContentBox
          id="environments"
          title="Environments"
          help="Environments are an area for your application. You can set up multiple environments, for instance, a development environment and a production environment."
          headerRight={<CreateEnvironmentModal />}
        >
          <EnvironmentsTable />
        </ContentBox>
        <ContentBox
          id="environment-clients"
          title="Environment clients"
          help="You can think of an Environment client as a single site. This is where a connection is created to your frontend. An environment client can have multiple environments attached to it. There must also be at least one domain attached to your environment client."
          headerRight={<CreateEnvironmentClientModal />}
        >
          <EnvironmentClientsTable />
        </ContentBox>
        <ContentBox
          id="domains"
          title="Domains"
          help="The Domain helps to filter your data correctly in the Enterspeed Delivery API. Since you might have multiple sites configured in Enterspeed, it is important for us to know which site you want data from, when sending the request. Each Domain can have multiple hostnames attached."
          headerRight={<CreateDomainModal />}
        >
          {flags.unconfiguredHostnames && (
            <UnconfiguredHostnames hideGoToDomainsLink />
          )}
          <Box>
            <DomainsTable />
          </Box>
        </ContentBox>
      </VStack>
    </>
  );
};

export default EnvironmentSettingsPage;
