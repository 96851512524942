import {
  chakraComponents,
  GroupBase,
  Select,
  SelectComponentsConfig
} from "chakra-react-select";
import useReactSelectStyles from "../styles/react-select-style";
import ISelectOption from "../types/selectInput";

const MultiDropdown = ({
  options,
  selectedOptions,
  onChange,
  placeholder,
  closeMenuOnSelect = false,
  maxSelectedItemsToShow = 2
}: {
  options: ISelectOption[];
  selectedOptions: ISelectOption[];
  onChange: (value: ISelectOption[]) => void;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  maxSelectedItemsToShow?: number;
}) => {
  const customComponents: SelectComponentsConfig<
    unknown,
    boolean,
    GroupBase<unknown>
  > = {
    MultiValueContainer: ({ children, ...props }) => {
      const selected = props.selectProps.value as ISelectOption[];
      const overflow = selected
        .slice(maxSelectedItemsToShow)
        .map((x) => x.label);
      const index = selected.findIndex(
        (item) => item.value === props.data.value // eslint-disable-line
      );

      const style = {
        fontWeight: "bold",
        marginLeft: "3px"
      };

      const title = overflow.join(", ");
      const hiddenItems = selected.length - maxSelectedItemsToShow;
      const label = `and ${hiddenItems} more`;

      return index < maxSelectedItemsToShow ? (
        <chakraComponents.MultiValueContainer {...props}>
          {children}
        </chakraComponents.MultiValueContainer>
      ) : index === maxSelectedItemsToShow ? (
        <div style={style} title={title}>
          {label}
        </div>
      ) : null;
    }
  };
  const reactSelectStyles = useReactSelectStyles();

  return (
    <Select
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={false}
      colorScheme="blue"
      isClearable
      isMulti
      value={selectedOptions}
      onChange={(value) => {
        return onChange(value as ISelectOption[]);
      }}
      components={customComponents}
      options={options}
      placeholder={placeholder}
    />
  );
};

export default MultiDropdown;
