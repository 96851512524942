import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import {
  faClose,
  faGripVertical,
  faThList
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DragControls,
  MotionConfig,
  Reorder,
  useDragControls
} from "framer-motion";
import { useEffect, useState } from "react";
import { getDisplayName } from "../helpers/fieldDisplayNames";
import { ILogsState, LogsAction } from "../types";

const ReorderIcon = ({ dragControls }: { dragControls?: DragControls }) => {
  return (
    <Icon
      cursor={"move"}
      height={"14px"}
      as={FontAwesomeIcon}
      icon={faGripVertical}
      onPointerDown={(event) => dragControls?.start(event)}
    ></Icon>
  );
};

const ColumnItem = ({
  column,
  onDelete,
  systemColumn
}: {
  column: string;
  onDelete?(): void;
  systemColumn: boolean;
}) => {
  const dragControls = useDragControls();
  const columnItem = (
    <Flex
      id={column}
      px={2.5}
      py={1}
      backgroundColor={useColorModeValue("gray.100", "gray.900")}
      rounded="base"
      position="relative"
      width={"100%"}
    >
      <Flex gap={1} alignItems={"center"}>
        {!systemColumn && <ReorderIcon dragControls={dragControls} />}
        <Text fontSize={"sm"}>{getDisplayName(column)}</Text>
      </Flex>
      <Spacer />
      {!systemColumn && (
        <Button
          onClick={onDelete}
          aria-label="Remove column"
          variant={"ghost"}
          colorScheme="red"
          size="xs"
          px={0}
          mx={0}
          mb={0.25}
        >
          <Icon color="red" as={FontAwesomeIcon} icon={faClose}></Icon>
        </Button>
      )}
    </Flex>
  );
  return (
    <Flex
      as={Reorder.Item}
      value={column}
      id={column}
      dragListener={false}
      dragControls={dragControls}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      dragTransition={{
        bounceStiffness: 600
      }}
      whileDrag="dragging"
      position="relative"
      userSelect={"none"}
      alignItems={"center"}
    >
      {columnItem}
    </Flex>
  );
};

const SelectedColumns = ({
  logsState,
  logsDispatch
}: {
  logsState: ILogsState;
  logsDispatch: React.Dispatch<LogsAction>;
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    ...logsState.selectedColumns
  ]);

  useEffect(() => {
    setSelectedColumns([...logsState.selectedColumns]);
  }, [logsState.selectedColumns]);

  const applySelectedColumnChanges = () => {
    logsDispatch({
      type: "set_columns",
      value: selectedColumns
    });
    onClose();
  };

  const toggleColumn = (column: string) => {
    const updatedColumns = [...selectedColumns];
    const matchingIndex = updatedColumns.findIndex((f) => f === column);
    if (matchingIndex >= 0) {
      updatedColumns.splice(matchingIndex, 1);
    } else {
      updatedColumns.push(column);
    }
    setSelectedColumns(updatedColumns);
  };

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={FontAwesomeIcon} icon={faThList}></Icon>}
            variant="ghost"
          >
            Columns
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody p={4}>
            <Flex
              direction={"column"}
              gap={2}
              as={Reorder.Group}
              axis="y"
              values={selectedColumns}
              onReorder={(ev: unknown) => setSelectedColumns(ev as string[])}
            >
              <MotionConfig reducedMotion="always">
                {selectedColumns.map((column) => (
                  <ColumnItem
                    key={column}
                    column={column}
                    systemColumn={false}
                    onDelete={() => toggleColumn(column)}
                  ></ColumnItem>
                ))}
              </MotionConfig>
            </Flex>
            <ButtonGroup display="flex" justifyContent="flex-end" mt={3}>
              <Button variant="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={applySelectedColumnChanges}
              >
                Apply
              </Button>
            </ButtonGroup>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default SelectedColumns;
