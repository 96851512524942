import { IAddedSourceEntity, IAddedSourceEntityFile } from "../types";

abstract class AbstractIngestValidator {
  private next?: AbstractIngestValidator;

  setNext(next: AbstractIngestValidator): AbstractIngestValidator {
    this.next = next;
    return next;
  }
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    if (this.next) {
      return this.next.validate(sourceEntities, source);
    }
    return [true, undefined];
  }
}

export class IngestValidatorSourceNotNull extends AbstractIngestValidator {
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    if (!source) {
      return [false, "Please select a source"];
    }
    return super.validate(sourceEntities, source);
  }
}

export class IngestValidatorSourceEntitiesAdded extends AbstractIngestValidator {
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    if (!sourceEntities.length) {
      return [false, "Please add at least one entity"];
    }
    return super.validate(sourceEntities, source);
  }
}

export class IngestValidatorNoEmptyOriginIds extends AbstractIngestValidator {
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    const sourceEntitiesWithEmptyOriginId = sourceEntities.filter(
      (sourceEntity) => {
        if ("originId" in sourceEntity) {
          return !sourceEntity.originId;
        }
      }
    );

    if (sourceEntitiesWithEmptyOriginId.length) {
      return [false, "Please add an Origin ID for each entity"];
    }

    return super.validate(sourceEntities, source);
  }
}

export class IngestValidatorNoEmptyData extends AbstractIngestValidator {
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    const sourceEntitiesWithNoData = sourceEntities.filter((sourceEntity) => {
      if ("data" in sourceEntity) {
        return !sourceEntity.data;
      }
    });

    if (sourceEntitiesWithNoData.length) {
      return [false, "Please add data for each entity"];
    }

    return super.validate(sourceEntities, source);
  }
}

export class IngestValidatorValidData extends AbstractIngestValidator {
  validate(
    sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[],
    source?: string
  ): [boolean, string?] {
    const sourceEntitiesWithInvalidData = sourceEntities.filter(
      (sourceEntity) => {
        if ("data" in sourceEntity) {
          try {
            JSON.parse(sourceEntity.data);
          } catch (e) {
            return true;
          }
        }
      }
    );

    if (sourceEntitiesWithInvalidData.length === 1) {
      return [false, "Please add valid JSON for your entity"];
    }
    if (sourceEntitiesWithInvalidData.length > 1) {
      const invalidFiles = sourceEntitiesWithInvalidData.map(
        (sourceEntity) => sourceEntity.originId
      );
      return [
        false,
        `Please add valid JSON for each entity: ${invalidFiles.join(", ")}`
      ];
    }

    return super.validate(sourceEntities, source);
  }
}

export const ingestValidatorChain = new IngestValidatorSourceNotNull();
ingestValidatorChain
  .setNext(new IngestValidatorSourceEntitiesAdded())
  .setNext(new IngestValidatorNoEmptyOriginIds())
  .setNext(new IngestValidatorNoEmptyData())
  .setNext(new IngestValidatorValidData());
