import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENTS } from "../../../constants/queryKey";
import { EnvironmentService } from "../environment.service";

export const deleteEnvironment = (environmentId: string) => {
  const environmentService = container.resolve(EnvironmentService);
  return environmentService.deleteEnvironment(environmentId);
};

export const useDeleteEnvironment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEnvironment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ENVIRONMENTS] });
    }
  });
};
