import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  useDisclosure
} from "@chakra-ui/react";

import { faGearCode, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useDebounce } from "use-debounce";
import EmptyState from "../../../components/EmptyState";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import DeleteModal from "../../../components/modals/DeleteModal";
import MultiDropdown from "../../../components/MultiDropdown";
import Pagination from "../../../components/Pagination";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import formatDateTime from "../../../helpers/formatDateTime";
import {
  getSourceOption,
  getSourceOptions
} from "../../../helpers/getSourceOptions";
import useMixPanel from "../../../mixpanel/useMixPanel";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import { DataTableAction } from "../../../types/dataTable";
import { EmptyStateType } from "../../../types/emptyState";
import ISelectOption from "../../../types/selectInput";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { useDeleteEntitiesBulk } from "../api/deleteEntitiesBulk";
import { useAllEntities } from "../api/getAllEntities";
import { useSourceEntityTypes } from "../api/getSourceEntityTypes";
import { useReprocessEntity } from "../api/reprocessEntity";
import { ISourceEntity, SourceEntityTableModalType } from "../types";
import IEnterspeedIdentifiableSelectOption from "./../../../types/identifiableSelectInput";

const SourceEntitiesTable = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { pathname } = useLocation();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "updatedAt", desc: true }
  ]);
  const [selectedSource, setSelectedSource] =
    useState<IEnterspeedIdentifiableSelectOption | null>();

  const [selectedTypes, setSelectedTypes] = useState<ISelectOption[]>([]);

  const [filterTerm, setFilterTerm] = useState<string | null>(null);

  const [debouncedFilterTerm] = useDebounce(filterTerm, 500);

  const deleteEntitiesBulk = useDeleteEntitiesBulk();

  const { data: dataSources } = useSourceGroups();

  const {
    data: sourceEntityTypesData,
    refetch: refetchTypes,
    isRefetching: isRefetchingTypes
  } = useSourceEntityTypes(selectedSource?.value as string);

  useEffect(() => {
    if (!query) {
      return;
    }

    if (query.has("term")) {
      setFilterTerm(query.get("term"));
    }
    if (query.has("type")) {
      const types = query.get("type")?.split(",");
      setSelectedTypes(
        sourceEntityTypes()?.filter((t) =>
          types?.some((v) => v === (t.value as string))
        )
      );
    }
  }, [sourceEntityTypesData, query]);

  useEffect(() => {
    if (!dataSources) {
      return;
    }

    const sourceGuid = query.get("id");
    if (sourceGuid) {
      setSelectedSource(getSourceOption(dataSources, sourceGuid));
    }
  }, [dataSources, query]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: bulkIsOpen,
    onOpen: bulkOnOpen,
    onClose: bulkOnClose
  } = useDisclosure();

  const [modalType, setModalType] = useState<SourceEntityTableModalType>();
  const [rowData, setRowData] = useState<ISourceEntity | null>(null);

  const reprocessEntity = useReprocessEntity();
  const mixpanel = useMixPanel();

  const [bulkRowData, setBulkRowData] = useState<ISourceEntity[] | null>(null);

  const {
    data,
    isLoading,
    refetch: refetchEntities,
    isRefetching: isRefetchingEntities,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = useAllEntities({
    id: selectedSource?.value ? (selectedSource.value as string) : null,
    term: debouncedFilterTerm,
    type: selectedTypes.map((x) => x.value as string),
    sortBy: sorting.map((s) => ({
      propertyName: s.id,
      direction: s.desc ? "DESC" : "ASC"
    }))
  });

  const refetch = () => {
    return Promise.all([refetchEntities(), refetchTypes()]);
  };

  const isRefetching = isRefetchingEntities && isRefetchingTypes;

  const tableData =
    data?.pages.flatMap((page) =>
      page.results.map(
        (item): ISourceEntity => ({
          id: item.id,
          sourceName: item.sourceName,
          originId: item.originId,
          type: item.type,
          url: item.url,
          updatedAt: item.updatedAt,
          sourceId: selectedSource?.value as string
        })
      )
    ) ?? [];

  const totalEntitiesCount = data ? data.pages[0].total ?? 0 : 0;
  const sourceOptions = getSourceOptions(dataSources);
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();

  const sourceEntityTypes = () => {
    if (!sourceEntityTypesData) {
      return [];
    }

    return sourceEntityTypesData
      .map(
        (item) =>
          ({
            label: item,
            value: item
          } as ISelectOption)
      )
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  const columnHelper = createColumnHelper<ISourceEntity>();

  const columns = [
    columnHelper.accessor("sourceName", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.sourceName}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Source",
      enableSorting: false
    }),
    columnHelper.accessor("type", {
      cell: (info) => info.getValue(),
      header: "Type",
      enableSorting: false
    }),
    columnHelper.accessor("originId", {
      cell: (info) => (
        <Badge colorScheme="gray" fontWeight="semibold" textTransform="none">
          {info.getValue()}
        </Badge>
      ),
      header: "ID",
      enableSorting: false
    }),
    columnHelper.accessor("url", {
      cell: (info) => info.getValue(),
      header: "URL",
      enableSorting: false
    }),
    columnHelper.accessor("updatedAt", {
      cell: (info) => formatDateTime(info.getValue()),
      header: "Updated at",
      enableSorting: true
    })
  ];

  const handleActionClick = (
    row: ISourceEntity,
    type: SourceEntityTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<SourceEntityTableModalType, ISourceEntity>[] =
    [
      {
        type: SourceEntityTableModalType.DELETE,
        icon: faTrashCan,
        onClick: (row) => {
          handleActionClick(row, SourceEntityTableModalType.DELETE);
        }
      },
      {
        type: SourceEntityTableModalType.REPROCESS_ENTITY,
        icon: faGearCode,
        onClick: (row) => {
          reprocessEntity.mutate({ sourceEntityIds: [row.id] });
          mixpanel.track("sourceEntityReprocessed", { id: row.id });
        }
      }
    ];

  const ActionModal = ({
    type,
    rowData,
    isOpen,
    onClose
  }: {
    type: SourceEntityTableModalType;
    rowData: ISourceEntity;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    if (type === SourceEntityTableModalType.DELETE && selectedSource) {
      return (
        <DeleteModal
          type="source entity"
          name={rowData?.originId || ""}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={() => {
            onClose();

            if (selectedSource.value) {
              deleteEntitiesBulk.mutate({
                sourceId: selectedSource.value as string,
                entities: [rowData]
              });
            }
          }}
        />
      );
    }
    return <></>;
  };

  const handleRowClick = (row: ISourceEntity) => {
    navigate(`/source-entities/${row.sourceId}/${row.originId}`);
  };

  const handleSelectSource = (value?: IEnterspeedIdentifiableSelectOption) => {
    setSelectedSource(value);
    setFilterTerm("");

    if (value && pathname && query) {
      const newQuery = new URLSearchParams(query);
      newQuery.set("id", value.value as string);
      return navigate(`${pathname}?${newQuery.toString()}`);
    }
    if (pathname && query) {
      const newQuery = new URLSearchParams(query);
      newQuery?.delete("id");
      return navigate(`${pathname}?${newQuery.toString()}`);
    }
  };

  const handleSelectTypes = (value: ISelectOption[]) => {
    const types = value.filter((t) =>
      sourceEntityTypes().some((v) => v.value === t.value)
    );
    setSelectedTypes(types);

    if (!types.length && query && pathname) {
      const newQuery = new URLSearchParams(query);
      newQuery?.delete("type");
      return navigate(`${pathname}?${newQuery.toString()}`);
    }

    if (query && pathname) {
      const newQuery = new URLSearchParams(query);
      newQuery.set("type", types?.map((x) => x.value).join(","));
      return navigate(`${pathname}?${newQuery.toString()}`);
    }
  };

  const handleFilterSearch = (value: string) => {
    if (value.length <= 1 && pathname && query) {
      setFilterTerm("");

      const newQuery = new URLSearchParams(query);
      newQuery?.delete("term");
      return navigate(`${pathname}?${newQuery.toString()}`);
    }
    if (value.length > 1 && query && pathname) {
      setFilterTerm(value);
      const newQuery = new URLSearchParams(query);
      newQuery.set("term", value);
      return navigate(`${pathname}?${newQuery.toString()}`);
    }
  };

  const bulkOptions = [
    {
      label: "Delete",
      value: "bulk-delete"
    }
  ];

  const handleBulkCallback = (
    option: string,
    selectedRows: ISourceEntity[]
  ) => {
    if (option === "bulk-delete" && selectedRows.length > 0) {
      setBulkRowData(selectedRows);
      bulkOnOpen();
    }
  };

  return (
    <>
      <Box>
        <SimpleGrid spacing={8} columns={3} mb="8">
          <FormControl>
            <FormLabel color="gray.500" fontSize="xs">
              Sources
            </FormLabel>
            <Select<
              IEnterspeedIdentifiableSelectOption,
              true,
              GroupBase<IEnterspeedIdentifiableSelectOption>
            >
              size="sm"
              useBasicStyles
              chakraStyles={reactSelectStyles}
              colorScheme="brand"
              isClearable
              placeholder="Select source"
              options={sourceOptions}
              components={new EnterspeedIdentifiableSelect()}
              value={selectedSource}
              onChange={(value) => {
                return handleSelectSource(
                  value as unknown as IEnterspeedIdentifiableSelectOption
                );
              }}
            />
          </FormControl>
          {selectedSource && (
            <>
              <FormControl mr="4">
                <FormLabel color="gray.500" fontSize="xs">
                  Search
                </FormLabel>

                <Input
                  defaultValue={filterTerm ?? ""}
                  onChange={(e) => handleFilterSearch(e.target.value)}
                  placeholder="ID or URL (type at least 2 characters)"
                  size="sm"
                />
              </FormControl>
              <Flex alignItems="flex-end">
                <FormControl>
                  <FormLabel color="gray.500" fontSize="xs">
                    Type
                  </FormLabel>
                  <MultiDropdown
                    options={sourceEntityTypes()}
                    selectedOptions={selectedTypes}
                    onChange={(value) => {
                      return handleSelectTypes(value);
                    }}
                    placeholder="Select one or more types"
                  />
                </FormControl>
                <Flex alignItems="flex-end" ml={4}>
                  <ReloadButton loading={isRefetching} onClick={refetch} />
                </Flex>
              </Flex>
            </>
          )}
        </SimpleGrid>
      </Box>
      {!dataSources?.length ? (
        <EmptyState type={EmptyStateType.NO_SOURCE_ENTITIES}>
          <NavLink to="/settings/data-sources">
            <Button>Go to Data sources</Button>
          </NavLink>
        </EmptyState>
      ) : !selectedSource ? (
        <EmptyState customDescription="Please select a source so we can find some entities for you." />
      ) : (
        <>
          <DataTable
            loading={isLoading}
            columns={columns}
            data={tableData}
            onRowClickCallback={(row: ISourceEntity) => handleRowClick(row)}
            actions={actions}
            enableRowSelection={true}
            enableRowSelectionAll={true}
            bulkOptions={bulkOptions}
            bulkOptionsCallback={(option, selectedRows) =>
              handleBulkCallback(option as string, selectedRows)
            }
            sorting={sorting}
            setSorting={setSorting}
          />

          {tableData.length > 0 && (
            <Pagination
              disabled={!hasNextPage}
              current={tableData.length}
              total={totalEntitiesCount}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
            />
          )}
          {rowData && modalType && (
            <ActionModal
              type={modalType}
              rowData={rowData}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
          {selectedSource && bulkRowData && (
            <DeleteModal
              type="source entity"
              name={`${bulkRowData.length.toString()} entit${
                bulkRowData.length > 1 ? "ies" : "y"
              }`}
              isOpen={bulkIsOpen}
              onClose={bulkOnClose}
              onSuccess={() => {
                if (selectedSource.value) {
                  deleteEntitiesBulk.mutate({
                    sourceId: selectedSource.value as string,
                    entities: bulkRowData
                  });
                }

                bulkOnClose();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default SourceEntitiesTable;
