"use client";

import { Monaco } from "@monaco-editor/react";
import {
  editor,
  IDisposable,
  IRange,
  MonacoModelContentChangedEvent
} from "monaco-editor";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import BaseCodeEditor from "../../../components/CodeEditor/BaseCodeEditor";
import { IJsonFormatCodeEditorProps } from "../types";

const JsonSchemaCodeEditor = ({
  onEditorMount,
  diffComparison,
  leftSideBottomBarElements,
  rightSideBottomBarElements,
  bottomBarActionButtons,
  size,
  options,
  schemaType,
  value,
  onValueChange,
  onValidationChange
}: IJsonFormatCodeEditorProps) => {
  let completionProvider: IDisposable | null = null;

  useEffect(() => {
    return () => {
      completionProvider?.dispose();
    };
  }, [completionProvider]);

  /**
   * Debounced value used for trigger of onValidationChange event.
   * Comparing to JS editor, JSON editor is not emitting this event on each change, so we simulate same behavior.
   */
  const [latestSavedSchemaVersionData, setLatestSavedSchemaVersionData] =
    useState<string>(``);
  const [debouncedLatestSchemaVersionData] = useDebounce(
    latestSavedSchemaVersionData,
    1000
  );
  useEffect(() => {
    onValidationChange?.([]);
  }, [debouncedLatestSchemaVersionData]);

  const setEditorSnippetAndInsertionSuggestions = (monaco: Monaco) => {
    completionProvider = monaco.languages.registerCompletionItemProvider(
      "json",
      {
        provideCompletionItems: (model, position) => {
          const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column
          });
          const match = textUntilPosition.match("");
          if (!match) {
            return { suggestions: [] };
          }
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn
          };
          return {
            suggestions: createDependencyProposals(monaco, range)
          };
        }
      }
    );
  };

  const createDependencyProposals = (monaco: Monaco, range: IRange) => {
    const globalSchemaSuggestions = [
      {
        label: '"properties" (field)',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "The properties you want your schema to consist of.",
        insertText: '"properties": {}',
        range
      },
      {
        label: "string shorthand (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Basic string mapping.",
        insertText: `"myPropertyName": "{p.myInput}"`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "string (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Basic string mapping.",
        insertText: `"myPropertyName": {
  "type": "string",
  "value": "{p.myInput}",
  "default": "My default value (optional)"
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "number (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Basic number or integer mapping.",
        insertText: `"myPropertyName": {
  "type": "number",
  "value": "{p.myInput}",
  "default": "My default value (optional)",
  "precision": "1 (Rounds a decimal value to a specified number - optional)"
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "boolean (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Basic boolean mapping.",
        insertText: `"myPropertyName": {
  "type": "boolean",
  "value": "{p.myInput}",
  "default": true (optional),
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "array (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "Mapping of an array, defining the input to iterate and the items definition. Array property type is designed for working with collections.",
        insertText: `"myPropertyName": {
    "type": "array",
    "input": "{p.myInput}",
    "items": {
      "type": "string",
      "value": "{item}"
    }
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "object (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Mapping of an object.",
        insertText: `"myPropertyName": {
    "type": "object",
    "properties": {
      "mySubProperty": "p.mySubPropertyValue"
    }
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "reference (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Referencing another schema.",
        insertText: `"myPropertyName": {
    "type": "reference",
    "originId": "The originId of the source entity (id can be used instead of the originId property)",
    "alias": "The alias of the schema you wish to reference",
    "source": "Allows you to define a different source (optional)"
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      }
    ];

    const normalSchemaSuggestions = [
      {
        label: '"triggers" (field)',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "The source groups you want this schema to trigger on. A source group should contain one or more source entity types (array).",
        insertText: `"triggers": {
    "myDataSource": ["myEntityType"]
}`,
        range
      },

      {
        label: '"route" URL (field)',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Routing by URL",
        insertText: `"route": {
    "url": "{url}"
}`,
        range
      },
      {
        label: '"route" handle (field)',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Routing by handles",
        insertText: `"route": {
    "handles": ["myHandle"]
}`,
        range
      },
      {
        label: '"actions" (field)',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "Actions is used when a new view has been generated from a schema. It defines which specific actions to take following the newly generated view.",
        insertText: `"actions": [
    {
      "type": "process",
      "alias": "myAlias",
      "originId": "myOriginId (optional)",
      "source": "mySource (optional)"
    }
]`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },

      {
        label: "partial (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Referencing a partial schema to map the data into.",
        insertText: `"myPropertyName": {
    "type": "array",
    "input": "{p.contentBlocks} (what goes into the partial schema)",
    "items": {
        "type": "partial",
        "input": "{item}",
        "alias": "Block-{item.contentType} (Is used to resolve what partial schema to use)"
    }
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "dynamic (property type)",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Dynamic mapping using the path selector.",
        insertText: `"myPropertyName": {
    "*": "p.content"
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "Example schema: Map all source entity properties",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "A schema which dynamically maps all properties from your source entities to the product-object.",
        insertText: `{
    "triggers": {
        "cms": ["product"]
    },
    "route": {
        "url": "{url}"
    },
    "properties": {
        "product": {
          "*": "p"
        }
    }
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "Example schema: Site settings",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "A schema containing essential site settings, here Site name, Logo and Login page link.",
        insertText: `{
	"triggers": {
		"cms": [
			"site"
		]
	},
	"route": {
		"handles": [
			"settings"
		]
	},
	"properties": {
		"siteName": "{p.siteName}",
		"logo": "{properties.logo[0].url}",
		"loginPage": {
			"type": "reference",
			"originId": "{properties.loginPage[0].id}",
			"view": "LinkItem"
		}
	}
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "Example schema: Category products",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "A schema for listing all the products related to a specific category.",
        insertText: `{
	"triggers": {
		"cms": [
			"category"
		]
	},
	"route": {
		"url": "/categories/{p.slug}"
	},
	"properties": {
		"title": "{p.name}",
		"description": "{p.description}",
		"products" :{
			"type": "array",
			"input": {
				"\\$lookup": {
					"filter": "type eq 'product' and properties.categoryId eq '{originId}'"
				}
			},
			"var": "product",
			"items": {
				"type": "object",
				"properties": {
					"headline": "{product.p.name}"
				}
			}
		}
	}
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      },
      {
        label: "Example schema: Top 3 product reviews",
        kind: monaco.languages.CompletionItemKind.Function,
        documentation:
          "A schema for listing the 3 highest rated product reviews.",
        insertText: `{
	"triggers": {
		"cms": [
			"reviews"
		]
	},
	"properties": {
		"reviews": {
			"type": "array",
			"input": {
				"\\$lookup": {
					"filter": "type eq 'review' and properties.hashtags/any(t: t eq '#productreviews')",
					"orderBy": {
						"property": "properties.rating",
						"sort": "desc"
					},
					"top": 3
				}
			},
			"items": {
				"type": "reference",
				"originId": "{item.originId}",
				"view": "Review"
			}
		}
	}
}`,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range
      }
    ];

    if (schemaType === "normal") {
      return [...globalSchemaSuggestions, ...normalSchemaSuggestions];
    }
    return [...globalSchemaSuggestions];
  };

  const editorMounted = (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          uri: `${process.env.NEXT_PUBLIC_CDN_URL}/schemas/mapping.schema.json`
        }
      ],
      enableSchemaRequest: true
    });

    setEditorSnippetAndInsertionSuggestions(monaco);
    onEditorMount?.(editorInstance, monaco);
  };

  const valueChanged = (
    value: string | undefined,
    event: MonacoModelContentChangedEvent
  ) => {
    setLatestSavedSchemaVersionData(value ?? ``);
    onValueChange?.(value, event);
  };

  return (
    <>
      <BaseCodeEditor
        value={value}
        options={{
          ...options
        }}
        language="json"
        size={size}
        onEditorMount={editorMounted}
        onValueChange={valueChanged}
        leftSideBottomBarElements={leftSideBottomBarElements}
        rightSideBottomBarElements={rightSideBottomBarElements}
        bottomBarActionButtons={bottomBarActionButtons}
        diffComparison={diffComparison}
      ></BaseCodeEditor>
    </>
  );
};

export default JsonSchemaCodeEditor;
