import { Badge } from "@chakra-ui/react";

const LevelBadge = ({
  level
}: {
  level: "Warning" | "Information" | "Error";
}) => {
  let displayText = "";
  switch (level) {
    case "Error":
      displayText = "error";
      break;
    case "Warning":
      displayText = "warn";
      break;
    case "Information":
      displayText = "info";
      break;
  }
  return (
    <Badge
      colorScheme={color[level]}
      variant="subtle"
      fontSize="0.75em"
      fontWeight="normal"
    >
      {displayText}
    </Badge>
  );
};

const color = {
  Warning: "yellow",
  Information: "blue",
  Error: "red"
};

export default LevelBadge;
