import ISelectOption from "../types/selectInput";

export const getRouteTypeOptions = (): ISelectOption[] =>
  [
    {
      label: "Url",
      value: "Url"
    },
    {
      label: "Handle",
      value: "Handle"
    }
  ] as ISelectOption[];

export const getRouteTypeOption = (
  routeOptions: ISelectOption[],
  routeValue: string | null
): ISelectOption => {
  const routeOption = routeOptions.find((s) => s.value === routeValue);
  return routeOption ?? routeOptions[0];
};
