import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../features/auth/store";

export const NoTenantsRoutes = () => {
  const hasTenants = useAuthStore(
    (state) => state.user?.tenantIds.length !== 0
  );

  if (hasTenants) {
    return <Navigate to="/"></Navigate>;
  }

  return <Outlet></Outlet>;
};

export default NoTenantsRoutes;
