import { SchemaFormat } from "../types";

const getSchemaFormat = (format: SchemaFormat | null | undefined) => {
  switch (format) {
    case "json":
      return "JSON";
    case "javascript":
      return "JS";
    default:
      return "JSON";
  }
};

export default getSchemaFormat;
