import { orderBy } from "lodash";
import ISelectOption from "../../../types/selectInput";
import { deployStateSortOrder } from "../util";
import { ChangeState, IBulkDeploy, SchemaType } from "./index";

type UpdateParams =
  | SetNewVersionAction
  | RedeployAction
  | ExcludeAction
  | UnpublishAction;

type SetNewVersionAction = {
  action: "setNewVersion";
  params: { version: number | null };
};

type RedeployAction = {
  action: "Redeploy";
};
type ExcludeAction = {
  action: "ToggleExclude";
  params: { value: boolean };
};

type UnpublishAction = {
  action: "Unpublish";
};

export class BulkDeploy implements IBulkDeploy {
  private _state: ChangeState;

  constructor({
    id,
    mappingSchemaGuid,
    name,
    alias,
    version,
    deployVersion,
    currentVersion,
    exclude,
    schemaType,
    useVersionFromDifferentEnv,
    useVersionFromDifferentEnvClass
  }: Omit<IBulkDeploy, "state">) {
    this.id = id;
    this.mappingSchemaGuid = mappingSchemaGuid;
    this.name = name;
    this.alias = alias;
    this.version = version;
    this.deployVersion = deployVersion;
    this.currentVersion = currentVersion;
    this.exclude = exclude;
    this.schemaType = schemaType;
    this.useVersionFromDifferentEnv = useVersionFromDifferentEnv;
    this.useVersionFromDifferentEnvClass = useVersionFromDifferentEnvClass;
    this._state = this.calculateState(true);
    this.path = name.split("/");
  }

  id: string;
  mappingSchemaGuid: string;
  name: string;
  alias: string;
  version: ISelectOption;
  deployVersion: ISelectOption | null;
  currentVersion: number | null;
  exclude: boolean;
  schemaType: SchemaType;
  useVersionFromDifferentEnv: boolean;
  useVersionFromDifferentEnvClass: boolean;
  path: string[];

  public update(to: UpdateParams) {
    if (to.action === "ToggleExclude") {
      this.exclude = to.params.value;
    }

    if (to.action === "Redeploy") {
      this.exclude = false;
      this._state = "Redeployed";
    }

    if (to.action === "setNewVersion") {
      const newVersion = to.params.version
        ? this.versionOptions.find(
            (x) => x.value === to.params.version?.toString()
          ) ?? null
        : null;

      this.deployVersion = newVersion;

      this._state = this.calculateState(false);
    }

    if (to.action === "Unpublish") {
      this.deployVersion = null;
      if (this.currentVersion) {
        this.exclude = false;
      }
      this._state = "Unpublished";
    }
  }

  public get versionOptions(): ISelectOption[] {
    return this.version === null
      ? []
      : Array.from(
          { length: parseInt(this.version.value as string) },
          (_value, idx) => idx + 1
        )
          .map((x) => ({
            value: x.toString(),
            label:
              x === parseInt(this.version.value as string)
                ? `${x} (latest)`
                : x.toString()
          }))
          .reverse();
  }

  private get isAdded() {
    return !this.currentVersion && this.deployVersion;
  }

  private get isUpgraded() {
    return (
      this.currentVersion &&
      this.deployVersion?.value &&
      this.currentVersion < parseInt(this.deployVersion.value as string)
    );
  }

  private get isDowngraded() {
    return (
      this.currentVersion &&
      this.deployVersion?.value &&
      this.currentVersion > parseInt(this.deployVersion.value as string)
    );
  }

  private get isRedeploy() {
    return (
      !this.exclude &&
      this.currentVersion &&
      this.deployVersion?.value &&
      this.currentVersion === parseInt(this.deployVersion.value as string)
    );
  }

  private get isUnpublish() {
    return this.currentVersion && !this.deployVersion?.value;
  }

  get state(): ChangeState {
    return this._state;
  }

  private calculateState(init: boolean): ChangeState {
    if (this.isAdded) {
      return "Added";
    }
    if (this.isUpgraded) {
      return "Updated";
    }
    if (this.isDowngraded) {
      return "Reverted";
    }
    if (this.isRedeploy && !init) {
      return "Redeployed";
    }
    if (this.isUnpublish) {
      return "Unpublished";
    }

    return "Unchanged";
  }
}

export function sortBulkDeployList(data: BulkDeploy[]) {
  return orderBy(
    data,
    [
      ({ state }) => deployStateSortOrder[state], // first by state

      ({ name }) => name.toLowerCase(), // then by folder name
      ({ alias }) => alias.toLowerCase() // last by alias
    ],
    ["asc", "asc", "asc", "asc"]
  );
}

export function copyVersionsFromEnv(
  data: BulkDeploy[],
  copyEnvironmentSchemas: BulkDeploy[]
) {
  return sortBulkDeployList(
    data.map((schema) => {
      const copyTo = new BulkDeploy({ ...schema });
      const copyFrom = copyEnvironmentSchemas.find(
        (copy) => copyTo.id === copy.id
      );
      if (copyFrom) {
        copyTo.update({
          action: "setNewVersion",
          params: { version: copyFrom.currentVersion }
        });
      }
      if (!copyTo?.deployVersion && copyTo.currentVersion) {
        copyTo.update({ action: "Redeploy" });
      }
      if (copyTo.deployVersion?.value === copyTo.currentVersion?.toString()) {
        copyTo.update({ action: "ToggleExclude", params: { value: true } });
      }
      return copyTo;
    })
  );
}
