import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DOMAINS } from "../../../constants/queryKey";
import { DomainService } from "../domain.service";

export const deleteDomain = ({ id }: { id: string }) => {
  const domainService = container.resolve(DomainService);
  return domainService.deleteDomain(id);
};

export const useDeleteDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDomain,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DOMAINS] });
    }
  });
};
