import { Badge, Box, useColorModeValue } from "@chakra-ui/react";
import { orderBy } from "lodash";
import { BulkDeploy } from "../types/BulkDeploy";
import { badgeColorMap, deployStateSortOrder } from "../util";

export const BulkDeploySummary = ({
  deployConfig
}: {
  deployConfig: BulkDeploy[];
}) => {
  const oddColor = useColorModeValue("gray.50", "gray.600");
  const evenColor = useColorModeValue("gray.100", "gray.800");
  return (
    <Box
      m={"24px 0;"}
      sx={{
        "--odd-color-summary": `var(--chakra-colors-${oddColor.replace(
          ".",
          "-"
        )})`,
        "--even-color-summary": `var(--chakra-colors-${evenColor.replace(
          ".",
          "-"
        )})`,

        "div:nth-of-type(even)": {
          bg: "var(--even-color-summary)"
        },
        "div:nth-of-type(odd)": {
          bg: "var(--odd-color-summary)"
        }
      }}
    >
      {orderBy(deployConfig, [({ state }) => deployStateSortOrder[state]]).map(
        (d) => (
          <Box key={d.alias} p={"6px 8px;"}>
            {<strong>{d.alias}</strong>}
            {` will be `}
            <Badge colorScheme={badgeColorMap[d.state]}>{d.state}</Badge>
            {d.state === "Updated" && d.deployVersion ? (
              <span>
                {` to version `}
                {<strong>{d.deployVersion.label}</strong>}
              </span>
            ) : d.state === "Reverted" && d.deployVersion ? (
              <span>
                {` to version `}
                {<strong>{d.deployVersion.label}</strong>}
              </span>
            ) : (
              ""
            )}
          </Box>
        )
      )}
    </Box>
  );
};
