import { useEffect } from "react";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { EmptyStateType } from "../../../types/emptyState";
import { useTreeState } from "../contexts/schema-list";
import { ISchemaListProps } from "../types/schema-list";
import SchemaListFilter from "./SchemaListFilter";
import SchemaListTreeView from "./SchemaListTreeView";

const SchemaList = ({
  schemas,
  alwaysOpen,
  collapsedPaths,
  expandedPaths,
  filterTerm,
  query,
  advancedSearch,
  isLoading,
  isRefetching,
  filterButtons,
  onEditSchema,
  onDuplicateSchema,
  onDeleteSchema,
  onCreateSchema
}: ISchemaListProps) => {
  const { state, dispatch } = useTreeState();

  const mixpanel = useMixPanel();

  useEffect(() => {
    if (!isRefetching) {
      dispatch({
        type: "initialize",
        schemas,
        alwaysOpen,
        advancedSearch,
        collapsedPaths,
        expandedPaths,
        filterTerm,
        query
      });
    }
  }, [schemas, isRefetching]);

  const onFilterTermChange = (value: string) => {
    dispatch({
      type: "search",
      term: value
    });
  };

  const onAdvancedSearch = (value: string) => {
    mixpanel.track("schemaSearchAdvanced");
    dispatch({
      type: "advancedSearch",
      query: value
    });
  };

  return (
    <>
      <SchemaListFilter
        onTermChange={onFilterTermChange}
        onAdvancedSearch={onAdvancedSearch}
        advancedSearch={advancedSearch}
        term={state.filterTerm}
        query={state.query}
      >
        {filterButtons}
      </SchemaListFilter>

      {(isLoading || isRefetching) && <LoadingAnimation />}

      {state.nodes.filter((x) => !x.hidden).length === 0 &&
        !(isLoading || isRefetching) && (
          <EmptyState type={EmptyStateType.NO_SCHEMAS} />
        )}

      {!(isLoading || isRefetching) && (
        <SchemaListTreeView
          data={state.nodes}
          query={query}
          onEditSchema={onEditSchema}
          onCreateSchema={onCreateSchema}
          onDuplicateSchema={onDuplicateSchema}
          onDeleteSchema={onDeleteSchema}
        />
      )}
    </>
  );
};

export default SchemaList;
