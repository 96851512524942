import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { BiService } from "./bi.service";

const queryFn = () => {
  const biClient = container.resolve(BiService);
  return biClient.GetTenantsWithErrors();
};

const useTenantsWithErrors = () => {
  return useQuery({ queryFn, queryKey: ["BI", "TENANT_ERRORS"] });
};

export default useTenantsWithErrors;
