import { Text } from "@chakra-ui/react";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { OLD_APP_URL } from "../../../constants/app";
import { ITenant } from "../types";

const TenantSwitchConfirmModal = ({
  switchToTenant,
  handleTenantSwitch,
  isOpen,
  onClose
}: {
  switchToTenant: ITenant;
  handleTenantSwitch: (tenant: ITenant) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ConfirmModal
      title="Switch tenant"
      children={
        <>
          {switchToTenant?.isUsingSourceGroups ? (
            <Text>
              Are you sure you want to switch to{" "}
              <Text as="b">{switchToTenant?.name}</Text>?
            </Text>
          ) : (
            <Text>
              Tenant <Text as="b">{switchToTenant?.name}</Text> is deprecated
              and cannot be used with this app. Click confirm to open the old
              ap.
            </Text>
          )}
        </>
      }
      onSuccess={() => {
        if (!switchToTenant) {
          return;
        }
        if (!switchToTenant.isUsingSourceGroups) {
          return window.location.replace(OLD_APP_URL);
        }
        handleTenantSwitch(switchToTenant);
      }}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default TenantSwitchConfirmModal;
