import { Button, Flex, useColorModeValue } from "@chakra-ui/react";
import capitalizeFirst from "../../helpers/capitalizeFirst";
import { ICodeEditorBottomBarProps } from "../../types/codeEditor";

const BottomBar = ({
  theme,
  wordWrap,
  minimapVisible,
  leftChildren,
  rightChildren,
  onEditorCommandRequest,
  onToggleMinimap
}: ICodeEditorBottomBarProps) => {
  const bottomRightActions = [
    {
      label: `Minimap: ${minimapVisible ? "On" : "Off"}`,
      onClick: () => onToggleMinimap?.()
    },
    {
      label: `Word wrap: ${capitalizeFirst(wordWrap)}`,
      onClick: () => onEditorCommandRequest?.("> Set Word Wrap ")
    },
    {
      label: `Theme: ${theme}`,
      onClick: () => onEditorCommandRequest?.("> Set theme ")
    }
  ];

  return (
    <Flex
      bg={useColorModeValue("brand.100", "brandDarkBlue")}
      justifyContent="space-between"
      alignItems="center"
    >
      {leftChildren}
      <Flex ml="auto" wrap={"wrap"}>
        {rightChildren}
        {bottomRightActions.map((action) => (
          <Button
            key={action.label}
            variant="unstyled"
            size="xs"
            px="2"
            _hover={{ bg: "brand.200" }}
            fontWeight="normal"
            borderRadius="none"
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default BottomBar;
