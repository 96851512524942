import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IAuthenticateRequestPayload, IAuthenticateResponse } from "./types";

const AUTH_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI as string;

@singleton()
export class AuthService {
  public constructor(private readonly http: ManagementClient) {}

  public async authenticate(payload: IAuthenticateRequestPayload) {
    const url = `/api/v1/identity/authenticate?redirect_uri=${AUTH_REDIRECT_URI}`;
    const { data } = await this.http.post<IAuthenticateResponse>(url, payload);

    return data;
  }

  public async signOut() {
    await this.http.post<IAuthenticateResponse>("/api/v1/identity/signout");
  }
}
