import { useTreeState } from "../contexts/schema-list";
import {
  ITreeViewNodeProps,
  SchemaItemTreeNode,
  VirtualFolderTreeNode
} from "../types/schema-list";
import SchemaListFolder from "./SchemaListFolder";
import SchemaListItem from "./SchemaListItem";
import SchemaListTreeView from "./SchemaListTreeView";

const SchemaListTreeViewNode = ({
  node,
  query,
  onDuplicateSchema,
  onDeleteSchema,
  onCreateSchema,
  onEditSchema
}: ITreeViewNodeProps) => {
  const { dispatch } = useTreeState();
  if (node.hidden) {
    return null;
  }

  const toggle = (virtualFolderNode: VirtualFolderTreeNode) => {
    dispatch({
      type: virtualFolderNode.isExpanded ? "collapse" : "expand",
      path: virtualFolderNode.path
    });
  };

  if (node.type === "folder") {
    const folderNode = node as VirtualFolderTreeNode;
    return (
      <SchemaListFolder
        key={node.key}
        name={node.name}
        folderPath={folderNode.path}
        isOpen={folderNode.isExpanded}
        onClick={() => toggle(folderNode)}
        onCreateSchema={() => onCreateSchema(`${folderNode.path}/`)}
      >
        {folderNode.isExpanded && (
          <SchemaListTreeView
            key={`tree-view-${folderNode.path}`}
            data={folderNode.children}
            query={query}
            onEditSchema={onEditSchema}
            onDuplicateSchema={onDuplicateSchema}
            onDeleteSchema={onDeleteSchema}
            onCreateSchema={onCreateSchema}
          />
        )}
      </SchemaListFolder>
    );
  }

  const schemaNode = node as SchemaItemTreeNode;
  return (
    <>
      <SchemaListItem
        key={node.key}
        name={node.name}
        query={query}
        schema={schemaNode.data}
        onEditSchema={() =>
          onEditSchema(
            schemaNode.data.id.mappingSchemaGuid,
            schemaNode.data.name
          )
        }
        onDuplicateSchema={() =>
          onDuplicateSchema(
            schemaNode.data.id.mappingSchemaGuid,
            schemaNode.data.name
          )
        }
        onDeleteSchema={() =>
          onDeleteSchema(
            schemaNode.data.id.mappingSchemaGuid,
            schemaNode.data.name
          )
        }
      />
    </>
  );
};

export default SchemaListTreeViewNode;
