export interface IAuthenticateRequestPayload {
  type: string;
  token?: string;
  state?: string;
  frontendRedirectUrl?: string;
}

export interface IAuthenticateResponse {
  isValid: boolean;
  redirectUrl?: string;
  token?: IAuthenticateTokenResponse;
  user?: IAuthenticateUserResponse;
  links?: IAuthLinks;
}

export interface IAuthToken {
  accessToken?: string;
  refreshToken?: string;
}

export interface IAuthenticateTokenResponse extends IAuthToken {
  expiresIn: number;
  tokenType: string;
  scope: string;
}

export interface IAuthUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  roles?: string[];
  tenants: IAuthenticateTenantsResponse;
}

export interface IAuthenticateUserResponse extends IAuthUser {
  tenants: IAuthenticateTenantsResponse;
}

export interface IAuthenticateTenantsResponse {
  [key: string]: Array<string>;
}

export interface IAuthLinks {
  signInUrl?: string;
  signOutUrl?: string;
  resetPasswordUrl?: string;
  editProfileUrl?: string;
}

export enum AuthRequestType {
  AUTHENTICATE = "authorization_code",
  REFRESH = "refresh_token"
}

export interface IAuthState {
  isAdmin: boolean;
  adminFeaturesEnabled: boolean;
  isValid: boolean;
  user?: IAuthStateUser;
  links?: IAuthLinks;
  tokens: IAuthToken;
  addTenant: (tenantId: string) => void;
  removeTenant: (tenantId: string) => void;
  register: (partial: Partial<IAuthState>) => void;
  toggleAdminFeatures: () => void;
}
export interface IAuthStateUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  multipleEmailsWarning?: boolean;
  roles?: string[];
  tenantIds: string[];
}

export interface ISignOutModalProps {
  isOpen: boolean;
  onClose: () => void;
}
