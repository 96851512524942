import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  ToastId,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { Buffer } from "buffer";
import { Select } from "chakra-react-select";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { container } from "tsyringe";
import BasicJsonCodeEditor from "../../../../components/CodeEditor/BasicJsonCodeEditor";
import {
  getSourceOption,
  getSourceOptions
} from "../../../../helpers/getSourceOptions";
import useReactSelectStyles from "../../../../styles/react-select-style";
import { IApiErrorResponse } from "../../../../types/errors";
import ISelectOption from "../../../../types/selectInput";
import { useSourceGroups } from "../../../source-groups/api/getSourceGroups";
import { useTenantStore } from "../../../tenants/store";
import { useTestSchema } from "../../api/testSchema";
import { SchemaEvaluationService } from "../../formats/SchemaEvaluationService";
import { ISchemaVersion, SchemaType } from "../../types";
import {
  getDefaultTestData,
  validateInputType,
  validateTestSourceEntityInputData
} from "../testing-schemas";
import {
  DryRunInputFormat,
  ISchemaDryRunResultDto,
  ISchemaDryRunSourceEntityDto,
  TestInput,
  TestInputSourceEntity
} from "../types";
import TestConsoleEntries from "./TestConsoleEntries";
import TestErrors from "./TestErrors";

const invalidJsonMessage = "Invalid json";
const TestArbitraryJson = ({
  schema,
  schemaAlias,
  schemaType
}: {
  schema: ISchemaVersion;
  schemaType: SchemaType;
  schemaAlias: string;
}) => {
  const evaluationService = container.resolve(SchemaEvaluationService);

  const activeTenant = useTenantStore(({ activeTenant }) => activeTenant);
  const toast = useToast();
  const toastIdRef = useRef<ToastId>();

  const reactSelectStyles = useReactSelectStyles();

  const { data: dataSources } = useSourceGroups();

  const {
    mutateAsync,
    isPending: isLoadingTestResult,
    isSuccess,
    error,
    isError
  } = useTestSchema();

  const isSuccessResponse = (
    _testResult: ISchemaDryRunResultDto | IApiErrorResponse
  ): _testResult is ISchemaDryRunResultDto => isSuccess;

  const [arbitraryInput, setArbitraryInput] = useState<
    TestInput | TestInputSourceEntity
  >(getDefaultTestData(schema.format, schemaType));

  const [selectedSource, setSelectedSource] = useState<ISelectOption>();

  const [testResult, setTestResult] = useState<
    ISchemaDryRunResultDto | IApiErrorResponse
  >();

  const [invalidMessage, setInvalidMessage] = useState("");

  const [tabIndex, setTabIndex] = useState(0);
  const [schemaHasChanged, setSchemaHasChanged] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const validate = (): [boolean, string] => {
    if (invalidMessage) {
      return [false, invalidMessage];
    }
    if (!selectedSource?.value) {
      return [
        false,
        "JSON must have a source id. You can fill it using the selector"
      ];
    }

    if (validateInputType(arbitraryInput, schema.format, schemaType)) {
      return validateTestSourceEntityInputData(arbitraryInput);
    }
    return [true, ""];
  };

  useEffect(() => {
    const { valid } = evaluationService.evaluate(
      schemaType,
      schema.format,
      schema.data,
      { isUsingSourceGroups: activeTenant.isUsingSourceGroups }
    );
    setSchemaHasChanged(true);
    if (!valid) {
      setInvalidMessage("Schema is not valid");
    } else {
      setInvalidMessage("");
    }
  }, [schema]);

  useEffect(() => {
    const sourceId = searchParams?.get("id");
    if (sourceId && dataSources && !invalidMessage) {
      setSelectedSource(getSourceOption(dataSources, sourceId));
    }
  }, [dataSources, invalidMessage]);

  useEffect(() => {
    try {
      const jsonInput = sessionStorage.getItem("jsonInput");
      if (jsonInput) {
        setArbitraryInput(
          JSON.parse(
            Buffer.from(jsonInput, "base64").toString("utf8")
          ) as TestInput
        );
      }
    } catch (error) {
      setInvalidMessage(invalidJsonMessage);
    }
  }, []);

  useEffect(() => {
    const title = "Testing schema";
    if (isLoadingTestResult) {
      toastIdRef.current = toast({
        status: "loading",
        title
      });
    }

    if (
      isSuccess &&
      toastIdRef.current &&
      testResult &&
      isSuccessResponse(testResult)
    ) {
      toast.update(toastIdRef.current, {
        status: testResult.succeeded ? "success" : "error",
        description: testResult.succeeded
          ? "Test ran successfully"
          : `Test had ${testResult.errors?.length ?? 0} error(s)`,
        title
      });
    }

    if (isError && toastIdRef.current && error) {
      toast.update(toastIdRef.current, {
        status: "error",
        title,
        description: error.response?.data.detail
      });
    }
  }, [testResult, isLoadingTestResult]);

  const handleSourceSelect = (value: ISelectOption | ISelectOption[]) => {
    if (value && !Array.isArray(value)) {
      setSelectedSource(value);
      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.set("id", value.value as string);
      setSearchParams(newSearch);
    }

    if (!value) {
      setSelectedSource(undefined);
      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.delete("id");
      setSearchParams(newSearch);
    }
  };

  const getDryRunDto = (): {
    dryRunDto: ISchemaDryRunSourceEntityDto;
    format: DryRunInputFormat;
  } => {
    if (validateInputType(arbitraryInput, schema.format, schemaType)) {
      const dryRunDto: ISchemaDryRunSourceEntityDto = {
        input: arbitraryInput,
        schemaType,
        schemaAlias,
        schemaVersionData: Buffer.from(schema.data, "utf8").toString("base64"),
        schemaVersionFormat: schema.format,
        sourceId: `gid://Source/${selectedSource?.value as string}`
      };
      return { dryRunDto, format: "simple" };
    }
    const dryRunDto: ISchemaDryRunSourceEntityDto = {
      input: arbitraryInput,
      schemaType,
      schemaAlias,
      schemaVersionData: Buffer.from(schema.data, "utf8").toString("base64"),
      schemaVersionFormat: schema.format,
      sourceId: `gid://Source/${selectedSource?.value as string}`
    };
    return { dryRunDto, format: "raw" };
  };

  const test = async () => {
    const dryRunDto = getDryRunDto();

    try {
      setSchemaHasChanged(false);
      const testPromise = mutateAsync(dryRunDto);
      const result = await testPromise;
      setTestResult(result);
    } catch (error) {
      const err = error as AxiosError<IApiErrorResponse>;
      setTestResult(err.response?.data);
    } finally {
      setTabIndex(1);
    }
  };

  return (
    <>
      <Flex mb="8">
        <FormControl>
          <FormLabel color="gray.500" fontSize="xs">
            Sources
          </FormLabel>

          <Flex>
            <Flex grow={1}>
              <Select
                size="sm"
                useBasicStyles
                chakraStyles={reactSelectStyles}
                colorScheme="brand"
                isClearable
                value={selectedSource}
                onChange={(value) => {
                  setTabIndex(0);
                  setTestResult(undefined);
                  return handleSourceSelect(value as ISelectOption);
                }}
                options={getSourceOptions(dataSources ?? [])}
                placeholder="Select source"
              />
            </Flex>

            <Tooltip
              label={validate()[1]}
              isDisabled={validate()[0]}
              placement="bottom-start"
            >
              <Button
                ml="2"
                colorScheme="gray"
                onClick={test}
                isDisabled={!validate()[0]}
                isLoading={isLoadingTestResult}
              >
                Test
              </Button>
            </Tooltip>
          </Flex>
        </FormControl>
      </Flex>

      {testResult && isSuccessResponse(testResult) && (
        <>
          <TestErrors testResult={testResult} />
          <TestConsoleEntries testResult={testResult} />
        </>
      )}

      <Tabs variant="default" isFitted index={tabIndex} onChange={setTabIndex}>
        <TabList>
          <Tab>Input</Tab>
          <Tooltip
            placement="bottom-start"
            label={
              !testResult
                ? "No output produced. Click test to produce output"
                : schemaHasChanged && testResult
                ? "The schema has changed since last test, test again to test the updated schema"
                : ""
            }
          >
            <Tab isDisabled={!testResult}>
              {schemaHasChanged && testResult ? (
                <>
                  <Icon as={FontAwesomeIcon} icon={faTriangleExclamation} />
                </>
              ) : (
                <></>
              )}
              Output
            </Tab>
          </Tooltip>
        </TabList>
        <TabPanels>
          <TabPanel>
            <BasicJsonCodeEditor
              value={JSON.stringify(arbitraryInput, null, 2)}
              options={{ readOnly: false }}
              onValueChange={(value) => {
                if (value) {
                  try {
                    setArbitraryInput(JSON.parse(value) as TestInput);

                    setInvalidMessage("");

                    sessionStorage.setItem(
                      "jsonInput",
                      Buffer.from(value, "utf8").toString("base64")
                    );
                  } catch (error) {
                    setInvalidMessage(invalidJsonMessage);
                  }
                }
              }}
            />
          </TabPanel>
          <TabPanel>
            <BasicJsonCodeEditor
              value={JSON.stringify(testResult, null, 2)}
              options={{ readOnly: true }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
export default TestArbitraryJson;
