import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import {
  faInputText,
  faList,
  faTrashCan
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsisVertical, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Monaco } from "@monaco-editor/react";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import { editor, MonacoMarker } from "monaco-editor";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {
  unstable_usePrompt,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { container } from "tsyringe";
import { useDebounce } from "use-debounce";
import { validate } from "uuid";
import { useShallow } from "zustand/react/shallow";
import ContentBox from "../../components/ContentBox";
import EmptyState from "../../components/EmptyState";
import TenantMismatch from "../../components/Errors/TenantMismatch";
import LoadingAnimation from "../../components/LoadingAnimation";
import MetaTitle from "../../components/MetaTitle";
import DeleteModal from "../../components/modals/DeleteModal";
import EditNameModal from "../../components/modals/EditNameModal";
import ReloadButton from "../../components/ReloadButton";
import { SidePanelContext } from "../../context/SidePanelContext";
import { useDeleteSchema } from "../../features/schemas/api/deleteSchema";
import { useEditSchema } from "../../features/schemas/api/editSchema";
import { useEditSchemaVersion } from "../../features/schemas/api/editSchemaVersion";
import { useCompareSchema } from "../../features/schemas/api/getCompareSchema";
import { useSchema } from "../../features/schemas/api/getSchema";
import EditorAutoSaveBottomBarButton from "../../features/schemas/components/EditorAutoSaveBottomBarButton";
import GoToVersionModal from "../../features/schemas/components/GoToVersionModal";
import JavascriptSchemaCodeEditor from "../../features/schemas/components/JavascriptSchemaCodeEditor";
import JsonSchemaCodeEditor from "../../features/schemas/components/JsonSchemaCodeEditor";
import SchemaPendingChangesBottomBarButton from "../../features/schemas/components/SchemaPendingChangesBottomBarButton";
import SchemaValidationErrorsBottomBarButton from "../../features/schemas/components/SchemaValidationErrorsBottomBarButton";
import { SchemaEvaluationService } from "../../features/schemas/formats/SchemaEvaluationService";
import TestSourceEntityPanel from "../../features/schemas/testing-schemas/components/TestSourceEntityPanel";
import {
  ISchemaVersion,
  ISchemaVersionDetails,
  ISchemaVersionMetadataState,
  ISchemaVersionValidityState,
  SchemaActionModelSubmissionResult,
  SchemaModalType
} from "../../features/schemas/types";
import getSchemaFormat from "../../features/schemas/utils/getSchemaFormat";
import getSchemaType from "../../features/schemas/utils/getSchemaType";
import { useTenantStore } from "../../features/tenants/store";
import { useGeneralStore } from "../../generalStore";
import useReactSelectStyles from "../../styles/react-select-style";
import {
  ErrorDescriptor,
  IDiffComparison,
  LocalStorageSettingKeys
} from "../../types/codeEditor";
import { IApiErrorResponse } from "../../types/errors";
import ISelectOption from "../../types/selectInput";

const SchemaDetailPage = () => {
  // Retrieve route params
  const navigate = useNavigate();
  const params = useParams<{ schemaParam: string }>();
  const schemaParam = params.schemaParam;

  const schemaGuid =
    schemaParam && validate(schemaParam) ? schemaParam : undefined;
  const schemaAlias =
    schemaParam && !validate(schemaParam) ? schemaParam : undefined;

  const isFullscreen = useGeneralStore(
    useShallow(({ fullscreen }) => fullscreen)
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const version = searchParams?.get("version");
  const activeTenant = useTenantStore(({ activeTenant }) => activeTenant);

  // Hooks
  const { mutate: editSchemaVersion, isPending: editSchemaIsLoading } =
    useEditSchemaVersion(schemaGuid ?? "");

  // States
  const { data, refetch, isRefetching, isLoading, error, isError } = useSchema(
    schemaGuid,
    version ? parseInt(version) : undefined,
    schemaAlias
  );

  const [schemaVersion, setSchemaVersion] = useState<
    ISchemaVersion | undefined
  >();

  const [compareVersion, setCompareVersion] = useState<number | undefined>();
  const [schemaVersionMetadataState, setSchemaVersionMetadataState] =
    useState<ISchemaVersionMetadataState>({
      editable: false,
      sourceEntityTypes: []
    });
  const [schemaVersionValidityState, setSchemaVersionValidityState] =
    useState<ISchemaVersionValidityState>({
      errors: [],
      valid: true
    });

  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }
    setSchemaVersion(data.version);
  }, [data]);

  useEffect(() => {
    if (data && schemaAlias) {
      navigate(`/schemas/${data.id.mappingSchemaGuid}`, { replace: true });
    }
  }, [data, schemaAlias, navigate]);

  // User settings - Auto save
  const [autoSave, setAutoSave] = useState<boolean>(
    window.localStorage.getItem(LocalStorageSettingKeys.autoSave) === "true"
  );
  useEffect(() => {
    localStorage.setItem(LocalStorageSettingKeys.autoSave, autoSave.toString());
  }, [autoSave]);

  // References
  const latestSavedSchemaVersionData = useRef<string | undefined>();

  // Contexts
  const { setOpen, setTitle, setContent, setCustomStartWidth } =
    useContext(SidePanelContext);

  useEffect(() => {
    setHasPendingChanges(
      latestSavedSchemaVersionData.current !== schemaVersion?.data
    );
  }, [latestSavedSchemaVersionData, schemaVersion]);

  unstable_usePrompt({
    message:
      "Your schema contains unsaved changes. Are you sure you wish to proceed?",
    when: ({ currentLocation, nextLocation }) =>
      hasPendingChanges && currentLocation.pathname !== nextLocation.pathname
  });

  const clearSessionStorage = () => {
    sessionStorage.removeItem("jsonInput");
  };

  useEffect(() => {
    return clearSessionStorage;
  }, []);

  // Debounced values
  const [debouncedSchemaVersionValue] = useDebounce(schemaVersion, 2000);
  useEffect(() => {
    if (!autoSave || !schemaVersionValidityState.valid || !hasPendingChanges) {
      return;
    }

    saveSchemaVersion();
  }, [debouncedSchemaVersionValue]);

  // Mounted
  useEffect(() => {
    if (!data) {
      return;
    }

    latestSavedSchemaVersionData.current = data.version.data;
    setSchemaVersionMetadataState({
      editable:
        data.version?.isEditable ||
        data.version?.id?.version === data?.latestVersion,
      sourceEntityTypes: []
    });
    onValidationChange([]);
  }, [data]);

  const { data: compareVersionData } = useCompareSchema({
    id: schemaGuid,
    version: compareVersion
  });
  useEffect(() => {
    setCompareVersion(undefined);
  }, [version]);

  useEffect(() => {
    if (
      !data ||
      !schemaVersion ||
      searchParams.get("sidePanel") !== "testSchemas"
    ) {
      return;
    }
    setTitle("Test schema");
    setContent(
      <TestSourceEntityPanel
        schema={schemaVersion}
        schemaAlias={data.viewHandle}
        schemaType={data.type}
      ></TestSourceEntityPanel>
    );
    setOpen(true);
    setCustomStartWidth(40);
  }, [data, schemaVersion, searchParams]);

  const onEditorMount = (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    // Configure custom editor actions
    editorInstance.addAction({
      id: "save-draft",
      label: "Save draft",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,
      run: () =>
        (
          document.querySelector(
            "[data-es-save-schema-version-btn]"
          ) as HTMLButtonElement
        ).click()
    });
  };

  const onSchemaVersionContentChange = (
    value: string | undefined,
    _event: editor.IModelContentChangedEvent
  ) => {
    if (!data) {
      return;
    }

    setSchemaVersion((prevState) => {
      return {
        ...prevState,
        data: value
      } as ISchemaVersion;
    });
  };

  const setSchemaAsInvalid = (errors: ErrorDescriptor[]) => {
    setSchemaVersionMetadataState((prevState) => ({
      editable: prevState?.editable ?? false,
      sourceEntityTypes: []
    }));
    setSchemaVersionValidityState({
      valid: false,
      errors: errors
    });
  };

  const onValidationChange = (markers: MonacoMarker[]) => {
    if (!data || !schemaVersion) {
      return;
    }

    const evaluationService = container.resolve(SchemaEvaluationService);

    const evaluationResult = evaluationService.evaluate(
      data.type,
      schemaVersion.format,
      schemaVersion?.data ?? ``,
      {
        isUsingSourceGroups: activeTenant?.isUsingSourceGroups
      }
    );

    if (evaluationResult && !evaluationResult.valid) {
      setSchemaAsInvalid(evaluationResult.errors);
      return;
    }

    // Lastly, we ensure that our evaluators haven't missed some validation error
    if (markers.length) {
      const errors = markers.map(
        (marker) =>
          ({
            description: marker.message,
            location: {
              start: {
                column: marker.startColumn,
                line: marker.startLineNumber
              },
              end: {
                column: marker.endColumn,
                line: marker.endLineNumber
              }
            },
            column: marker.startColumn
          } as ErrorDescriptor)
      );
      setSchemaAsInvalid(errors);
      return;
    }

    // Schema version code is valid
    setSchemaVersionMetadataState((prevState) => ({
      editable: prevState?.editable ?? false,
      sourceEntityTypes: evaluationResult?.value?.sourceEntityTypes ?? []
    }));
    setSchemaVersionValidityState({
      valid: true,
      errors: []
    });
  };

  const getSchemaNameWithoutFolders = (name: string): string => {
    return name?.split("/").pop() ?? "";
  };

  const getfoldersFromSchemaName = (name: string): string[] => {
    const segments = name?.split("/") ?? [];
    segments.pop();

    return segments;
  };

  const diffComparisonOptions = (): IDiffComparison | undefined => {
    if (!compareVersion || !compareVersionData) {
      return undefined;
    }

    return {
      language: compareVersionData.version.format,
      value: compareVersionData.version.data,
      options: {}
    };
  };

  const standaloneCodeEditorOptions =
    (): editor.IStandaloneEditorConstructionOptions => {
      return {
        readOnly: !schemaVersionMetadataState?.editable
      };
    };

  const leftSideBottomBarElements = (): ReactNode => {
    if (!schemaVersionMetadataState?.editable) {
      return null;
    }

    return (
      <Flex>
        <SchemaValidationErrorsBottomBarButton
          errors={schemaVersionValidityState?.errors}
        />
        <SchemaPendingChangesBottomBarButton
          hasPendingChanges={hasPendingChanges ?? false}
        />
      </Flex>
    );
  };

  const rightSideBottomBarElements = (): ReactNode => {
    if (
      !schemaVersionValidityState.valid ||
      !schemaVersionMetadataState.editable
    ) {
      return null;
    }

    return (
      <EditorAutoSaveBottomBarButton
        enabled={autoSave}
        onToggle={() => setAutoSave(!autoSave)}
      />
    );
  };

  const BottomBarActionButtons = () => {
    return (
      <ButtonGroup justifyContent={"space-between"} my={"1em"}>
        <Button
          data-es-save-schema-version-btn
          variant="primary"
          loadingText="Saving..."
          isLoading={editSchemaIsLoading}
          isDisabled={!schemaVersionValidityState.valid || !hasPendingChanges}
          onClick={saveSchemaVersion}
        >
          Save draft
        </Button>
        <Button
          variant={"subtle"}
          isDisabled={!data}
          isLoading={isLoading}
          onClick={() => {
            const newQuery = new URLSearchParams(searchParams);
            newQuery.set("sidePanel", "testSchemas");
            setSearchParams(newQuery);
          }}
        >
          Source entities and test
        </Button>
      </ButtonGroup>
    );
  };

  const saveSchemaVersion = () => {
    if (
      !data ||
      !schemaVersion ||
      !schemaVersionMetadataState.editable ||
      !schemaVersionValidityState.valid ||
      !hasPendingChanges
    ) {
      return;
    }

    editSchemaVersion({
      payload: {
        format: schemaVersion.format,
        schema: schemaVersion.data
      },
      schemaGuid: schemaVersion.id.mappingSchemaGuid,
      version: schemaVersion.id.version!
    });
    latestSavedSchemaVersionData.current = schemaVersion?.data;
    setHasPendingChanges(false);
  };

  const ErrorHandler = ({ error }: { error: Error | null }) => {
    if (error instanceof AxiosError) {
      const response = error.response as AxiosResponse<IApiErrorResponse>;
      if (response.data.code === "TenantMismatch") {
        return (
          <TenantMismatch
            type="schema"
            tenantId={
              response.data.detail?.includes("gid://Tenant/")
                ? response.data.detail?.substring(
                    response.data.detail.indexOf("'") + 1,
                    response.data.detail.lastIndexOf("'")
                  )
                : undefined
            }
          ></TenantMismatch>
        );
      }
      if (
        response.status === HttpStatusCode.NotFound ||
        response.status === HttpStatusCode.BadRequest
      ) {
        return (
          <EmptyState
            customDescription={
              schemaGuid
                ? `No schema found for id gid://Schemas/${schemaGuid}`
                : schemaAlias
                ? `No schema found with alias: "${schemaAlias}"`
                : ""
            }
          ></EmptyState>
        );
      }
    }

    return <></>;
  };

  return (
    <>
      <MetaTitle title={"Schema"}></MetaTitle>
      <Flex direction={"column"} width={"100%"}>
        {isError ? (
          <ErrorHandler error={error}></ErrorHandler>
        ) : isLoading ? (
          <LoadingAnimation></LoadingAnimation>
        ) : data ? (
          <ContentBox
            title={getSchemaNameWithoutFolders(data.name)}
            childrenMt={8}
            headerLeft={
              data && (
                <SchemaMenu
                  versions={data?.versions}
                  currentVersion={data?.version.id.version as number}
                  schemaGuid={data?.id.mappingSchemaGuid}
                  name={data?.name}
                />
              )
            }
            headerRight={
              <Flex>
                <CompareVersionSelect
                  versions={data?.versions}
                  currentVersion={data?.version.id.version as number}
                  compareVersionCallBack={(compareVersion) =>
                    setCompareVersion(compareVersion)
                  }
                  clearSelect={compareVersion === undefined}
                />
                <ReloadButton loading={isRefetching} onClick={refetch} />
              </Flex>
            }
          >
            {!isFullscreen && (
              <Box fontSize="sm" color="gray.400">
                <Text>
                  <Text as="strong">Folder: </Text>
                  {`/${getfoldersFromSchemaName(data.name).join("/")}`}
                </Text>
                <Text>
                  <Text as="strong">Schema type: </Text>
                  {getSchemaType(data?.type)}
                </Text>
                <Text>
                  <Text as="strong">Schema format: </Text>
                  {getSchemaFormat(data?.version.format)}
                </Text>
                <Text>
                  <Text as="strong">Alias: </Text>
                  {data?.viewHandle}
                </Text>
                <Text>
                  <Text as="strong">Status: </Text>
                  {schemaVersionMetadataState?.editable ? "Editable" : "Locked"}
                </Text>
                <Text>
                  <Text as="strong">Version: </Text>
                  {data?.version.id.version}
                </Text>
              </Box>
            )}
          </ContentBox>
        ) : (
          <></>
        )}
        {data && schemaVersion && (
          <Flex mt="8" direction={"column"}>
            <Flex direction={"column"}>
              {schemaVersion.format === "javascript" ? (
                <JavascriptSchemaCodeEditor
                  value={schemaVersion.data}
                  onEditorMount={onEditorMount}
                  onValueChange={onSchemaVersionContentChange}
                  onValidationChange={onValidationChange}
                  options={standaloneCodeEditorOptions()}
                  leftSideBottomBarElements={leftSideBottomBarElements()}
                  rightSideBottomBarElements={rightSideBottomBarElements()}
                  diffComparison={diffComparisonOptions()}
                ></JavascriptSchemaCodeEditor>
              ) : (
                <JsonSchemaCodeEditor
                  value={schemaVersion.data}
                  schemaType={data.type}
                  onEditorMount={onEditorMount}
                  onValueChange={onSchemaVersionContentChange}
                  onValidationChange={onValidationChange}
                  options={standaloneCodeEditorOptions()}
                  leftSideBottomBarElements={leftSideBottomBarElements()}
                  rightSideBottomBarElements={rightSideBottomBarElements()}
                  diffComparison={diffComparisonOptions()}
                ></JsonSchemaCodeEditor>
              )}
            </Flex>
            <BottomBarActionButtons></BottomBarActionButtons>
          </Flex>
        )}
      </Flex>
    </>
  );
};

const SchemaMenu = ({
  schemaGuid,
  name,
  versions,
  currentVersion
}: {
  schemaGuid: string;
  name: string;
  currentVersion: number;
  versions: ISchemaVersionDetails[];
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<SchemaModalType>();

  const actions = [
    {
      type: "Rename",
      onClick: () => {
        onOpen();
        setModalType(SchemaModalType.EDIT);
      },
      icon: faInputText
    },
    {
      type: "Delete",
      onClick: () => {
        onOpen();
        setModalType(SchemaModalType.DELETE);
      },
      icon: faTrashCan
    },
    {
      type: "Go to version",
      onClick: () => {
        onOpen();
        setModalType(SchemaModalType.GO_TO_VERSION);
      },
      icon: faList
    }
  ];

  return (
    <>
      <Menu>
        <Tooltip label="Settings" isOpen={tooltipOpen}>
          <MenuButton
            onClick={() => setTooltipOpen(false)}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            display="block"
            ml="auto"
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={FontAwesomeIcon} icon={faEllipsisVertical} />}
            variant="ghost"
          />
        </Tooltip>
        <MenuList>
          {actions?.map((action) => (
            <MenuItem
              key={action.type}
              onClick={() => action.onClick()}
              py="3"
              icon={
                action.icon && (
                  <Icon
                    as={FontAwesomeIcon}
                    icon={action.icon}
                    color="gray.500"
                  />
                )
              }
            >
              {action.type}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <ActionModal
        onClose={onClose}
        isOpen={isOpen}
        type={modalType}
        schemaGuid={schemaGuid}
        name={name}
        versions={versions}
        currentVersion={currentVersion}
      />
    </>
  );
};

const CompareVersionSelect = ({
  versions,
  currentVersion,
  compareVersionCallBack,
  clearSelect
}: {
  versions: ISchemaVersionDetails[] | undefined;
  currentVersion: number;
  compareVersionCallBack: (compareVersion: number | undefined) => void;
  clearSelect: boolean;
}) => {
  const reactSelectStyles = useReactSelectStyles();

  useEffect(() => {
    if (clearSelect && selectedCompareVersion) {
      handleLeaveCompare();
    }
  }, [clearSelect]);

  const [selectedCompareVersion, setSelectedCompareVersion] =
    useState<ISelectOption | null>();

  const versionsOptions = () => {
    const versionsToCompare = versions?.filter(
      (version) => version.id.version !== currentVersion
    );

    versionsToCompare?.sort((a, b) => {
      const versionA = a?.id?.version ?? 0;
      const versionB = b?.id?.version ?? 0;
      if (versionA < versionB) {
        return 1;
      }
      if (versionA > versionB) {
        return -1;
      }

      return 0;
    });

    return versionsToCompare?.map((version) => ({
      label: `Version: ${version?.id?.version ?? "unknown"}`,
      value: version.id.version
    }));
  };

  const handleSelect = (value: ISelectOption) => {
    setSelectedCompareVersion(value);
    compareVersionCallBack(parseInt(value.value as string));
  };

  const handleLeaveCompare = () => {
    setSelectedCompareVersion(null);
    compareVersionCallBack(undefined);
  };

  if (!versions || versions.length === 1 || !currentVersion) {
    return null;
  }

  return (
    <Flex mr="2" w={"220px"}>
      {selectedCompareVersion && (
        <Tooltip label="Leave version compare" placement="top">
          <IconButton
            aria-label="Leave version compare"
            icon={<Icon as={FontAwesomeIcon} icon={faTimes} />}
            colorScheme="brand"
            variant="ghost"
            mr="2"
            onClick={() => handleLeaveCompare()}
          />
        </Tooltip>
      )}
      <Select
        size="sm"
        useBasicStyles
        chakraStyles={reactSelectStyles}
        value={selectedCompareVersion}
        placeholder="Compare with version"
        colorScheme="brand"
        onChange={(value) => handleSelect(value as ISelectOption)}
        options={versionsOptions()}
      />
    </Flex>
  );
};

const ActionModal = ({
  type,
  schemaGuid,
  name,
  versions,
  isOpen,
  onClose,
  currentVersion
}: {
  type: SchemaModalType | undefined;
  name: string;
  schemaGuid: string;
  versions: ISchemaVersionDetails[];
  currentVersion: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const deleteSchema = useDeleteSchema();
  const editSchema = useEditSchema(schemaGuid);
  const navigate = useNavigate();

  const onDeleteSubmit =
    async (): Promise<SchemaActionModelSubmissionResult> => {
      try {
        await deleteSchema.mutateAsync({
          schemaGuid
        });
        return {
          valid: true
        };
      } catch {
        return {
          valid: false
        };
      }
    };

  const onEditNameSubmit = async (event: {
    name: string;
  }): Promise<SchemaActionModelSubmissionResult> => {
    try {
      await editSchema.mutateAsync({
        schemaGuid,
        payload: {
          name: event.name
        }
      });
      return {
        valid: true
      };
    } catch {
      return {
        valid: false
      };
    }
  };

  switch (type) {
    case SchemaModalType.DELETE:
      return (
        <DeleteModal
          type="schema"
          name={name}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onDeleteSubmit}
          onSuccess={() => {
            onClose();
            navigate("/schemas");
          }}
        />
      );
    case SchemaModalType.EDIT:
      return (
        <EditNameModal
          type="schema"
          name={name}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onEditNameSubmit}
          onSuccess={() => {
            onClose();
          }}
        />
      );
    case SchemaModalType.GO_TO_VERSION:
      return (
        <GoToVersionModal
          versions={versions}
          currentVersion={currentVersion}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};

export default SchemaDetailPage;
