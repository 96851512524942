import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { SCHEMAS } from "../../../constants/queryKey";
import { useTenantStore } from "../../tenants/store";
import { SchemaService } from "../schema.service";

export const getSchemas = async (query?: string) => {
  const schemaService = container.resolve(SchemaService);

  return !query
    ? schemaService.getSchemas()
    : schemaService.searchSchemas(query);
};

export const useSchemas = (query?: string) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({
      activeTenant
    }))
  );

  return useQuery({
    queryKey: [SCHEMAS, query],
    queryFn: () => getSchemas(query),
    enabled: !!activeTenant.id
  });
};
