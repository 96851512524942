import { ObjectProperty } from "@babel/types";
import { ErrorDescriptor } from "../../../../types/codeEditor";
import { FunctionValidator } from "./function-base.validator";

export class ItemsFunctionValidator implements FunctionValidator {
  validate(property: ObjectProperty): ErrorDescriptor[] {
    if (
      !["ArrowFunctionExpression", "FunctionExpression"].some(
        (expression) => expression === property.value.type
      )
    ) {
      return [
        {
          description: "Items must be a function",
          location: property.loc ? { ...property.loc } : undefined
        }
      ];
    }
    return [];
  }
}
