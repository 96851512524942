import { Select } from "chakra-react-select";
import _ from "lodash";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";
import useDestinationsByTenant from "../../destinations/api/useDestinationByTenant";

const ServiceNamePicker = ({
  value,
  onChange
}: {
  value: string | null;
  onChange(value: string): void;
}) => {
  const reactSelectStyles = useReactSelectStyles();
  const defaultServiceOptions: ISelectOption[] = [
    {
      label: "Ingest API",
      value: "Ingest API"
    },
    {
      label: "Management API",
      value: "Management API"
    },
    {
      label: "Processing Worker",
      value: "Processing Worker"
    },
    {
      label: "Import Worker (weu)",
      value: "Import Worker - weu"
    },
    {
      label: "Import Worker (sea)",
      value: "Import Worker - sea"
    },
    {
      label: "Delivery API (weu)",
      value: "Delivery API - weu"
    },
    {
      label: "Delivery API (sea)",
      value: "Delivery API - sea"
    }
  ];
  const { data: destinations } = useDestinationsByTenant();
  const serviceOptions = _.sortBy(
    [
      ...defaultServiceOptions,
      ...(destinations?.map((d) =>
        // Temp fix for destinations until new webhook destination is deployed
        d.name === "Webhook"
          ? {
              label: `Destinations - ${d.name}`,
              value: `Webhooks`
            }
          : {
              label: `Destinations - ${d.name}`,
              value: `Destinations - ${d.name}`
            }
      ) ?? [])
    ],
    (x) => x.label
  );

  const selectedServiceName = value
    ? serviceOptions.find((f) => f.value === value)
    : null;

  return (
    <Select
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select service"
      options={serviceOptions}
      value={selectedServiceName}
      onChange={(value) => {
        const opt = value as ISelectOption;
        onChange(opt.value as string);
      }}
    />
  );
};

export default ServiceNamePicker;
