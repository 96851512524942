import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import {
  IDeleteAllSourceEntitiesInSourceResponse,
  ISourceCreatePayload,
  ISourceEditPayload,
  ISourceResponse,
  ISourceResponseId
} from "./types";

@singleton()
export class SourceService {
  public constructor(private readonly http: ManagementClient) {}

  async createDataSource(payload: ISourceCreatePayload) {
    const { data } = await this.http.post<
      ISourceResponseId,
      ISourceCreatePayload
    >("/api/v1/tenant/sources", payload);
    return data;
  }

  async getDataSources() {
    const { data } = await this.http.get<ISourceResponse[]>(
      "/api/v1/tenant/sources"
    );
    return data;
  }

  async editDataSource(id: string, payload: ISourceEditPayload) {
    const { data } = await this.http.put<ISourceResponseId>(
      `/api/v1/tenant/sources/${id}`,
      payload
    );
    return data;
  }

  async deleteDataSource(id: string) {
    await this.http.delete(`/api/v1/tenant/sources/${id}`);
  }

  async deleteAllEntities(sourceId: string) {
    const { data } =
      await this.http.delete<IDeleteAllSourceEntitiesInSourceResponse>(
        `/api/v1/tenant/sources/${sourceId}/entities`
      );
    return data;
  }

  async getEntityTypesInSource(sourceId: string) {
    const { data } = await this.http.get<string[]>(
      `/api/v1/tenant/sources/${sourceId}/entityTypes`
    );
    return data;
  }
}
