import { ReactNode } from "react";

export enum EmptyStateType {
  DEFAULT = "default",
  NO_SOURCE_ENTITIES = "noSourceEntities",
  NO_RECEIVED_INVITATIONS = "noReceivedInvitations",
  NO_SENT_INVITATIONS = "noSentInvitations",
  ENVIRONMENT_CLIENTS_NO_DOMAINS = "noDomains",
  NO_SCHEMA_CHANGES = "noSchemaChanges",
  NO_SCHEMAS = "noSchemas",
  NO_ENVIRONMENTS_IN_VERSIONS = "noEnvironmentsInVersions",
  NO_SCHEMAS_IN_VERSIONS = "noSchemasInVersions",
  VIEW_NOT_AVAILABLE_ON_MOBILE = "viewNotAvailableOnMobile",
  ROUTE_INSPECTOR_NO_ENVIRONMENT_CLIENTS = "routeInspectorNoEnvironmentClients",
  ROUTE_INSPECTOR_NO_ROUTES_FOUND = "routeInspectorNoRoutesFound",
  ROUTE_INSPECTOR_ENTER_VALUES_TO_SEARCH = "routeInspectorEnterValuesToSearch",
  TENANT_HEALTH_NO_ERRORS = "tenantHealtNoErrors"
}

export default interface IEmptyStateConfig {
  title: string;
  description?: ReactNode;
  illustration?: ReactNode;
}
