import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input
} from "@chakra-ui/react";
import { useState } from "react";
import { ISchemaListFilterProps } from "../types/schema-list";

const SchemaListFilter = ({
  onTermChange,
  advancedSearch,
  onAdvancedSearch,
  term,
  query,
  children
}: ISchemaListFilterProps) => {
  const [advancedQuery, setAdvancedQuery] = useState(query);

  return (
    <Flex alignItems="flex-end" mb="4">
      {!advancedSearch && (
        <FormControl mr="4">
          <FormLabel color="gray.500" fontSize="xs">
            Filter schemas
          </FormLabel>
          <Input
            placeholder="Type name or alias of a schema"
            value={term}
            onChange={(e) => onTermChange(e.target.value)}
          />
        </FormControl>
      )}
      {advancedSearch && (
        <HStack direction="row" w={"100%"} alignItems="end">
          <FormControl>
            <FormLabel color="gray.500" fontSize="xs" w={"100%"}>
              Search in schemas
            </FormLabel>
            <Input
              placeholder="Search for content of a schema"
              value={advancedQuery}
              onChange={(e) => setAdvancedQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  return onAdvancedSearch(advancedQuery);
                }
              }}
            />
          </FormControl>
          <Button
            variant="subtle"
            h={"38px"}
            mr="4"
            onClick={() => onAdvancedSearch(advancedQuery)}
          >
            Search
          </Button>
        </HStack>
      )}
      {children}
    </Flex>
  );
};

export default SchemaListFilter;
