import {
  Badge,
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDown,
  faEllipsisVertical
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { ISourceGroup, SourceGroupModalType } from "../types";

const SourceGroup = ({
  name,
  alias,
  numberOfSources,
  type,
  children,
  marginBottom,
  openModalCallback,
  gid
}: ISourceGroup) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <chakra.details
      bg={useColorModeValue("gray.50", "gray.700")}
      borderRadius="md"
      sx={{
        "&[open]": {
          ".chevron": {
            transform: "rotate(180deg)"
          }
        }
      }}
      mb={marginBottom}
    >
      <chakra.summary
        _hover={{ bg: useColorModeValue("gray.100", "gray.800") }}
        cursor="pointer"
        padding="12px 18px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          "&::-webkit-details-marker": {
            display: "none"
          }
        }}
      >
        <Flex alignItems="center">
          <Icon
            className="chevron"
            as={FontAwesomeIcon}
            icon={faChevronDown}
            mr="4"
            transition="transform 0.2s"
          />
          <Flex flexDirection="column" alignItems="flex-start">
            <Flex alignItems="flex-end">
              <Box fontSize="md" fontWeight="semibold">
                {name}
              </Box>
              <Box fontSize="sm" ml="1" color="gray.500">
                ({numberOfSources} source
                {numberOfSources !== 1 && "s"})
              </Box>
            </Flex>
            <Box mt="1" fontSize="xs" color="gray.500">
              Alias: {alias}
            </Box>
            <AdminTableRowId id={gid} />
          </Flex>
        </Flex>

        <Flex>
          <Box mr="4">
            <Badge
              variant="outline"
              fontSize="md"
              fontWeight="normal"
              colorScheme="gray"
              borderRadius="md"
            >
              {type}
            </Badge>
          </Box>
          <Menu>
            <Tooltip label="Settings" isOpen={tooltipOpen}>
              <MenuButton
                onClick={() => setTooltipOpen(false)}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                display="block"
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={FontAwesomeIcon} icon={faEllipsisVertical} />}
                variant="ghost"
              />
            </Tooltip>
            <MenuList>
              <MenuItem
                onClick={() =>
                  openModalCallback(SourceGroupModalType.EDIT_SOURCE_GROUP)
                }
                py="3"
                icon={
                  <Icon as={FontAwesomeIcon} icon={faPencil} color="gray.500" />
                }
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() =>
                  openModalCallback(SourceGroupModalType.DELETE_SOURCE_GROUP)
                }
                py="3"
                icon={
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faTrashCan}
                    color="gray.500"
                  />
                }
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </chakra.summary>
      <SimpleGrid padding="12px 18px" columns={{ base: 1, xl: 2 }} spacing={10}>
        {children}

        <Flex
          p="24px"
          bg={useColorModeValue("white", "gray.900")}
          border="1px dashed"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          borderRadius="md"
          mb="0"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box color="gray.500" fontSize="md" mb="4">
            Add a new data source to this group
          </Box>
          <Box>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={() =>
                openModalCallback(SourceGroupModalType.EDIT_SOURCE_GROUP)
              }
            >
              Add data source
            </Button>
          </Box>
        </Flex>
      </SimpleGrid>
    </chakra.details>
  );
};

export default SourceGroup;
