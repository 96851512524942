import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import { useState } from "react";
import { IEnvironmentClientDomainsModalProps } from "../types";
import EnvironmentClientDomainsTable from "./EnvironmentClientDomainsTable";

const EnvironmentClientDomainsModal = ({
  name,
  envClientDomains,
  isOpen,
  onClose,
  onSuccess
}: IEnvironmentClientDomainsModalProps) => {
  const initialSelectedDomainsIds = envClientDomains
    ? envClientDomains.map((item) => item.id)
    : [];

  const [selectedDomainIds, setSelectedDomainIds] = useState<string[]>(
    initialSelectedDomainsIds
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
        <ModalHeader>Domains for {name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text pb="4">
            Add the domains from which you want to use this environment client
          </Text>
          <EnvironmentClientDomainsTable
            envClientDomains={envClientDomains}
            tableCallback={(ids) => {
              setSelectedDomainIds(ids);
            }}
          />
          <Flex pt={8} justifyContent="flex-end">
            <Button colorScheme="gray" variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="primary"
              isLoading={false}
              type="submit"
              isDisabled={isEqual(selectedDomainIds, initialSelectedDomainsIds)}
              onClick={() => onSuccess(selectedDomainIds)}
            >
              Save changes
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnvironmentClientDomainsModal;
