import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANTS } from "../../../constants/queryKey";
import { TenantService } from "../tenants.service";
import { ITenant } from "../types";

export const getTenants = async (
  tenantIds: string[],
  retries = 3,
  delay = 1000
): Promise<ITenant[]> => {
  const tenantService = container.resolve(TenantService);

  const fetchTenants = async (): Promise<ITenant[]> => {
    return await tenantService.getByIds(
      tenantIds.map((tenantId) => tenantId.replace("gid://Tenant/", ""))
    );
  };

  const retryFetch = async (
    retries: number,
    delay: number
  ): Promise<ITenant[]> => {
    let tenants: ITenant[] = [];
    // Force retry if counts does not match.
    // It occurs when a user with tenants accept an invite, but update are not reflected in db yet.
    // Not sure if this is the best approach.
    // Can lead to slightly increased response time if user have trashed tenants.
    // How do we delete from user tenant list?
    for (let i = 0; i < retries; i++) {
      tenants = await fetchTenants();
      if (tenantIds.length === tenants.length) {
        return tenants;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    return tenants;
  };

  return retryFetch(retries, delay); // Retry up to 3 times with a 1-second delay
};

export const useTenants = ({ tenantIds }: { tenantIds?: string[] }) => {
  return useQuery({
    queryKey: [TENANTS, tenantIds],
    queryFn: () => {
      if (!tenantIds || tenantIds.length === 0) {
        return [];
      }

      return getTenants(tenantIds, 3, 500);
    },
    enabled: !!tenantIds
  });
};
