import { ILogsState } from "../types";

export const DefaultActivityState: ILogsState = {
  items: [],
  types: ["ACTIVITY"],
  period: {
    from: "1h",
    to: undefined
  },
  sortingDirection: "Descending",
  systemColumns: ["timestamp"],
  selectedColumns: ["message", "properties.ActorId"],
  displayType: "DISPLAY_VALUE_LINK"
};
