import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";
import { ICreateDestinationEnvironmentConfigurationDto } from "../types";

const createDestinationEnvironmentConfigurationFn = (
  dto: ICreateDestinationEnvironmentConfigurationDto
) => {
  const destinationService = container.resolve(DestinationsService);

  return destinationService.addEnvironmentConfigurationToDestinationConfiguration(
    dto
  );
};

const useCreateDestinationEnvironmentConfiguration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDestinationEnvironmentConfigurationFn,
    onSuccess: (_data, { alias }) =>
      queryClient.invalidateQueries({ queryKey: [DESTINATIONS, alias] })
  });
};

export default useCreateDestinationEnvironmentConfiguration;
