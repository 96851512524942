import { injectable, registry } from "tsyringe";
import JavascriptSchemaVersionFormat from "./JavascriptSchemaVersionFormat";
import { JsonSchemaVersionFormat } from "./JsonSchemaVersionFormat";

export const INJECTION_TOKEN_FORMATTERS = Symbol("ISchemaVersionFormat");

@injectable()
@registry([
  {
    token: INJECTION_TOKEN_FORMATTERS,
    useToken: JavascriptSchemaVersionFormat
  },
  { token: INJECTION_TOKEN_FORMATTERS, useToken: JsonSchemaVersionFormat }
])
export class SchemaVersionFormatModule {}
