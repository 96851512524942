import { ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import RouteInspector from "../features/routes/components/RouteInspector";

const RouteInspectorPage = () => {
  const title = "Route inspector";

  return (
    <>
      <MetaTitle title={title}></MetaTitle>

      <ContentBox
        title={title}
        help={
          <Stack>
            <Text>Inspect your routes by URL or handle.</Text>
            <Text>Types of routes.</Text>
            <UnorderedList>
              <ListItem>
                Handle: A route created in a schema using context.handle('')
              </ListItem>
              <ListItem>
                URL: A route created in a schema using context.url('')
              </ListItem>
              <ListItem>
                Explicit redirect: A route created in a schema using
                context.url('')
              </ListItem>
              <ListItem>
                Implicit redirect: Automatically created redirects based on data
                from the `redirects` property on a source entity
              </ListItem>
            </UnorderedList>
          </Stack>
        }
      >
        <RouteInspector />
      </ContentBox>
    </>
  );
};

export default RouteInspectorPage;
