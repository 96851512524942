import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENTS } from "../../../constants/queryKey";
import { EnvironmentService } from "../environment.service";
import { IEnvironmentEditPayload } from "../types";

export const editEnvironment = ({
  environmentId,
  payload
}: {
  environmentId: string;
  payload: IEnvironmentEditPayload;
}) => {
  const environmentService = container.resolve(EnvironmentService);
  return environmentService.editEnvironment(environmentId, payload);
};

export const useEditEnvironment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editEnvironment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ENVIRONMENTS] });
    }
  });
};
