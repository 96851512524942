export interface IApiResponse<T> {
  status: number;
  data: T;
  headers: { [key: string]: unknown };
}

export interface IPagination {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

export interface IPaginationResponse {
  hasNextPage: boolean;
  hasPreviousPage?: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface IPaginated<T> {
  total: number;
  pageInfo: IPaginationResponse;
  results: T[];
}

export enum ApiResponseStatusCode {
  OK = 200,
  FORBIDDEN = 403
}

export enum DeliveryApiVersionTypes {
  V1 = "v1",
  V2 = "v2"
}

export interface ProblemDetailsDto {
  title: string;
  detail: string;
}

export type SortingDirection = "ASC" | "DESC";

export interface ISortBy {
  propertyName: string;
  direction: SortingDirection;
}

export interface IPage<T> {
  total: number;
  results: T[];
}

export type QueryOptions = {
  tenantId?: string;
};
