import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { useCreateManagementClient } from "../api/createManagementClient";

const CreateManagementClientModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toaster = useCustomToast();

  const createManagementClient = useCreateManagementClient();

  const inputBgColorMode = useColorModeValue("white", "gray.700");

  return (
    <>
      <Button variant="subtle" onClick={onOpen}>
        Create
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Create new Management Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">Give your management client a descriptive name.</Text>
            <Formik
              initialValues={{ name: "" }}
              validateOnBlur={false}
              onSubmit={async ({ name }) => {
                onClose();

                await toaster.subscribe(
                  createManagementClient.mutateAsync({
                    payload: { name }
                  }),
                  {
                    type: "management client",
                    actionNames: ["created", "creating"]
                  }
                );
              }}
            >
              {({ dirty, errors }) => (
                <Form>
                  <Field name="name" validate={isRequired("Name is required")}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={!!form.errors.name}>
                        <FormLabel>Name</FormLabel>
                        <Input
                          data-1p-ignore
                          bg={inputBgColorMode}
                          autoFocus
                          {...field}
                          placeholder="Your management client name"
                        />
                        <FormErrorMessage>
                          {form.errors.name?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex pt={8} justifyContent="flex-end">
                    <Button
                      colorScheme="gray"
                      variant="ghost"
                      mr={3}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      isLoading={createManagementClient.isPending}
                      isDisabled={!dirty || !!errors.name}
                      type="submit"
                    >
                      Create
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateManagementClientModal;
