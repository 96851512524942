import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import {
  DEPLOYMENTS_FROM_ENVIRONMENT,
  SCHEMA
} from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";
import { ISchemaVersionEditPayload } from "../types";

export const editSchemaVersion = ({
  schemaGuid,
  version,
  payload
}: {
  schemaGuid: string;
  version: number;
  payload: ISchemaVersionEditPayload;
}) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.editSchemaVersion(schemaGuid, version, payload);
};

export const useEditSchemaVersion = (schemaGuid: string) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: editSchemaVersion,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          (queryKey[0] === SCHEMA && queryKey[1] === schemaGuid) ||
          queryKey[0] === DEPLOYMENTS_FROM_ENVIRONMENT
      });

      toast({
        title: "Schema was updated",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error updating schema",
        status: "error",
        ...defaultToast
      });
    }
  });
};
