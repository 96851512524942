import { ReactNode } from "react";
import ContentBox from "../ContentBox";

const MetricsTile = ({
  title,
  subtitle,
  help,
  children,
  removeTileMenu
}: {
  title: string;
  subtitle?: ReactNode;
  help: string;
  children: ReactNode;
  removeTileMenu?: ReactNode;
}) => {
  return (
    <ContentBox
      title={title}
      subTitle={subtitle}
      help={help}
      fullHeight={true}
      childrenMt={16}
      headerRight={removeTileMenu}
    >
      {children}
    </ContentBox>
  );
};

export default MetricsTile;
