import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { Reducer } from "../reducers/schemas-tree";
import { DefaultState } from "../states/schema-list";
import { ITreeState, SchemasTreeAction } from "../types/schema-list";

export const TreeStateContext = createContext<{
  state: ITreeState;
  dispatch: React.Dispatch<SchemasTreeAction>;
}>({
  state: DefaultState,
  dispatch: () => null
});

export const TreeProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, DefaultState);
  return (
    <TreeStateContext.Provider value={{ state, dispatch }}>
      {children}
    </TreeStateContext.Provider>
  );
};

export const useTreeState = () => {
  return useContext(TreeStateContext);
};
