import { Icon, IconButton, Tooltip, useColorMode } from "@chakra-ui/react";
import { faMoon, faSunBright } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DarkModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Tooltip
      label={colorMode === "light" ? "Toggle dark mode" : "Toggle light mode"}
    >
      <IconButton
        onClick={toggleColorMode}
        _hover={{ opacity: 0.75 }}
        variant="ghost"
        aria-label={
          colorMode === "light" ? "Toggle dark mode" : "Toggle light mode"
        }
        icon={
          <Icon
            as={FontAwesomeIcon}
            icon={colorMode === "dark" ? faMoon : faSunBright}
            size="xl"
            color="gray.500"
            cursor="pointer"
          />
        }
      />
    </Tooltip>
  );
};

export default DarkModeToggle;
