import { faGear, faGears, faList } from "@fortawesome/pro-solid-svg-icons";
import { SchemaType } from "../types";

const getSchemaTypeIcon = (type: SchemaType | null | undefined) => {
  switch (type) {
    case "normal":
      return faGears;
    case "partial":
      return faGear;
    case "collection":
      return faList;
    default:
      return faGears;
  }
};

export default getSchemaTypeIcon;
