import { Identifier, ObjectProperty, ReturnStatement } from "@babel/types";
import { ErrorDescriptor } from "../../../../types/codeEditor";
import { FunctionValidator } from "./function-base.validator";
import { BaseFunctionExpression, LiteralValue } from "./javascript";

export class ObjectReturnStatementValidator implements FunctionValidator {
  validate(property: ObjectProperty): ErrorDescriptor[] {
    const propertiesFunction = property.value as BaseFunctionExpression;
    if (propertiesFunction.body.type === "BlockStatement") {
      const returnStatement = propertiesFunction.body.body.find(
        (b) => b.type === "ReturnStatement"
      ) as ReturnStatement;
      if (!returnStatement) {
        return [
          {
            description: "lacking return statement",
            location: property.loc ? { ...property.loc } : undefined
          }
        ];
      }

      if (this.isSourceEntityPassTrough(propertiesFunction, returnStatement)) {
        return [];
      }

      const result = this.validateReturnType(property, returnStatement);
      if (result?.length) {
        return result;
      }
    }
    return [];
  }

  private validateReturnType(
    property: ObjectProperty,
    returnStatement: ReturnStatement
  ) {
    if (returnStatement.argument?.type === "ObjectExpression") {
      return;
    }

    let description = "method must return object";
    if (
      returnStatement.argument &&
      (returnStatement.argument.type === "StringLiteral" ||
        returnStatement.argument.type === "NumericLiteral" ||
        returnStatement.argument.type === "BooleanLiteral")
    ) {
      const literal = returnStatement.argument as LiteralValue;
      description += ` and not ${typeof literal.value}`;
    } else if (
      returnStatement &&
      returnStatement.argument?.type === "ArrayExpression"
    ) {
      description += ` and not array`;
    }

    return [
      {
        description,
        location: property.loc ? { ...property.loc } : undefined
      }
    ];
  }

  private isSourceEntityPassTrough(
    propertiesFunction: BaseFunctionExpression,
    returnStatement: ReturnStatement
  ) {
    const sourceEntityParam = propertiesFunction.params[0] as Identifier;
    return (
      sourceEntityParam.name ===
        (returnStatement.argument as Identifier).name ||
      // this may seem odd, but it allows all identifiers to be pass through.
      // We could build logic to search for the note of latest assignment and validate if object or not,
      // but that will be a later time.
      !!(returnStatement.argument as Identifier)
    );
  }
}
