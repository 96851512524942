import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import RegenerateApiKeyModal from "../../../components/modals/RegenerateApiKeyModal";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { useDeleteManagementClient } from "../api/deleteManagementClient";
import { useEditManagementClient } from "../api/editManagementClient";
import { useRegenerateAccessKeyManagementClient } from "../api/regenerateAccessKeyManagementClient";
import { IManagementClient, ManagementClientTableModalType } from "../types";

const ManagementClientsActionModal = ({
  type,
  managementClient,
  isOpen,
  onClose,
  onAccessKeyRegenerated
}: {
  type: ManagementClientTableModalType | undefined;
  managementClient: IManagementClient;
  isOpen: boolean;
  onClose: () => void;
  onAccessKeyRegenerated: (accessKey: string) => void;
}) => {
  const toaster = useCustomToast();
  const deleteManagementClient = useDeleteManagementClient();
  const editManagementClient = useEditManagementClient();
  const regenerateAccessKey = useRegenerateAccessKeyManagementClient();

  switch (type) {
    case ManagementClientTableModalType.DELETE:
      return (
        <DeleteModal
          type="environment"
          name={managementClient?.name || ""}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={async () => {
            onClose();

            await toaster.subscribe(
              deleteManagementClient.mutateAsync({
                managementClientGuid: managementClient.managementClientGuid
              }),
              {
                type: "management client",
                actionNames: ["deleted", "deleting"]
              }
            );
          }}
        />
      );
    case ManagementClientTableModalType.EDIT:
      return (
        <EditNameModal
          type="environment client"
          name={managementClient.name}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={async ({ name }) => {
            onClose();

            await toaster.subscribe(
              editManagementClient.mutateAsync({
                managementClientGuid: managementClient.managementClientGuid,
                payload: {
                  name
                }
              }),
              {
                type: "management client",
                actionNames: ["edited", "editing"],
                description: `Updating management client name from "${managementClient.name}" to "${name}"`
              }
            );

            return { valid: true };
          }}
        />
      );
    case ManagementClientTableModalType.REGENERATE_API_KEY:
      return (
        <RegenerateApiKeyModal
          name={managementClient?.name || ""}
          description="This will immediately cause all Management API request with the previous API key to be denied for this management client."
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={async () => {
            await toaster.subscribe(
              regenerateAccessKey.mutateAsync({
                managementClientGuid: managementClient.managementClientGuid
              }),
              {
                titles: [
                  "Successfully regenerated API key",
                  "Regenerating API key"
                ],
                description: `Regenerating management client "${managementClient.name}'s" API key`
              }
            );
            onAccessKeyRegenerated(managementClient.accessKey);
            onClose();
          }}
        />
      );
    default:
      return null;
  }
};

export default ManagementClientsActionModal;
