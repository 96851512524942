import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import {
  faCheck,
  faPencil,
  faTag,
  faTrashCan
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { DomainTableModalType, IDomain, IDomainTable } from "../types";

import ConfirmModal from "../../../components/modals/ConfirmModal";
import ReloadButton from "../../../components/ReloadButton";
import StringListPopover from "../../../components/StringListPopover";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { DataTableAction } from "../../../types/dataTable";
import { useEditDomain } from "../api/editDomain";
import { useDomains } from "../api/getDomains";
import DomainHostnamesModal from "./DomainHostnamesModal";
import DomainsActionModal from "./DomainsActionModal";

const DomainsTable = () => {
  const { data, isLoading, refetch, isRefetching } = useDomains();
  const [tableData, setTableData] = useState<IDomainTable[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setTableData(
      data.map(({ name, hostnames, id: { idValue, domainGuid } }) => ({
        name,
        hostnames,
        domainGuid,
        id: idValue,
        useRelativeUrls: hostnames.includes("root.tld")
      }))
    );
  }, [data]);

  useEffect(() => {
    setRowData((row) =>
      !row ? row : tableData.find((d) => d.id === row.id) ?? null
    );
  }, [tableData]);

  const ThisDomainUsesRelativeUrls = ({
    name,
    hostnames,
    domainGuid
  }: {
    name: string;
    hostnames: string[];
    domainGuid: string;
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const editDomain = useEditDomain();

    const removeRootTld = () => {
      editDomain.mutate({
        id: domainGuid,
        payload: {
          name: name,
          hostnames: hostnames.filter((hostname) => hostname !== "root.tld")
        }
      });
    };

    return (
      <>
        <Flex alignItems="center">
          <Icon
            as={FontAwesomeIcon}
            icon={faCheck}
            color="green"
            size="lg"
            pr="2"
          />
          <Text fontStyle="italic" color="gray.500" pr="2">
            This domain uses relative URL's
          </Text>
          <Tooltip label="Remove relative URL's from this domain">
            <IconButton
              onClick={onOpen}
              variant="ghost"
              colorScheme="red"
              icon={<Icon as={FontAwesomeIcon} icon={faTrashCan} />}
              aria-label={"Remove relative URL's from this domain"}
            />
          </Tooltip>
        </Flex>
        <ConfirmModal
          title="Remove relative URL's from domain"
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={() => {
            removeRootTld();
            onClose();
          }}
        >
          <Text>
            Are you sure you want to remove relative URL's from the{" "}
            <Text as="b">{name}</Text>-domain?
          </Text>
        </ConfirmModal>
      </>
    );
  };

  const columnHelper = createColumnHelper<IDomainTable>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.name}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("hostnames", {
      cell: (info) => <StringListPopover list={info.getValue()} />,
      header: "Hostnames"
    }),
    columnHelper.accessor("useRelativeUrls", {
      cell: (props) =>
        props.row.original.useRelativeUrls && (
          <ThisDomainUsesRelativeUrls
            name={props.row.original.name}
            hostnames={props.row.original.hostnames}
            domainGuid={props.row.original.domainGuid}
          />
        ),
      id: "relativeUrls",
      header: "Use relative URL's"
    })
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenHostnames,
    onOpen: onOpenHostnames,
    onClose: onCloseHostnames
  } = useDisclosure();

  const [modalType, setModalType] = useState<DomainTableModalType>();
  const [rowData, setRowData] = useState<IDomain | null>(null);

  const handleActionClick = (row: IDomain, type: DomainTableModalType) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<DomainTableModalType, IDomain>[] = [
    {
      type: DomainTableModalType.EDIT,
      icon: faPencil,
      onClick: (row: IDomain) => {
        handleActionClick(row, DomainTableModalType.EDIT);
      }
    },
    {
      type: DomainTableModalType.EDIT_HOSTNAMES,
      icon: faTag,
      onClick: (row: IDomain) => {
        setRowData(row);
        onOpenHostnames();
      }
    },
    {
      type: DomainTableModalType.DELETE,
      icon: faTrashCan,
      onClick: (row: IDomain) => {
        handleActionClick(row, DomainTableModalType.DELETE);
      }
    }
  ];

  return (
    <>
      <ReloadButton loading={isRefetching} onClick={refetch} />
      <DataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        actions={actions}
      />
      {rowData && (
        <>
          <DomainsActionModal
            type={modalType}
            domain={rowData}
            isOpen={isOpen}
            onClose={onClose}
          />

          {/* This modal exists outside the ActionsModal, in order to not rerender on domains query invalidation on host name update */}
          <DomainHostnamesModal
            domain={rowData}
            isOpen={isOpenHostnames}
            onClose={onCloseHostnames}
          />
        </>
      )}
    </>
  );
};

export default DomainsTable;
