import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { IDomainHostnamesModalProps } from "../types";
import CreateDomainHostnameModal from "./CreateDomainHostnameModal";
import DomainHostnamesTable from "./DomainHostnamesTable";

const DomainHostnamesModal = ({
  domain,
  isOpen,
  onClose
}: IDomainHostnamesModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
        <ModalHeader>Edit hostnames for {domain.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text pb="4">Add the hostnames you want to use this domain</Text>
          <Box
            p="4"
            bg={useColorModeValue("white", "gray.900")}
            border="1px solid"
            borderColor={useColorModeValue("gray.200", "gray.900")}
            borderRadius="sm"
          >
            <Flex pb="4" justifyContent="space-between" alignItems="center">
              <Text fontSize="md" fontWeight="semibold">
                Hostnames
              </Text>
              <CreateDomainHostnameModal domain={domain} />
            </Flex>
            <DomainHostnamesTable domain={domain} />
          </Box>
          <Flex pt={8} justifyContent="flex-end">
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DomainHostnamesModal;
