const camelize = (str: string) => {
  // Camelcase string
  str = str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) {
      return "";
    }
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

  // Remove non alpha chars
  const regex = /[^A-Za-z0-9]/g;
  return str.replace(regex, "");
};

export default camelize;
