import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { QueryOptions } from "../../types/api";
import { IDomainResponse } from "../domains/types";
import { IEnvironmentClientResponse } from "../environment-clients/types";
import { IEnvironmentResponse } from "../environments/types";
import { IManagementClientResponse } from "../management-clients/types";
import { ISchemaListResponse } from "../schemas/types";
import { ISourceGroupsResponse } from "../source-groups/types";
import { ITenantUser } from "../tenants/types";
import { enhanceLogEntry } from "./helpers/logEnhancer";
import {
  IAggregatedLogsPayload,
  IAggregatedLogsResponse,
  IEnhancedLogsResponse,
  ILogsPayload,
  ILogsResponse
} from "./types";

@singleton()
export class LogsService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async getAggregatedLogs(
    payload: IAggregatedLogsPayload,
    options?: QueryOptions
  ) {
    const { data } = await this.managementClient.post<IAggregatedLogsResponse>(
      `/api/v1/tenant/logs/aggregate`,
      {
        ...payload
      },
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );

    return data;
  }

  public async getLogs(payload: ILogsPayload, options?: QueryOptions) {
    const { data } = await this.managementClient.post<ILogsResponse>(
      `/api/v1/tenant/logs`,
      {
        ...payload
      },
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );

    return data;
  }

  public async getEnhancedLogs(
    payload: ILogsPayload,
    schemas: ISchemaListResponse[],
    environments: IEnvironmentResponse[],
    sources: ISourceGroupsResponse[],
    environmentClients: IEnvironmentClientResponse[],
    tenantUsers: ITenantUser[],
    domains: IDomainResponse[],
    managementClients: IManagementClientResponse[],
    options?: QueryOptions
  ): Promise<IEnhancedLogsResponse> {
    const { data } = await this.managementClient.post<ILogsResponse>(
      `/api/v1/tenant/logs`,
      {
        ...payload
      },
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );

    return {
      ...data,
      logLines: data.logLines.map((m) =>
        enhanceLogEntry(
          m,
          schemas,
          environments,
          sources,
          environmentClients,
          tenantUsers,
          domains,
          managementClients
        )
      )
    };
  }
}
