import { Box, useColorModeValue } from "@chakra-ui/react";
import { chakraComponents, GroupBase, OptionProps } from "chakra-react-select";
import useAdminFeatures from "../helpers/useAdminFeatures";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";
import {
  IIdDisplayFormat,
  simpleIdValueDisplayFormat
} from "./../helpers/idFormatters";
import AdminTableRowId from "./table/AdminTableRowId";

class EnterspeedIdentifiableSelect {
  idDisplayFormatter: IIdDisplayFormat;

  constructor(formatter?: IIdDisplayFormat) {
    this.idDisplayFormatter = formatter ?? simpleIdValueDisplayFormat;
  }

  Option = ({
    children,
    ...props
  }: OptionProps<
    IEnterspeedIdentifiableSelectOption,
    true,
    GroupBase<IEnterspeedIdentifiableSelectOption>
  > & { idFormatter?: (id: string) => string }) => {
    const bgColor = useColorModeValue("gray.200", "gray.700");
    const adminFeatures = useAdminFeatures();

    return (
      <chakraComponents.Option {...props}>
        {adminFeatures && (
          <Box
            mr="1"
            py="0.5"
            px="1"
            borderRadius="sm"
            fontSize="x-small"
            bg={bgColor}
            textTransform="uppercase"
          >
            <AdminTableRowId
              id={props.data.id}
              formatter={this.idDisplayFormatter}
            ></AdminTableRowId>
          </Box>
        )}

        {children}
      </chakraComponents.Option>
    );
  };
}

export default EnterspeedIdentifiableSelect;
