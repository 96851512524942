import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import {
  faLock,
  faLockOpen,
  faPencil,
  faTrashCan
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreatableSelect } from "chakra-react-select";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import EnvironmentBadge from "../../../components/EnvironmentBadge";
import MultiDropdown from "../../../components/MultiDropdown";
import { SourceTypes } from "../../../constants/source";
import camelize from "../../../helpers/camelize";
import { isRequired } from "../../../helpers/fieldIsRequired";
import useMixPanel from "../../../mixpanel/useMixPanel";
import ISelectOption from "../../../types/selectInput";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { useInputColors } from "../../tenants/hooks/colors";
import { useCreateSourceGroup } from "../api/createSourceGroup";
import { useEditSourceGroup } from "../api/editSourceGroup";
import { ISourceGroupsResponse, ISourceItem } from "../types";

const CreateSourceGroupModal = ({
  isOpen,
  onClose,
  dataSourceBeingEdited
}: {
  isOpen: boolean;
  onClose: () => void;
  dataSourceBeingEdited?: ISourceGroupsResponse;
}) => {
  const [aliasEditable, setAliasEditable] = useState(false);
  const [aliasChanged, setAliasChanged] = useState(false);
  const [dataSourceName, setDataSourceName] = useState("");
  const [dataSourceEnvironments, setDataSourceEnvironments] = useState<
    ISelectOption[]
  >([]);
  const [dataSources, setDataSources] = useState<ISourceItem[]>(
    dataSourceBeingEdited?.sources.map((item) => ({
      name: item.source.name,
      id: item.source.id.sourceGuid,
      type: item.source.type,
      environments: item.environments.map((env) => ({
        label: env.name,
        value: env.id.idValue
      }))
    })) ?? []
  );
  const [updatingSourceId, setUpdatingSourceId] = useState("");
  const [editedDataSourceName, setEditedDataSourceName] = useState("");
  const [editedDataSourceEnvironments, setEditedDataSourceEnvironments] =
    useState<ISelectOption[]>([]);

  const [showUnsavedDataSourceWarning, setShowUnsavedDataSourceWarning] =
    useState(false);

  const { data: environments } = useEnvironments();

  const createSourceGroup = useCreateSourceGroup();
  const editSourceGroup = useEditSourceGroup();

  const { bg, disabled } = useInputColors();

  const mixpanel = useMixPanel();

  const addDataSource = (type?: string) => {
    const newDataSource = {
      name: dataSourceName,
      id: `new-${dataSources.length + 1}`,
      type: type ?? "",
      environments: dataSourceEnvironments.map((env: ISelectOption) => env)
    };

    setDataSources((sources) => [...sources, newDataSource]);

    setDataSourceName("");
    setDataSourceEnvironments([]);
  };

  const clearStateOnClose = () => {
    setDataSourceName("");
    setDataSourceEnvironments([]);
    setDataSources([]);
    setAliasEditable(false);
    setAliasChanged(false);
    setUpdatingSourceId("");
    setShowUnsavedDataSourceWarning(false);
  };

  const editDataSource = (item: ISourceItem) => {
    setUpdatingSourceId(item.id);
    setEditedDataSourceName(item.name);

    setEditedDataSourceEnvironments(
      item.environments.map((x: ISelectOption) => {
        const environmentOption: ISelectOption = {
          label: x.label,
          value: x.value
        };
        return environmentOption;
      })
    );
  };

  const saveUpdatedDataSource = (item: ISourceItem) => {
    const updatedDataSources = dataSources.map((x) =>
      x.id === item.id ? item : x
    );
    setDataSources(updatedDataSources);
    setUpdatingSourceId("");
  };

  const environmentOptions = (): ISelectOption[] => {
    if (!environments) {
      return [];
    }

    return environments
      .filter(
        (x) =>
          !dataSources.some((y) =>
            y.environments.some((z) => z.value === x.id.idValue)
          )
      )
      .map((item) => {
        const environmentOption: ISelectOption = {
          label: item.name,
          value: item.id.idValue
        };
        return environmentOption;
      });
  };

  const sourceTypeOptions = (): ISelectOption[] => {
    const typeOptions: ISelectOption[] = Object.values(SourceTypes).map(
      (value: string) => {
        return {
          value,
          label: value
        };
      }
    );
    return typeOptions;
  };

  const deleteSource = (item: ISourceItem) => {
    setDataSources(dataSources.filter((x) => x.id !== item.id));
  };

  const haveUnsavedDataSource = () => {
    return !!(
      dataSourceName ||
      dataSourceEnvironments.length !== 0 ||
      updatingSourceId
    );
  };

  useEffect(() => {
    if (!dataSourceName && dataSourceEnvironments.length === 0) {
      setShowUnsavedDataSourceWarning(false);
    }
  }, [dataSourceName, dataSourceEnvironments, updatingSourceId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="3xl"
        onCloseComplete={clearStateOnClose}
      >
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>
            {dataSourceBeingEdited
              ? `Edit ${dataSourceBeingEdited?.name}`
              : "Create new data source"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                name: dataSourceBeingEdited?.name || "",
                alias: dataSourceBeingEdited?.alias || "",
                type: dataSourceBeingEdited?.type
                  ? {
                      label: dataSourceBeingEdited?.type,
                      value: dataSourceBeingEdited?.type
                    }
                  : undefined
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values: {
                name: string;
                alias: string;
                type: ISelectOption | undefined;
              }) => {
                const payload = {
                  group: {
                    name: values.name,
                    type: values.type?.value as string,
                    alias: values.alias
                  },
                  sources: dataSources.map((x) => ({
                    name: x.name,
                    id: x.id,
                    type: x.type,
                    environmentIds: x.environments.map(
                      (y) => y.value?.toString() || ""
                    )
                  }))
                };

                if (dataSourceBeingEdited) {
                  const editPayload = {
                    ...payload,
                    group: {
                      ...payload.group,
                      alias: dataSourceBeingEdited?.alias
                    },
                    id: dataSourceBeingEdited?.id
                  };
                  editSourceGroup.mutate({
                    payload: editPayload,
                    dataSourceBeingEdited: dataSourceBeingEdited
                  });
                } else {
                  createSourceGroup.mutate(payload);
                }
                onClose();
              }}
            >
              {({ values, setFieldValue, handleChange }) => {
                return (
                  <Form>
                    <Text fontWeight="semibold" mb="2">
                      Source group
                    </Text>
                    <Text fontSize="sm" mb="4">
                      Group different versions or environments of the same data
                      source into one group to allow for easy deployment to
                      different Delivery API environments.
                    </Text>

                    <SimpleGrid columns={2} spacing={4}>
                      <Field
                        name="name"
                        validate={isRequired("Name is required")}
                      >
                        {({ field, form }: FieldProps) => (
                          <FormControl isInvalid={!!form.errors.name} mb="6">
                            <FormLabel fontSize="sm">Name</FormLabel>
                            <Input
                              data-1p-ignore
                              id="name"
                              autoFocus
                              bg={bg}
                              {...field}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChange(e);
                                if (!aliasChanged) {
                                  setFieldValue(
                                    "alias",
                                    camelize(e.target.value)
                                  ).catch(console.error);
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {!!form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="type"
                        validate={isRequired("Type is required")}
                      >
                        {({ field, form }: FieldProps) => {
                          return (
                            <FormControl isInvalid={!!form.errors.type} mb="6">
                              <FormLabel fontSize="sm">Type</FormLabel>
                              <CreatableSelect
                                {...field}
                                useBasicStyles
                                isClearable
                                onChange={(value) => {
                                  setFieldValue("type", value).catch(
                                    console.error
                                  );
                                }}
                                options={sourceTypeOptions()}
                              />
                              <FormErrorMessage>
                                {form.errors.type?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </SimpleGrid>
                    {!dataSourceBeingEdited?.alias && (
                      <SimpleGrid columns={2} spacing={8}>
                        <Field
                          name="alias"
                          validate={isRequired("Alias is required")}
                        >
                          {({ field, form }: FieldProps) => (
                            <FormControl isInvalid={!!form.errors.alias} mb="6">
                              <FormLabel fontSize="sm">Alias</FormLabel>
                              <InputGroup size="md">
                                <Input
                                  isDisabled={!aliasEditable}
                                  bg={aliasEditable ? bg : disabled.bg}
                                  pr="4.5rem"
                                  {...field}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                    setAliasChanged(true);
                                  }}
                                />
                                <InputRightElement width="4.5rem">
                                  <Tooltip
                                    label={
                                      aliasEditable
                                        ? "Disable alias editing"
                                        : "Edit alias"
                                    }
                                  >
                                    <IconButton
                                      aria-label="Toggle edit alias"
                                      colorScheme="gray"
                                      icon={
                                        <Icon
                                          as={FontAwesomeIcon}
                                          icon={
                                            aliasEditable ? faLockOpen : faLock
                                          }
                                        />
                                      }
                                      onClick={() =>
                                        setAliasEditable(!aliasEditable)
                                      }
                                    />
                                  </Tooltip>
                                </InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>
                                {form.errors.alias?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </SimpleGrid>
                    )}
                    <Divider my="6" />
                    <Text fontWeight="semibold" mb="2">
                      Data sources
                    </Text>
                    <Text fontSize="sm" mb="4">
                      Add data sources to your group and choose which
                      environment they should be connected to. Each data source
                      is unique and gets its own API key.
                    </Text>
                    <HStack
                      spacing={4}
                      borderBottom="1px solid"
                      borderColor="gray.200"
                      mb="6"
                      pb="2"
                    >
                      <Text
                        minWidth="35%"
                        maxWidth="35%"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Name
                      </Text>
                      <Text
                        minWidth="50%"
                        maxWidth="50%"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Environments
                      </Text>
                    </HStack>
                    {dataSources.map((dataSource: ISourceItem) =>
                      updatingSourceId === dataSource.id ? (
                        <div key={dataSource.id}>
                          <HStack spacing={4} mb={8}>
                            <Input
                              borderRadius="md"
                              size="sm"
                              minWidth="35%"
                              maxWidth="35%"
                              bg={bg}
                              value={editedDataSourceName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setEditedDataSourceName(e.target.value)}
                            />

                            <Box minWidth="45%" maxWidth="45%">
                              <MultiDropdown
                                options={environmentOptions()}
                                selectedOptions={editedDataSourceEnvironments}
                                onChange={(value) =>
                                  setEditedDataSourceEnvironments(value)
                                }
                                closeMenuOnSelect={true}
                              />
                            </Box>

                            <Button
                              size="xs"
                              width="10%"
                              variant="subtle"
                              isDisabled={
                                !editedDataSourceName ||
                                !editedDataSourceEnvironments.length
                              }
                              onClick={() => {
                                mixpanel.track("saveUpdatedDataSource");
                                return saveUpdatedDataSource({
                                  ...dataSource,
                                  name: editedDataSourceName,
                                  environments: editedDataSourceEnvironments
                                });
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              size="xs"
                              width="10%"
                              colorScheme="gray"
                              onClick={() => setUpdatingSourceId("")}
                            >
                              Cancel
                            </Button>
                          </HStack>
                          <Divider my="6" />
                        </div>
                      ) : (
                        <div key={dataSource.id}>
                          <HStack key={dataSource.id} spacing={4} mb={4}>
                            <Box minWidth="35%" maxWidth="35%">
                              {dataSource.name}
                            </Box>
                            <Box minWidth="45%" maxWidth="45%">
                              {dataSource.environments.map(
                                (environment: ISelectOption, i: number) => (
                                  <EnvironmentBadge
                                    key={`${environment.label}-${i}`}
                                    name={environment.label}
                                    marginRight={2}
                                  />
                                )
                              )}
                            </Box>
                            <Flex width="20%" justifyContent="flex-end">
                              <Tooltip label="Edit">
                                <IconButton
                                  variant="ghost"
                                  colorScheme="gray"
                                  size="xs"
                                  aria-label="Edit"
                                  mr="2"
                                  icon={
                                    <Icon
                                      as={FontAwesomeIcon}
                                      icon={faPencil}
                                    />
                                  }
                                  onClick={() => editDataSource(dataSource)}
                                />
                              </Tooltip>
                              <Tooltip label="Delete">
                                <IconButton
                                  variant="ghost"
                                  colorScheme="gray"
                                  size="xs"
                                  aria-label="Delete"
                                  onClick={() => deleteSource(dataSource)}
                                  icon={
                                    <Icon
                                      as={FontAwesomeIcon}
                                      icon={faTrashCan}
                                    />
                                  }
                                />
                              </Tooltip>
                            </Flex>
                          </HStack>
                          <Divider my="6" />
                        </div>
                      )
                    )}
                    <HStack spacing={4}>
                      <Input
                        data-1p-ignore
                        borderRadius="md"
                        size="sm"
                        minWidth="35%"
                        maxWidth="35%"
                        bg={bg}
                        placeholder="Name"
                        value={dataSourceName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setDataSourceName(e.target.value)
                        }
                      />

                      <Box minWidth="45%" maxWidth="45%">
                        <MultiDropdown
                          options={environmentOptions()}
                          selectedOptions={dataSourceEnvironments}
                          onChange={(value) => setDataSourceEnvironments(value)}
                          closeMenuOnSelect={true}
                          placeholder="Select environment..."
                        />
                      </Box>

                      <Button
                        size="sm"
                        width="20%"
                        variant="subtle"
                        isDisabled={
                          !dataSourceName || !dataSourceEnvironments.length
                        }
                        onClick={() =>
                          addDataSource(values.type?.value as string)
                        }
                      >
                        Add
                      </Button>
                    </HStack>
                    {showUnsavedDataSourceWarning && (
                      <Alert status="error" mt="8" borderRadius="md">
                        <AlertIcon />
                        You have an unsaved data source. Either add or clear
                        input fields to create data source.
                      </Alert>
                    )}

                    <Flex pt={8} justifyContent="flex-end">
                      <Button
                        colorScheme="gray"
                        variant="ghost"
                        mr={3}
                        onClick={onClose}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="primary"
                        isLoading={createSourceGroup.isPending}
                        type={haveUnsavedDataSource() ? "button" : "submit"}
                        onClick={() => {
                          haveUnsavedDataSource() &&
                            setShowUnsavedDataSourceWarning(true);
                        }}
                      >
                        {dataSourceBeingEdited ? "Update" : "Create"}
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateSourceGroupModal;
