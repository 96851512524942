import { gql, GraphQLClient } from "graphql-request";
import { inject, singleton } from "tsyringe";
import { IProductUpdatesResponse } from "./types";

@singleton()
export class ProductUpdatesService {
  constructor(
    @inject("managementGraphQlClient")
    private readonly managementGraphQl: GraphQLClient
  ) {}

  async getPosts(count: number) {
    const document = gql`
      query ($count: Int) {
        productUpdates(count: $count) {
          title
          summary
          tags {
            name
            color
          }
          url
          publishedAt
        }
      }
    `;

    const { productUpdates } =
      await this.managementGraphQl.request<IProductUpdatesResponse>(document, {
        count
      });
    return productUpdates;
  }
}
