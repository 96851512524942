import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { container } from "tsyringe";
import { AuthService } from "../auth.service";
import { useAuthStore } from "../store";
import {
  AuthRequestType,
  IAuthenticateRequestPayload,
  IAuthenticateResponse
} from "../types";

let authPromise: Promise<IAuthenticateResponse> | null = null;

const useAuth = (): {
  authenticate: () => Promise<void>;
  refresh: () => Promise<IAuthenticateResponse>;
} => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const routeIncludesAdmin = pathname?.includes("/admin") ?? false;

  return {
    authenticate: async () => {
      if (authPromise) {
        return;
      }

      if (useAuthStore.getState().isValid) {
        return;
      }

      let frontendRedirectUrl = pathname;
      frontendRedirectUrl += searchParams.size
        ? `?${searchParams.toString()}`
        : "";

      authPromise = handleAuthentication({
        type: AuthRequestType.AUTHENTICATE,
        token: searchParams.get("code") ?? "",
        state: searchParams.get("state") ?? "",
        frontendRedirectUrl
      });

      const response = await authPromise;

      if (!response?.isValid && response?.links?.signInUrl) {
        return window.location.replace(response.links.signInUrl);
      }

      if (routeIncludesAdmin && !response?.user?.roles?.includes("admin")) {
        return navigate("/", { replace: true });
      }

      if (response?.redirectUrl) {
        return navigate(response.redirectUrl, {
          replace: true
        });
      }
    },
    refresh: refreshToken
  };
};

export async function refreshToken() {
  return handleAuthentication({
    type: AuthRequestType.REFRESH,
    token: useAuthStore.getState().tokens?.refreshToken,
    state: "",
    frontendRedirectUrl: ""
  });
}

async function handleAuthentication(
  payload: IAuthenticateRequestPayload
): Promise<IAuthenticateResponse> {
  try {
    const authService = container.resolve(AuthService);

    const data = await authService.authenticate(payload);

    if (data.isValid) {
      const { isValid, links, token, user } = data;

      const isAdmin = user?.roles?.includes("admin") ?? false;
      const adminFeaturesEnabled =
        localStorage.getItem("adminFeaturesEnabled") === "true";

      useAuthStore.setState({
        tokens: token,
        isValid,
        links,
        user: { ...user, tenantIds: Object.keys(user?.tenants ?? {}) },
        isAdmin,
        adminFeaturesEnabled: adminFeaturesEnabled
      });

      return data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const { data, status } =
        error.response as AxiosResponse<IAuthenticateResponse>;
      const { links } = data;
      if (status === HttpStatusCode.Unauthorized && links?.signInUrl) {
        useAuthStore.setState({ isValid: false, links });
      }

      return data;
    }
    throw error;
  }
  return Promise.reject("auth error");
}

export default useAuth;
