import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { TENANT_USERS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { TenantService } from "../tenants.service";

export const removeTenantUser = ({ id }: { id: string }) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.removeUserFromTenant(id);
};

export const useRemoveTenantUser = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: removeTenantUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TENANT_USERS] });
      toast({
        title: "User was removed",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error removing user",
        status: "error",
        ...defaultToast
      });
    }
  });
};
