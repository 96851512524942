import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENTITIES } from "../../../constants/queryKey";
import { ISortBy } from "../../../types/api";
import { SourceEntityService } from "../source-entity.service";
import { ISourceEntityPaginationResponse } from "../types";

export const getAllEntities = async ({
  id,
  term,
  type,
  sortBy,
  continuationToken,
  signal
}: {
  id: string | null;
  term: string | null;
  type: string[];
  sortBy?: ISortBy[];
  continuationToken?: string;
  signal?: AbortSignal;
}): Promise<
  ISourceEntityPaginationResponse & {
    continuationToken?: string;
  }
> => {
  if (!id) {
    return { results: [], total: 0, continuationToken: undefined };
  }
  const sourceEntityService = container.resolve(SourceEntityService);

  const { sourceEntities, continuationToken: next } =
    await sourceEntityService.getSourceEntityPage(
      { id, term: term ?? "", type, sortBy },
      continuationToken,
      signal
    );

  return { ...sourceEntities, continuationToken: next };
};

export const useAllEntities = ({
  term,
  id,
  type,
  sortBy
}: {
  id: string | null;
  term: string | null;
  type: string[];
  sortBy?: ISortBy[];
}) => {
  return useInfiniteQuery({
    queryKey: [ENTITIES, id, term, type, sortBy],
    queryFn: ({
      pageParam: continuationToken,
      signal
    }: QueryFunctionContext<unknown[], string>) =>
      getAllEntities({ id, term, type, continuationToken, signal, sortBy }),
    getNextPageParam: (lastPage) => lastPage.continuationToken,
    enabled: !!id,
    initialPageParam: ""
  });
};
