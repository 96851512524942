import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IRadioCardInput from "../../types/radioCard";
import HelpIcon from "../HelpIcon";

interface IRadioCardInputWithIcon extends IRadioCardInput {
  icon: IconProp;
}

const RadioCards = ({
  label,
  options,
  help,
  mb,
  valueCallback,
  selectedValue
}: {
  label: string;
  options: IRadioCardInputWithIcon[];
  help?: string;
  mb?: number;
  valueCallback: (value: string) => string;
  selectedValue?: string;
}) => {
  const handleChange = (option: IRadioCardInput) => () => {
    valueCallback(option.value as string);
  };

  const bg = useColorModeValue("white", "gray.700");
  const colorSelected = useColorModeValue("brand.900", "brand.300");
  const textColor = useColorModeValue("black", "white");

  return (
    <Box mb={mb}>
      <FormControl>
        <FormLabel>
          {label} {help && <HelpIcon ml={2} size="sm" label={help} />}
        </FormLabel>
      </FormControl>
      <SimpleGrid columns={2} gap="4">
        {options.map((option) => {
          const isSelected = option.value === selectedValue;

          return (
            <Flex
              key={option.value}
              as="label"
              bg={bg}
              borderRadius="md"
              boxShadow="sm"
              outline="2px solid"
              height="48px"
              outlineColor={isSelected ? colorSelected : "transparent"}
              alignItems="center"
              cursor={isSelected ? "default" : "pointer"}
              _hover={{
                opacity: isSelected ? 1 : 0.8
              }}
              _focus={{
                opacity: isSelected ? 1 : 0.8,
                outline: "1px solid",
                outlineColor: colorSelected
              }}
              tabIndex={isSelected ? undefined : 0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleChange(option)();
                }
              }}
            >
              <Input
                display="none"
                type="radio"
                name={label}
                value={option.value || ""}
                onClick={handleChange(option)}
              />
              <Flex
                bg="brand.200"
                p="2"
                borderLeftRadius="md"
                height="48px"
                width="48px"
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  size="lg"
                  color="brand.900"
                  as={FontAwesomeIcon}
                  icon={option.icon}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                p="2"
                height="48px"
              >
                <Box
                  color={isSelected ? colorSelected : textColor}
                  fontWeight="500"
                  pl="2"
                >
                  {option.label}
                </Box>
                {isSelected && (
                  <Icon
                    pr="2"
                    size="lg"
                    color={colorSelected}
                    as={FontAwesomeIcon}
                    icon={faCheckCircle}
                  />
                )}
              </Flex>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default RadioCards;
