import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { VIEWS } from "../../../constants/queryKey";
import { IViewGetPayload, IViewListResponse } from "../types";
import { ViewService } from "../view.service";

export const getAllViews = async ({
  payload,
  signal,
  continuationToken
}: {
  payload: IViewGetPayload;
  continuationToken?: string;
  signal?: AbortSignal;
}) => {
  if (!payload.environmentId) {
    return Promise.resolve({
      results: [] as IViewListResponse[],
      continuationToken: undefined,
      total: 0
    });
  }
  const viewService = container.resolve(ViewService);

  const { viewPage, continuationToken: nextContinuationToken } =
    await viewService.getViewsPage(payload, continuationToken, signal);

  return { ...viewPage, continuationToken: nextContinuationToken };
};

export const useAllViews = (payload: IViewGetPayload) => {
  return useInfiniteQuery({
    queryKey: [
      VIEWS,
      payload.environmentId,
      payload.params.schemaAlias,
      payload.params.sourceEntityOriginId,
      payload.params.sourceGuid,
      payload.params.sortBy
    ],
    queryFn: ({
      signal,
      pageParam
    }: QueryFunctionContext<unknown[], string | undefined>) =>
      getAllViews({
        payload,
        signal,
        continuationToken: pageParam
      }),
    getNextPageParam: (lastPage) => lastPage.continuationToken,
    enabled: !!payload.environmentId,
    initialPageParam: undefined
  });
};
