import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { Reducer } from "../reducers/logs";
import { DefaultState } from "../states/logs";
import { ILogsState, LogsAction } from "../types";

export const LogsContext = createContext<{
  state: ILogsState;
  dispatch: React.Dispatch<LogsAction>;
}>({
  state: DefaultState,
  dispatch: () => null
});

export const LogsProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, DefaultState);
  return (
    <LogsContext.Provider value={{ state, dispatch }}>
      {children}
    </LogsContext.Provider>
  );
};

export const useLogsState = () => {
  return useContext(LogsContext);
};
