import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import LoadingAnimation from "../components/LoadingAnimation";
import useAuth from "../features/auth/api/authenticate";
import { useAuthStore } from "../features/auth/store";
import { SchemaVersionFormatModule } from "../features/schemas/formats/SchemaVersionFormat.module";
import { useTenants } from "../features/tenants/api/getTenants";
import { useTenantStore } from "../features/tenants/store";
import { ITenant } from "../features/tenants/types";
import { HttpModule } from "../lib/http.module";

const ProtectedRoute = () => {
  const flags = useFlags();
  const auth = useAuth();

  const userState = useAuthStore();

  const { user, isValid } = userState;

  const [setAvailableTenants, availableTenants, setActiveTenant, activeTenant] =
    useTenantStore(
      useShallow(
        ({
          setAvailableTenants,
          availableTenants,
          setActiveTenant,
          activeTenant
        }) => [
          setAvailableTenants,
          availableTenants,
          setActiveTenant,
          activeTenant
        ]
      )
    );

  const { data: tenants } = useTenants({
    tenantIds: user?.tenantIds
  });

  useEffect(() => {
    if (tenants) {
      setAvailableTenants(tenants);
    }
  }, [tenants, setAvailableTenants]);

  useEffect(() => {
    container.resolve(HttpModule);
    container.resolve(SchemaVersionFormatModule);
    auth.authenticate().catch(console.error);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    Sentry.setUser({
      id: user.id,
      roles: user.roles
    });
  }, [user]);

  useEffect(() => {
    if (!activeTenant.id || !flags) {
      return;
    }

    Sentry.setContext("tenant", { ...activeTenant, ...flags });
  }, [activeTenant, flags]);

  useEffect(() => {
    if (availableTenants.length > 0 && !activeTenant.id) {
      // only set active tenant if not already set
      const active = getActiveTenant(availableTenants, userState.isAdmin);
      setActiveTenant(userState, active, true);
    }
  }, [availableTenants, setActiveTenant, userState, activeTenant]);

  if (!isValid) {
    return <LoadingAnimation></LoadingAnimation>;
  }

  return <Outlet></Outlet>;
};

const getActiveTenant = (
  availableTenants: ITenant[],
  isAdmin = false
): ITenant => {
  if (isAdmin) {
    const tenantString = window.localStorage.getItem("enterspeed-tenant");

    const tenantFromLocalStorage = tenantString
      ? (JSON.parse(tenantString) as ITenant)
      : null;
    if (tenantFromLocalStorage) {
      return tenantFromLocalStorage;
    }
  }

  const current = getCurrentTenantSetInLocalStorage(availableTenants);
  if (current) {
    return current;
  }

  const [activeTenant] = availableTenants;
  return activeTenant;
};

function getCurrentTenantSetInLocalStorage(tenants: ITenant[]) {
  return tenants.find(
    (tenant) =>
      tenant.id.idValue === window.localStorage.getItem("enterspeed-tenant-id")
  );
}
export default ProtectedRoute;
