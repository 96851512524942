import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useShallow } from "zustand/react/shallow";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../features/tenants/store";

const TenantMismatch = ({
  tenantId,
  type
}: {
  type: string;
  tenantId?: string;
}) => {
  const { availableTenants } = useTenantStore(
    useShallow(({ availableTenants }) => ({ availableTenants }))
  );
  const tenantSwitcher = useTenantSwitcher();

  const actualTenant = availableTenants.find((t) => t.id.idValue === tenantId);

  return (
    <Flex
      w={"100%"}
      align={"center"}
      justify={"center"}
      direction={"column"}
      gap={"2em"}
    >
      <Heading>This {type} belongs to a different tenant</Heading>

      {actualTenant && (
        <Flex direction={"column"} gap={"1em"}>
          <Text>Do you want to switch to {actualTenant.name}</Text>
          <Button
            variant={"primary"}
            onClick={() =>
              tenantSwitcher.switch(actualTenant, { redirectToHome: false })
            }
          >
            Yes, switch tenant
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default TenantMismatch;
