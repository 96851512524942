import { formatDateTimeWithoutSeconds } from "../../../helpers/formatDateTime";
import { ILogsPeriodFilter } from "../types";
import {
  IRelativePeriodOption,
  relativePeriodOptions
} from "../types/period-filter";

export const getRelativePeriod = (
  period: ILogsPeriodFilter
): IRelativePeriodOption | undefined => {
  return relativePeriodOptions.find((p) => p.value === period?.from);
};

export const isAbsolutePeriod = (period: ILogsPeriodFilter): boolean => {
  if (getRelativePeriod(period)) {
    return false;
  }

  return !isToNow(period.to);
};

export const getPeriodTitle = (period: ILogsPeriodFilter): string => {
  // relative period
  const from = relativePeriodOptions.find((p) => p.value === period?.from);
  if (from) {
    return from.label;
  }

  return `${formatDateTimeWithoutSeconds(period?.from ?? "")} - ${
    period.to === "now" ? "Now" : formatDateTimeWithoutSeconds(period?.to ?? "")
  }`;
};

export const isToNow = (to: string | null | undefined): boolean => {
  return to === "now";
};
