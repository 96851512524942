import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const destinationQueryFn = ({ alias }: { alias?: string }) => {
  if (!alias) {
    return undefined;
  }
  const destinationsService = container.resolve(DestinationsService);
  return destinationsService.getDestinationByAlias(alias);
};

const useDestination = ({ alias }: { alias?: string }) => {
  return useQuery({
    queryKey: [DESTINATIONS, alias],
    queryFn: () => destinationQueryFn({ alias }),
    enabled: !!alias
  });
};

export default useDestination;
