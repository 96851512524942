import { Badge } from "@chakra-ui/react";
import { LogType } from "../types";

const TypeBadge = ({ type }: { type: LogType }) => {
  return (
    <Badge
      colorScheme={color[type]}
      variant="subtle"
      fontSize="0.75em"
      fontWeight="normal"
    >
      {type}
    </Badge>
  );
};

const color = {
  SYSTEM: "teal",
  INTERNAL: "purple",
  ACTIVITY: "blue"
};

export default TypeBadge;
