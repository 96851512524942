import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IPage } from "../../types/api";
import {
  ICreateDestinationConfigurationResponseDto,
  ICreateDestinationEnvironmentConfigurationDto,
  ICreateDestinationEnvironmentConfigurationResponseDto,
  IDestination,
  IDestinationAliasesResponseDto,
  IDestinationConfiguration,
  IPageWithContinuationToken
} from "./types";

@singleton()
export class DestinationsService {
  public constructor(private readonly managementClient: ManagementClient) {}

  async getAllDestinations(
    continuationToken?: string
  ): Promise<IPageWithContinuationToken<IDestination>> {
    const { data, headers } = await this.managementClient.get<
      IPage<IDestination>
    >(`api/v1/apps`, {
      headers: {
        "x-continuation-token": continuationToken
      }
    });
    return {
      data: data.results,
      continuationToken: headers["x-continuation-token"] as string,
      total: data.total
    };
  }

  async getAllDestinationsByTenant(): Promise<IDestination[]> {
    const { data } = await this.managementClient.get<IDestination[]>(
      `api/v1/tenant/apps`,
      {}
    );
    return data;
  }

  async getDestinationByAlias(alias: string) {
    const { data } = await this.managementClient.get<IDestinationConfiguration>(
      `/api/v1/tenant/destination-groups/${alias}`
    );
    return data;
  }

  async getDestinationAliases() {
    const { data } = await this.managementClient.get<
      IDestinationAliasesResponseDto[]
    >(`/api/v1/tenant/destination-groups/_list-aliases`);
    return data;
  }

  async getConfiguredDestinations(
    continuationToken?: string
  ): Promise<IPageWithContinuationToken<IDestinationConfiguration>> {
    const { data, headers } = await this.managementClient.get<
      IPage<IDestinationConfiguration>
    >(`/api/v1/tenant/destination-groups`, {
      headers: {
        "x-continuation-token": continuationToken
      }
    });

    return {
      data: data.results,
      continuationToken: headers["x-continuation-token"] as string,
      total: data.total
    };
  }

  async createDestinationConfiguration(alias: string, appId: string) {
    const { data } =
      await this.managementClient.post<ICreateDestinationConfigurationResponseDto>(
        `api/v1/tenant/destination-groups`,
        { alias, appId }
      );
    return data;
  }

  async deleteDestinationConfiguration(alias: string) {
    const { data } =
      await this.managementClient.delete<ICreateDestinationConfigurationResponseDto>(
        `api/v1/tenant/destination-groups/${alias}`
      );
    return data;
  }

  async addEnvironmentConfigurationToDestinationConfiguration(
    dto: ICreateDestinationEnvironmentConfigurationDto
  ) {
    const { alias, ...rest } = dto;
    const { data } =
      await this.managementClient.post<ICreateDestinationEnvironmentConfigurationResponseDto>(
        `api/v1/tenant/destination-groups/${alias}/configuration`,
        rest
      );
    return data;
  }

  async editEnvironmentConfigurationToDestinationConfiguration(
    dto: ICreateDestinationEnvironmentConfigurationDto
  ) {
    const { alias, environmentId, ...rest } = dto;
    const [, , , environmentGuid] = environmentId.split("/");
    const { data } =
      await this.managementClient.put<ICreateDestinationEnvironmentConfigurationResponseDto>(
        `api/v1/tenant/destination-groups/${alias}/configuration/${environmentGuid}`,
        rest
      );
    return data;
  }

  async deleteEnvironmentConfigurationToDestinationConfiguration(dto: {
    alias: string;
    environmentId: string;
  }) {
    const { alias, environmentId } = dto;
    const [, , , environmentGuid] = environmentId.split("/");
    const { data } =
      await this.managementClient.delete<ICreateDestinationEnvironmentConfigurationResponseDto>(
        `api/v1/tenant/destination-groups/${alias}/configuration/${environmentGuid}`
      );
    return data;
  }
}
