import { Box, Flex, Switch, Tag, Text } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import HelpIcon from "../../../components/HelpIcon";
import SecretKey from "../../../components/SecretKey";
import { DataTable } from "../../../components/table/DataTable";
import { getBadgeColor } from "../../../constants/colors";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { DataTableAction } from "../../../types/dataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { IEnvironmentResponse } from "../../environments/types";
import {
  DestinationEnvironmentConfigurationModal,
  IDestination,
  IDestinationEnvironmentConfiguration
} from "../types";

const DestinationEnvironmentConfigurationTable = ({
  destination,
  configuration,
  environments,
  environmentClients,
  actions,
  onToggleEnabled
}: {
  destination: IDestination;
  configuration: IDestinationEnvironmentConfiguration[];
  environments?: IEnvironmentResponse[];
  environmentClients?: IEnvironmentClientResponse[];
  actions: DataTableAction<
    DestinationEnvironmentConfigurationModal,
    IDestinationEnvironmentConfiguration
  >[];
  onToggleEnabled: (
    row: IDestinationEnvironmentConfiguration,
    newState: boolean
  ) => void;
}) => {
  const adminFeatures = useAdminFeatures();
  const dynamicColumnNames = Object.entries(destination?.ui ?? {}).filter(
    ([key]) => key !== "enterspeedEnvironmentClientApiKey"
  );
  const getEnvironmentName = (environmentId: string) => {
    const environment = environments?.find(
      (env) => env.id.idValue === environmentId
    );
    return environment?.name;
  };

  const getEnvironmentClient = (id?: string) =>
    environmentClients?.find((client) => id === client.id.idValue);

  const columnHelper =
    createColumnHelper<IDestinationEnvironmentConfiguration>();

  const defaultColumns = [
    columnHelper.accessor("environmentId", {
      cell: (col) => getEnvironmentName(col.getValue()),
      header: "Environment"
    })
  ];

  const adminColumns = adminFeatures
    ? [
        columnHelper.accessor("regions", {
          cell: (col) => (
            <Flex gap={"0.5em"}>
              {col.getValue()?.map((region, idx) => {
                const [color, bgColor] = getBadgeColor(idx);
                return (
                  <Tag key={region} color={color} bgColor={bgColor}>
                    {region}
                  </Tag>
                );
              })}
            </Flex>
          )
        })
      ]
    : [];

  const environmentClientColumn = destination.ui
    ?.enterspeedEnvironmentClientApiKey
    ? columnHelper.accessor("environmentClientId", {
        header: "Environment Client",
        cell: (col) => {
          const id = col.getValue();

          if (
            !id &&
            col.row.original.settings.enterspeedEnvironmentClientApiKey
          ) {
            return (
              <Flex align={"center"} direction={"column"} maxW={"300px"}>
                <Text color={"red.500"}>Environment client not found</Text>
              </Flex>
            );
          }

          const environmentClient = id ? getEnvironmentClient(id) : null;

          return environmentClient?.name;
        }
      })
    : null;

  const dynamicColumns = dynamicColumnNames.map(([key, value]) =>
    columnHelper.accessor(`settings.${key}`, {
      header: () => (
        <Flex align={"center"}>
          <Text>{value.label}</Text>
          {value.helpText && <HelpIcon label={value.helpText}></HelpIcon>}
        </Flex>
      ),
      enableSorting: false,
      cell: (info) => {
        if (!info.row.original.settings || !info.row.original.settings[key]) {
          return <></>;
        }
        const field = info.row.original.settings[key];
        if (!field.optional && !field.value) {
          return (
            <Text className="fa-bounce" color="red.500">
              ** Required **
            </Text>
          );
        }
        if (field.type === "string") {
          return field.secret ? (
            <Box maxW={"20em"} overflowWrap={"break-word"}>
              <SecretKey
                content={field.value as string}
                updated={false}
                maxW="20em"
              ></SecretKey>
            </Box>
          ) : (
            <Text maxW={"20em"} overflowWrap={"break-word"}>
              {field.value as string}
            </Text>
          );
        }
        if (field.type === "object") {
          return <pre>{JSON.stringify(field.value, null, 2)}</pre>;
        }
        return <></>;
      }
    })
  );

  const enabledCol = columnHelper.display({
    header: "Status",
    cell: (col) => {
      const enabled = col.row.original.enabled;
      return (
        <Switch
          isChecked={enabled}
          onChange={({ target }) =>
            onToggleEnabled(col.row.original, target.checked)
          }
        ></Switch>
      );
    }
  });

  const columns = [
    ...defaultColumns,
    ...(environmentClientColumn ? [environmentClientColumn] : []),
    ...adminColumns,
    ...dynamicColumns,
    enabledCol
  ];

  return (
    <DataTable
      columns={columns}
      data={configuration}
      actions={actions}
      emptyState={EmptyStateType.DEFAULT}
      enableSorting={false}
    ></DataTable>
  );
};

export default DestinationEnvironmentConfigurationTable;
