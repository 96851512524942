import { ITreeViewProps } from "../types/schema-list";
import SchemaListTreeViewNode from "./SchemaListTreeViewNode";

const SchemaListTreeView = ({
  data,
  query,
  onDuplicateSchema,
  onDeleteSchema,
  onCreateSchema,
  onEditSchema
}: ITreeViewProps) => {
  return (
    <>
      {data?.map((node) => (
        <SchemaListTreeViewNode
          key={node.key}
          node={node}
          query={query}
          onDeleteSchema={onDeleteSchema}
          onDuplicateSchema={onDuplicateSchema}
          onEditSchema={onEditSchema}
          onCreateSchema={onCreateSchema}
        />
      ))}
    </>
  );
};

export default SchemaListTreeView;
