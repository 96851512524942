import { Buffer } from "buffer";
import { singleton } from "tsyringe";
import { ISchemaVersionFormat, SchemaFormat } from "../types";

@singleton()
export class JavascriptSchemaVersionFormat implements ISchemaVersionFormat {
  canHandle(schemaFormat: SchemaFormat): boolean {
    return schemaFormat === "javascript";
  }

  preProcessValue(schemaVersionData: string): string {
    return Buffer.from(schemaVersionData ?? "", "base64").toString("utf8");
  }

  postProcessValue(schemaVersionData: string): unknown {
    return Buffer.from(schemaVersionData, "utf8").toString("base64");
  }
}

export default JavascriptSchemaVersionFormat;
