import {
  Box,
  Flex,
  Link,
  List,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
const InteractivePlaceholderLink = ({
  key,
  link,
  title,
  details,
  linkIcon,
  linkText
}: {
  key?: string | undefined | null;
  title?: string | undefined | null;
  link?: string | undefined | null;
  linkIcon?: React.JSX.Element | undefined | null;
  linkText: string;
  details?: { [key: string]: React.ReactNode | string } | undefined | null;
}) => {
  const detailKeys = Object.keys(details ?? {});
  const linkBottomBorderColor = useColorModeValue("brand.200", "gray.600");
  const hoverLinkBottomBorderColor = useColorModeValue("brand.300", "gray.500");
  return (
    <>
      <Tooltip
        key={key ?? Math.random()}
        padding={3}
        label={
          <>
            <Box textAlign={"center"}>{title ?? "Click to open"}</Box>
            {detailKeys.length > 0 && (
              <List
                borderTop={"1px"}
                borderColor={"gray.300"}
                marginTop={"5px"}
                paddingTop={"5px"}
                display={"flex"}
                flexDirection={"column"}
                gap={1.5}
              >
                {detailKeys.map((key) => (
                  <Flex direction={"column"} key={key}>
                    <Text fontWeight={500} fontSize={"xs"}>
                      {key}:
                    </Text>
                    <Text fontWeight={400} fontSize={"xs"} opacity={0.8}>
                      {details?.[key]}
                    </Text>
                  </Flex>
                ))}
              </List>
            )}
          </>
        }
      >
        <Box role="group" as="span">
          {link ? (
            <Link
              as={RouterLink}
              mx={0.5}
              px={0.5}
              target={"_blank"}
              overflowWrap={"break-word"}
              borderBottomWidth={"2px"}
              borderBottomStyle={"dotted"}
              borderColor={linkBottomBorderColor}
              textDecoration={"none"}
              _hover={{
                borderColor: hoverLinkBottomBorderColor,
                transform: "border-color",
                transitionDuration: "0.1s",
                transitionTimingFunction: "ease-in-out"
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              to={link}
            >
              {linkIcon}
              <Text as="span">{linkText}</Text>
            </Link>
          ) : (
            <Text
              cursor={"not-allowed"}
              as="span"
              mx={0.5}
              px={0.5}
              overflowWrap={"break-word"}
              borderBottomWidth={"2px"}
              borderBottomStyle={"dotted"}
              borderColor={linkBottomBorderColor}
              textDecoration={"none"}
              _hover={{
                borderColor: hoverLinkBottomBorderColor,
                transform: "border-color",
                transitionDuration: "0.1s",
                transitionTimingFunction: "ease-in-out"
              }}
            >
              {linkIcon}
              <Text as="span">{linkText}</Text>
            </Text>
          )}
        </Box>
      </Tooltip>
    </>
  );
};

export default InteractivePlaceholderLink;
