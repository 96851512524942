import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DOMAINS, UNCONFIGURED_HOSTNAMES } from "../../../constants/queryKey";
import { DomainService } from "../domain.service";
import { IDomainEditPayload } from "../types";

export const editHostname = ({
  id,
  payload
}: {
  id: string;
  payload: IDomainEditPayload;
}) => {
  const domainService = container.resolve(DomainService);
  return domainService.editDomain(id, payload);
};

export const useEditHostname = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editHostname,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DOMAINS] });
      await queryClient.invalidateQueries({
        queryKey: [UNCONFIGURED_HOSTNAMES]
      });
    }
  });
};
