import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DOMAINS } from "../../../constants/queryKey";
import { DomainService } from "../domain.service";
import { IDomainEditPayload } from "../types";

export const editDomain = ({
  id,
  payload
}: {
  id: string;
  payload: IDomainEditPayload;
}) => {
  const domainService = container.resolve(DomainService);
  return domainService.editDomain(id, payload);
};

export const useEditDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editDomain,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DOMAINS] });
    }
  });
};
