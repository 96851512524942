import qs from "qs";
import { singleton } from "tsyringe";
import { IngestClient } from "../../lib/ingest-api.client";
import { ManagementClient } from "../../lib/management-api.client";
import {
  IAddedSourceEntity,
  IAddedSourceEntityFile,
  ISourceEntitiesGetPayload,
  ISourceEntity,
  ISourceEntityPaginationResponse,
  ISourceReprocessEntity
} from "./types";

export type SourceEntityFormat = "raw" | "placeholders";

@singleton()
export class SourceEntityService {
  constructor(
    private readonly ingestClient: IngestClient,
    private readonly managementClient: ManagementClient
  ) {}

  public async getEntityById(
    sourceId: string,
    originId: string,
    format: SourceEntityFormat
  ) {
    const { data } = await this.managementClient.get<ISourceEntity>(
      `/api/v1/tenant/sources/${sourceId}/entities/${originId}`,
      {
        params: {
          format
        }
      }
    );
    return data;
  }

  addEntity(
    sourceEntity: IAddedSourceEntity | IAddedSourceEntityFile,
    apiKey: string
  ) {
    return this.ingestClient.create(
      sourceEntity.originId,
      sourceEntity.data,
      apiKey
    );
  }

  deleteByIds(sourceId: string, ids: string[]) {
    return this.managementClient.delete(
      `/api/v1/tenant/sources/${sourceId}/entities/_bulk`,
      {
        bulk: ids.map((id) => {
          return { id };
        })
      }
    );
  }

  public async getSourceEntityPage(
    { id: sourceId, type, term, sortBy }: ISourceEntitiesGetPayload,
    continuationToken?: string,
    signal?: AbortSignal
  ) {
    const { data, headers } =
      await this.managementClient.get<ISourceEntityPaginationResponse>(
        `/api/v1/tenant/sources/${sourceId}/entities-list`,
        {
          headers: {
            "x-continuation-token": continuationToken
          },
          params: {
            type,
            term,
            sortBy
          },
          paramsSerializer: {
            serialize: (params) => {
              return qs.stringify(params, { allowDots: true });
            }
          },
          signal
        }
      );

    return {
      sourceEntities: data,
      continuationToken: headers["x-continuation-token"] as string
    };
  }

  public async getUnconfiguredHostnames() {
    const { data } = await this.managementClient.get<string[]>(
      "/api/v1/tenant/source-entities/_unconfigured-hostnames"
    );
    return data;
  }

  public async processEntity(payload: ISourceReprocessEntity) {
    return this.managementClient.post(
      "/api/v1/tenant/source-entities/_process",
      payload
    );
  }
}
