import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const deleteDestinationConfigurationFn = ({ alias }: { alias: string }) => {
  const destinationService = container.resolve(DestinationsService);

  return destinationService.deleteDestinationConfiguration(alias);
};

const useDeleteDestinationConfiguration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDestinationConfigurationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DESTINATIONS] })
  });
};

export default useDeleteDestinationConfiguration;
