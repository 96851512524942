import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENT_CLIENTS } from "../../../constants/queryKey";
import { EnvironmentClientService } from "../environment-client.service";
import { IEnvironmentClientCreatePayload } from "../types";

export const createEnvironmentClient = ({
  environmentId,
  payload
}: {
  environmentId: string;
  payload: IEnvironmentClientCreatePayload;
}) => {
  const environmentClientService = container.resolve(EnvironmentClientService);
  return environmentClientService.createEnvironmentClient(
    environmentId,
    payload
  );
};

export const useCreateEnvironmentClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createEnvironmentClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ENVIRONMENT_CLIENTS]
      });
    }
  });
};
