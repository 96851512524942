import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { SOURCE_ENTITY_TYPES } from "../../../constants/queryKey";
import { SourceService } from "../../data-sources/source.service";

export const getSourceEntityTypes = async (sourceId?: string) => {
  if (!sourceId) {
    return [];
  }
  const sourceService = container.resolve(SourceService);
  return sourceService.getEntityTypesInSource(sourceId);
};

export const useSourceEntityTypes = (sourceId?: string) => {
  return useQuery({
    queryKey: [SOURCE_ENTITY_TYPES, sourceId],
    queryFn: () => getSourceEntityTypes(sourceId),
    enabled: !!sourceId
  });
};
