import { Circle, useColorModeValue } from "@chakra-ui/react";

const NumberCircle = ({ number }: { number: number }) => {
  return (
    <Circle
      size="30px"
      bg={useColorModeValue("gray.200", "gray.700")}
      fontSize="xs"
      fontWeight="semibold"
      userSelect="none"
    >
      {number}
    </Circle>
  );
};

export default NumberCircle;
