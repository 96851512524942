import { Button, Icon } from "@chakra-ui/react";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ILogsState, LogsAction } from "../types";

const ToggleDisplayModeButton = ({
  logsState,
  logsDispatch
}: {
  logsState: ILogsState;
  logsDispatch: React.Dispatch<LogsAction>;
}) => {
  const showRawIds = logsState.displayType === "ID_VALUE_LINK";
  return (
    <Button
      leftIcon={
        <Icon
          as={FontAwesomeIcon}
          icon={showRawIds ? faEye : faEyeSlash}
        ></Icon>
      }
      variant="ghost"
      onClick={() =>
        showRawIds
          ? logsDispatch({
              type: "set_display_type",
              value: "DISPLAY_VALUE_LINK"
            })
          : logsDispatch({
              type: "set_display_type",
              value: "ID_VALUE_LINK"
            })
      }
    >
      Raw ids
    </Button>
  );
};
export default ToggleDisplayModeButton;
