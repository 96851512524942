import { Box, Icon, IconButton, keyframes, Tooltip } from "@chakra-ui/react";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnnounceKit from "announcekit-react";

const ProductUpdates = () => {
  const pulse = keyframes`
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 117, 117, 0.7);
      }
  
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 117, 117, 0);
      }
  
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 117, 117, 0);
      }
    }
    `;

  return (
    <>
      <Box
        sx={{
          ".announcekit-widget-badge": {
            position: "absolute !important",
            width: "10px!important",
            height: "10px!important",
            bottom: "0",
            right: "0",
            animation: `${pulse} 2s infinite`,
            borderRadius: "50%"
          }
        }}
      >
        <AnnounceKit
          catch-click=".ak-trigger"
          widget="https://announcekit.app/widgets/v2/2mz4c"
        >
          <Tooltip label="Open product updates">
            <IconButton
              aria-label="Open product updates"
              _hover={{ opacity: 0.75 }}
              variant="ghost"
              icon={
                <Icon
                  className="ak-trigger"
                  as={FontAwesomeIcon}
                  icon={faBell}
                  size="xl"
                  color="gray.500"
                  cursor="pointer"
                  _hover={{ opacity: 0.75 }}
                />
              }
            />
          </Tooltip>
        </AnnounceKit>
      </Box>
    </>
  );
};

export default ProductUpdates;
