import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { USERS } from "../../../constants/queryKey";
import { IUserSearchPayload } from "../types";
import { UserService } from "../user.service";

const getUsers = async (payload: IUserSearchPayload, signal?: AbortSignal) => {
  const userService = container.resolve(UserService);
  return userService.getUsers(payload, signal);
};

export const useUsers = (payload: IUserSearchPayload) => {
  return useInfiniteQuery({
    queryKey: [USERS, payload.term],
    queryFn: ({ pageParam, signal }) =>
      getUsers({ ...payload, after: pageParam }, signal),
    getNextPageParam: (lastPage) => {
      if (!lastPage.pageInfo.hasNextPage) {
        return undefined;
      }

      return lastPage.pageInfo.endCursor;
    },
    initialPageParam: ""
  });
};
