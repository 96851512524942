import { Button } from "@chakra-ui/react";
import useMixPanel from "../../mixpanel/useMixPanel";

export const GettingStartedItemActionExternalLink = ({
  description,
  href
}: {
  href: string;
  description: string;
}) => {
  const mixpanel = useMixPanel();
  return (
    <Button
      variant={"ghost"}
      onClick={() => {
        mixpanel.track("gettingStartedAction", {
          type: "externalLink",
          href
        });
        window.open(href);
      }}
    >
      {description}
    </Button>
  );
};
