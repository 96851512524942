import { useEffect, useState } from "react";

const CountdownTimer = ({
  initialSeconds,
  action
}: {
  initialSeconds: number;
  action?: () => void;
}) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      return action ? action() : undefined;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds, action]);

  return <>{seconds}</>;
};

export default CountdownTimer;
