import {
  DomainId,
  EnvironmentClientId,
  EnvironmentId,
  MappingSchemaId,
  RouteId,
  SourceEntityId,
  SourceGroupId,
  SourceId,
  ViewId
} from "../../../types/ids";

export type DisplayType = "ID_VALUE_LINK" | "DISPLAY_VALUE_LINK";

export type EnhancementKey =
  | `Source|${string}`
  | `SourceGroup|${string}`
  | `Actor|${string}`
  | `Environment|${string}`
  | `EnvironmentClient|${string}`
  | `MappingSchema|${string}`
  | `SourceEntity|${string}`
  | `Route|${string}`
  | `Release|${string}`
  | `Domain|${string}`
  | `View|${string}`;

export type Enhancement =
  | SourceEnhancement
  | EnvironmentEnhancement
  | EnvironmentClientEnhancement
  | SourceEntityEnhancement
  | MappingSchemaEnhancement
  | ViewEnhancement
  | RouteEnhancement
  | SourceGroupEnhancement
  | ReleaseEnhancement
  | ActorEnhancement
  | DomainEnhancement;

export type SourceEnhancement = {
  key: `Source|${string}`;
  id: SourceId;
  sourceGroupName: string;
  name: string;
};

export type DomainEnhancement = {
  key: `Domain|${string}`;
  id: DomainId;
  name: string;
};

export type ReleaseEnhancement = {
  key: `Release|${string}`;
  id: string;
  environmentId: string;
};

export type ActorEnhancement = {
  key: `Actor|${string}`;
  id: string;
  name: string;
  type: "USER" | "MANAGEMENT_CLIENT";
};

export type SourceGroupEnhancement = {
  key: `SourceGroup|${string}`;
  id: SourceGroupId;
  name: string;
  type: string;
};

export type EnvironmentEnhancement = {
  key: `Environment|${string}`;
  id: EnvironmentId;
  name: string;
};

export type EnvironmentClientEnhancement = {
  key: `EnvironmentClient|${string}`;
  id: EnvironmentClientId;
  name: string;
  environmentName: string;
};

export type MappingSchemaEnhancement = {
  key: `MappingSchema|${string}`;
  id: MappingSchemaId;
  name: string;
};

export type SourceEntityEnhancement = {
  key: `SourceEntity|${string}`;
  id: SourceEntityId;
};

export type ViewEnhancement = {
  key: `View|${string}`;
  id: ViewId;
};

export type RouteEnhancement = {
  key: `Route|${string}`;
  id: RouteId;
};

export type Placeholder =
  | "SourceEntity"
  | "SourceEntityId"
  | "MappingSchema"
  | "MappingSchemaId"
  | "MappingSchemaVersion"
  | "MappingSchemaVersionId"
  | "View"
  | "ViewId"
  | "Environment"
  | "EnvironmentId"
  | "EnvironmentClient"
  | "EnvironmentClientId"
  | "Source"
  | "SourceId"
  | "SourceGroup"
  | "SourceGroupId"
  | "ActiveRouteId"
  | "ActiveRoute"
  | "Route"
  | "RouteId"
  | "Level"
  | "User"
  | "UserId"
  | "Actor"
  | "ActorId"
  | "Release"
  | "ReleaseId"
  | "Domain"
  | "DomainId"
  | "Type";

export function isPlaceholder(input: string): input is Placeholder {
  return [
    "SourceEntity",
    "SourceEntityId",
    "MappingSchema",
    "MappingSchemaId",
    "MappingSchemaVersion",
    "MappingSchemaVersionId",
    "View",
    "ViewId",
    "Environment",
    "EnvironmentId",
    "EnvironmentClient",
    "EnvironmentClientId",
    "Source",
    "SourceId",
    "SourceGroup",
    "SourceGroupId",
    "ActiveRouteId",
    "ActiveRoute",
    "Route",
    "RouteId",
    "Level",
    "User",
    "UserId",
    "Actor",
    "ActorId",
    "Release",
    "ReleaseId",
    "Domain",
    "DomainId",
    "Type"
  ].includes(input);
}
