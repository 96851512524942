import { Badge, Box, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import LoadingAnimation from "../../../components/LoadingAnimation";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { TenantTableAction } from "../../../pages/admin/TenantTableAction";
import { DataTableAction } from "../../../types/dataTable";
import { ITenant, ITenantProperties } from "../types";
import { daysUntilDeletion } from "../util";

type TenantRow = ITenantProperties & {
  trashedAt?: Date;
};

export const TenantTable = ({
  tenants,
  isLoading,
  onTenantRowClick,
  action
}: {
  tenants: ITenant[];
  isLoading?: boolean;
  showCurrentUserAssigned?: boolean;
  onTenantRowClick: (tenant: ITenant) => void;
  action?: DataTableAction<TenantTableAction, ITenantProperties>[];
}) => {
  const columnHelper = createColumnHelper<TenantRow>();

  const mappedTenants: TenantRow[] = tenants.map((x) => ({
    id: x.id.idValue,
    name: x.name,
    isUsingSourceGroups: x.isUsingSourceGroups,
    isUsingSchemasBulkDeployment: x.isUsingSchemasBulkDeployment,
    javascriptSchemaFormatConfig: x.javascriptSchemaFormatConfig,
    avoidRefresh: true,
    trashedAt: x.trashedAt
  }));

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <Box>
            {props.row.original.name}{" "}
            {props.row.original.trashedAt ? (
              <Badge bg={"red.900"} color={"red.200"}>
                Deleted in {daysUntilDeletion(props.row.original.trashedAt)}{" "}
                days
              </Badge>
            ) : (
              <></>
            )}
            {!props.row.original.isUsingSourceGroups ? (
              <Tooltip label="Tenant is non source groups">
                <Badge bg={"orange.900"} color={"orange.200"}>
                  Deprecated
                </Badge>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("id", {
      cell: (info) => info.getValue(),
      header: "ID"
    }),
    columnHelper.accessor("javascriptSchemaFormatConfig.enabled", {
      cell: (info) => (info.getValue() ? "✔️" : "-"),
      header: "Javascript"
    })
  ];

  if (isLoading) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <DataTable
        actions={action}
        loading={isLoading}
        columns={columns}
        data={mappedTenants}
        onRowClickCallback={(tr) => {
          const tenant = tenants.find((t) => t.id.idValue === tr.id);
          if (!tenant) {
            console.error("no tenant found for row");
            return;
          }
          onTenantRowClick(tenant);
        }}
      />
    </>
  );
};
