import { ChangeState } from "./types";

export const badgeColorMap: { [key in ChangeState]: string } = {
  Added: "green",
  Updated: "yellow",
  Unpublished: "red",
  Unchanged: "gray",
  Redeployed: "blue",
  Reverted: "orange"
};

export const deployStateSortOrder: { [key in ChangeState]: number } = {
  Added: 3,
  Unpublished: 2,
  Updated: 1,
  Redeployed: 4,
  Unchanged: 5,
  Reverted: 0
};
