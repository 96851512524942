import { InfiniteData } from "@tanstack/react-query";
import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import {
  getUserOptionById,
  getUserOptions
} from "../../../helpers/getUserOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import { IPaginated } from "../../../types/api";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { ITenantUser } from "../../tenants/types";

const SingleValueConditionUserPicker = ({
  onSelect,
  value,
  users
}: {
  value: string | null;
  onSelect(value: string | null | undefined): void;
  users: InfiniteData<IPaginated<ITenantUser>, unknown> | undefined;
}) => {
  const schemaOptions = getUserOptions(users?.pages[0].results);
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const [selectedUser, setSelectedUser] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  useEffect(() => {
    if (!users) {
      return;
    }

    const dataUserOption = value
      ? getUserOptionById(users?.pages[0].results, value)
      : undefined;
    setSelectedUser(dataUserOption);
  }, [users, value]);

  const handleSchemaFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    setSelectedUser(value);
    onSelect(value?.value as string | null);
  };

  return (
    <Select<
      IEnterspeedIdentifiableSelectOption,
      true,
      GroupBase<IEnterspeedIdentifiableSelectOption>
    >
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select actor"
      options={schemaOptions}
      components={new EnterspeedIdentifiableSelect()}
      value={selectedUser}
      onChange={(value) => {
        return handleSchemaFilter(
          value as unknown as IEnterspeedIdentifiableSelectOption
        );
      }}
    />
  );
};

export default SingleValueConditionUserPicker;
