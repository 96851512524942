import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANTS } from "../../../constants/queryKey";
import { AdminService } from "../admin.service";
import { ITenantSearchRequest } from "../types";

const getAllTenantsFn = (
  params: ITenantSearchRequest,
  signal?: AbortSignal
) => {
  const adminService = container.resolve(AdminService);
  return adminService.getTenantsPage(params, signal);
};

export const useGetAllTenantQuery = (params: ITenantSearchRequest) => {
  return useInfiniteQuery({
    queryFn: ({ signal, pageParam }) =>
      getAllTenantsFn({ ...params, after: pageParam }, signal),
    queryKey: [TENANTS, params.term],
    getNextPageParam: (lastPage) => {
      if (!lastPage.pageInfo.hasNextPage) {
        return undefined;
      }

      return lastPage.pageInfo.endCursor;
    },
    initialPageParam: ""
  });
};
