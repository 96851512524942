import { useColorModeValue } from "@chakra-ui/react";
import { ChakraStylesConfig } from "chakra-react-select";

const useReactSelectStyles: () => ChakraStylesConfig = () => {
  const bgColorMode = useColorModeValue("white", "gray.700");
  return {
    container: (provided, _state) => ({
      ...provided,
      w: "100%"
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: bgColorMode,
      borderWidth: "1px",
      borderRadius: "md"
    }),
    option: (provided) => ({ ...provided, pl: "2em" }),
    groupHeading: (provided) => ({
      ...provided,
      color: "gray.500",
      fontSize: "sm",
      fontWeight: "bold",
      fontStyle: "italic",
      cursor: "default"
    })
  };
};

export default useReactSelectStyles;
