import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentBox from "../../components/ContentBox";
import EmptyState from "../../components/EmptyState";
import LoadingAnimation from "../../components/LoadingAnimation";
import Pagination from "../../components/Pagination";
import useCreateDestinationConfiguration from "../../features/destinations/api/useCreateDestinationConfiguration";
import useGetAllDestinations from "../../features/destinations/api/useGetAllDestinations";
import CreateDestinationConfigurationModal from "../../features/destinations/components/CreateDestionationConfigurationModal";
import DestinationItem from "../../features/destinations/components/DestinationItem";
import { IDestination } from "../../features/destinations/types";
import useMixPanel from "../../mixpanel/useMixPanel";
import { IApiErrorResponse } from "../../types/errors";

const Destinations = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const bg = useColorModeValue("gray.50", "gray.700");

  const mixpanel = useMixPanel();

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetAllDestinations();

  const [selected, setSelected] = useState<IDestination>();

  const createMutation = useCreateDestinationConfiguration();

  if (isLoading) {
    return (
      <ContentBox>
        <LoadingAnimation></LoadingAnimation>
      </ContentBox>
    );
  }

  if (!data) {
    return <EmptyState></EmptyState>;
  }

  const destinations = data.pages.flatMap((page) => page.data);

  return (
    <>
      <Flex
        margin={"auto"}
        alignItems={"stretch"}
        minW={"480px"}
        maxW={"1200px"}
        w="100%"
        direction={"column"}
        textAlign={"center"}
        gap={"2em"}
      >
        <Box>
          <Heading>Integrate a destination with Enterspeed</Heading>
          <Box textAlign={"justify"}>
            <Text mt="10px" ml="40px" mr="40px">
              With Destinations you can push view data to external systems. It
              could be that you want to push content or product data to a search
              engine or get notified on by a webhook when a specific view is
              updated. {"You can read more about Destinations on our "}
              <Button
                variant={"link"}
                as={Link}
                target="_blank"
                to={
                  "https://docs.enterspeed.com/reference/js/actions#destination"
                }
              >
                doc site.
              </Button>
            </Text>
          </Box>
        </Box>
        <List w={"100%"}>
          {destinations.map((destination) => (
            <ListItem
              key={destination.id}
              mb={"1em"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setSelected(destination);
                mixpanel.track("Open create destination dialog", {
                  app: destination.name
                });
                onOpen();
              }}
            >
              {
                <Card w="100%" textAlign={"left"} _hover={{ cursor: "hand" }}>
                  <CardBody bg={bg}>
                    <DestinationItem
                      destination={destination}
                    ></DestinationItem>
                  </CardBody>
                </Card>
              }
            </ListItem>
          ))}
        </List>
        <Pagination
          disabled={!hasNextPage}
          current={destinations.length}
          total={data.pages[0].total ?? 0}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
        ></Pagination>
      </Flex>
      {selected && (
        <CreateDestinationConfigurationModal
          isOpen={isOpen}
          onClose={onClose}
          destination={selected}
          onSubmit={async (create) => {
            mixpanel.track("Submit create destination", { app: selected.name });
            const promise = createMutation.mutateAsync({
              appId: selected.id,
              ...create
            });
            toast.promise(promise, {
              success: () => ({
                title: `Created destination with ${create.alias}`
              }),
              loading: {
                title: `Creating destination with ${create.alias}`
              },
              error: (error: AxiosError) => {
                const response =
                  error.response as AxiosResponse<IApiErrorResponse>;

                if (response.status === HttpStatusCode.Conflict) {
                  return {
                    title: "Failed creating destination",
                    description: response.data.detail
                  };
                }

                return {
                  title: response.data.title,
                  description: response.data.detail
                };
              }
            });

            try {
              const response = await promise;
              if (response) {
                navigate(`/settings/destinations-settings/${create.alias}`);
              }
            } catch (error) {
              //  Try/catch is needed as an awaited promise that throws uncaught exception will enter react error boundary.
              // The error is handled elsewhere.
            }
          }}
        ></CreateDestinationConfigurationModal>
      )}
    </>
  );
};

export default Destinations;
