import { Flex, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";

const MetricsTileBody = ({
  header,
  children,
  tooltipBody
}: {
  header?: ReactNode;
  children?: ReactNode;
  tooltipBody?: ReactNode;
}) => {
  return (
    <Flex direction={"column"} grow={1}>
      {header && (
        <Flex fontSize="20px" fontWeight="semibold" pb="2">
          {header}
        </Flex>
      )}
      <Tooltip padding={3} label={tooltipBody}>
        <Flex grow={1} direction={"column"}>
          {children}
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default MetricsTileBody;
