import _ from "lodash";
import { colors } from "../styles/theme";

export const badgeColors = [
  "#00B5D8",
  "#00A3C4",
  "#805AD5",
  "#6B46C1",
  "#D53F8C",
  "#B83280",
  "#38A169",
  "#2F855A",
  "#3182CE",
  "#2B6CB0",
  "#D69E2E",
  "#DD6B20",
  "#B7791F",
  "#C05621"
];

export const getBadgeColor = (index: number, prime = 5): [string, string] => {
  const primedIndex = (index * prime) % badgeColors.length;
  return [badgeColors[primedIndex], `${badgeColors[primedIndex]}33`];
};

export const getChakraColorAsHex = (path: string) => {
  const colorString = _.get(colors, path, "");
  return typeof colorString === "string" ? colorString : "";
};
