import { Button, Icon } from "@chakra-ui/react";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { useLogsState } from "../contexts/logs";

const ToggleInternalLogsVisiblityButton = () => {
  const { state, dispatch } = useLogsState();
  const adminFeatures = useAdminFeatures();
  const internalLogsVisible = state.types?.includes("INTERNAL") ?? false;
  if (!adminFeatures) {
    return null;
  }

  return (
    <Button
      leftIcon={
        <Icon
          as={FontAwesomeIcon}
          icon={internalLogsVisible ? faEye : faEyeSlash}
        ></Icon>
      }
      variant="ghost"
      onClick={() =>
        internalLogsVisible
          ? dispatch({
              type: "set_visible_log_types",
              value: undefined
            })
          : dispatch({
              type: "set_visible_log_types",
              value: ["ACTIVITY", "INTERNAL", "SYSTEM"]
            })
      }
    >
      Internal logs
    </Button>
  );
};
export default ToggleInternalLogsVisiblityButton;
