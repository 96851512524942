import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { APPS } from "../../../constants/queryKey";
import { useTenantStore } from "../../tenants/store";
import { DestinationsService } from "../destinations.service";

const queryFn = () => {
  const destinationsService = container.resolve(DestinationsService);
  return destinationsService.getAllDestinationsByTenant();
};

const useDestinationsByTenant = () => {
  const tenantId = useTenantStore(useShallow((state) => state.activeTenant.id));
  return useQuery({
    queryKey: [APPS, tenantId],
    queryFn
  });
};

export default useDestinationsByTenant;
