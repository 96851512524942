import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { faKey, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DataSourceModalType, IDataSource } from "../types";

import EnvironmentBadge from "../../../components/EnvironmentBadge";
import DeleteModal from "../../../components/modals/DeleteModal";
import RegenerateApiKeyModal from "../../../components/modals/RegenerateApiKeyModal";
import SecretKey from "../../../components/SecretKey";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTableAction } from "../../../types/dataTable";
import { useDeleteAllEntities } from "../api/deleteAllEntities";
import { useRegenerateAccessKeyDataSource } from "../api/regenerateAccessKeyDataSource";

const DataSource = ({
  name,
  numberOfSourceEntities,
  environments,
  accessKey,
  sourceId,
  type: dataSourceType
}: IDataSource) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<DataSourceModalType>();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [accessKeyUpdated, setAccessKeyUpdated] = useState("");

  const handleAccessKeyUpdated = (accessKey: string) => {
    setAccessKeyUpdated(accessKey);
  };

  const handleActionClick = (type: DataSourceModalType) => {
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<DataSourceModalType>[] = [
    {
      type: DataSourceModalType.DELETE_ALL_ENTITIES,
      icon: faTrashCan,
      onClick: () => handleActionClick(DataSourceModalType.DELETE_ALL_ENTITIES)
    },
    {
      type: DataSourceModalType.REGENERATE_API_KEY,
      icon: faKey,
      onClick: () => handleActionClick(DataSourceModalType.REGENERATE_API_KEY)
    }
  ];

  const ActionModal = ({
    type,
    isOpen,
    onClose
  }: {
    type: DataSourceModalType | undefined;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const deleteAllEntities = useDeleteAllEntities();
    const regenerateAccessKey = useRegenerateAccessKeyDataSource();

    switch (type) {
      case DataSourceModalType.DELETE_ALL_ENTITIES:
        return (
          <DeleteModal
            name="all source entities"
            customTitle={`Are you sure you want to delete all entities from ${name}?`}
            customDescription="This will start deletion of all entity data on this source, and cant be undone."
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={() => {
              deleteAllEntities.mutate({
                sourceId: sourceId.sourceGuid
              });
              onClose();
            }}
          />
        );
      case DataSourceModalType.REGENERATE_API_KEY:
        return (
          <RegenerateApiKeyModal
            name={name}
            description="This will immediately cause all Management API request with the previous API key to be denied for this management client."
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={() => {
              const payload = {
                name: name,
                type: dataSourceType,
                regenerateAccessKey: true,
                environmentIds: environments.map((env) => env.id.idValue)
              };

              regenerateAccessKey.mutate({
                id: sourceId.sourceGuid,
                payload: payload
              });
              handleAccessKeyUpdated(accessKey);
              onClose();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <VStack
        bg={useColorModeValue("white", "gray.900")}
        border="1px solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
        borderRadius="md"
        padding="12px"
      >
        <Flex
          justifyContent="space-between"
          width="100%"
          borderBottom="1px solid"
          borderColor="gray.200"
          pb="3"
        >
          <Flex flexDirection="column">
            <Box fontWeight="semibold">{name}</Box>
            <Box fontSize="xs" color="gray.500">
              {numberOfSourceEntities} source entitie
              {numberOfSourceEntities !== 1 && "s"}
            </Box>
            <AdminTableRowId id={sourceId.idValue} />
          </Flex>
          <HStack>
            {environments.map((environment) => {
              return (
                <EnvironmentBadge
                  key={environment.id.idValue}
                  name={environment.name}
                />
              );
            })}
            <Menu>
              <Tooltip label="Settings" isOpen={tooltipOpen}>
                <MenuButton
                  onClick={() => setTooltipOpen(false)}
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}
                  display="block"
                  ml="auto"
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={FontAwesomeIcon} icon={faEllipsisVertical} />}
                  variant="ghost"
                />
              </Tooltip>
              <MenuList>
                {actions?.map((action) => (
                  <MenuItem
                    key={action.type}
                    onClick={action.onClick}
                    py="3"
                    icon={
                      action.icon && (
                        <Icon
                          as={FontAwesomeIcon}
                          icon={action.icon}
                          color="gray.500"
                        />
                      )
                    }
                  >
                    {action.type}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
        <Flex
          flexDirection="column"
          mt="1"
          alignItems="flex-start"
          width="100%"
        >
          <Text fontSize="xs" color="gray.500" fontStyle="italic">
            API key
          </Text>
          <SecretKey
            updated={accessKeyUpdated === accessKey}
            content={accessKey}
          />
        </Flex>
      </VStack>
      <ActionModal type={modalType} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default DataSource;
