import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import HelpIcon from "../../../components/HelpIcon";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { IApiErrorResponse } from "../../../types/errors";
import { useCreateDomain } from "../api/createDomain";
import { useDomains } from "../api/getDomains";

const CreateDomainModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [useRelativeUrls, setUseRelativeUrls] = useState(false);

  const { data: domains } = useDomains();
  const toast = useToast();

  const inputBgColorMode = useColorModeValue("white", "gray.700");

  const createDomain = useCreateDomain();

  const checkForRootTldInHostNames = () =>
    domains?.some((d) => d.hostnames.includes("root.tld")) ?? false;

  return (
    <>
      <Button variant="subtle" onClick={onOpen}>
        Create
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={() => setUseRelativeUrls(false)}
      >
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Create new domain</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">Give your domain a descriptive name.</Text>
            <Formik
              initialValues={{ name: "" }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={async ({ name }) => {
                const createDomainPromise = createDomain.mutateAsync({
                  payload: {
                    name,
                    hostnames: useRelativeUrls ? ["root.tld"] : []
                  }
                });
                toast.promise(createDomainPromise, {
                  success: {
                    title: "Domain created successfully",
                    description: `Created domain "${name}"`
                  },
                  loading: {
                    title: "Creating domain",
                    description: `Creating domain "${name}"`
                  },
                  error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                    title: response?.data.detail ?? "Error creating domain"
                  })
                });
                await createDomainPromise;
                onClose();
              }}
            >
              <Form>
                <Field name="name" validate={isRequired("Name is required")}>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!form.errors.name} mb="6">
                      <FormLabel>Name</FormLabel>
                      <Input
                        data-1p-ignore
                        bg={inputBgColorMode}
                        autoFocus
                        {...field}
                        placeholder="Your domain name"
                      />
                      <FormErrorMessage>
                        {form.errors.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex alignItems="center">
                  <Checkbox
                    isDisabled={checkForRootTldInHostNames()}
                    isChecked={useRelativeUrls}
                    onChange={(e) => setUseRelativeUrls(e.target.checked)}
                    sx={{
                      ".chakra-checkbox__control": {
                        bg: "white"
                      }
                    }}
                  >
                    Use relative URL's{" "}
                  </Checkbox>
                  <HelpIcon label="Use relative URL's for this domain. This will add 'root.tld' to your hostnames. Can only be added to a single domain." />
                </Flex>
                {checkForRootTldInHostNames() && (
                  <Alert status="warning" mt="4">
                    <AlertIcon />
                    Only one domain can use relative URL's (root.tld)
                  </Alert>
                )}
                <Flex pt={8} justifyContent="flex-end">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    mr={3}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    isLoading={createDomain.isPending}
                    type="submit"
                  >
                    Create
                  </Button>
                </Flex>
              </Form>
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDomainModal;
