import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ISchemaVersion, SchemaType } from "../../types";
import TestArbitraryJson from "./TestArbitraryJson";
import { TestSourceEntity } from "./TestSourceEntity";

export interface ITestSourceEntityPanel {
  schema: ISchemaVersion;
  schemaType: SchemaType;
  schemaAlias: string;
}

const TestSourceEntityPanel = ({
  schema,
  schemaAlias,
  schemaType
}: ITestSourceEntityPanel) => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchTabIndex = searchParams?.get("tabIndex");
    if (searchTabIndex !== undefined && searchTabIndex !== null) {
      setTabIndex(parseInt(searchTabIndex));
    }
  }, []);

  return (
    <Tabs
      variant="default"
      isFitted
      onChange={(index) => {
        const newSearch = new URLSearchParams(searchParams ?? undefined);
        newSearch.set("tabIndex", index.toString());
        if (!pathname) {
          return;
        }
        navigate(`${pathname}?${newSearch.toString()}`);
        setTabIndex(index);
      }}
      index={tabIndex}
    >
      <TabList>
        <Tab>Source Entities</Tab>
        <Tab>JSON</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <TestSourceEntity
            schema={schema}
            schemaAlias={schemaAlias}
            schemaType={schemaType}
          ></TestSourceEntity>
        </TabPanel>
        <TabPanel>
          <TestArbitraryJson
            schema={schema}
            schemaAlias={schemaAlias}
            schemaType={schemaType}
          ></TestArbitraryJson>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TestSourceEntityPanel;
