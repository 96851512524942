import { GroupBase } from "chakra-react-select";
import { ISourceGroupsResponse } from "../features/source-groups/types";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";
import { ValueType } from "./valueType";

export const getSourceOptions = (
  dataSources?: ISourceGroupsResponse[],
  valueType?: ValueType | undefined | null
): GroupBase<IEnterspeedIdentifiableSelectOption>[] => {
  if (!dataSources || dataSources.length === 0) {
    return [];
  }

  return dataSources
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(
      (sourceGroup): GroupBase<IEnterspeedIdentifiableSelectOption> => ({
        label: `${sourceGroup.name} (${sourceGroup.type.toUpperCase()})`,
        options: sourceGroup.sources.map((source) => {
          return {
            id: source.source.id.idValue,
            label: source.source.name,
            value:
              valueType === "FULL"
                ? source.source.id.idValue
                : source.source.id.sourceGuid
          } as IEnterspeedIdentifiableSelectOption;
        })
      })
    )
    .sort((a, b) =>
      comparePossibleUndefinedProperty(a.label ?? "", b.label ?? "")
    );
};

export const getSourceOption = (
  sourceGroups: ISourceGroupsResponse[],
  identifierValue: string,
  valueType?: ValueType | undefined | null
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!sourceGroups || sourceGroups.length === 0) {
    return undefined;
  }

  const sources = sourceGroups
    .flatMap(({ sources }) => sources)
    .flatMap(({ source }) => source);

  if (sources.length === 0) {
    return undefined;
  }

  const source = sources.find((s) => {
    if (valueType === "FULL") {
      return s.id.idValue === identifierValue;
    }

    return s.id.sourceGuid === identifierValue;
  });
  return source
    ? {
        id: source.id.idValue,
        label: source.name,
        value: valueType === "FULL" ? source.id.idValue : source.id.sourceGuid
      }
    : undefined;
};

export const comparePossibleUndefinedProperty = (a: string, b: string) => {
  return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare(b)) : 0;
};
