import qs from "qs";
import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IRouteDeliveryResponse } from "../tenants/types";
import {
  IViewGetPayload,
  IViewListPaginationResponse,
  IViewResponse
} from "./types";

@singleton()
export class ViewService {
  constructor(private readonly http: ManagementClient) {}

  public async getViewById(id: string) {
    const { data } = await this.http.get<IViewResponse>(
      `/api/v1/tenant/views`,
      {
        params: { id }
      }
    );
    return data;
  }

  public async getViewsPage(
    { params, environmentId }: IViewGetPayload,
    continuationToken?: string,
    signal?: AbortSignal
  ) {
    if (!environmentId) {
      return { viewPage: { results: [], total: 0 } };
    }
    const { data, headers } = await this.http.get<IViewListPaginationResponse>(
      `/api/v1/tenant/environments/${environmentId}/view-list`,
      {
        params: { ...params },
        headers: {
          "x-continuation-token": continuationToken
        },
        paramsSerializer: {
          serialize: (params) => qs.stringify(params, { allowDots: true })
        },
        signal
      }
    );
    return {
      viewPage: data,
      continuationToken: headers["x-continuation-token"] as string
    };
  }

  public async getDeliveryContext(routeId: string) {
    const { data } = await this.http.post<IRouteDeliveryResponse>(
      "/api/v1/tenant/routes/_delivery",
      {
        routeId
      }
    );
    return data;
  }
}
