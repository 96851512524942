import { Flex, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { MouseEvent, useState } from "react";

const CopyableText = ({
  id,
  w,
  fontSize
}: {
  id: string;
  w?: string;
  fontSize?: string;
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const copyId = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await navigator.clipboard.writeText(id);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const hoverColor = useColorModeValue("brand.50", "brand.200");

  return (
    <Flex
      px={"0.25em"}
      height={"1.5em"}
      w={w}
      _hover={{ backgroundColor: hoverColor }}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
      fontSize={fontSize ?? "xs"}
      fontStyle="italic"
    >
      <Tooltip
        closeOnClick={false}
        label={copied ? "ID copied!" : "Copy ID to clipboard"}
      >
        <span onClick={copyId}>
          <Text color={copied ? "green.500" : "gray.500"} cursor="pointer">
            {id}
          </Text>
        </span>
      </Tooltip>
    </Flex>
  );
};

export default CopyableText;
