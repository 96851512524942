import { Badge, Box, Text, useToast } from "@chakra-ui/react";
import { faFileSlash, faPaperPlaneTop } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Pagination from "../../../components/Pagination";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { formatTime, timeFromNow } from "../../../helpers/dateHelper";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { IPagination } from "../../../types/api";
import { DataTableAction } from "../../../types/dataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { useCancelTenantInvite } from "../api/cancelTenantInvite";
import { useTenantSentInvitations } from "../api/getTenantSentInvitations";
import { useResendTenantInvite } from "../api/resendTenantInvite";
import {
  InviteState,
  ISentInvitation,
  TenantSentInvitationsTableActionType
} from "../types";

const TenantSentInvitationsTable = () => {
  const pagination: IPagination = {
    first: 10
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useTenantSentInvitations(pagination);

  const cancelInvitation = useCancelTenantInvite();
  const resendInvitation = useResendTenantInvite();

  const mixpanel = useMixPanel();

  const pageData = data?.pages.map((page) => page.results).flat() || [];

  const total = data?.pages[0]?.total;

  const toast = useToast();

  const tableData = pageData
    ? pageData?.map((item) => {
        const invitation: ISentInvitation = {
          email: item.invitee.email,
          status: item.state,
          dateSent: item.createdAt,
          dateExpires: item.expiresAt,
          id: item.id.idValue.replace("gid://Invite/", "")
        };
        return invitation;
      })
    : [];

  const columnHelper = createColumnHelper<ISentInvitation>();

  const columns = [
    columnHelper.accessor("email", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.email}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Email"
    }),
    columnHelper.accessor("status", {
      cell: (info) => {
        const badgeColor = {
          Pending: "yellow",
          Accepted: "green",
          Expired: "red",
          Cancelled: "red",
          Declined: "red"
        };
        return (
          <Badge
            colorScheme={badgeColor[info.getValue() as keyof typeof badgeColor]}
          >
            {info.getValue()}
          </Badge>
        );
      },
      header: "Status"
    }),
    columnHelper.accessor("dateSent", {
      cell: (info) => (
        <>
          <Text>{timeFromNow(info.getValue())}</Text>
          <Text fontSize="xs" color="gray.400">
            {formatTime(info.getValue())}
          </Text>
        </>
      ),
      header: "Sent"
    }),
    columnHelper.accessor("dateExpires", {
      cell: (props) =>
        props.row.original.status === "Pending" && (
          <>
            <Text>{timeFromNow(props.cell.getValue())}</Text>
            <Text fontSize="xs" color="gray.400">
              {formatTime(props.cell.getValue())}
            </Text>
          </>
        ),
      header: "Expires"
    })
  ];

  const actions: DataTableAction<
    TenantSentInvitationsTableActionType,
    ISentInvitation
  >[] = [
    {
      type: TenantSentInvitationsTableActionType.REVOKE_INVITATION,
      icon: faFileSlash,
      onClick: (row: ISentInvitation) => {
        if (row.status === InviteState.PENDING) {
          cancelInvitation.mutate({ id: row.id });
          mixpanel.track("inviteCancelled", {
            inviteId: row.id
          });
        } else {
          toast({
            title: "This action can only be used for Pending invites",
            status: "warning",
            duration: 9000,
            isClosable: true
          });
        }
      }
    },
    {
      type: TenantSentInvitationsTableActionType.RESEND_EMAIL,
      icon: faPaperPlaneTop,
      onClick: (row: ISentInvitation) => {
        if (row.status === InviteState.PENDING) {
          resendInvitation.mutate({ id: row.id });
          mixpanel.track("inviteResend", {
            inviteId: row.id
          });
        } else {
          toast({
            title: "This action can only be used for Pending invites",
            status: "warning",
            duration: 9000,
            isClosable: true
          });
        }
      }
    }
  ];

  return (
    <>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={tableData}
        actions={actions}
        emptyState={EmptyStateType.NO_SENT_INVITATIONS}
      />
      {tableData.length > 0 && (
        <Pagination
          disabled={!hasNextPage || isLoading || isFetchingNextPage}
          current={pageData.length}
          total={total as number}
          loadMore={() => fetchNextPage()}
          loading={isFetchingNextPage}
        />
      )}
    </>
  );
};

export default TenantSentInvitationsTable;
