import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { useSignOut } from "../api/signOut";
import { ISignOutModalProps } from "../types";
import CountdownTimer from "./CountDownTimer";

const SignOutModal = ({ isOpen, onClose }: ISignOutModalProps) => {
  const mixpanel = useMixPanel();
  const { signOut } = useSignOut();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>Sign out</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text pb="4">
                You will be signed out in{" "}
                <Text as="b">
                  <CountdownTimer
                    initialSeconds={10}
                    action={() => {
                      mixpanel.reset();
                      return signOut();
                    }}
                  ></CountdownTimer>
                </Text>
                {". Click cancel now if you do not wish to proceed."}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  mixpanel.reset();
                  return signOut();
                }}
                ml={3}
              >
                Sign out now
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default SignOutModal;
