import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";

export const BulkDeployEnvironmentPicker = ({
  label,
  placeholder,
  value,
  options,
  onChange
}: {
  label: string;
  placeholder: string;
  value?: ISelectOption | null;
  options: ISelectOption[];
  onChange: (selected: ISelectOption) => void;
}) => {
  const reactSelectStyles = useReactSelectStyles();
  return (
    <>
      <FormControl>
        <FormLabel color="gray.500" fontSize="xs">
          {label}
        </FormLabel>
        <Select
          size="sm"
          useBasicStyles
          chakraStyles={reactSelectStyles}
          colorScheme="blue"
          isClearable
          value={value}
          onChange={(value) => onChange(value as ISelectOption)}
          options={options}
          placeholder={placeholder}
        />
      </FormControl>
    </>
  );
};
