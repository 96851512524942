import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ANNOUNCEKITNEWS } from "../../../constants/queryKey";
import { ProductUpdatesService } from "../product-updates.service";

const getNewsFn = ({ count }: { count: number }) => {
  const productUpdatesService = container.resolve(ProductUpdatesService);
  return productUpdatesService.getPosts(count);
};

const useProductUpdates = ({ count }: { count: number }) =>
  useQuery({
    queryKey: [ANNOUNCEKITNEWS, count],
    queryFn: () => getNewsFn({ count })
  });

export default useProductUpdates;
