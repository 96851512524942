import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { BiService } from "./bi.service";

const queryFn = () => {
  const biClient = container.resolve(BiService);
  return biClient.GetTenantExceededLimits();
};

const useTenantLimits = () => {
  return useQuery({ queryFn, queryKey: ["BI", "TENANT_LIMITS"] });
};

export default useTenantLimits;
