import { useMutation } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TenantService } from "../tenants.service";
import { TenantConfig } from "../types";

const mutationFn = ({
  tenantGuid,
  tenantConfig
}: {
  tenantGuid: string;
  tenantConfig: TenantConfig;
}) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.updateTenantConfig(tenantGuid, tenantConfig);
};

const useUpdateTenantConfig = () => {
  return useMutation({
    mutationFn
  });
};

export default useUpdateTenantConfig;
