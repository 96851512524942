import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { SourceService } from "../data-sources/source.service";
import { ISourceResponseId } from "../data-sources/types";
import {
  ISourceBase,
  ISourceCreatePayload,
  ISourceEditPayload,
  ISourceGroupId,
  ISourceGroupsCreateWithSources,
  ISourceGroupsEditWithSources,
  ISourceGroupsResponse
} from "./types";

@singleton()
export class SourceGroupService {
  constructor(
    private readonly http: ManagementClient,
    private readonly sourceService: SourceService
  ) {}

  async getSourceGroups() {
    const { data } = await this.http.get<ISourceGroupsResponse[]>(
      "/api/v1/tenant/source-groups"
    );

    return data;
  }

  async createSourceGroup(payload: ISourceGroupsCreateWithSources) {
    const {
      data: { idValue }
    } = await this.http.post<ISourceGroupId>(
      "/api/v1/tenant/source-groups",
      payload.group
    );

    return Promise.all(
      payload.sources
        .map((source) => ({
          name: source.name,
          environmentIds: source.environmentIds,
          sourceGroupId: idValue,
          type: payload.group.type
        }))
        .map((sourcePayload) =>
          this.http.post<ISourceResponseId>(
            "/api/v1/tenant/sources",
            sourcePayload
          )
        )
    );
  }

  deleteSourceGroup(id: string) {
    return this.http.delete(`/api/v1/tenant/source-groups/${id}`);
  }

  async editSourceGroup(
    payload: ISourceGroupsEditWithSources,
    dataSourceBeingEdited: ISourceGroupsResponse
  ) {
    const updateSourceGroupPromise = this.http.put(
      `/api/v1/tenant/source-groups/${payload.id.sourceGroupGuid}`,
      payload.group
    );

    const deletePromises = dataSourceBeingEdited.sources.map(async (source) => {
      if (!payload.sources.some((x) => x.id === source.source.id.sourceGuid)) {
        return this.http.delete(
          `/api/v1/tenant/sources/${source.source.id.sourceGuid}`
        );
      }
    });

    const upsertPromises = payload.sources
      .filter((source): source is ISourceBase & { id: string } => !!source.id)
      .map(async (source) => {
        const sourcePayload: ISourceCreatePayload = {
          name: source.name,
          environmentIds: source.environmentIds,
          type: payload.group.type,
          sourceGroupId: payload.id.idValue
        };

        if (source.id?.startsWith("new-")) {
          return this.http.post("/api/v1/tenant/sources", sourcePayload);
        }
        return this.http.put(
          `/api/v1/tenant/sources/${source.id}`,
          sourcePayload
        );
      });

    return Promise.all([
      updateSourceGroupPromise,
      ...deletePromises,
      ...upsertPromises
    ]);
  }

  regenerateAccessKey(sourceId: string, payload: ISourceEditPayload) {
    return this.http.put(`/api/v1/tenant/sources/${sourceId}`, payload);
  }
}
