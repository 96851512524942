import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousRoute = (
    location?.state as { previousRoute: Location | undefined }
  )?.previousRoute;

  return (
    <Flex
      p="16"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      width={"100%"}
    >
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 2fr"
        }}
      >
        <Box
          color={useColorModeValue("brand.700", "brand.200")}
          fontSize={"100px"}
          fontWeight={"900"}
          textAlign={{ base: "center", lg: "left" }}
        >
          404
        </Box>
        <Box
          py="4"
          pl={{ lg: "8" }}
          ml={{ lg: "8" }}
          borderLeft={{ lg: "solid 1px lightgray" }}
          textAlign={{ base: "center", lg: "left" }}
        >
          <Heading size="2xl" fontWeight={"300"} mb="4">
            Page not found
          </Heading>
          {previousRoute && (
            <Text mb="4">{`No page found for route ${previousRoute.pathname}`}</Text>
          )}
          <Button variant="primary" size="md" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </Box>
      </Grid>
    </Flex>
  );
};

export default NotFound;
