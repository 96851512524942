import { Box, Flex, Progress } from "@chakra-ui/react";

export const ToastProgress = ({
  total,
  current
}: {
  total: number;
  current: number;
}) => {
  return (
    <>
      <Box>
        <Box justifyItems={"center"}>
          <Progress size={"md"} value={Math.floor((current / total) * 100)} />
        </Box>
        <Flex justify={"end"}>
          {current} / {total}
        </Flex>
      </Box>
    </>
  );
};
