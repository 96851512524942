import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReloadButton = ({
  loading,
  alignRight = true,
  disabled = false,
  disabledTooltip,
  onClick,
  marginBottom = 0
}: {
  loading?: boolean;
  alignRight?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  marginBottom?: number;
  onClick: () => void;
}) => {
  return (
    <Tooltip label={disabled && disabledTooltip ? disabledTooltip : "Reload"}>
      <IconButton
        mb={marginBottom}
        onClick={onClick}
        display="flex"
        ml={alignRight ? "auto" : "0"}
        role="group"
        _hover={{
          backgroundColor: "brand.100"
        }}
        _active={{
          backgroundColor: "brand.200"
        }}
        isLoading={loading}
        isDisabled={disabled}
        variant="ghost"
        aria-label="Reload"
        icon={
          <Icon
            _groupHover={{ transform: "rotate(180deg)" }}
            transition="transform 0.5s"
            as={FontAwesomeIcon}
            icon={faArrowRotateRight}
          />
        }
      />
    </Tooltip>
  );
};

export default ReloadButton;
