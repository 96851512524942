import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INVITES } from "../../../constants/queryKey";
import { InviteService } from "../invite.service";

const getInvitesFn = () => {
  const inviteService = container.resolve(InviteService);
  return inviteService.getInvites();
};

export const useInvites = () => {
  return useQuery({
    queryKey: [INVITES],
    queryFn: getInvitesFn,
    refetchInterval: 30_000
  });
};
