import { useColorModeValue } from "@chakra-ui/react";

export const useInputColors = () => ({
  bg: useColorModeValue("white", "gray.700"),
  disabled: { bg: useColorModeValue("gray.300", "gray.700") }
});

export const useTextColors = () => ({
  bg: useColorModeValue("white", "gray.900"),
  text: useColorModeValue("black", "white"),
  textShadow: useColorModeValue(
    "0 0 6px rgba(0,0,0,.5)",
    "0 0 6px rgba(255,255,255,.5)"
  ),
  active: { bg: useColorModeValue("green.100", "green.900") }
});

export const useMenuButtonColors = () => ({
  bg: useColorModeValue("gray.300", "gray.600"),
  active: { bg: useColorModeValue("gray.400", "gray.500") }
});

export const useModalColors = () => ({
  bg: useColorModeValue("gray.100", "gray.800")
});

export const useSummaryColors = () => ({
  bg: useColorModeValue("gray.200", "gray.900"),
  hover: {
    bg: useColorModeValue("whiteAlpha.400", "whiteAlpha.100")
  }
});
