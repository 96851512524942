import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATION_ALIASES } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const destinationQueryFn = () => {
  const destinationsService = container.resolve(DestinationsService);
  return destinationsService.getDestinationAliases();
};

const useConfiguredDestinationAliases = () =>
  useQuery({
    queryFn: () => destinationQueryFn(),
    queryKey: [DESTINATION_ALIASES]
  });

export default useConfiguredDestinationAliases;
