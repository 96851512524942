import {
  Button,
  Divider,
  Flex,
  HStack,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import CommandBar from "../../features/commandbar/components/CommandBar";
import BulkDeployPanel from "../../features/deployment/components/BulkDeployPanel";
import TenantCreateTenantModal from "../../features/tenants/components/TenantCreateTenantModal";
import DarkModeToggle from "./DarkModeToggle";
import HelpMenu from "./HelpMenu";
import ImpersonationBanner from "./ImpersonationBanner";
import Logo from "./Logo";
import Navigation from "./Navigation";
import ProductUpdates from "./ProductUpdates";
import TenantPicker from "./TenantPicker";
import UserMenu from "./UserMenu";

const Header = ({
  enableNavigation = true,
  enableCommandbar = true,
  enableDeploy = true
}: {
  enableNavigation?: boolean;
  enableCommandbar?: boolean;
  enableDeploy?: boolean;
}) => {
  const flags = useFlags();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex
      bg={bgColor}
      boxShadow="sm"
      borderBottom={"1px solid"}
      borderColor={borderColor}
      direction={"column"}
    >
      <ImpersonationBanner></ImpersonationBanner>
      <Flex
        p={{ base: ".5em", md: "0.5em 2em" }}
        justifyContent="space-between"
        width="100%"
        flexDirection={{ base: "column", md: "row" }}
        wrap={"wrap"}
        rowGap={"1em"}
      >
        <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
          <Logo />
          <Divider
            height="30"
            color="gray.700"
            mr="4"
            ml="6"
            display={{ base: "none", md: "block" }}
            orientation="vertical"
          />
          <Flex alignItems="center" mt={{ base: 4, md: 0 }}>
            <TenantPicker />
            <Button size={"xs"} variant={"subtle"} onClick={onOpen}>
              New tenant
            </Button>
            <TenantCreateTenantModal isOpen={isOpen} onClose={onClose} />
          </Flex>
        </Flex>
        <HStack
          spacing={{ base: 1, md: 3 }}
          justifyContent="center"
          mt={{ base: 4, md: 0 }}
        >
          {enableCommandbar && flags.commandbar && <CommandBar />}
          <DarkModeToggle />
          <HelpMenu />
          <ProductUpdates />
          <UserMenu />
        </HStack>
      </Flex>
      <Flex
        px={{ base: "16px", md: "24px" }}
        justifyContent="space-between"
        width="100%"
        borderTop="1px solid"
        borderColor={borderColor}
        alignItems="center"
        py={{ base: 2, md: 0 }}
      >
        {enableNavigation && <Navigation />}
        {enableDeploy && <BulkDeployPanel />}
      </Flex>
    </Flex>
  );
};

export default Header;
