import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { INVITES } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { InviteService } from "../invite.service";

const declineInviteFn = ({ id }: { id: string }) => {
  const inviteService = container.resolve(InviteService);
  return inviteService.declineInvite(id);
};

export const useDeclineInvite = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: declineInviteFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVITES] });
      toast({
        title: "Invitation was declined",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error declining invitation",
        status: "error",
        ...defaultToast
      });
    }
  });
};
