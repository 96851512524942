import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { faLock, faLockOpen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldProps, Form, Formik } from "formik";
import { useRef, useState } from "react";
import camelize from "../../../helpers/camelize";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { useInputColors, useModalColors } from "../../tenants/hooks/colors";
import { IDuplicateSchemaModalProps } from "../types";

const DuplicateSchemaModal = ({
  isOpen,
  onClose,
  sourceSchemaName,
  onSubmit,
  onSuccess
}: IDuplicateSchemaModalProps) => {
  const nameInputRef = useRef(null);
  const [aliasEditable, setAliasEditable] = useState(false);
  const [aliasChanged, setAliasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearStateOnClose = () => {
    setAliasEditable(false);
    setAliasChanged(false);
  };

  const submit = async (values: { name: string; alias: string }) => {
    setIsLoading(true);
    const submissionResult = await onSubmit({
      name: values.name,
      viewHandle: values.alias
    });
    setIsLoading(false);

    if (submissionResult.valid) {
      onSuccess();
    }
  };

  const { bg } = useModalColors();
  const { bg: inputBg, disabled } = useInputColors();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        initialFocusRef={nameInputRef}
        size="4xl"
        onCloseComplete={clearStateOnClose}
      >
        <ModalOverlay>
          <ModalContent bg={bg} p="4">
            <ModalHeader>Create a duplicate of {sourceSchemaName}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text>
                Give your schema a name and an unique alias. Please note that
                the alias cannot be changed later on.
              </Text>
              <Alert status="info" mt="4" mb="8">
                <AlertIcon />
                <AlertTitle>Tip:</AlertTitle> You can organize your schemas into
                folders, up to two levels deep, by adding slashes (/) in the
                name.
              </Alert>

              <Formik
                initialValues={{
                  name: "",
                  alias: ""
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submit}
              >
                {({ setFieldValue, handleChange }) => {
                  return (
                    <Form>
                      <SimpleGrid columns={2} spacing={4}>
                        <Field
                          name="name"
                          validate={isRequired("Name is required")}
                        >
                          {({ field, form }: FieldProps) => (
                            <FormControl isInvalid={!!form.errors.name} mb="6">
                              <FormLabel fontSize="sm">Name</FormLabel>
                              <Input
                                ref={nameInputRef}
                                id="name"
                                bg={inputBg}
                                {...field}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleChange(e);
                                  if (!aliasChanged) {
                                    let alias = e.target.value;
                                    if (alias.includes("/")) {
                                      alias = alias.substring(
                                        alias.lastIndexOf("/") + 1
                                      );
                                    }
                                    alias = camelize(alias);
                                    return setFieldValue("alias", alias);
                                  }
                                }}
                              />
                              <FormErrorMessage>
                                {form.errors.name?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Field
                          name="alias"
                          validate={isRequired("Alias is required")}
                        >
                          {({ field, form }: FieldProps) => (
                            <FormControl isInvalid={!!form.errors.alias} mb="6">
                              <FormLabel fontSize="sm">Alias</FormLabel>
                              <InputGroup size="md">
                                <Input
                                  isDisabled={!aliasEditable}
                                  bg={aliasEditable ? inputBg : disabled.bg}
                                  pr="4.5rem"
                                  {...field}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                    setAliasChanged(true);
                                  }}
                                />
                                <InputRightElement width="4.5rem">
                                  <Tooltip
                                    label={
                                      aliasEditable
                                        ? "Disable alias editing"
                                        : "Edit alias"
                                    }
                                  >
                                    <IconButton
                                      aria-label="Toggle edit alias"
                                      colorScheme="gray"
                                      icon={
                                        <Icon
                                          as={FontAwesomeIcon}
                                          icon={
                                            aliasEditable ? faLockOpen : faLock
                                          }
                                        />
                                      }
                                      onClick={() =>
                                        setAliasEditable(!aliasEditable)
                                      }
                                    />
                                  </Tooltip>
                                </InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>
                                {form.errors.alias?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </SimpleGrid>
                      <Flex pt={8} justifyContent="flex-end">
                        <Button
                          colorScheme="gray"
                          variant="ghost"
                          mr={3}
                          onClick={onClose}
                          isDisabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          type="submit"
                        >
                          Create
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default DuplicateSchemaModal;
