import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEvent, useState } from "react";

const CopyValueActionButton = ({ value }: { value: string }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const copyId = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await navigator.clipboard.writeText(value);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Tooltip closeOnClick={false} label={copied ? "Copied!" : "Copy value"}>
      <IconButton
        onClick={copyId}
        size="xs"
        variant="link"
        aria-label="Copy value"
        icon={<Icon as={FontAwesomeIcon} icon={faCopy} />}
      />
    </Tooltip>
  );
};

export default CopyValueActionButton;
