import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { container } from "tsyringe";
import { METRICS, SOURCE_ENTITIES } from "../../../constants/queryKey";
import { QueryOptions } from "../../../types/api";
import { MetricsService } from "../metrics.service";

const queryFn = (options?: QueryOptions) => {
  const metricsService = container.resolve(MetricsService);
  return metricsService.getSourceEntitiesMetrics(options);
};

const useSourceEntitiesMetrics = (options?: QueryOptions) => {
  const flags = useFlags();

  const { enabled } = flags.dashboard as {
    enabled: boolean;
    features: string[];
  };

  return useQuery({
    queryKey: [METRICS, SOURCE_ENTITIES, options?.tenantId],
    queryFn: () => queryFn(options),
    enabled,
    refetchInterval: 61_000
  });
};

export default useSourceEntitiesMetrics;
