import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import RegenerateApiKeyModal from "../../../components/modals/RegenerateApiKeyModal";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { useDeleteEnvironmentClient } from "../api/deleteEnvironmentClient";
import { useEditEnvironmentClient } from "../api/editEnvironmentClient";
import { EnvironmentClientTableModalType, IEnvironmentClient } from "../types";
import EnvironmentClientDomainsModal from "./EnvironmentClientDomainsModal";

const EnvironmentClientActionModal = ({
  type,
  environmentClient,
  isOpen,
  onClose,
  onAccessKeyUpdated
}: {
  type: EnvironmentClientTableModalType | undefined;
  environmentClient: IEnvironmentClient;
  isOpen: boolean;
  onClose: () => void;
  onAccessKeyUpdated: (accessKey: string) => void;
}) => {
  const toast = useToast();
  const deleteEnvironmentClient = useDeleteEnvironmentClient();
  const editEnvironmentClient = useEditEnvironmentClient();

  const handleEditEnvironmentClient = ({
    rowData,
    name,
    regenerateAccessKey = false,
    domains
  }: {
    rowData: IEnvironmentClient;
    name?: string;
    regenerateAccessKey?: boolean;
    domains?: string[];
  }) => {
    const result = editEnvironmentClient.mutateAsync({
      environmentId: rowData.environmentGuid,
      clientId: rowData.clientGuid,
      payload: {
        name: name ? name : rowData.name,
        allowedDomains: domains ? domains : rowData.domainIds,
        regenerateAccessKey: regenerateAccessKey
      }
    });
    if (regenerateAccessKey) {
      onAccessKeyUpdated(rowData.accessKey);
    }
    return result;
  };

  switch (type) {
    case EnvironmentClientTableModalType.DELETE:
      return (
        <DeleteModal
          type="environment"
          name={environmentClient?.name || ""}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={async () => {
            onClose();
            const deletePromise = deleteEnvironmentClient.mutateAsync({
              environmentId: environmentClient.environmentGuid,
              clientId: environmentClient.clientGuid
            });
            toast.promise(deletePromise, {
              success: {
                title: "Environment client successfully was deleted",
                description: `Deleted environment client "${environmentClient.name}"`,
                ...defaultToast
              },
              loading: {
                title: "Deleting environment client",
                description: `Deleting environment client "${environmentClient.name}"`,
                ...defaultToast
              },
              error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                title: response?.data.detail ?? "Error deleting environment",
                description: `Error deleting environment "${environmentClient.name}"`,
                ...defaultToast
              })
            });
            await deletePromise;
          }}
        />
      );
    case EnvironmentClientTableModalType.EDIT:
      return (
        <EditNameModal
          type="environment client"
          name={environmentClient.name}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={async ({ name }) => {
            onClose();
            const title = "Edit environment client name";

            const editPromise = handleEditEnvironmentClient({
              rowData: environmentClient,
              name: name
            });
            toast.promise(
              handleEditEnvironmentClient({
                rowData: environmentClient,
                name: name
              }),
              {
                success: {
                  title,
                  description: `Updated from "${environmentClient.name}" to "${name}"`,
                  ...defaultToast
                },
                loading: {
                  title,
                  description: `Updating from "${environmentClient.name}" to "${name}"`,
                  ...defaultToast
                },
                error: (err: AxiosError<IApiErrorResponse>) => ({
                  title,
                  description: err.response?.data.detail,
                  ...defaultToast
                })
              }
            );
            await editPromise;
            return { valid: true };
          }}
        />
      );
    case EnvironmentClientTableModalType.EDIT_DOMAINS:
      return (
        <EnvironmentClientDomainsModal
          name={environmentClient?.name}
          envClientDomains={environmentClient.domains}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={async (selectedDomains) => {
            onClose();

            const editDomainPromise = handleEditEnvironmentClient({
              rowData: environmentClient,
              domains: selectedDomains
            });
            const title = `Updating environment client "${environmentClient.name}"'s domains`;
            toast.promise(editDomainPromise, {
              success: {
                title,
                ...defaultToast
              },
              loading: {
                title,
                ...defaultToast
              },
              error: (err: AxiosError<IApiErrorResponse>) => ({
                title,
                description: err.response?.data.detail,
                ...defaultToast
              })
            });

            await editDomainPromise;
          }}
        />
      );
    case EnvironmentClientTableModalType.REGENERATE_API_KEY:
      return (
        <RegenerateApiKeyModal
          name={environmentClient?.name || ""}
          description="This will immediately cause all Delivery API request with the previous API key to be denied for this environment client."
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={async () => {
            onClose();

            const regeneratingKeyPromise = handleEditEnvironmentClient({
              rowData: environmentClient,
              regenerateAccessKey: true
            });

            toast.promise(regeneratingKeyPromise, {
              success: {
                title: `Regenerated access key`,
                description: `Key of environment client "${environmentClient.name}" was regenerated`
              },
              loading: { title: "Regenerating access key" },
              error: (err: AxiosError<IApiErrorResponse>) => ({
                title: "Regenerating access key",
                description: err.response?.data.detail,
                ...defaultToast
              })
            });

            await regeneratingKeyPromise;
          }}
        />
      );
    default:
      return null;
  }
};

export default EnvironmentClientActionModal;
