import { ILogsState } from "../types";

export const DefaultState: ILogsState = {
  items: [],
  condition: undefined,
  types: undefined,
  period: {
    from: "1h",
    to: undefined
  },
  sortingDirection: "Descending",
  systemColumns: ["timestamp"],
  selectedColumns: ["properties.Level", "service", "message"],
  displayType: "DISPLAY_VALUE_LINK"
};
