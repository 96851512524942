import { SortingDirection } from "../../../types/dataTable";
import ISelectOption from "../../../types/selectInput";
import {
  DisplayType,
  Enhancement,
  EnhancementKey
} from "./interactivePlaceholders";

export type LogLevel = "Warning" | "Information" | "Error";
export type LogType = "SYSTEM" | "INTERNAL" | "ACTIVITY";

export interface ILog {
  id: string;
  type: LogType;
  timestamp: string;
  level: LogLevel;
  service: string;
  message: string;
  messageTemplate: string;
  properties: Record<string, any>;
}

export type Operator = "EQUAL" | "NOT_EQUAL" | "AND" | "IN";
export type InputType =
  | "SOURCE_PICKER"
  | "ENVIRONMENT_PICKER"
  | "MAPPING_SCHEMA_PICKER_BY_ID"
  | "TEXT"
  | "LOG_LEVEL_PICKER"
  | "SERVICE_PICKER"
  | "USER_PICKER_BY_ID";

export interface IFilterableFieldOption extends ISelectOption {
  inputType: InputType;
}

export interface IOperatorSelectOption extends ISelectOption {
  isSingleValue: boolean;
}

export interface ICondition {
  $type: Operator | undefined;
}

export interface IFrontendCondition extends ICondition {
  id: string;
}

export interface IFieldFrontendCondition extends IFrontendCondition {
  field: string;
}

export interface ISingleValueFrontendCondition extends IFieldFrontendCondition {
  value: string;
}

export interface IMultiValueFrontendCondition extends IFieldFrontendCondition {
  values: string[];
}

export interface IConditionEqual extends ISingleValueFrontendCondition {
  field: string;
  value: string;
}

export interface IConditionNotEqual extends ISingleValueFrontendCondition {
  field: string;
  value: string;
}

export interface IConditionIn extends IMultiValueFrontendCondition {
  field: string;
  values: string[];
}

export interface IConditionAnd extends IFrontendCondition {
  items: IFrontendCondition[];
}

export interface ILogsPayload {
  condition?: ICondition;
  fromDate?: string | null;
  toDate?: string | null;
  scrollId?: string | null;
  sortOrder?: SortingDirection;
  color?: string | undefined | null;
  types?: LogType[] | undefined;
}

export interface IAggregatedLogsPayload extends ILogsPayload {
  aggregateFields: string[];
}

export interface IAggregatedLogsResponse {
  key: string;
  results: IAggregatedLog | IAggregatedLogLine;
}

export interface IAggregatedLog {
  [key: string]: IAggregatedLogLines;
}

export interface IAggregatedLogLines {
  key: string;
  results: IAggregatedLogLine;
}

export interface IAggregatedLogLine {
  [key: string]: number;
}

export interface ILogsResponse {
  scrollId: string;
  totalHits: number;
  logLines: ILog[];
}

export interface IEnhancedLogsResponse {
  scrollId: string;
  totalHits: number;
  logLines: IEnhancedLog[];
}

export interface ILogTemplatePlaceholder {
  placeholder: string;
  link?: string;
}

export interface IAddOrEditFilterCondition {
  filterFields: IFilterableFieldOption[];
  condition?: IFrontendCondition;
  onClose: () => void;
  onSuccess: (condition: IFrontendCondition) => void;
}

export interface IEnhancedLog {
  id: string;
  timestamp: string;
  level: LogLevel;
  service: string;
  message: string;
  type: LogType;
  messageTemplate: string;
  enhancements: Record<EnhancementKey, Enhancement>;
  properties: Record<string, any>;
}

// Actions

export type InitializeAction = {
  type: "initialize";
  items: IEnhancedLog[];
};

export type SetConditionAction = {
  type: "set_condition";
  value: IFrontendCondition | IFrontendCondition[];
};

export type SetSortingDirectionAction = {
  type: "set_sorting_direction";
  value: SortingDirection;
};

export type SetPeriodAction = {
  type: "set_period";
  value: ILogsPeriodFilter;
};

export type SetColumnsAction = {
  type: "set_columns";
  value: string[];
};

export type ToggleColumnAction = {
  type: "toggle_column";
  value: string;
};

export type SetDisplayTypeAction = {
  type: "set_display_type";
  value: DisplayType;
};

export type SetVisibleLogTypesAction = {
  type: "set_visible_log_types";
  value: LogType[] | undefined;
};

export type LogsAction =
  | InitializeAction
  | SetConditionAction
  | SetPeriodAction
  | SetSortingDirectionAction
  | SetColumnsAction
  | ToggleColumnAction
  | SetDisplayTypeAction
  | SetVisibleLogTypesAction;

export interface ILogsState {
  items: IEnhancedLog[];
  condition?: IConditionAnd;
  period: ILogsPeriodFilter;
  sortingDirection: SortingDirection;
  selectedColumns: string[];
  systemColumns: string[];
  displayType: DisplayType;
  types?: LogType[] | undefined;
}

export type RelativePeriod = "15m" | "1h" | "1d" | "1w" | "30d";
export type Now = "NOW";
export interface ILogsPeriodFilter {
  from?: string | null | RelativePeriod;
  to?: string | null | Now;
}

export function isFieldFrontendCondition(
  object: IFrontendCondition
): object is IFieldFrontendCondition {
  return "field" in object;
}

// eslint-disable-next-line unused-imports/no-unused-vars
export function isSingleValueFrontendCondition(
  object: IFrontendCondition
): object is ISingleValueFrontendCondition {
  return "value" in object && "field" in object;
}

export function isMultiValueFrontendCondition(
  object: IFrontendCondition
): object is IMultiValueFrontendCondition {
  return "values" in object && "field" in object;
}

export function isEqualCondition(
  object: IFrontendCondition
): object is IConditionEqual {
  return object.$type === "EQUAL";
}

export function isNotEqualCondition(
  object: IFrontendCondition
): object is IConditionNotEqual {
  return object.$type === "NOT_EQUAL";
}

export function isInCondition(
  object: IFrontendCondition
): object is IConditionIn {
  return object.$type === "IN";
}
