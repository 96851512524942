import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import { getBadgeColor } from "../../../constants/colors";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { IDestinationConfiguration } from "../types";

const DestinationConfigurationInfoBox = ({
  destinationConfiguration
}: {
  destinationConfiguration: IDestinationConfiguration;
}) => {
  const adminFeatures = useAdminFeatures();

  return (
    <Box fontSize="sm" color="gray.400" mb="2em">
      <Text>
        <Text as="strong">Alias: </Text>
        {destinationConfiguration.alias}
      </Text>
      {adminFeatures && (
        <Flex gap={"0.5em"}>
          <Text as="strong">Regions: </Text>
          <Flex gap={"0.5em"}>
            {destinationConfiguration.regions?.map((region, idx) => {
              const [color, bgColor] = getBadgeColor(idx);

              return (
                <Tag key={region} color={color} bgColor={bgColor}>
                  {region}
                </Tag>
              );
            })}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default DestinationConfigurationInfoBox;
