"use client";

import {
  Badge,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { formatTime, timeFromNow } from "../../../helpers/dateHelper";
import { IGoToVersionModal } from "../types";

const GoToVersionModal = ({
  versions,
  currentVersion,
  isOpen,
  onClose
}: IGoToVersionModal) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="4xl"
      >
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>Go to version</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text>View version history of your schema.</Text>
              <Box>
                {versions
                  .slice()
                  .reverse()
                  .map((version) => (
                    <Flex
                      key={version.id.idValue}
                      justifyContent="space-between"
                      alignItems="center"
                      py="2"
                      px="4"
                      borderRadius="sm"
                      mt="4"
                      bg="white"
                      cursor="pointer"
                      _hover={{
                        backgroundColor: "gray.50"
                      }}
                      onClick={() => {
                        onClose();
                        if (version.id.version) {
                          return navigate(
                            `/schemas/${version.id.mappingSchemaGuid}?version=${version.id.version}`
                          );
                        }
                      }}
                    >
                      <Flex alignItems="center">
                        <Text fontWeight="bold">{version.id.version}</Text>
                        {currentVersion === version.id.version && (
                          <Badge ml="2">Current</Badge>
                        )}
                      </Flex>
                      <Text fontStyle="italic" color="gray.500">
                        {formatTime(version.updatedAt)}
                        <Box as="span" ml="1" fontSize="xs">
                          ({timeFromNow(version.updatedAt)})
                        </Box>
                      </Text>
                    </Flex>
                  ))}
              </Box>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default GoToVersionModal;
