import { CloseButton, Flex, keyframes, Text, Tooltip } from "@chakra-ui/react";
import { useShallow } from "zustand/react/shallow";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../features/tenants/store";
import CopyableText from "../table/CopyableText";

const ImpersonationBanner = () => {
  const { activeTenant, isImpersonating } = useTenantStore(
    useShallow(({ availableTenants, activeTenant, isImpersonating }) => ({
      availableTenants,
      activeTenant,
      isImpersonating
    }))
  );
  const tenantSwitcher = useTenantSwitcher();
  const defaultColor = "yellow.400";
  const pulse = keyframes({
    "0%": { backgroundColor: "var(--chakra-colors-yellow-400)" },
    "50%": { backgroundColor: "var(--chakra-colors-yellow-500)" },
    "100%": { backgroundColor: "var(--chakra-colors-yellow-400)" }
  });
  return isImpersonating ? (
    <Flex
      fontSize={"md"}
      bg={defaultColor}
      w="100%"
      justifyContent={"center"}
      animation={`${pulse} 2s infinite linear`}
      py="0.5em"
      alignItems={"center"}
    >
      <Text>You are currently impersonating as</Text>
      <Text mx={"1"} as={"b"}>
        {activeTenant.name}
      </Text>
      <CopyableText id={activeTenant.id}></CopyableText>

      <Tooltip
        label={
          "Click to exit impersonation mode and return to previously used tenant"
        }
      >
        <CloseButton
          size={"xl"}
          ml="1em"
          onClick={() => tenantSwitcher.switchToPreviousAvailableTenant()}
        />
      </Tooltip>
    </Flex>
  ) : (
    <></>
  );
};

export default ImpersonationBanner;
