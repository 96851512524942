import { Checkbox } from "@chakra-ui/react";
import { useShallow } from "zustand/react/shallow";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import { useAuthStore } from "../../features/auth/store";

const PreferencesPage = () => {
  const title = "Preferences";
  const [adminFeaturesEnabled, toggleAdminFeatures] = useAuthStore(
    useShallow(({ adminFeaturesEnabled, toggleAdminFeatures }) => [
      adminFeaturesEnabled,
      toggleAdminFeatures
    ])
  );

  const handleShowTableRowIdClick = () => {
    toggleAdminFeatures();
  };

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox title={title}>
        <Checkbox
          onChange={handleShowTableRowIdClick}
          isChecked={adminFeaturesEnabled}
        >
          Use admin features
        </Checkbox>
      </ContentBox>
    </>
  );
};

export default PreferencesPage;
