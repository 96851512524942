import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { QueryOptions } from "../../types/api";
import {
  IEnvironmentCreatePayload,
  IEnvironmentEditPayload,
  IEnvironmentResponse
} from "./types";

@singleton()
export class EnvironmentService {
  public constructor(private readonly managementClient: ManagementClient) {}

  public async getAllEnvironments(options?: QueryOptions) {
    const { data } = await this.managementClient.get<IEnvironmentResponse[]>(
      "/api/v1/tenant/environments",
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );
    return data;
  }

  public async createEnvironment(payload: IEnvironmentCreatePayload) {
    const { data } = await this.managementClient.post(
      "/api/v1/tenant/environments",
      payload
    );
    return data;
  }

  public async deleteEnvironment(environmentId: string) {
    const { data } = await this.managementClient.delete(
      `/api/v1/tenant/environments/${environmentId}`
    );
    return data;
  }

  public async editEnvironment(
    environmentId: string,
    payload: IEnvironmentEditPayload
  ) {
    const { data } = await this.managementClient.put(
      `/api/v1/tenant/environments/${environmentId}`,
      {
        name: payload.name
      }
    );
    return data;
  }
}
