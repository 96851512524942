import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import _ from "lodash";
import { useEffect, useReducer, useState } from "react";
import { Form } from "react-router-dom";
import HelpIcon from "../../../components/HelpIcon";
import useReactSelectStyles from "../../../styles/react-select-style";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { IEnvironmentResponse } from "../../environments/types";
import {
  IDestination,
  IDestinationEnvironmentConfiguration,
  IDestinationEnvironmentConfigurationAppSettings,
  IEditDestinationEnvironmentConfigurationDto
} from "../types";

// type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type DestinationEnvironmentConfigurationEditModalData = {
  app: IDestination;
  configuration: IDestinationEnvironmentConfiguration;
  environment: IEnvironmentResponse;
  environmentClientOptions: IEnvironmentClientResponse[];
};

type SetEnvironmentClientAction = {
  action: "setEnvironmentClient";
  environmentClient: IEnvironmentClientResponse;
};

type SetCustomData = {
  action: "setCustomData";
  data: Record<string, unknown>;
};

type Initialize = {
  action: "initialize";
};

type Reset = {
  action: "reset";
};

type ReducerAction =
  | SetEnvironmentClientAction
  | SetCustomData
  | Reset
  | Initialize;

const DestinationEnvironmentConfigurationEditModal = ({
  modalData,
  isOpen,
  onClose,
  onSubmit
}: {
  modalData: DestinationEnvironmentConfigurationEditModalData;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    destinationConfiguration: Omit<
      IEditDestinationEnvironmentConfigurationDto,
      "alias"
    >
  ) => void;
}) => {
  const modalBg = useColorModeValue("gray.100", "gray.800");
  const inputBgColorMode = useColorModeValue("white", "gray.700");
  const reactSelectStyles = useReactSelectStyles();
  const [invalidMessage, setInvalidMessage] = useState<string>();

  const defaultState: Omit<
    IEditDestinationEnvironmentConfigurationDto,
    "alias"
  > = {
    environmentId: "",
    environmentClientId: "",
    settings: { enterspeedEnvironmentClientApiKey: "" },
    enabled: false
  };

  useEffect(() => {
    dispatch({ action: "initialize" });
  }, [isOpen]);

  const reducer: (
    current: Omit<IEditDestinationEnvironmentConfigurationDto, "alias">,
    action: ReducerAction
  ) => Omit<IEditDestinationEnvironmentConfigurationDto, "alias"> = (
    current: Omit<IEditDestinationEnvironmentConfigurationDto, "alias">,
    action: ReducerAction
  ) => {
    if (action.action === "setEnvironmentClient") {
      return {
        ...current,
        settings: {
          ...current.settings,
          enterspeedEnvironmentClientApiKey: action.environmentClient.accessKey
        },
        environmentClientId: action.environmentClient.id.idValue
      };
    }

    if (action.action === "setCustomData") {
      return {
        ...current,
        settings: {
          ...current.settings,
          ...action.data,
          enterspeedEnvironmentClientApiKey:
            current.settings?.enterspeedEnvironmentClientApiKey ?? ""
        }
      };
    }

    if (action.action === "initialize") {
      return {
        environmentId: modalData.configuration.environmentId,
        environmentClientId: modalData.configuration.environmentClientId,
        regions: modalData.configuration.regions,
        enabled: modalData.configuration.enabled,
        settings: Object.entries(modalData.configuration.settings ?? {}).reduce(
          (acc, [key, { value }]) => {
            acc[key] = value as string;
            return acc;
          },
          {} as IDestinationEnvironmentConfigurationAppSettings
        )
      };
    }

    if (action.action === "reset") {
      return { ...defaultState };
    }

    return current;
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  const getEnvironmentClientById = (id?: string) =>
    modalData.environmentClientOptions?.find(
      (client) => id === client.id.idValue
    );

  useEffect(() => {
    if (_.isEqual(state, modalData.configuration)) {
      setInvalidMessage("No changes to configuration");
      return;
    } else if (!state.environmentId) {
      setInvalidMessage("Environment must be selected");
      return;
    } else if (
      modalData.app.ui?.enterspeedEnvironmentClientApiKey &&
      !state?.environmentClientId
    ) {
      setInvalidMessage("Environment client must be selected");
      return;
    }
    const missingFields = Object.entries(modalData.configuration.settings ?? {})
      .filter(
        ([key, value]) =>
          !value.optional && key !== "enterspeedEnvironmentClientApiKey"
      )
      .filter(([key]) => state.settings && !state.settings[key])
      .map(([_, value]) => value);
    if (missingFields.length) {
      setInvalidMessage(`${missingFields[0].label} is required`);
    } else {
      setInvalidMessage(undefined);
    }
  }, [
    state,
    modalData.configuration.settings,
    modalData.configuration,
    modalData.app.ui?.enterspeedEnvironmentClientApiKey
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent bg={modalBg} p="4">
          <ModalCloseButton></ModalCloseButton>
          <ModalHeader>Edit destination environment configuration</ModalHeader>
          <ModalBody>
            <Text>
              <Text as="b">{"Environment: "}</Text>{" "}
              <Tag>{modalData.environment.name}</Tag>
            </Text>
            <Form>
              {modalData.app.ui?.enterspeedEnvironmentClientApiKey && (
                <FormControl key={"enterspeedEnvironmentClientApiKey"}>
                  <FormLabel pt="4">Environment client</FormLabel>
                  <Select
                    options={modalData.environmentClientOptions?.map((e) => ({
                      label: e.name,
                      value: e
                    }))}
                    value={
                      state.environmentClientId
                        ? {
                            label: getEnvironmentClientById(
                              state.environmentClientId
                            )?.name,
                            value: getEnvironmentClientById(
                              state.environmentClientId
                            )
                          }
                        : null
                    }
                    onChange={(e) =>
                      dispatch({
                        action: "setEnvironmentClient",
                        environmentClient: (
                          e as {
                            value: IEnvironmentClientResponse;
                            label: string;
                          }
                        ).value
                      })
                    }
                    useBasicStyles
                    chakraStyles={reactSelectStyles}
                  ></Select>
                </FormControl>
              )}
              {Object.entries(modalData.app.ui ?? {})
                .filter(([key]) => key !== "enterspeedEnvironmentClientApiKey")
                .map(([key, value]) => {
                  return (
                    <FormControl key={key}>
                      <FormLabel pt="4">
                        {value.label}
                        {value.helpText && (
                          <HelpIcon label={value.helpText}></HelpIcon>
                        )}
                      </FormLabel>
                      <Input
                        bg={inputBgColorMode}
                        value={
                          state?.settings && state.settings[key]
                            ? (state.settings[key] as string)
                            : ""
                        }
                        onBlur={(change) =>
                          dispatch({
                            action: "setCustomData",
                            data: { [key]: change.target.value.trim() }
                          })
                        }
                        onChange={(change) =>
                          dispatch({
                            action: "setCustomData",
                            data: { [key]: change.target.value }
                          })
                        }
                      ></Input>
                    </FormControl>
                  );
                })}
            </Form>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="gray"
                onClick={() => {
                  dispatch({ action: "reset" });
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Tooltip isDisabled={!invalidMessage} label={invalidMessage}>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    onSubmit({ ...state });
                    dispatch({ action: "reset" });
                  }}
                  isDisabled={!!invalidMessage}
                >
                  Save
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default DestinationEnvironmentConfigurationEditModal;
