import { Icon } from "@chakra-ui/react";
import {
  faCogs,
  faDatabase,
  faEye,
  faRocket,
  faSitemap,
  IconDefinition
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type EnterspeedEntityType =
  | "ENVIRONMENT"
  | "ENVIRONMENT_CLIENT"
  | "SOURCE"
  | "SOURCE_GROUP"
  | "SOURCE_ENTITY"
  | "SCHEMA"
  | "RELEASE"
  | "VIEW"
  | "ROUTE"
  | "DOMAIN";
const EnterspeedEntityTypeIcon = ({ type }: { type: EnterspeedEntityType }) => {
  const types: Record<EnterspeedEntityType, IconDefinition> = {
    ENVIRONMENT: faSitemap,
    ENVIRONMENT_CLIENT: faSitemap,
    DOMAIN: faSitemap,
    SCHEMA: faCogs,
    RELEASE: faRocket,
    ROUTE: faEye,
    VIEW: faEye,
    SOURCE: faDatabase,
    SOURCE_ENTITY: faDatabase,
    SOURCE_GROUP: faDatabase
  };
  return <Icon as={FontAwesomeIcon} icon={types[type]} mr="4px" />;
};

export default EnterspeedEntityTypeIcon;
