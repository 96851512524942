import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Input,
  Link,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {
  faArrowLeft,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "chakra-react-select";

import { zip } from "lodash";
import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import BasicJsonCodeEditor from "../../../components/CodeEditor/BasicJsonCodeEditor";
import CurlEditor from "../../../components/CurlEditor";
import EmptyState from "../../../components/EmptyState";
import ReloadButton from "../../../components/ReloadButton";
import formatDateTime from "../../../helpers/formatDateTime";
import useMixPanel from "../../../mixpanel/useMixPanel";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";
import { IRouteDeliveryResponse } from "../../tenants/types";
import { useDeliveryContext } from "../api/getDeliveryContext";
import { useView } from "../api/getView";

const ViewsPanel = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const mixpanel = useMixPanel();

  const { data, isLoading, refetch, isRefetching } = useView(id);
  const [activeCurlEditorRouteId, setActiveCurlEditorRouteId] = useState<
    string | null
  >(null);
  const [deliveryData, setDeliveryData] =
    useState<IRouteDeliveryResponse | null>(null);

  const [referenceIdFilterTerm, setReferenceIdFilterTerm] =
    useState<string>("");
  const [referencedIdsPageSize, setReferencedIdsPageSize] =
    useState<number>(10);
  const [referencedIdsStart, setReferencedIdsStart] = useState<number>(0);
  const [referencedIdsEnd, setReferencedIdsEnd] = useState<number>(
    referencedIdsPageSize
  );

  const responses = useDeliveryContext({ routeIds: data?.routeIds ?? [] });

  const currentCode = () => {
    if (data?.properties) {
      return JSON.stringify(data?.properties, null, "\t");
    } else {
      return "";
    }
  };

  const filteredCurrentViewReferencedIds = (): string[] => {
    if (!data) {
      return [];
    }

    return data?.referencedIds.filter((x) =>
      x.toLowerCase().includes(referenceIdFilterTerm.toLowerCase())
    );
  };

  const getRouteName = (id: string) => {
    const route = id.split("/Route/")[1];
    return route.replace("Url/", "Url: /").replace("Handle/", "Handle: ");
  };

  const referencedIdsPageSizeOptions = () => {
    const stepOptions = [10, 25, 50, 100, 500, 1000];

    return stepOptions.map(
      (item) =>
        ({
          label: `${item.toString()} results`,
          value: item.toString()
        } as ISelectOption)
    );
  };

  const handleCurlButtonClick = (id: string) => {
    if (!data) {
      return;
    }
    const idx = data.routeIds.findIndex((r) => r === id);
    if (idx === -1) {
      return;
    }
    const deliveryContext = responses[idx].data;
    setDeliveryData(deliveryContext ?? null);
    toggleActiveCurlEditorRouteId(id);
  };

  const toggleActiveCurlEditorRouteId = (id: string) => {
    setActiveCurlEditorRouteId((prevState) => {
      if (prevState === id) {
        return null;
      }
      mixpanel.track("curlEditorShow");
      return id;
    });
  };

  const handleReferencedIdClick = (id: string) => {
    const newSearch = new URLSearchParams(searchParams);
    newSearch.set("id", id);
    setSearchParams(newSearch);
  };

  const handlePagination = (direction: "prev" | "next") => {
    if (direction === "prev") {
      setReferencedIdsStart(referencedIdsStart - referencedIdsPageSize);
      setReferencedIdsEnd(referencedIdsEnd - referencedIdsPageSize);
    } else {
      setReferencedIdsStart(referencedIdsStart + referencedIdsPageSize);
      setReferencedIdsEnd(referencedIdsEnd + referencedIdsPageSize);
    }
  };

  useEffect(() => {
    setReferencedIdsStart(0);
    setReferencedIdsEnd(referencedIdsPageSize);
  }, [referenceIdFilterTerm, referencedIdsPageSize]);

  const reactSelectStyles = useReactSelectStyles();

  const routeColors = useColorModeValue("gray.50", "gray.700");

  if (!id) {
    return <></>;
  }
  return (
    <Box>
      <Flex justifyContent="space-between">
        <IconButton
          variant="ghost"
          colorScheme="brand"
          aria-label="Back"
          onClick={() => {
            navigate(-1);
            setActiveCurlEditorRouteId(null);
          }}
          icon={<Icon as={FontAwesomeIcon} icon={faArrowLeft} />}
        />

        <ReloadButton loading={isRefetching} onClick={refetch} />
      </Flex>
      {isLoading || isRefetching ? (
        <Stack>
          <Skeleton height="40px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="40px" />
          <Skeleton mt="4" height="300px" />
        </Stack>
      ) : (
        <Box fontSize="sm" mt="2" position="relative">
          <VStack alignItems="flex-start">
            <Text>
              <Text as="b">View ID: </Text>
              {data?.id.idValue}
            </Text>
            <Text>
              <Text as="b">Source entity origin ID: </Text>
              <Link
                as={RouterLink}
                color="brand.900"
                to={`/source-entities/${data?.id.sourceGuid ?? ""}/${
                  data?.id.originId ?? ""
                }`}
              >
                <Button variant="link" ml="1">
                  {data?.id.originId}
                </Button>
              </Link>
            </Text>
            <Text>
              <Text as="b">Schema alias: </Text>
              <Link
                as={RouterLink}
                color="brand.900"
                to={`/schemas/${data?.id.viewHandle ?? ""}`}
              >
                <Button variant="link" ml="1">
                  {data?.id.viewHandle}
                </Button>
              </Link>
            </Text>
            <Text>
              <Text as="b">Updated at: </Text>
              {formatDateTime(data?.updatedAt)}
            </Text>
            {data?.referencedIds.length && (
              <Box w="100%" mb={4}>
                <Box mb={1}>
                  <Text as="b">
                    Referenced ID's{" "}
                    <small>
                      ({filteredCurrentViewReferencedIds().length} result
                      {filteredCurrentViewReferencedIds().length !== 1 && "s"})
                    </small>
                    :
                  </Text>
                </Box>
                <Flex mb={4}>
                  <Input
                    width="100%"
                    size="sm"
                    placeholder="Filter: Type name of referenced ID"
                    value={referenceIdFilterTerm}
                    onChange={(e) => setReferenceIdFilterTerm(e.target.value)}
                  />
                  <Box width="250px" ml={4} zIndex={3}>
                    <Select
                      size="sm"
                      useBasicStyles
                      chakraStyles={reactSelectStyles}
                      colorScheme="brand"
                      defaultValue={referencedIdsPageSizeOptions()[0]}
                      onChange={(option) =>
                        setReferencedIdsPageSize(
                          parseInt((option as ISelectOption).value as string)
                        )
                      }
                      options={referencedIdsPageSizeOptions()}
                    />
                  </Box>
                </Flex>
                {filteredCurrentViewReferencedIds().length ? (
                  <Box>
                    <Box>
                      {filteredCurrentViewReferencedIds()
                        .slice(referencedIdsStart, referencedIdsEnd)
                        .map((item) => (
                          <Flex
                            key={item}
                            mb={1}
                            border="1px solid"
                            borderColor="gray.200"
                            borderRadius="sm"
                            fontSize="xs"
                            paddingX={2}
                            paddingY={1}
                            alignItems="center"
                            justifyContent="space-between"
                            bg={routeColors}
                          >
                            <Text size="xs" pr="4.5rem">
                              {item}
                            </Text>

                            <Button
                              variant="subtle"
                              height="unset"
                              padding="3px 6px"
                              lineHeight="1"
                              borderRadius="sm"
                              fontSize="12px"
                              onClick={() => handleReferencedIdClick(item)}
                            >
                              View
                            </Button>
                          </Flex>
                        ))}
                    </Box>
                    {filteredCurrentViewReferencedIds().length >
                      referencedIdsPageSize && (
                      <Flex flexDirection="column" alignItems="center" mt={4}>
                        <ButtonGroup variant="outline" spacing={4} mb={2}>
                          <IconButton
                            isDisabled={referencedIdsStart === 0}
                            variant="ghost"
                            colorScheme="brand"
                            onClick={() => handlePagination("prev")}
                            aria-label="Previous"
                            icon={
                              <Icon as={FontAwesomeIcon} icon={faChevronLeft} />
                            }
                          />
                          <IconButton
                            isDisabled={
                              referencedIdsEnd >=
                              filteredCurrentViewReferencedIds().length
                            }
                            variant="ghost"
                            colorScheme="brand"
                            onClick={() => handlePagination("next")}
                            aria-label="Next"
                            icon={
                              <Icon
                                as={FontAwesomeIcon}
                                icon={faChevronRight}
                              />
                            }
                          />
                        </ButtonGroup>
                        <small>
                          Showing {referencedIdsStart}-
                          {referencedIdsEnd >
                          filteredCurrentViewReferencedIds().length
                            ? referencedIdsStart +
                              (filteredCurrentViewReferencedIds().length -
                                referencedIdsStart)
                            : referencedIdsEnd}{" "}
                          of {filteredCurrentViewReferencedIds().length} result
                          {filteredCurrentViewReferencedIds().length !== 1 &&
                            "s"}
                        </small>
                      </Flex>
                    )}
                  </Box>
                ) : (
                  <EmptyState />
                )}
              </Box>
            )}
            {data?.routeIds.length && (
              <Box w="100%">
                <Box mb={1}>
                  <Text as="b">Route/Handle: </Text>
                </Box>
                {zip(data.routeIds, responses).map(([item, response]) => (
                  <Box key={item}>
                    <Flex
                      opacity={response?.isError ? "60%" : ""}
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="sm"
                      fontSize="xs"
                      paddingX={2}
                      paddingY={1}
                      alignItems="center"
                      justifyContent="space-between"
                      bg={routeColors}
                    >
                      <Text size="xs" pr="4.5rem">
                        {item ? getRouteName(item) : "unknown"}
                      </Text>

                      <Button
                        height="unset"
                        padding="3px 6px"
                        lineHeight="1"
                        borderRadius="sm"
                        fontSize="12px"
                        variant="subtle"
                        isLoading={response?.isLoading}
                        isDisabled={response?.isError}
                        onClick={() =>
                          item ? handleCurlButtonClick(item) : undefined
                        }
                      >
                        CURL
                      </Button>
                    </Flex>
                    {response?.isError && (
                      <Text size={"xs"} color={"red.500"} fontSize="smaller">
                        {response?.error.response?.data.detail}
                      </Text>
                    )}
                    {item && activeCurlEditorRouteId === item && (
                      <Box mb={4}>
                        <CurlEditor
                          deliveryData={deliveryData}
                          url={deliveryData?.url}
                          handles={
                            deliveryData?.handle ? [deliveryData?.handle] : []
                          }
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </VStack>
          <Box mt="8">
            <BasicJsonCodeEditor
              value={currentCode()}
              options={{
                readOnly: true
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ViewsPanel;
