export const hasSlash = (name: string) => name.includes("/");
export const startsWithSlash = (name: string) => name.charAt(0) === "/";
export const endsWithSlash = (name: string) => name.slice(-1) === "/";
export const repeatingSlash = (name: string) => name.includes("//");
export const maxFolderDepthLimit = (name: string, maxDepth: number) =>
  name.split("/").length > maxDepth;

const schemaHasValidFolderName = (name: string) => {
  return (
    hasSlash(name) &&
    !(
      startsWithSlash(name) ||
      endsWithSlash(name) ||
      repeatingSlash(name) ||
      maxFolderDepthLimit(name, 5)
    )
  );
};

export default schemaHasValidFolderName;
