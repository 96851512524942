import { IOperatorSelectOption } from "../types";

export const operators: IOperatorSelectOption[] = [
  {
    label: "is",
    value: "EQUAL",
    isSingleValue: true
  },
  {
    label: "is not",
    value: "NOT_EQUAL",
    isSingleValue: true
  },
  {
    label: "in",
    value: "IN",
    isSingleValue: false
  }
];
