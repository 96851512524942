const SCHEMA_INVALID_CODE =
  "The Schema code is invalid, please fix the code before saving";
const SCHEMA_INVALID = "Schema format is invalid";
const SCHEMA_TRIGGERS_MUST_BE_AN_OBJECT = "Triggers must be an object";
const SCHEMA_TRIGGERS_MUST_BE_A_FUNCTION = "Triggers must be a function";
const SCHEMA_TRIGGERS_MISSING = "A schema must have at least one trigger";
const SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE =
  "Triggers must have a group and an entity type";
const SCHEMA_SOURCE_ENTITY_TYPES_INVALID =
  'Please add at least one "sourceEntityType"';
const SCHEMA_PROPERTIES_MUST_BE_A_FUNCTION = "Properties must be a function";
const SCHEMA_ITEMS_MUST_BE_A_FUNCTION = "Items must be a function";
export {
  SCHEMA_INVALID,
  SCHEMA_INVALID_CODE,
  SCHEMA_TRIGGERS_MUST_BE_AN_OBJECT,
  SCHEMA_TRIGGERS_MISSING,
  SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE,
  SCHEMA_SOURCE_ENTITY_TYPES_INVALID,
  SCHEMA_TRIGGERS_MUST_BE_A_FUNCTION,
  SCHEMA_PROPERTIES_MUST_BE_A_FUNCTION,
  SCHEMA_ITEMS_MUST_BE_A_FUNCTION
};
