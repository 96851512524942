import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const timeFromNow = (date: string) => {
  return dayjs(date).fromNow();
};

export const formatTime = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm");
};

export const formatTimeWithSec = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
};
