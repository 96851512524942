import { IEnvironmentClientResponse } from "../features/environment-clients/types";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";

export const getEnvironmentClientOptions = (
  environmentClients: IEnvironmentClientResponse[] | undefined
): IEnterspeedIdentifiableSelectOption[] => {
  if (!environmentClients || environmentClients.length === 0) {
    return [];
  }

  return environmentClients
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((env) => {
      return {
        id: env.id.idValue,
        label: env.name,
        value: btoa(env.id.idValue)
      } as IEnterspeedIdentifiableSelectOption;
    });
};

export const getEnvironmentClientOption = (
  environmentClients: IEnvironmentClientResponse[] | undefined,
  environmentClientId: string
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!environmentClients || environmentClients.length === 0) {
    return undefined;
  }

  const environmentClient = environmentClients.find(
    (s) => s.id.idValue === atob(environmentClientId)
  );
  return environmentClient
    ? {
        id: environmentClient.id.idValue,
        label: environmentClient.name,
        value: btoa(environmentClient.id.idValue)
      }
    : undefined;
};
