import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { defaultToast } from "../../../constants/toast";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { IApiErrorResponse } from "../../../types/errors";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { IEnvironmentResponse } from "../../environments/types";
import { useInputColors, useModalColors } from "../../tenants/hooks/colors";
import { useCreateEnvironmentClient } from "../api/createEnvironmentClient";
import { useEditEnvironmentClient } from "../api/editEnvironmentClient";
import { IEnvironmentClientResponseId } from "../types";
import EnvironmentClientDomainsModal from "./EnvironmentClientDomainsModal";

const CreateEnvironmentClientModal = () => {
  const [environmentClient, setEnvironmentClient] =
    useState<IEnvironmentClientResponseId>();
  const [environmentClientName, setEnvironmentClientName] =
    useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDomainsModal,
    onOpen: onOpenDomainsModal,
    onClose: onCloseDomainsModal
  } = useDisclosure();

  const { bg } = useInputColors();
  const { bg: modalBg } = useModalColors();

  const createEnvironmentClient = useCreateEnvironmentClient();

  useEffect(() => {
    if (!environmentClient) {
      return;
    }
    onOpenDomainsModal();
  }, [environmentClient]);

  const editEnvironmentClient = useEditEnvironmentClient();

  const { data: environments } = useEnvironments();

  const toast = useToast();

  interface FormValues {
    name: string;
    environment: string;
  }

  return (
    <>
      <Button variant="subtle" onClick={onOpen}>
        Create
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg={modalBg} p="4">
          <ModalHeader>Create new Environment client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">Give your environment client a descriptive name.</Text>
            <Formik
              initialValues={{ name: "", environment: "" }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={async ({ name, environment }) => {
                onClose();

                setEnvironmentClientName(name);
                const resultPromise = createEnvironmentClient.mutateAsync({
                  payload: { name },
                  environmentId: environment
                });

                toast.promise(resultPromise, {
                  success: {
                    title: "Environment client created successfully",
                    description: `Environment client "${name}" created`,
                    ...defaultToast
                  },
                  loading: {
                    title: "Creating environment client",
                    ...defaultToast
                  },
                  error: (error: AxiosError<IApiErrorResponse>) => ({
                    title:
                      error?.response?.data.detail ??
                      "Error creating environment client"
                  })
                });

                setEnvironmentClient(await resultPromise);
                return await resultPromise;
              }}
            >
              {({ dirty, values }) => (
                <Form>
                  <Field name="name" validate={isRequired("Name is required")}>
                    {({
                      field,
                      form: { errors }
                    }: FieldProps<string, FormValues>) => (
                      <FormControl
                        isRequired={true}
                        isInvalid={!!errors.name}
                        mb="4"
                      >
                        <FormLabel>Name</FormLabel>
                        <Input
                          data-1p-ignore
                          bg={bg}
                          autoFocus
                          {...field}
                          placeholder="Your environment client name"
                        />
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="environment"
                    validate={() => {
                      isRequired("Environment is required");
                    }}
                  >
                    {({
                      field,
                      form: { errors }
                    }: FieldProps<string, FormValues>) => (
                      <FormControl isInvalid={!!errors.environment}>
                        <FormLabel>Environment</FormLabel>
                        <Select {...field} placeholder="Select an environment">
                          {environments?.map(
                            (environment: IEnvironmentResponse) => (
                              <option
                                key={environment.id.environmentGuid}
                                value={environment.id.environmentGuid}
                              >
                                {environment.name}
                              </option>
                            )
                          )}
                        </Select>
                        <FormErrorMessage>
                          {errors.environment}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex pt={8} justifyContent="flex-end">
                    <Button
                      colorScheme="gray"
                      variant="ghost"
                      mr={3}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      isLoading={createEnvironmentClient.isPending}
                      type="submit"
                      isDisabled={!dirty || !values.environment || !values.name}
                    >
                      Create
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <EnvironmentClientDomainsModal
        name={environmentClientName}
        isOpen={isOpenDomainsModal}
        onClose={onCloseDomainsModal}
        onSuccess={async (selectedDomains) => {
          onCloseDomainsModal();

          if (environmentClient) {
            await editEnvironmentClient.mutateAsync({
              environmentId: environmentClient.environmentGuid,
              clientId: environmentClient.clientGuid,
              payload: {
                name: environmentClientName,
                allowedDomains: selectedDomains,
                regenerateAccessKey: false
              }
            });
          }
        }}
      />
    </>
  );
};

export default CreateEnvironmentClientModal;
