import {
  Code,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  ToastId,
  UseToastOptions
} from "@chakra-ui/react";
import {
  faCircleMinus,
  faCirclePlus,
  faCircleXmark
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { chunk } from "lodash";
import { MutableRefObject, ReactNode } from "react";
import { container } from "tsyringe";
import { ENTITIES, SOURCE_ENTITY_TYPES } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IIngestErrorResponse } from "../../../types/errors";
import { ToastProgress } from "../components/ToastProgress";
import { SourceEntityService } from "../source-entity.service";
import {
  IAddedSourceEntity,
  IAddedSourceEntityFile,
  IngestResponse
} from "../types";

type ToastStatus =
  | "info"
  | "warning"
  | "success"
  | "error"
  | "loading"
  | undefined;

const notChangedMessage =
  "Source entity hasn't changed and won't be processed.";
const processingMessage = "Processing has been initiated.";

const addEntities = async ({
  sourceEntities,
  apiKey,
  toast,
  toastIdRef
}: {
  sourceEntities: IAddedSourceEntity[] | IAddedSourceEntityFile[];
  apiKey: string;
  toast: {
    update(id: ToastId, options: Omit<UseToastOptions, "id">): void;
    (options?: UseToastOptions | undefined): ToastId;
  };
  toastIdRef: MutableRefObject<ToastId | undefined>;
}): Promise<(IngestResponse | IIngestErrorResponse)[]> => {
  const sourceEntityService = container.resolve(SourceEntityService);

  const results: (IngestResponse & IIngestErrorResponse)[] = [];

  const currentDefaults: UseToastOptions = {
    ...defaultToast,
    title: "Ingesting source entities",
    description: ToastProgress({
      current: results.length,
      total: sourceEntities.length
    }),
    duration: null,
    status: "loading"
  };
  toastIdRef.current = toast(currentDefaults);

  for (const batch of chunk(sourceEntities, 5)) {
    toast.update(toastIdRef.current, {
      ...currentDefaults,
      description:
        results.length > 1 ? (
          <ToastProgress
            current={results.length}
            total={sourceEntities.length}
          ></ToastProgress>
        ) : (
          <></>
        )
    });

    const chunkResults = await Promise.all(
      batch.map(async (entity) => {
        try {
          const response = await sourceEntityService.addEntity(entity, apiKey);
          return { ...response, originId: entity.originId };
        } catch (error) {
          const defaultResponse = {
            message: "Unknown error",
            status: 500,
            originId: entity.originId
          };

          if (error instanceof AxiosError) {
            const { data } =
              error.response as AxiosResponse<IIngestErrorResponse>;

            return { ...data, originId: entity.originId };
          }

          if (error instanceof Error) {
            return { ...defaultResponse, message: error.message };
          }

          return defaultResponse;
        }
      })
    );

    results.push(...chunkResults);
  }

  const updateToast = (
    toastId: ToastId,
    status: ToastStatus,
    title: ReactNode,
    description: ReactNode,
    duration = 5000
  ) => {
    toast.update(toastId, {
      ...currentDefaults,
      status,
      title,
      description,
      duration
    });
  };

  const DetailsList = ({
    errors,
    processed,
    notChanged
  }: {
    processed: IngestResponse[];
    notChanged: IngestResponse[];
    errors: (IIngestErrorResponse & IngestResponse)[];
  }) => (
    <List>
      {processed.map(({ originId }) => (
        <ListItem key={originId}>
          <Text as="b">
            <ListIcon
              as={FontAwesomeIcon}
              icon={faCirclePlus}
              color={"green.500"}
            ></ListIcon>{" "}
            <Code background={"transparent"} color={"black"}>
              {originId}
            </Code>
          </Text>
        </ListItem>
      ))}
      {notChanged.map(({ originId }) => (
        <ListItem key={originId}>
          <Text as="b">
            <ListIcon
              as={FontAwesomeIcon}
              icon={faCircleMinus}
              color={"gray.500"}
            ></ListIcon>{" "}
            <Code background={"transparent"} color={"black"}>
              {originId}
            </Code>{" "}
          </Text>
        </ListItem>
      ))}
      {errors.map(({ originId, message, errors }) => {
        const details = Object.entries(errors ?? {});
        return (
          <ListItem key={originId}>
            <Text as="b">
              <ListIcon
                as={FontAwesomeIcon}
                icon={faCircleXmark}
                color={"red.500"}
              ></ListIcon>{" "}
              <Code background={"transparent"} color={"black"}>
                {originId}
              </Code>{" "}
            </Text>
            <Flex gap={"0.25rem"}>
              <Text fontWeight={600}>{message}: </Text>
              <Text>
                {details.length === 1
                  ? `"${details[0][0]}: ${details[0][1]}"`
                  : details.length > 1
                  ? `${details.length} errors`
                  : ""}
              </Text>
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );

  const allSuccess = results.every((r) => r.status === 200);
  const notChanged = results.filter((r) => r.message === notChangedMessage);
  const processing = results.filter((r) => r.message === processingMessage);
  const someSuccess = results.some((r) => r.status === 200);
  const errorResults = results.filter((r) => r.status >= 400);

  if (results.length === 1) {
    const [ingestResponse] = results;
    const { status, message, originId } = ingestResponse;

    const errorDetails = Object.entries(ingestResponse.errors ?? {});

    if (status >= 400) {
      updateToast(
        toastIdRef.current,
        "error",
        <Text>
          Ingest failed for{" "}
          <Code background={"transparent"} color={"black"}>
            {originId}
          </Code>
        </Text>,
        <Flex direction={"column"}>
          <Heading size={"sm"}>Errors:</Heading>
          <List>
            {errorDetails.length === 0 ? (
              <ListItem flexDirection={"row"}>
                <Text>
                  <ListIcon
                    as={FontAwesomeIcon}
                    icon={faCircleXmark}
                    color={"red.500"}
                  ></ListIcon>
                  {message}
                </Text>
              </ListItem>
            ) : (
              errorDetails.map(([key, value]) => (
                <ListItem flexDirection={"row"}>
                  <Text>
                    <ListIcon
                      as={FontAwesomeIcon}
                      icon={faCircleXmark}
                      color={"red.500"}
                    ></ListIcon>
                    <Text as="b">{key}</Text> {value}
                  </Text>
                </ListItem>
              ))
            )}
          </List>
        </Flex>
      );
    } else if (status === 200) {
      updateToast(
        toastIdRef.current,
        "success",
        message,
        <DetailsList
          processed={processing}
          notChanged={notChanged}
          errors={errorResults}
        ></DetailsList>
      );
    }
  } else {
    if (allSuccess) {
      updateToast(
        toastIdRef.current,
        "success",
        "Ingest succeeded",
        <DetailsList
          processed={processing}
          notChanged={notChanged}
          errors={errorResults}
        />
      );
    } else if (someSuccess) {
      updateToast(
        toastIdRef.current,
        "error",
        `Ingests had ${errorResults.length} errors of total ${results.length}`,
        <DetailsList
          processed={processing}
          notChanged={notChanged}
          errors={errorResults}
        />,
        10_000
      );
    } else {
      updateToast(
        toastIdRef.current,
        "error",
        "Ingest failed for all entities",
        <DetailsList
          processed={processing}
          notChanged={notChanged}
          errors={errorResults}
        ></DetailsList>,
        10_000
      );
    }
  }

  return results;
};

export const useAddEntities = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addEntities,
    onSuccess: () => {
      // after source entity is ingested
      // it must be indexed in cosmos
      // to be available in the list
      // it can take some time
      // how can we handle congestion without using websocket for now?
      setTimeout(() => {
        queryClient
          .invalidateQueries(
            {
              predicate: ({ queryKey }) =>
                queryKey[0] === ENTITIES || queryKey[0] === SOURCE_ENTITY_TYPES
            },
            {}
          )
          .catch(console.error);
      }, 500);
    }
  });
};
