import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import {
  DEPLOYMENTS_FROM_ENVIRONMENT,
  SCHEMA
} from "../../../constants/queryKey";
import { DeploymentService } from "../deployment.service";
import { ISchemaRelease } from "../types";

const createReleaseFn = ({
  environmentId,
  schemas
}: {
  environmentId: string;
  schemas: ISchemaRelease[];
}) => {
  const deploymentService = container.resolve(DeploymentService);
  return deploymentService.createRelease(environmentId, schemas);
};

export const useCreateRelease = ({
  environmentGuid
}: {
  environmentGuid?: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: environmentGuid ? createReleaseFn : undefined,
    onSuccess: async (_, { schemas }) => {
      await queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === DEPLOYMENTS_FROM_ENVIRONMENT &&
            query.queryKey[1] === environmentGuid) ||
          (query.queryKey[0] === SCHEMA &&
            schemas.some((s) => s.schemaId === query.queryKey[1]))
      });
    }
  });
};
