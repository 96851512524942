import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import {
  IManagementClientCreatePayload,
  IManagementClientEditPayload,
  IManagementClientResponse,
  IManagementClientResponseId
} from "./types";

@singleton()
export class ManagementClientService {
  public constructor(private readonly managementClient: ManagementClient) {}

  public async getAllManagementClient() {
    const { data } = await this.managementClient.get<
      IManagementClientResponse[]
    >("/api/v1/tenant/management-clients/");
    return data;
  }

  public async createManagementClient(payload: IManagementClientCreatePayload) {
    const { data } =
      await this.managementClient.post<IManagementClientResponseId>(
        `/api/v1/tenant/management-clients/`,
        payload
      );
    return data;
  }

  public async deleteManagementClient(managementClientGuid: string) {
    const { data } = await this.managementClient.delete(
      `/api/v1/tenant/management-clients/${managementClientGuid}`
    );
    return data;
  }

  public async editManagementClient(
    managementClientGuid: string,
    payload: IManagementClientEditPayload
  ) {
    const { data } = await this.managementClient.put(
      `/api/v1/tenant/management-clients/${managementClientGuid}`,
      payload
    );
    return data;
  }

  public async regenerateAccessKey(managementClientGuid: string) {
    const { data } = await this.managementClient.put(
      `/api/v1/tenant/management-clients/${managementClientGuid}/_regenerate`
    );
    return data;
  }
}
