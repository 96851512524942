import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENT_CLIENTS } from "../../../constants/queryKey";
import { EnvironmentClientService } from "../environment-client.service";

export const getEnvironmentClients = async () => {
  const environmentClientService = container.resolve(EnvironmentClientService);
  return environmentClientService.getAllEnvironmentClient();
};

export const useEnvironmentClients = () => {
  return useQuery({
    queryKey: [ENVIRONMENT_CLIENTS],
    queryFn: getEnvironmentClients
  });
};
