import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SourceEntityService } from "../source-entity.service";
import { ISourceReprocessEntity } from "../types";

export const reprocessEntity = (payload: ISourceReprocessEntity) => {
  const sourceEntityService = container.resolve(SourceEntityService);
  return sourceEntityService.processEntity(payload);
};

export const useReprocessEntity = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: reprocessEntity,
    onSuccess: () => {
      toast({
        title: "Processing of source entity started",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error processing source entity",
        status: "error",
        ...defaultToast
      });
    }
  });
};
