import reactStringReplace from "react-string-replace";
import {
  DisplayType,
  Enhancement,
  EnhancementKey
} from "../types/interactivePlaceholders";
import InterpolatedField from "./InterpolatedField";

const InterpolatedText = ({
  text,
  properties,
  enhancements,
  displayType
}: {
  text: string;
  properties: Record<string, unknown>;
  enhancements: Record<EnhancementKey, Enhancement>;
  displayType: DisplayType;
}) => {
  const extractPlaceholders = (text: string): string[] => {
    if (typeof text === "string" || (text as unknown) instanceof String) {
      return text?.match(/[^{]+(?=\})/g) ?? [];
    }

    return [];
  };

  const remapPlaceholders = (
    text: string,
    placeholders: string[],
    enhancements: Record<EnhancementKey, Enhancement>,
    properties: Record<string, any>
  ): React.ReactNode[] => {
    let reactMessage: React.ReactNode[] = reactStringReplace(text);
    placeholders.forEach((placeholder) => {
      const link: React.ReactNode | undefined = (
        <InterpolatedField
          displayType={displayType}
          key={Math.random()}
          input={placeholder}
          properties={properties}
          enhancements={enhancements}
          fallbackValue={`{${placeholder}}`}
        ></InterpolatedField>
      );
      reactMessage = reactStringReplace(
        reactMessage,
        `{${placeholder}}`,
        () => link
      );
    });
    return reactMessage;
  };

  const teaserHelper = (): React.ReactNode => {
    const placeholders = extractPlaceholders(text);
    const remappedPlaceholders = remapPlaceholders(
      text,
      placeholders,
      enhancements,
      properties
    );
    return <>{remappedPlaceholders}</>;
  };
  return <>{teaserHelper()}</>;
};

export default InterpolatedText;
