import { Box, Flex } from "@chakra-ui/react";
import { APP_VERSION } from "../constants/app";
import { useSettings } from "../features/settings/api/getSettings";

const Footer = () => {
  const { data } = useSettings();

  const currentYear = new Date().getFullYear();
  const platformVersion = data?.version || "unknown";

  return (
    <Flex
      p="4"
      justifyContent="center"
      alignItems="end"
      flexDirection={{ base: "column", md: "row" }}
      grow={1}
    >
      <Box>© Enterspeed 2020-{currentYear}</Box>
      <Box px="2" display={{ base: "none", md: "block" }}>
        -
      </Box>
      <Box>Build {APP_VERSION}</Box>
      <Box px="2" display={{ base: "none", md: "block" }}>
        -
      </Box>
      <Box>Platform {platformVersion}</Box>
    </Flex>
  );
};

export default Footer;
