import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENT_CLIENTS } from "../../../constants/queryKey";
import { EnvironmentClientService } from "../environment-client.service";
import { IEnvironmentClientEditPayload } from "../types";

export const editEnvironmentClient = ({
  environmentId,
  clientId,
  payload
}: {
  environmentId: string;
  clientId: string;
  payload: IEnvironmentClientEditPayload;
}) => {
  const environmentClientService = container.resolve(EnvironmentClientService);
  return environmentClientService.editEnvironmentClient(
    environmentId,
    clientId,
    payload
  );
};

export const useEditEnvironmentClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editEnvironmentClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ENVIRONMENT_CLIENTS]
      });
    }
  });
};
