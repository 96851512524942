import { Button, Flex, Text } from "@chakra-ui/react";

const Pagination = ({
  current,
  total,
  loadMore,
  disabled,
  loading
}: {
  current: number;
  total: number;
  loadMore: () => void;
  disabled: boolean;
  loading: boolean;
}) => {
  return (
    <Flex mt="12" alignItems="center" flexDirection="column">
      {current !== total && (
        <Button
          isDisabled={disabled}
          variant="subtle"
          onClick={loadMore}
          isLoading={loading}
          loadingText="Loading"
        >
          Load more
        </Button>
      )}
      {loading ? (
        <></>
      ) : (
        <Text mt="4" color="gray.500" fontSize="sm">
          Showing {current} of {total}
        </Text>
      )}
    </Flex>
  );
};

export default Pagination;
