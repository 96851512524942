import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from "@chakra-ui/react";

import { BulkDeploy } from "../types/BulkDeploy";
import { BulkDeploySummary } from "./BulkDeploySummary";

export const BulkDeployModal = ({
  deployConfig,
  isOpen,
  onClose,
  onSubmit
}: {
  environmentGuid: string;
  deployConfig: BulkDeploy[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Confirm deployment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              <Box>
                {`You are about to deploy ${deployConfig.length} schema changes`}
                <BulkDeploySummary
                  deployConfig={deployConfig}
                ></BulkDeploySummary>
                {`Do you want to continue?`}
              </Box>
            }
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="gray"
              bg={useColorModeValue("gray.300", "gray.700")}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onClose();
                onSubmit();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
