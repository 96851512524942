import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { useDeleteEnvironment } from "../api/deleteEnvironment";
import { useEditEnvironment } from "../api/editEnvironment";
import { EnvironmentTableModalType, IEnvironment } from "../types";

const EnvironmentsActionsModal = ({
  type,
  environment,
  isOpen,
  onClose
}: {
  type: EnvironmentTableModalType;
  environment: IEnvironment;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const deleteEnvironment = useDeleteEnvironment();
  const editEnvironment = useEditEnvironment();

  switch (type) {
    case EnvironmentTableModalType.DELETE:
      return (
        <DeleteModal
          type="environment"
          name={environment.name}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={() => {
            onClose();

            const deleteEnvironmentPromise = deleteEnvironment.mutateAsync(
              environment.environmentGuid
            );
            toast.promise(deleteEnvironmentPromise, {
              success: {
                title: "Deleted environment successfully",
                description: `Deleted environment"${environment.name}"`,
                ...defaultToast
              },
              loading: {
                title: "Deleting environment",
                description: `Deleting environment "${environment.name}"`,
                ...defaultToast
              },
              error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                title: response?.data.detail ?? "Error deleting environment",
                status: "error",
                ...defaultToast
              })
            });
          }}
        />
      );
    case EnvironmentTableModalType.EDIT:
      return (
        <EditNameModal
          type="environment"
          name={environment?.name || ""}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={async ({ name }) => {
            onClose();

            const editEnvironmentPromise = editEnvironment.mutateAsync({
              environmentId: environment.environmentGuid,
              payload: { name }
            });
            toast.promise(editEnvironmentPromise, {
              success: {
                title: "Edited environment successfully",
                description: `Edited environment name from "${environment.name}" to "${name}`,
                ...defaultToast
              },
              loading: {
                title: "Editing environment",
                description: `Editing environment name from "${environment.name}" to "${name}`,
                ...defaultToast
              },
              error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                title: response?.data.detail ?? "Error editing environment",
                status: "error",
                ...defaultToast
              })
            });

            await editEnvironmentPromise;
            return { valid: true };
          }}
          onSuccess={() => {
            onClose();
          }}
        />
      );
    default:
      return null;
  }
};

export default EnvironmentsActionsModal;
