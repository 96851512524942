import { Text } from "@chakra-ui/react";
import { IRegenerateApiKeyModalProps } from "../../types/modal";
import ConfirmModal from "./ConfirmModal";

const RegenerateApiKeyModal = ({
  name,
  isOpen,
  onClose,
  onSuccess,
  description
}: IRegenerateApiKeyModalProps) => {
  return (
    <ConfirmModal
      title="Regenerate API Key"
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
    >
      <Text pb="6">
        Are you sure you want to regenerate the API key for{" "}
        <Text as="b">{name}</Text>?
      </Text>
      <Text>{description}</Text>
    </ConfirmModal>
  );
};

export default RegenerateApiKeyModal;
