import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { VIEW } from "../../../constants/queryKey";
import { ViewService } from "../view.service";

export const getViewFn = async (id: string | null) => {
  if (!id) {
    return Promise.resolve(null);
  }
  const viewService = container.resolve(ViewService);
  return viewService.getViewById(id);
};

export const useView = (id: string | null) => {
  return useQuery({
    queryKey: [VIEW, id],
    queryFn: () => getViewFn(id),
    enabled: !!id
  });
};
