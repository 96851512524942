import {
  parseDomainId,
  parseEnvironmentClientId,
  parseEnvironmentId,
  parseExplicitRedirectRouteId,
  parseHandleRouteId,
  parseImplicitRedirectRouteId,
  parseMappingSchemaId,
  parseMappingSchemaVersionId,
  parseSourceEntityId,
  parseSourceGroupId,
  parseSourceId,
  parseUrlRouteId,
  parseViewId
} from "../../../helpers/ids";
import {
  getRouteKindDisplayText,
  isRouteKindViewAware
} from "../../../helpers/routes";
import { LogType } from "../types";
import {
  ActorEnhancement,
  DisplayType,
  DomainEnhancement,
  Enhancement,
  EnhancementKey,
  EnvironmentClientEnhancement,
  EnvironmentEnhancement,
  MappingSchemaEnhancement,
  SourceEnhancement,
  SourceGroupEnhancement
} from "../types/interactivePlaceholders";
import EnterspeedEntityTypeIcon from "./EnterspeedEntityTypeIcon";
import InteractivePlaceholderLink from "./InteractivePlaceholderLink";
import InterpolatedText from "./InterpolatedText";
import LevelBadge from "./LevelBadge";
import TypeBadge from "./TypeBadge";

const InterpolatedField = ({
  displayType,
  input,
  properties,
  enhancements,
  fallbackValue
}: {
  displayType: DisplayType;
  input: string;
  properties: Record<string, unknown>;
  enhancements: Record<EnhancementKey, Enhancement>;
  fallbackValue: React.ReactNode | string | undefined | null;
}) => {
  const createSourceEntityLink = (id: string): React.ReactNode | undefined => {
    const sourceEntityId = parseSourceEntityId(id);
    if (!sourceEntityId) {
      return undefined;
    }

    const sourceEntityEnhancement = enhancements[`SourceEntity|${id}`];
    const source = enhancements[
      `Source|${sourceEntityId?.sourceGuid ?? ""}`
    ] as SourceEnhancement | undefined;
    const dependenciesResolved = sourceEntityEnhancement && source;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          link={`/source-entities/${sourceEntityId.sourceGuid}/${sourceEntityId.originId}`}
          title={"Click to open source entity"}
          linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE_ENTITY"} />}
          linkText={id}
        ></InteractivePlaceholderLink>
      );
    }

    const details = {
      Source: source.name,
      "Source group": source.sourceGroupName,
      "Source entity origin id": sourceEntityId.originId
    };
    return (
      <InteractivePlaceholderLink
        link={`/source-entities/${sourceEntityId.sourceGuid}/${sourceEntityId.originId}`}
        title={"Click to open source entity"}
        linkText={`${source.sourceGroupName} • ${sourceEntityId.originId}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE_ENTITY"} />}
        details={details}
      ></InteractivePlaceholderLink>
    );
  };

  const createMappingSchemaLink = (id: string): React.ReactNode | undefined => {
    const mappingSchemaId = parseMappingSchemaId(id);
    if (!mappingSchemaId) {
      return undefined;
    }

    const schema = enhancements[
      `MappingSchema|${mappingSchemaId.mappingSchemaGuid}`
    ] as MappingSchemaEnhancement | undefined;
    const dependenciesResolved = !!schema;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          link={`/schemas/${mappingSchemaId.mappingSchemaGuid}`}
          title={"Click to open schema"}
          linkIcon={<EnterspeedEntityTypeIcon type={"SCHEMA"} />}
          linkText={id}
        ></InteractivePlaceholderLink>
      );
    }

    return (
      <InteractivePlaceholderLink
        link={`/schemas/${mappingSchemaId.mappingSchemaGuid}`}
        title={"Click to open schema"}
        linkText={schema.name}
        linkIcon={<EnterspeedEntityTypeIcon type={"SCHEMA"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createMappingSchemaVersionLink = (
    id: string
  ): React.ReactNode | undefined => {
    const mappingSchemaVersionId = parseMappingSchemaVersionId(id);
    if (!mappingSchemaVersionId) {
      return undefined;
    }
    const schema = enhancements[
      `MappingSchema|${mappingSchemaVersionId.mappingSchemaGuid}`
    ] as MappingSchemaEnhancement | undefined;
    const dependenciesResolved = !!schema;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          link={`/schemas/${mappingSchemaVersionId.mappingSchemaGuid}?version=${mappingSchemaVersionId.version}`}
          title={"Click to open schema version"}
          linkIcon={<EnterspeedEntityTypeIcon type={"SCHEMA"} />}
          linkText={id}
        ></InteractivePlaceholderLink>
      );
    }

    return (
      <InteractivePlaceholderLink
        link={`/schemas/${mappingSchemaVersionId.mappingSchemaGuid}?version=${mappingSchemaVersionId.version}`}
        title={"Click to open schema version"}
        linkText={`${schema.name} v${mappingSchemaVersionId.version}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"SCHEMA"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createEnvironmentLink = (
    idAsString: string
  ): React.ReactNode | undefined => {
    const id = parseEnvironmentId(idAsString);
    if (!id) {
      return undefined;
    }

    const environment = enhancements[`Environment|${id.environmentGuid}`] as
      | EnvironmentEnhancement
      | undefined;
    const dependenciesResolved = !!environment;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Environment"}
          linkText={idAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"ENVIRONMENT"} />}
        ></InteractivePlaceholderLink>
      );
    }

    return (
      <InteractivePlaceholderLink
        title={"Environment"}
        linkText={environment.name}
        linkIcon={<EnterspeedEntityTypeIcon type={"ENVIRONMENT"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createDomainLink = (
    idAsString: string
  ): React.ReactNode | undefined => {
    const id = parseDomainId(idAsString);
    if (!id) {
      return undefined;
    }

    const domain = enhancements[`Domain|${id.domainGuid}`] as
      | DomainEnhancement
      | undefined;
    const dependenciesResolved = !!domain;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Domain"}
          linkText={idAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"DOMAIN"} />}
        ></InteractivePlaceholderLink>
      );
    }

    return (
      <InteractivePlaceholderLink
        title={"Domain"}
        linkText={domain.name}
        linkIcon={<EnterspeedEntityTypeIcon type={"DOMAIN"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createReleaseLink = (
    properties: Record<string, unknown>,
    enhancements: Record<EnhancementKey, Enhancement>
  ): React.ReactNode | undefined => {
    const releaseTotalSchemasChanged = properties[
      "ReleaseTotalSchemas"
    ] as string;
    return (
      <InterpolatedText
        text={`${releaseTotalSchemasChanged} changes to {Environment}`}
        properties={properties}
        enhancements={enhancements}
        displayType={displayType}
      ></InterpolatedText>
    );
  };

  const createEnvironmentClientLink = (
    idAsString: string
  ): React.ReactNode | undefined => {
    const id = parseEnvironmentClientId(idAsString);
    if (!id) {
      return undefined;
    }

    const environmentClient = enhancements[
      `EnvironmentClient|${idAsString}`
    ] as EnvironmentClientEnhancement | undefined;
    const dependenciesResolved = !!environmentClient;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Environment client"}
          linkText={idAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"ENVIRONMENT_CLIENT"} />}
        ></InteractivePlaceholderLink>
      );
    }

    const details = {
      Environment: environmentClient.environmentName,
      Client: environmentClient.name
    };

    return (
      <InteractivePlaceholderLink
        title={"Environment client"}
        linkText={`${environmentClient.name} • ${environmentClient.environmentName}`}
        details={details}
        linkIcon={<EnterspeedEntityTypeIcon type={"ENVIRONMENT_CLIENT"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createActorLink = (idAsString: string): React.ReactNode | undefined => {
    const actor = enhancements[`Actor|${idAsString}`] as
      | ActorEnhancement
      | undefined;
    const dependenciesResolved = !!actor;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Actor"}
          linkText={idAsString}
        ></InteractivePlaceholderLink>
      );
    }

    const details = {
      Id: actor.id,
      Type: actor.type
    };
    return (
      <InteractivePlaceholderLink
        title={"Actor"}
        details={details}
        linkText={actor.name}
      ></InteractivePlaceholderLink>
    );
  };

  const createSourceLink = (
    idAsString: string
  ): React.ReactNode | undefined => {
    const id = parseSourceId(idAsString);
    if (!id) {
      return undefined;
    }
    const source = enhancements[`Source|${id.sourceGuid}`] as
      | SourceEnhancement
      | undefined;
    const dependenciesResolved = !!source;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Source"}
          linkText={idAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE"} />}
        ></InteractivePlaceholderLink>
      );
    }
    return (
      <InteractivePlaceholderLink
        title={"Source"}
        linkText={`${source.sourceGroupName} • ${source.name}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createSourceGroupLink = (
    idAsString: string
  ): React.ReactNode | undefined => {
    const id = parseSourceGroupId(idAsString);
    if (!id) {
      return undefined;
    }

    const sourceGroup = enhancements[`SourceGroup|${id.sourceGroupGuid}`] as
      | SourceGroupEnhancement
      | undefined;
    const dependenciesResolved = !!sourceGroup;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          title={"Source group"}
          linkText={idAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE_GROUP"} />}
        ></InteractivePlaceholderLink>
      );
    }

    return (
      <InteractivePlaceholderLink
        title={"Source group"}
        linkText={`${sourceGroup.name}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"SOURCE_GROUP"} />}
      ></InteractivePlaceholderLink>
    );
  };

  const createViewLink = (id: string): React.ReactNode | undefined => {
    const viewId = parseViewId(id);
    if (!viewId) {
      return undefined;
    }

    const environment = enhancements[
      `Environment|${viewId.environmentGuid}`
    ] as EnvironmentEnhancement | undefined;
    const schema = enhancements[`MappingSchema|${viewId.viewHandle}`] as
      | MappingSchemaEnhancement
      | undefined;
    const source = enhancements[`Source|${viewId.sourceGuid}`] as
      | SourceEnhancement
      | undefined;
    const dependenciesResolved = environment && schema && source;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          link={`/views/?env=${viewId.environmentGuid}&source=${viewId.sourceGuid}&alias=${viewId.viewHandle}&sourceEntityOriginId=${viewId.originId}`}
          title={"Click to open view"}
          linkText={id}
          details={{
            "Source Entity ID": `gid://Source/${viewId.sourceGuid}/Entity/${viewId.originId}`,
            "Environment ID": `gid://Environment/${viewId.environmentGuid}`,
            "Schema alias": viewId.viewHandle
          }}
          linkIcon={<EnterspeedEntityTypeIcon type={"VIEW"} />}
        ></InteractivePlaceholderLink>
      );
    }

    const details = {
      Source: source?.name,
      "Source group": source?.sourceGroupName,
      "Source entity origin id": viewId.originId,
      Environment: environment?.name,
      "Mapping schema": schema?.name
    };

    return (
      <InteractivePlaceholderLink
        link={`/views/?env=${viewId.environmentGuid}&source=${viewId.sourceGuid}&alias=${viewId.viewHandle}&sourceEntityOriginId=${viewId.originId}`}
        title={"Click to open view"}
        linkText={`${environment.name} • ${schema.name} • ${viewId.originId}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"VIEW"} />}
        details={details}
      ></InteractivePlaceholderLink>
    );
  };

  const createRouteLink = (
    routeIdAsString: string
  ): React.ReactNode | undefined => {
    const routeId =
      parseUrlRouteId(routeIdAsString) ??
      parseHandleRouteId(routeIdAsString) ??
      parseImplicitRedirectRouteId(routeIdAsString) ??
      parseExplicitRedirectRouteId(routeIdAsString);

    if (!routeId) {
      return undefined;
    }

    const details = {
      "Route kind": getRouteKindDisplayText(routeId.kind)
    };

    if (!isRouteKindViewAware(routeId.kind)) {
      return undefined;
    }

    const environment = enhancements[
      `Environment|${routeId.environmentGuid}`
    ] as EnvironmentEnhancement | undefined;
    const schema = enhancements[`MappingSchema|${routeId.schemaAlias}`] as
      | MappingSchemaEnhancement
      | undefined;
    const source = enhancements[`Source|${routeId.sourceGuid}`] as
      | SourceEnhancement
      | undefined;

    const dependenciesResolved = environment && schema && source;
    const useIdValueDisplayType =
      displayType === "ID_VALUE_LINK" || !dependenciesResolved;
    if (useIdValueDisplayType) {
      return (
        <InteractivePlaceholderLink
          link={`/views/?env=${routeId.environmentGuid}&source=${routeId.sourceGuid}&alias=${routeId.schemaAlias}&sourceEntityOriginId=${routeId.originId}`}
          title={"Click to open route & view details"}
          linkText={routeIdAsString}
          linkIcon={<EnterspeedEntityTypeIcon type={"ROUTE"} />}
          details={{
            ...details,
            "Source Entity ID": `gid://Source/${routeId.sourceGuid}/Entity/${routeId.originId}`,
            "Environment ID": `gid://Environment/${routeId.environmentGuid}`,
            "Schema alias": routeId.schemaAlias
          }}
        ></InteractivePlaceholderLink>
      );
    }

    const routeValuePrefix = routeId.kind === "HANDLE" ? "handle: " : "";
    const routeValue =
      routeId.kind === "HANDLE" ? routeId.handle : routeId.relativeUrl;

    return (
      <InteractivePlaceholderLink
        link={`/views/?env=${routeId.environmentGuid}&source=${routeId.sourceGuid}&alias=${routeId.schemaAlias}&sourceEntityOriginId=${routeId.originId}`}
        title={"Click to open route & view details"}
        linkText={`${environment.name} • ${routeValuePrefix}${routeValue}`}
        linkIcon={<EnterspeedEntityTypeIcon type={"ROUTE"} />}
        details={{
          Source: source.name,
          "Source group": source.sourceGroupName,
          "Source entity origin id": routeId.originId,
          Environment: environment.name,
          "Mapping schema": schema.name,
          ...details
        }}
      ></InteractivePlaceholderLink>
    );
  };

  const remap = (
    placeholder: string,
    properties: Record<string, unknown>
  ): React.ReactNode | undefined => {
    let link: React.ReactNode | undefined = undefined;
    let fallbackDisplayValue: string | undefined = undefined;

    switch (placeholder) {
      case "SourceEntity":
      case "SourceEntityId":
        fallbackDisplayValue = properties.SourceEntityId as string;
        link = createSourceEntityLink(properties.SourceEntityId as string);
        break;
      case "MappingSchema":
      case "MappingSchemaId":
        fallbackDisplayValue = (properties.MappingSchemaId ??
          properties.MappingSchemaVersionId) as string;
        link = createMappingSchemaLink(properties.MappingSchemaId as string);
        break;
      case "MappingSchemaVersion":
      case "MappingSchemaVersionId":
        fallbackDisplayValue = properties.MappingSchemaVersionId as string;
        link = createMappingSchemaVersionLink(
          properties.MappingSchemaVersionId as string
        );
        break;
      case "View":
      case "ViewId":
        fallbackDisplayValue = properties.ViewId as string;
        link = createViewLink(properties.ViewId as string);
        break;
      case "Route":
      case "RouteId":
      case "ActiveRoute":
      case "ActiveRouteId":
        fallbackDisplayValue = properties.ActiveRouteId as string;
        link = createRouteLink(properties.RouteId as string);
        break;
      case "Source":
      case "SourceId":
        fallbackDisplayValue = properties.SourceId as string;
        link = createSourceLink(properties.SourceId as string);
        break;
      case "SourceGroup":
      case "SourceGroupId":
        fallbackDisplayValue = properties.SourceGroupId as string;
        link = createSourceGroupLink(properties.SourceGroupId as string);
        break;
      case "Environment":
      case "EnvironmentId":
        fallbackDisplayValue = properties.EnvironmentId as string;
        link = createEnvironmentLink(properties.EnvironmentId as string);
        break;
      case "Domain":
      case "DomainId":
        fallbackDisplayValue = properties.DomainId as string;
        link = createDomainLink(properties.DomainId as string);
        break;
      case "EnvironmentClient":
      case "EnvironmentClientId":
        fallbackDisplayValue = properties.EnvironmentClientId as string;
        link = createEnvironmentClientLink(
          properties.EnvironmentClientId as string
        );
        break;
      case "Actor":
      case "ActorId":
        fallbackDisplayValue = properties.ActorId as string;
        link = createActorLink(properties.ActorId as string);
        break;
      case "Release":
      case "ReleaseId":
        fallbackDisplayValue = properties.ReleaseId as string;
        link = createReleaseLink(properties, enhancements);
        break;
      case "Type":
        link = <TypeBadge type={properties.Type as LogType} />;
        break;
      case "Level":
        link = (
          <LevelBadge
            level={properties.Level as "Warning" | "Information" | "Error"}
          />
        );
        break;
    }

    return (
      link ??
      fallbackDisplayValue ??
      (properties[placeholder] as string) ??
      fallbackValue
    );
  };

  return <>{remap(input, properties)}</>;
};

export default InterpolatedField;
