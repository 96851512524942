import { useLocation } from "react-router-dom";
import { INavigationType } from "../types/navigation";

type Pages = "/settings" | "/admin" | "/account" | "/versions" | "/no-tenants";

const pages: { path: Pages; component: INavigationType }[] = [
  { path: "/no-tenants", component: "noNavigation" },
  { path: "/settings", component: "settingsNavigation" },
  { path: "/admin", component: "adminNavigation" },
  { path: "/account", component: "accountNavigation" },
  { path: "/versions", component: "versionsNavigation" }
];
const defaultPage: INavigationType = "homeNavigation";

const useActiveNavigation = (): INavigationType => {
  const { pathname } = useLocation();

  return (
    pages.find(({ path, component }) =>
      pathname?.startsWith(path) ? component : undefined
    )?.component ?? defaultPage
  );
};

export default useActiveNavigation;
