import { Flex, keyframes } from "@chakra-ui/react";

const NotificationBubble = ({
  notifications,
  pulsate
}: {
  notifications: number | undefined;
  pulsate?: boolean;
}) => {
  const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(4, 60, 199, 0.7);
      }
  
      70% {
        box-shadow: 0 0 0 10px rgba(4, 60, 199, 0);
      }
  
      100% {
        box-shadow: 0 0 0 0 rgba(4, 60, 199, 0);
      }
    `;

  return (
    <Flex
      width="16px"
      height="16px"
      color="#fff"
      bg="brand.900"
      borderRadius="full"
      fontSize="2xs"
      fontWeight="500"
      alignItems="center"
      justifyContent="center"
      animation={pulsate ? `${pulse} 2s infinite` : ""}
    >
      {notifications}
    </Flex>
  );
};

export default NotificationBubble;
