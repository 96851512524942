import { Button, HStack, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table/DataTable";
import { formatTime, timeFromNow } from "../../../helpers/dateHelper";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { EmptyStateType } from "../../../types/emptyState";
import { ITenantInvite } from "../../tenants/types";
import { useAcceptInvite } from "../api/acceptInvite";
import { useDeclineInvite } from "../api/declineInvite";
import { IReceivedInvitation } from "../types";

const ReceivedInvitationsTable = ({
  data,
  isLoading,
  acceptedTenantInvite
}: {
  data?: ITenantInvite[];
  isLoading?: boolean;
  acceptedTenantInvite: (tenantId: string) => void;
}) => {
  const acceptInvite = useAcceptInvite();
  const declineInvite = useDeclineInvite();
  const mixpanel = useMixPanel();

  const tableData = data
    ? data?.map(
        (item) =>
          ({
            name: `${item.inviter.firstname} ${item.inviter.lastname}`,
            email: item.inviter.email,
            message: item.message,
            dateReceived: item.createdAt,
            dateExpires: item.expiresAt,
            id: item.id.inviteGuid,
            tenant: item.tenant,
            tenantName: item.tenant.name
          } as IReceivedInvitation)
      )
    : [];

  const columnHelper = createColumnHelper<IReceivedInvitation>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <>
          {props.cell.getValue()}{" "}
          <Text color="gray.400">{props.row.original.email}</Text>
        </>
      ),
      header: "Invited by"
    }),
    columnHelper.accessor("tenantName", {
      cell: (info) => info.getValue(),
      header: "Invited to join"
    }),
    columnHelper.accessor("message", {
      cell: (info) => info.getValue(),
      header: "Message"
    }),
    columnHelper.accessor("dateReceived", {
      cell: (info) => (
        <>
          <Text>{timeFromNow(info.getValue())}</Text>
          <Text fontSize="xs" color="gray.400">
            {formatTime(info.getValue())}
          </Text>
        </>
      ),
      header: "Received"
    }),
    columnHelper.accessor("dateExpires", {
      cell: (info) => (
        <>
          <Text>{timeFromNow(info.getValue())}</Text>
          <Text fontSize="xs" color="gray.400">
            {formatTime(info.getValue())}
          </Text>
        </>
      ),
      header: "Expires"
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <HStack justifyContent="flex-end">
          <Button
            variant="primary"
            onClick={() => {
              acceptInvite.mutate(props.row.original);
              mixpanel.track("inviteAccepted", {
                inviteId: props.row.original.id
              });
              acceptedTenantInvite(props.row.original.tenant.id);
            }}
          >
            Accept
          </Button>
          <Button
            colorScheme="gray"
            onClick={() => {
              declineInvite.mutate({ id: props.row.original.id });
              mixpanel.track("inviteDeclined", {
                inviteId: props.row.original.id
              });
            }}
          >
            Decline
          </Button>
        </HStack>
      )
    })
  ];

  return (
    <>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={tableData}
        emptyState={EmptyStateType.NO_RECEIVED_INVITATIONS}
      />
    </>
  );
};

export default ReceivedInvitationsTable;
