import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INSPECTED_ROUTES } from "../../../constants/queryKey";
import { RouteService } from "../route.service";
import { IInspectRoutePayload, IInspectRouteResponse } from "../types";

export const getInspectedRoutes = async (
  payload: IInspectRoutePayload,
  continuationToken?: string
): Promise<
  IInspectRouteResponse & {
    continuationToken?: string;
  }
> => {
  const routeService = container.resolve(RouteService);
  const { routes, continuationToken: next } =
    await routeService.getInspectedRouted(payload, continuationToken);

  return { ...routes, continuationToken: next };
};

export const useInspectedRoutes = (
  payload: IInspectRoutePayload,
  enabled: boolean
) => {
  return useInfiniteQuery({
    queryKey: [INSPECTED_ROUTES],
    queryFn: ({
      pageParam: continuationToken
    }: QueryFunctionContext<unknown[], string>) =>
      getInspectedRoutes(payload, continuationToken),
    enabled: enabled,
    getNextPageParam: (lastPage) => lastPage.continuationToken,
    initialPageParam: ""
  });
};
