import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DOMAINS } from "../../../constants/queryKey";
import { DomainService } from "../domain.service";

export const getDomains = async () => {
  const domainService = container.resolve(DomainService);
  return domainService.getAllDomains();
};

export const useDomains = () => {
  return useQuery({
    queryKey: [DOMAINS],
    queryFn: getDomains
  });
};
