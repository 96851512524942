import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { TENANT_INVITES } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { TenantService } from "../tenants.service";
import { ITenantCreateInvitePayload } from "../types";

export const createInviteTenantUser = ({
  payload
}: {
  payload: ITenantCreateInvitePayload;
}) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.createTenantInvite(payload);
};

export const useCreateInviteTenantUser = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: createInviteTenantUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TENANT_INVITES] });
      toast({
        title: "User successfully invited",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      const errors = Object.values(response?.data["errors"] ?? {});

      if (errors.length) {
        const message = (errors as string[][])[0][0];
        if (
          message.includes("already has pending invitation for this tenant")
        ) {
          toast({
            status: "info",
            title: "User already invited",
            description: message
          });
        } else {
          const message = (errors as string[][])[0];

          toast({
            title: message ?? "Error inviting user",
            status: "error",
            ...defaultToast
          });
        }
      } else {
        toast({
          title: "Error inviting user",
          status: "error",
          ...defaultToast
        });
      }
    }
  });
};
