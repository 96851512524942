import { Box, Link, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useUnconfiguredHostnames } from "../api/getUnconfiguredHostnames";
import UnconfiguredHostnamesModal from "./UnconfiguredHostnamesModal";

const UnconfiguredHostnames = ({
  hideGoToDomainsLink
}: {
  hideGoToDomainsLink?: boolean;
}) => {
  const { data } = useUnconfiguredHostnames();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const color = useColorModeValue("gray.800", "gray.800");

  if (!data || !data.length) {
    return null;
  }

  return (
    <Box bg="yellow.400" p="4" my="4" color={color}>
      You have <strong>{data.length}</strong> unconfigured hostname
      {data.length !== 1 ? "s " : " "}
      <Box display="inline-block">
        {data.slice(0, 2).map((unconfiguredHostName, index) => {
          return (
            <Box display="inline-block" key={unconfiguredHostName}>
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                bg="yellow.200"
                padding="2px 4px"
                borderRadius="4px"
                mr="2"
              >
                {unconfiguredHostName}
              </Box>

              {index + 1 === 2 && data.length > 2 && (
                <Link onClick={() => onOpen()} color="brand.900">
                  and {data.length - 2} more
                </Link>
              )}
            </Box>
          );
        })}
      </Box>
      {"in your source entities. "}
      {!hideGoToDomainsLink && (
        <Box display="inline-block">
          {"Go to your "}
          <Link
            as={RouterLink}
            color="brand.900"
            to="/settings/environment-settings/#domains"
          >
            Domains settings
          </Link>
          {" to add "}
          {data.length !== 1 ? "them" : "it"}.
        </Box>
      )}
      <UnconfiguredHostnamesModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default UnconfiguredHostnames;
