import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue,
  useMediaQuery,
  VStack
} from "@chakra-ui/react";
import {
  faChartBar,
  faChartUser,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCloudArrowUp,
  faCogs,
  faDashboard,
  faDatabase,
  faEnvelope,
  faEye,
  faKey,
  faLink,
  faMagnifyingGlass,
  faSitemap,
  faSlidersV,
  faTachometer,
  faTrashCan,
  faUsers,
  faUsersCog
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useTenantStore } from "../features/tenants/store";
import { useGeneralStore } from "../generalStore";
import useActiveNavigation from "../helpers/getActiveNavigation";
import {
  INavigation,
  INavigationItem,
  INavigationType
} from "../types/navigation";

const SideMenu = () => {
  const [isLargerThan48Em] = useMediaQuery("(min-width: 48em)", {
    ssr: true,
    fallback: true
  });

  const activeNavigation = useActiveNavigation();

  if (!activeNavigation || !navigation[activeNavigation].length) {
    return <></>;
  }

  return (
    <>
      {isLargerThan48Em ? (
        <DesktopNavigation activeNavigation={activeNavigation} />
      ) : (
        <MobileNavigation activeNavigation={activeNavigation} />
      )}
    </>
  );
};

const MobileNavigation = ({
  activeNavigation
}: {
  activeNavigation: INavigationType;
}) => {
  const { pathname } = useLocation();
  const currentPage = navigation[activeNavigation].find(
    (item: INavigationItem) => item.href === pathname
  );

  const textColor = useColorModeValue("gray.500", "gray.500");
  const activeTextColor = useColorModeValue("brand.100", "brand.200");
  const activeBgColor = useColorModeValue("brand.100", "gray.600");

  return (
    <Box mx="24px" mt="24px">
      <Menu>
        <MenuButton
          variant="subtle"
          width="100%"
          as={Button}
          leftIcon={
            <Icon
              as={FontAwesomeIcon}
              icon={currentPage?.icon ? currentPage?.icon : faChevronRight}
              mr="6px"
            />
          }
        >
          {currentPage?.label}
        </MenuButton>
        <MenuList>
          {navigation[activeNavigation].map((item: INavigationItem) => {
            let isActive;

            if (item.catchAll) {
              isActive = pathname?.startsWith(item.href) ?? false;
            } else {
              isActive = item.href === pathname;
            }

            return (
              <MenuItem
                as={NavLink}
                to={item.href}
                key={item.label}
                color={isActive ? activeTextColor : textColor}
                pointerEvents={isActive ? "none" : "auto"}
                backgroundColor={isActive ? activeBgColor : "transparent"}
                p="3"
                fontWeight="semibold"
                icon={
                  item.icon ? (
                    <Icon as={FontAwesomeIcon} icon={item.icon} mr="4" />
                  ) : undefined
                }
              >
                {item.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

const DesktopNavigation = ({
  activeNavigation
}: {
  activeNavigation: INavigationType;
}) => {
  const { activeTenant } = useTenantStore();
  const ldFlags = useFlags();
  const flags = { ...ldFlags, activeTenant: !!activeTenant.id };
  const { pathname } = useLocation();
  const { setIsSideMenuCollapsed } = useGeneralStore(
    useShallow(({ setIsSideMenuCollapsed }) => ({ setIsSideMenuCollapsed }))
  );

  const savedCollapseState =
    localStorage.getItem("sideMenuCollapsed") === "true";
  const textColor = useColorModeValue("gray.500", "gray.500");
  const [collapsed, setCollapsed] = useState(savedCollapseState);
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const hoverColor = useColorModeValue("gray.900", "gray.300");
  const hoverBgColor = useColorModeValue("brand.100", "gray.700");
  const activeTextColor = useColorModeValue("brand.900", "brand.200");
  const activeBgColor = useColorModeValue("brand.100", "gray.600");

  const toggleCollaped = () => {
    const newState = !collapsed;
    setCollapsed(newState);
    localStorage.setItem("sideMenuCollapsed", newState.toString());
  };

  useEffect(() => {
    setIsSideMenuCollapsed(collapsed);
  }, [collapsed]);

  return (
    <Flex width={collapsed ? "4em" : "auto"}>
      <VStack
        spacing={2}
        align="stretch"
        borderRight={"1px solid"}
        borderColor={borderColor}
      >
        <Tooltip
          label={`Click to ${collapsed ? "expand" : "collapse"} side menu`}
        >
          <IconButton
            w={"3em"}
            variant={"ghost"}
            icon={
              <Icon
                as={FontAwesomeIcon}
                icon={collapsed ? faChevronsRight : faChevronsLeft}
              ></Icon>
            }
            onClick={() => toggleCollaped()}
            aria-label="collapse-sidebar"
            ml={"auto"}
          ></IconButton>
        </Tooltip>
        {navigation[activeNavigation]
          .filter((item) => (item?.enabled ? item.enabled(flags) : true))
          .map((item: INavigationItem) => {
            let isActive = false;

            if (item.catchAll) {
              isActive = pathname?.startsWith(item.href) ?? false;
            } else if (item.href === pathname) {
              isActive = true;
            }

            if (!isActive && item.subRoutes && item.subRoutes.length > 0) {
              isActive = item.subRoutes.includes(pathname);
            }

            return (
              <Tooltip
                key={item.label}
                label={item.label}
                isDisabled={!collapsed}
                placement="right"
              >
                <Button
                  as={NavLink}
                  to={item.href}
                  key={item.label}
                  color={isActive ? activeTextColor : textColor}
                  backgroundColor={isActive ? activeBgColor : "transparent"}
                  width="100%"
                  justifyContent="flex-start"
                  variant="ghost"
                  size="md"
                  borderLeftRadius="0"
                  leftIcon={
                    item.icon ? (
                      <Icon as={FontAwesomeIcon} icon={item?.icon} ml="6px" />
                    ) : undefined
                  }
                  _hover={{
                    color: hoverColor,
                    backgroundColor: hoverBgColor
                  }}
                >
                  {!collapsed ? item.label : ""}
                </Button>
              </Tooltip>
            );
          })}
      </VStack>
    </Flex>
  );
};

const navigation: INavigation = {
  homeNavigation: [
    {
      href: "/",
      label: "Overview",
      icon: faTachometer,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/source-entities",
      label: "Source entities",
      icon: faDatabase,
      catchAll: true,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/schemas",
      label: "Schemas",
      icon: faCogs,
      catchAll: true,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/views",
      label: "Generated views",
      icon: faEye,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/route-inspector",
      label: "Route inspector",
      icon: faLink,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    }
  ],
  settingsNavigation: [
    {
      href: "/settings/data-sources",
      label: "Data sources",
      icon: faDatabase,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/settings/environment-settings",
      label: "Environments",
      icon: faSitemap,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/settings/management-clients",
      label: "Management clients",
      icon: faKey,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/settings/destinations-settings",
      subRoutes: ["/settings/destinations"],
      label: "Destinations",
      icon: faCloudArrowUp,
      roles: [],
      enabled: (flags: Record<string, unknown>) =>
        (flags.destinations as boolean) && (flags.activeTenant as boolean),
      catchAll: true
    },
    {
      href: "/settings/activity-logs",
      label: "Activity logs",
      icon: faChartUser,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/settings/logs",
      label: "Logs",
      icon: faChartBar,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    },
    {
      href: "/settings/users",
      label: "Users",
      icon: faUsers,
      roles: [],
      enabled: (flags) => flags.activeTenant as boolean
    }
  ],
  adminNavigation: [
    { href: "/admin", label: "Dashboard", icon: faDashboard, roles: [] },
    {
      href: "/admin/tenants",
      label: "Tenants",
      icon: faUsersCog,
      roles: []
    },
    {
      href: "/admin/preferences",
      label: "Preferences",
      icon: faSlidersV,
      roles: []
    },
    {
      href: "/admin/user-search",
      label: "Search for users",
      icon: faMagnifyingGlass,
      roles: []
    },
    {
      href: "/admin/delete-tenant",
      label: "Delete tenant",
      icon: faTrashCan,
      roles: []
    }
  ],
  accountNavigation: [
    {
      href: "/account/invitations",
      label: "Invitations",
      icon: faEnvelope,
      roles: []
    }
  ],
  versionsNavigation: [],
  noNavigation: []
};

export default SideMenu;
