import _ from "lodash";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useMixPanel from "./useMixPanel";

type MixpanelUrlSubstitutionRule = {
  path: string;
  subs: string[];
  queryParamKeySubs: Record<string, string>;
};

const rules: MixpanelUrlSubstitutionRule[] = [
  {
    path: "/source-entities",
    subs: ["sourceId", "originId"],
    queryParamKeySubs: { id: "sourceId" }
  },
  { path: "/schemas", subs: ["schemaId"], queryParamKeySubs: {} },
  {
    path: "/views",
    subs: [],
    queryParamKeySubs: { id: "viewId", env: "environmentId" }
  }
];

const MixPanelContext = createContext({});

const MixpanelProvider = ({ children }: { children: ReactNode }) => {
  const mixpanel = useMixPanel();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [lastPath, setLastPath] = useState<string>();
  const [lastProps, setLastProps] = useState<Record<string, string>>();

  let path = pathname ?? "";
  path += searchParams ? `?${searchParams.toString()}` : "";
  const [url, setUrl] = useState<string>(path);

  useEffect(() => {
    let path = pathname ?? "";
    path += searchParams ? `?${searchParams.toString()}` : "";
    setUrl(path);
  }, [pathname, searchParams]);

  useEffect(() => {
    if (!url || !mixpanel) {
      return;
    }

    const [path, props] = handleSubstitutionRules(url);
    if (path === lastPath && _.isEqual(props, lastProps)) {
      return;
    }
    if (path === "/" && lastPath === "/") {
      return;
    }
    mixpanel.trackPageView({
      page: path,
      ...props
    });
    setLastPath(path);
    setLastProps(props);
  }, [url, mixpanel]);
  return (
    <MixPanelContext.Provider value={{}}>{children}</MixPanelContext.Provider>
  );
};

function handleSubstitutionRules(
  url: string
): [string, Record<string, string>] {
  const [path, queryProps] = handleQuerySubstitutions(url);
  const [substitutedPath, props] = handlePathSubstitutions(path);
  return [substitutedPath ?? path, { ...props, ...queryProps }];
}

function handleQuerySubstitutions(
  url: string
): [string, Record<string, string> | undefined] {
  const [path, query] = decodeURIComponent(url).split("?");
  const rule = rules.find((rule) => path.includes(rule.path));

  const queryParams = query?.split("&").map((q) => {
    const [key, value] = q.split("=");
    return { key, value };
  });
  const queryProps = queryParams?.reduce((acc, curr) => {
    acc[rule ? rule.queryParamKeySubs[curr.key] : curr.key] = curr.value;
    return acc;
  }, {} as Record<string, string>);
  return [path, queryProps];
}

function handlePathSubstitutions(
  path: string
): [string | undefined, Record<string, string> | undefined] {
  const rule = rules.find((rule) => path.includes(rule.path));
  if (!rule) {
    return [undefined, undefined];
  }

  const [leading, page, ...rest] = path.split("/");
  const substitutedSegments = rest.map((actual, idx) => {
    if (actual) {
      return `[${rule.subs[idx]}]`;
    }
    return actual;
  });

  const newUrl = [leading, page, ...substitutedSegments].join("/");

  const props = rest.reduce((acc, curr, idx) => {
    acc[rule.subs[idx]] = curr;
    return acc;
  }, {} as Record<string, string>);

  return [newUrl, props];
}

export default MixpanelProvider;
