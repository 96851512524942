import { Box, useDisclosure } from "@chakra-ui/react";
import { faKey, faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useManagementClients } from "../api/getManagementClients";
import { IManagementClient, ManagementClientTableModalType } from "../types";

import ReloadButton from "../../../components/ReloadButton";
import SecretKey from "../../../components/SecretKey";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { DataTableAction } from "../../../types/dataTable";
import ManagementClientsActionModal from "./ManagementClientsActionModal";

const ManagementClientsTable = () => {
  const { data, isLoading, refetch, isRefetching } = useManagementClients();

  const [accessKeyUpdated, setAccessKeyUpdated] = useState("");

  const handleAccessKeyUpdated = (accessKey: string) => {
    setAccessKeyUpdated(accessKey);
  };

  const tableData = data
    ? data.map(
        ({
          name,
          accessKey,
          id: { idValue, managementClientGuid }
        }): IManagementClient => ({
          name,
          accessKey,
          id: idValue,
          managementClientGuid
        })
      )
    : [];

  const columnHelper = createColumnHelper<IManagementClient>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.name}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("accessKey", {
      cell: (info) => {
        return (
          <SecretKey
            content={info.getValue()}
            updated={accessKeyUpdated === info.getValue()}
          />
        );
      },
      header: "API Key"
    })
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<ManagementClientTableModalType>();
  const [rowData, setRowData] = useState<IManagementClient | null>(null);

  const handleActionClick = (
    row: IManagementClient,
    type: ManagementClientTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<
    ManagementClientTableModalType,
    IManagementClient
  >[] = [
    {
      type: ManagementClientTableModalType.EDIT,
      icon: faPencil,
      onClick: (row: IManagementClient) => {
        handleActionClick(row, ManagementClientTableModalType.EDIT);
      }
    },
    {
      type: ManagementClientTableModalType.REGENERATE_API_KEY,
      icon: faKey,
      onClick: (row: IManagementClient) => {
        handleActionClick(
          row,
          ManagementClientTableModalType.REGENERATE_API_KEY
        );
      }
    },
    {
      type: ManagementClientTableModalType.DELETE,
      icon: faTrashCan,
      onClick: (row: IManagementClient) => {
        handleActionClick(row, ManagementClientTableModalType.DELETE);
      }
    }
  ];

  return (
    <>
      <ReloadButton loading={isRefetching} onClick={refetch} />
      <DataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        actions={actions}
      />
      {rowData && (
        <ManagementClientsActionModal
          type={modalType}
          managementClient={rowData}
          isOpen={isOpen}
          onClose={onClose}
          onAccessKeyRegenerated={handleAccessKeyUpdated}
        />
      )}
    </>
  );
};

export default ManagementClientsTable;
