import { useQueries } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DEPLOYMENTS_FROM_ENVIRONMENT } from "../../../constants/queryKey";
import { DeploymentService } from "../../deployment/deployment.service";
import { useTenantStore } from "../../tenants/store";

export const getSchemasFromEnvironment = async ({ id }: { id: string }) => {
  const deploymentService = container.resolve(DeploymentService);
  const {
    current: { schemas }
  } = await deploymentService.getDeploymentByEnvironment(id);
  return schemas;
};

const useSchemasFromEnvironments = ({
  environmentIds
}: {
  environmentIds?: string[];
}) => {
  const { activeTenant } = useTenantStore();
  return useQueries({
    queries:
      environmentIds?.map((e) => ({
        queryKey: [DEPLOYMENTS_FROM_ENVIRONMENT, e],
        queryFn: async () => {
          const schemas = await getSchemasFromEnvironment({
            id: e.replace("gid://Environment/", "")
          });
          return schemas.map((schema) => ({ ...schema, environmentId: e }));
        },
        enabled: !!activeTenant.id && !!environmentIds
      })) ?? []
  });
};

export default useSchemasFromEnvironments;
