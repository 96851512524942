import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { faEnvelope, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useInvites } from "../../features/account/api/getInvites";
import SignOutModal from "../../features/auth/components/SignOutModal";
import { useAuthStore } from "../../features/auth/store";
import { IAuthStateUser } from "../../features/auth/types";
import NotificationBubble from "../NotificationBubble";

function buildUserFullName(user?: IAuthStateUser) {
  if (user?.firstName && !user.lastName) {
    return user.firstName;
  }
  if (!user?.firstName && user?.lastName) {
    return user.lastName;
  }
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else {
    return "unknown";
  }
}

const UserMenu = () => {
  const [user, isAdmin] = useAuthStore(
    useShallow((state) => [state.user, state.isAdmin])
  );
  const fullName = buildUserFullName(user);
  const { data: invites, isLoading: invitesIsLoading } = useInvites();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const MenuItems = [
    {
      icon: faEnvelope,
      label: "Invitations",
      href: "/account/invitations"
    },
    {
      icon: faSignOut,
      label: "Sign out",
      onClick: () => onOpen()
    }
  ];

  return (
    <Menu>
      <Tooltip
        label="Open user menu"
        placement="bottom-start"
        isOpen={tooltipOpen}
      >
        <MenuButton
          onClick={() => setTooltipOpen(false)}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          _hover={{ opacity: "0.75" }}
        >
          <Avatar name={user ? fullName : "-"} size="sm" position="relative">
            {invites?.length !== 0 && !invitesIsLoading && (
              <Box position="absolute" top="-5px" right="-5px">
                <NotificationBubble
                  notifications={invites?.length}
                  pulsate={true}
                />
              </Box>
            )}
          </Avatar>
        </MenuButton>
      </Tooltip>
      <MenuList>
        <>
          <Box px="3" pb="2" borderBottom="1px solid" borderColor="gray.200">
            <Flex
              color={useColorModeValue("gray.500", "gray.300")}
              fontWeight="semibold"
              justifyContent="space-between"
              alignItems="center"
            >
              {fullName}
              {isAdmin && (
                <Image
                  ml={"2"}
                  src="/images/godmode.png"
                  alt="Admin"
                  height="20px"
                  width="17px"
                />
              )}
            </Flex>
            <Text
              color={useColorModeValue("gray.500", "gray.300")}
              fontSize={"sm"}
            >
              {user?.email}
            </Text>
          </Box>
          {MenuItems.map((item) =>
            item.href ? (
              <Link key={item.label} to={item.href}>
                <MenuItem
                  p="3"
                  icon={
                    <Icon
                      color="gray.500"
                      as={FontAwesomeIcon}
                      icon={item.icon}
                    />
                  }
                >
                  <Flex alignItems="center">
                    {item.label}
                    {invites?.length !== 0 && item.label === "Invitations" && (
                      <Box ml="2">
                        <NotificationBubble notifications={invites?.length} />
                      </Box>
                    )}
                  </Flex>{" "}
                </MenuItem>
              </Link>
            ) : (
              <MenuItem
                onClick={item.onClick}
                key={item.label}
                p="3"
                icon={
                  <Icon
                    color="gray.500"
                    as={FontAwesomeIcon}
                    icon={item.icon}
                  />
                }
              >
                {item.label}
              </MenuItem>
            )
          )}
        </>
      </MenuList>
      <SignOutModal isOpen={isOpen} onClose={onClose} />
    </Menu>
  );
};

export default UserMenu;
