import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { TenantLimitDto, TenantWithErrors } from "./types";

@singleton()
export class BiService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async GetTenantExceededLimits() {
    const { data } = await this.managementClient.get<TenantLimitDto[]>(
      "/api/v1/bi/limits"
    );
    return data;
  }

  public async GetTenantsWithErrors() {
    const { data } = await this.managementClient.get<TenantWithErrors[]>(
      "/api/v1/bi/errors"
    );
    return data;
  }
}
