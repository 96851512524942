import { Badge, Box, Spinner, useDisclosure } from "@chakra-ui/react";
import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useEnvironments } from "../api/getEnvironments";

import { useState } from "react";

import { uniq } from "lodash";
import EnvironmentBadge from "../../../components/EnvironmentBadge";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { getBadgeColor } from "../../../constants/colors";
import { DataTableAction } from "../../../types/dataTable";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { EnvironmentTableModalType, IEnvironment } from "../types";
import EnvironmentsActionsModal from "./EnvironmentsActionsModal";

const EnvironmentsTable = () => {
  const {
    data: environments,
    isLoading: isLoadingEnvironments,
    refetch: refetchEnvironments,
    isRefetching: isRefetchingEnvironments
  } = useEnvironments();
  const {
    data: sourceGroups,
    isLoading: isLoadingSources,
    refetch: refetchSources,
    isRefetching: isRefetchingSources
  } = useSourceGroups();

  const refetch = () => {
    return Promise.all([refetchEnvironments(), refetchSources()]);
  };

  const sourcesColors = uniq(
    sourceGroups?.flatMap((sg) => sg.sources).map((s) => s.source.id.idValue)
  ).reduce((acc, curr, idx) => {
    acc[curr] = getBadgeColor(idx);
    return acc;
  }, {} as Record<string, [string, string]>);

  const tableData = environments
    ? environments.map(
        (item): IEnvironment => ({
          name: item.name,
          environmentGuid: item.id.environmentGuid,
          id: item.id.idValue,
          sources:
            sourceGroups?.flatMap((sg) =>
              sg.sources
                .filter((s) =>
                  s.environments.some((e) => e.id.idValue === item.id.idValue)
                )
                .map((s) => s.source)
            ) ?? []
        })
      )
    : [];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<EnvironmentTableModalType>();
  const [rowData, setRowData] = useState<IEnvironment | null>(null);

  const columnHelper = createColumnHelper<IEnvironment>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <EnvironmentBadge name={props.row.original.name} />
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("sources", {
      header: "connected sources",
      cell: (props) => {
        return props.getValue().length > 0 &&
          (isLoadingSources || isRefetchingSources) ? (
          <Spinner key={`spinner-${props.row.original.environmentGuid}`} />
        ) : props.getValue().length > 0 ? (
          props.getValue().map(({ name, id: { idValue, sourceGuid } }) => {
            const [color, bgColor] = sourcesColors[idValue];
            return (
              <Badge
                key={sourceGuid}
                fontSize="xs"
                fontWeight="semibold"
                colorScheme="blue"
                borderRadius="md"
                mr={"1em"}
                color={color}
                bg={bgColor}
              >
                {name}
              </Badge>
            );
          })
        ) : (
          <></>
        );
      }
    })
  ];

  const handleActionClick = (
    row: IEnvironment,
    type: EnvironmentTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<EnvironmentTableModalType, IEnvironment>[] = [
    {
      type: EnvironmentTableModalType.EDIT,
      icon: faPencil,
      onClick: (row: IEnvironment) => {
        handleActionClick(row, EnvironmentTableModalType.EDIT);
      }
    },
    {
      type: EnvironmentTableModalType.DELETE,
      icon: faTrashCan,
      onClick: (row: IEnvironment) => {
        handleActionClick(row, EnvironmentTableModalType.DELETE);
      }
    }
  ];

  return (
    <>
      <ReloadButton loading={isRefetchingEnvironments} onClick={refetch} />
      <DataTable
        loading={isLoadingEnvironments}
        columns={columns}
        data={tableData}
        actions={actions}
      />
      {rowData && modalType && (
        <EnvironmentsActionsModal
          type={modalType}
          environment={rowData}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default EnvironmentsTable;
