import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { container } from "tsyringe";
import { ENTITY } from "../../../constants/queryKey";
import { IApiErrorResponse } from "../../../types/errors";
import {
  SourceEntityFormat,
  SourceEntityService
} from "../source-entity.service";

const getEntity = async (
  {
    sourceId,
    originId,
    format
  }: {
    sourceId?: string;
    originId?: string;
    format: SourceEntityFormat;
  } = { format: "placeholders" }
) => {
  if (!sourceId || !originId) {
    return null;
  }
  const sourceEntityService = container.resolve(SourceEntityService);
  return sourceEntityService.getEntityById(sourceId, originId, format);
};

export const useEntity = (
  {
    sourceId,
    originId,
    format
  }: {
    sourceId?: string;
    originId?: string;
    format: SourceEntityFormat;
  } = { format: "placeholders" }
) => {
  return useQuery({
    queryKey: [ENTITY, sourceId, originId, format],
    queryFn: () => getEntity({ sourceId, originId, format }),
    enabled: !!sourceId && !!originId,
    retry(failureCount, error) {
      if (error instanceof AxiosError) {
        const response = error.response as AxiosResponse<IApiErrorResponse>;
        if (
          response?.data.code === "TenantMismatch" ||
          response.status === HttpStatusCode.NotFound
        ) {
          return false;
        }
      }
      return failureCount > 3 ? false : true;
    }
  });
};
