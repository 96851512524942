import {
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useClipboard
} from "@chakra-ui/react";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTextColors } from "../features/tenants/hooks/colors";

const SecretKey = ({
  content,
  updated,
  maxW
}: {
  content: string;
  updated: boolean;
  maxW?: string;
}) => {
  const { value, onCopy, hasCopied } = useClipboard(content);
  const [show, setShow] = useState(false);

  const { bg, text, textShadow, active } = useTextColors();

  return (
    <Flex alignItems="center">
      <Tooltip
        closeOnClick={false}
        label={!show ? "Show secret key" : "Hide secret key"}
        hasArrow
      >
        <IconButton
          onClick={() => setShow(!show)}
          variant="ghost"
          mr="2"
          aria-label={!show ? "Show secret key" : "Hide secret key"}
          icon={<Icon as={FontAwesomeIcon} icon={!show ? faEye : faEyeSlash} />}
        />
      </Tooltip>
      <Tooltip
        closeOnClick={false}
        label={hasCopied ? "Copied!" : "Copy to clipboard"}
        hasArrow
      >
        <Text
          maxW={maxW}
          overflowWrap={"break-word"}
          onClick={onCopy}
          color={show || updated ? text : "transparent"}
          textShadow={show || updated ? "none" : textShadow}
          cursor="pointer"
          size="sm"
          border="none"
          transform={updated ? "translateY(4px)" : "translateY(0)"}
          transition="all ease-in-out 0.2s"
          bg={updated ? "green.100" : bg}
          _active={{
            bg: active.bg,
            transform: "translateY(4px)"
          }}
        >
          {value}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default SecretKey;
