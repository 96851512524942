import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { MANAGEMENT_CLIENTS } from "../../../constants/queryKey";
import { ManagementClientService } from "../management-client.service";
import { IManagementClientCreatePayload } from "../types";

export const createManagementClient = ({
  payload
}: {
  payload: IManagementClientCreatePayload;
}) => {
  const managementClientService = container.resolve(ManagementClientService);
  return managementClientService.createManagementClient(payload);
};

export const useCreateManagementClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createManagementClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MANAGEMENT_CLIENTS]
      });
    }
  });
};
