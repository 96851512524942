import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Field, FieldProps, Form, Formik } from "formik";
import { defaultToast } from "../../../constants/toast";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { IApiErrorResponse } from "../../../types/errors";
import { useInputColors } from "../../tenants/hooks/colors";
import { useEditHostname } from "../api/editHostname";
import { IDomain } from "../types";

const CreateDomainHostnameModal = ({
  domain: { domainGuid, name, hostnames }
}: {
  domain: IDomain;
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const editHostname = useEditHostname();

  const { bg } = useInputColors();

  return (
    <>
      <Button variant="subtle" onClick={onOpen}>
        Create
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Create new hostname</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">Enter a new hostname without protocol (https://)</Text>
            <Formik
              initialValues={{ name: "" }}
              validateOnBlur={false}
              onSubmit={({ name: newHostname }) => {
                onClose();

                const editHostnamePromise = editHostname.mutateAsync({
                  id: domainGuid,
                  payload: {
                    name,
                    hostnames: [newHostname, ...hostnames]
                  }
                });
                toast.promise(editHostnamePromise, {
                  success: {
                    title: "Hostname created",
                    description: `Hostname "${newHostname}" created`,
                    ...defaultToast
                  },
                  loading: {
                    title: "Creating Hostname",
                    description: `Creating hostname "${newHostname}"`,
                    ...defaultToast
                  },
                  error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                    title: response?.data.detail ?? "Error editing hostname",
                    status: "error",
                    ...defaultToast
                  })
                });
              }}
            >
              {({ errors, dirty }) => (
                <Form>
                  <Field name="name" validate={isRequired("Name is required")}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={!!form.errors.name}>
                        <FormLabel>Name</FormLabel>
                        <Input
                          data-1p-ignore
                          bg={bg}
                          autoFocus
                          {...field}
                          placeholder="Your hostname"
                        />
                        <FormErrorMessage>
                          {form.errors.name?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex pt={8} justifyContent="flex-end">
                    <Button
                      colorScheme="gray"
                      variant="ghost"
                      mr={3}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      isDisabled={!dirty || !!errors.name}
                      isLoading={editHostname.isPending}
                      type="submit"
                    >
                      Create
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDomainHostnameModal;
