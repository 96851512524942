import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Textarea,
  Tooltip,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import {
  DELIVERY_API_ENDPOINT_V1,
  DELIVERY_API_ENDPOINT_V2
} from "../constants/api";
import { ISchemaDeliveryResponse } from "../features/schemas/types";
import { IRouteDeliveryResponse } from "../features/tenants/types";
import useMixPanel from "../mixpanel/useMixPanel";
import useReactSelectStyles from "../styles/react-select-style";
import { DeliveryApiVersionTypes } from "../types/api";
import ISelectOption from "../types/selectInput";

const CurlEditor = ({
  deliveryData,
  url,
  handles
}: {
  deliveryData: ISchemaDeliveryResponse | IRouteDeliveryResponse | null;
  url?: string;
  handles?: string[];
}) => {
  const [showUrl, setShowUrl] = useState<boolean>(true);
  const [showHandles, setShowHandles] = useState<string[]>(handles ?? []);
  const [selectedEnvironmentClient, setSelectedEnvironmentClient] =
    useState<ISelectOption>();
  const [curlCopied, setCurlCopied] = useState<boolean>(false);
  const mixpanel = useMixPanel();

  const reactSelectStyles = useReactSelectStyles();

  const environmentClientsOptions = (): ISelectOption[] => {
    if (!deliveryData) {
      return [];
    }

    return deliveryData.environmentClients.map(
      (item) =>
        ({
          label: item.name,
          value: item.id.environmentGuid
        } as ISelectOption)
    );
  };

  useEffect(() => {
    if (environmentClientsOptions) {
      setSelectedEnvironmentClient(environmentClientsOptions()[0]);
    }
  }, [environmentClientsOptions]);

  const deliveryApiVersionsOptions = (): ISelectOption[] => {
    return Object.keys(DeliveryApiVersionTypes).map((key) => ({
      label: key,
      value:
        DeliveryApiVersionTypes[key as keyof typeof DeliveryApiVersionTypes]
    }));
  };

  const [selectedDeliveryApiVersion, setSelectedDeliveryApiVersion] =
    useState<ISelectOption>(deliveryApiVersionsOptions()?.[1] ?? null);

  const getDeliveryApiVersion = () => {
    switch (selectedDeliveryApiVersion.value) {
      case DeliveryApiVersionTypes.V1:
        return DELIVERY_API_ENDPOINT_V1;
      case DeliveryApiVersionTypes.V2:
        return DELIVERY_API_ENDPOINT_V2;
      default:
        return DELIVERY_API_ENDPOINT_V2;
    }
  };

  const currentEnvironment = () => {
    return selectedEnvironmentClient
      ? deliveryData?.environmentClients.find(
          (x) => x.id.environmentGuid === selectedEnvironmentClient.value
        )
      : null;
  };

  const curl = () => {
    if (deliveryData == null) {
      return "";
    }

    const newUrl = new URL(getDeliveryApiVersion());
    showHandles.forEach((handle) => {
      newUrl.searchParams.append("handle", handle);
    });

    if (url && showUrl) {
      newUrl.searchParams.append("url", url);
    }

    return `curl -L -X GET '${newUrl.toString()}' -H 'X-Api-Key: ${
      currentEnvironment()?.accessKey ?? "N/A"
    }'`;
  };

  const copyCurl = async (): Promise<void> => {
    await navigator.clipboard.writeText(curl());
    mixpanel.track("curlCopied");
    setCurlCopied(true);
    setTimeout(() => {
      setCurlCopied(false);
    }, 2000);
  };

  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.800")}
      p="8"
      position="relative"
      borderRadius="sm"
    >
      <VStack spacing={4}>
        <FormControl>
          <FormLabel color="gray.500" fontSize="xs">
            Environment client
          </FormLabel>
          <Select
            size="sm"
            useBasicStyles
            chakraStyles={reactSelectStyles}
            value={selectedEnvironmentClient}
            colorScheme="brand"
            onChange={(value) =>
              setSelectedEnvironmentClient(value as ISelectOption)
            }
            options={environmentClientsOptions()}
          />
        </FormControl>
        {url && (
          <FormControl>
            <FormLabel color="gray.500" fontSize="xs">
              URL
            </FormLabel>
            <Checkbox
              colorScheme="brand"
              isChecked={showUrl}
              onChange={(e) => setShowUrl(e.target.checked)}
            >
              {url}
            </Checkbox>
          </FormControl>
        )}
        {handles?.length && (
          <FormControl>
            <FormLabel color="gray.500" fontSize="xs">
              Handle{handles?.length > 1 && "s"}
            </FormLabel>
            <CheckboxGroup
              colorScheme="brand"
              defaultValue={handles}
              onChange={(value: string[]) => setShowHandles(value)}
            >
              {handles.map((handle) => (
                <Checkbox key={handle} value={handle}>
                  {handle}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </FormControl>
        )}
        <FormControl>
          <FormLabel color="gray.500" fontSize="xs">
            Delivery API version
          </FormLabel>
          <Select
            size="sm"
            useBasicStyles
            chakraStyles={reactSelectStyles}
            value={selectedDeliveryApiVersion}
            colorScheme="brand"
            onChange={(value) =>
              setSelectedDeliveryApiVersion(value as ISelectOption)
            }
            options={deliveryApiVersionsOptions()}
          />
        </FormControl>
        <Tooltip
          label={curlCopied ? "Copied!" : "Copy to clipboard"}
          placement="top"
          closeOnClick={false}
        >
          <Textarea
            bg={useColorModeValue("white", "gray.700")}
            value={curl()}
            cursor="pointer"
            onClick={copyCurl}
            readOnly
            _hover={{
              opacity: 0.8
            }}
            _active={{
              top: "5px"
            }}
            borderRadius="sm"
          />
        </Tooltip>
      </VStack>
    </Box>
  );
};

export default CurlEditor;
