import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { faSwap } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import Pagination from "../../components/Pagination";
import { TENANTS } from "../../constants/queryKey";
import { useGetAllTenantQuery } from "../../features/admin/api/getAllTenant";
import { TenantTable } from "../../features/tenants/components/TenantTable";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { ITenant, ITenantProperties } from "../../features/tenants/types";
import { DataTableAction } from "../../types/dataTable";
import { TenantTableAction } from "./TenantTableAction";

const TenantsPage = () => {
  const title = "Tenants";
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tableData, setTableData] = useState<ITenant[]>([]);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const tenantSwitcher = useTenantSwitcher();

  const { data, fetchNextPage, isFetchingNextPage, isLoading } =
    useGetAllTenantQuery({
      term: searchTerm,
      first: 5
    });

  const total = data?.pages[0].total ?? 0;

  useEffect(() => {
    if (data?.pages) {
      const newTableData = data?.pages.flatMap((page) => page.results).flat();
      setTableData(newTableData);
    }
  }, [data]);

  const handleSearch = useDebouncedCallback(async (term: string) => {
    await queryClient.cancelQueries({ queryKey: [TENANTS, searchTerm] });
    return setSearchTerm(term);
  }, 300);

  const handleLoadMore = async () => {
    const currentPage = data?.pages[data.pages.length - 1];

    if (currentPage) {
      await fetchNextPage();
    }
  };

  const handleTenantSwitch = (tenantProperties: ITenantProperties) => {
    const tenant = tableData.find((t) => t.id.idValue === tenantProperties.id);
    if (!tenant) {
      return;
    }
    tenantSwitcher.switch(tenant, { redirectToHome: false });
  };

  const actions: DataTableAction<TenantTableAction, ITenantProperties>[] = [
    {
      type: TenantTableAction.SWITCH,
      icon: faSwap,
      onClick: handleTenantSwitch,
      isDisabled: (tenant) => !tenant.isUsingSourceGroups
    }
  ];

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox title={title}>
        <Flex pt={"1em"}>
          <FormControl mr="4">
            <FormLabel color="gray.500" fontSize="xs">
              Search for users
            </FormLabel>
            <Input
              placeholder="Search by name, email or id (Nb. search is case-sensitive)"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </FormControl>
        </Flex>
        <TenantTable
          tenants={tableData}
          action={actions}
          onTenantRowClick={(tenant) =>
            navigate(`./${tenant.id.tenantGuid}`, { state: { tenant } })
          }
        ></TenantTable>
        {tableData.length > 0 && (
          <Pagination
            disabled={
              tableData.length === total || isLoading || isFetchingNextPage
            }
            current={tableData.length}
            total={total}
            loadMore={handleLoadMore}
            loading={isFetchingNextPage}
          />
        )}
      </ContentBox>
    </>
  );
};

export default TenantsPage;
