import {
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { faLock, faLockOpen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ILockableField from "../../types/lockableField";

const LockableField = ({
  label,
  lockedInitially = true,
  initialValue,
  valueCallback,
  marginTop,
  hideLabel
}: ILockableField) => {
  const [fieldLocked, setFieldLocked] = useState<boolean>(
    lockedInitially ? true : false
  );

  const [value, setValue] = useState<string>(initialValue ?? "");

  const handleChange = (value: string) => {
    setValue(value);
    valueCallback(value);
  };

  const lockedColorMode = useColorModeValue("white", "gray.700");
  const unlockedColorMode = useColorModeValue("gray.300", "gray.700");

  return (
    <FormControl mt={marginTop}>
      {!hideLabel && <FormLabel fontSize="sm">{label}</FormLabel>}
      <InputGroup size="md">
        <Input
          isDisabled={fieldLocked}
          bg={fieldLocked ? lockedColorMode : unlockedColorMode}
          pr="4.5rem"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e.target.value);
          }}
          value={value}
        />
        <InputRightElement width="4.5rem">
          <Tooltip
            label={fieldLocked ? `Edit ${label}` : `Disable editing ${label}`}
          >
            <IconButton
              aria-label={`Toggle edit ${label}`}
              colorScheme="gray"
              icon={
                <Icon
                  as={FontAwesomeIcon}
                  icon={fieldLocked ? faLock : faLockOpen}
                />
              }
              onClick={() => setFieldLocked(!fieldLocked)}
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default LockableField;
