import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Flex,
  Progress,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useInvites } from "../../account/api/getInvites";
import { useAuthStore } from "../../auth/store";
import noTenantLottie from "./no-tenant.json";
import TenantCreateTenantModal from "./TenantCreateTenantModal";

export const NoTenants = () => {
  const user = useAuthStore((state) => state.user);

  const [useDemoData, setUseDemoData] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const openCreateTenantModal = (useDemoData: boolean) => {
    setUseDemoData(useDemoData);
    return onOpen();
  };

  const { data: invitesData, isRefetching } = useInvites();

  return (
    <Flex
      direction={"column"}
      justifyItems={"center"}
      m="48px auto"
      maxW={"1200px"}
      gap={"2em"}
    >
      {invitesData && invitesData.length !== 0 && (
        <Card pt={"48px"}>
          <Flex display="grid" px={"48px"}>
            <Flex direction={"column"}>
              <Box fontSize="24px" fontWeight="500;">
                Pendign invitations <br />
              </Box>
              <Text>
                {`You have ${invitesData.length} pending ${
                  invitesData.length > 1 ? "invitations" : "invitation"
                } `}
                <Button as={NavLink} variant={"link"} to="/account/invitations">
                  Go to invitation page
                </Button>
                {" to join an existing tenant."}
              </Text>
            </Flex>
          </Flex>
          <Box mt={"3em"}>
            {isRefetching && <Progress size="xs" isIndeterminate />}
          </Box>
        </Card>
      )}
      {user?.multipleEmailsWarning && (
        <Card pt={"48px"} pb={"48px"}>
          <Flex display="grid" px={"48px"}>
            <Flex direction={"column"}>
              <Box fontSize="24px" fontWeight="500;">
                ⚠️ Duplicate user email <br />
              </Box>
              <Text>
                We've noticed multiple users registered with the same email
                address ({user?.email}). This can happen if you have both an
                email-and-password user and a Microsoft Entra ID user with the
                same email.
              </Text>
              <Text>
                If you can't find your tenants when signing in with your Entra
                ID (because, let's face it, technology can be tricky), try
                signing in with your username and password instead. Feel free to
                reach out to support@enterspeed.com, via chat, or your support
                Slack channel, and let us know your preferred sign-in method.
                We'll have you sorted in no time!
              </Text>
            </Flex>
          </Flex>
        </Card>
      )}
      <Card pt={"48px"}>
        <Flex display="grid" gridTemplateColumns={"3fr 2fr"} px={"48px"}>
          <Flex direction={"column"}>
            <Box fontSize="24px" fontWeight="500;">
              Awesome, {user?.firstName ?? ""}🙌
            </Box>
            <Box>
              <Text fontSize="16px">
                A tenant is like a property for your website. You can have
                multiple tenants on your Enterspeed account.
              </Text>

              <Text fontSize="16px" marginTop="24px">
                If this is your first time using Enterspeed, starting with some
                "real data" might be helpful. You can do this by creating a
                tenant with demo data.
              </Text>
              <Text fontSize="16px" marginTop="24px">
                Our demo data is built around a fictional Umbraco store. Here
                all the settings have been configured, schemas have been
                designed and demo data has been ingested. You can always delete
                the demo data when you're done testing.
              </Text>
            </Box>

            <Flex marginTop="48px" gap={"2em"}>
              <ButtonGroup isAttached variant="outline">
                <Button
                  variant="subtle"
                  onClick={() => openCreateTenantModal(false)}
                >
                  Create tenant
                </Button>
                <Button onClick={() => openCreateTenantModal(true)}>
                  With demo data
                </Button>
              </ButtonGroup>
              <TenantCreateTenantModal
                isOpen={isOpen}
                onClose={onClose}
                importDemoData={useDemoData}
              ></TenantCreateTenantModal>
            </Flex>
          </Flex>
          <Box marginLeft="auto">
            <Lottie animationData={noTenantLottie}></Lottie>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};
