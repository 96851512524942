type UiProp = {
  label: string;
  type: string;
  secret?: boolean;
  optional?: boolean;
  helpText?: string;
};

export type DestinationAccessibility = "public" | "internal";

export interface IDestination {
  accessibility: DestinationAccessibility;
  id: string;
  name: string;
  icon?: string;
  description: string;
  ui?: Record<string, UiProp>;
}

export interface IDestinationConfiguration {
  id: string;
  tenantId: string;
  alias: string;
  regions?: string[];
  environmentConfigurations?: IDestinationEnvironmentConfiguration[];
  app: IDestination;
}

export interface IDestinationEnvironmentConfiguration {
  id: string;
  environmentId: string;
  environmentClientId: string | null;
  regions?: string[];
  settings: IDestinationEnvironmentConfigurationAppUiSettings;
  enabled: boolean;
}

export interface IPageWithContinuationToken<T> {
  data: T[];
  continuationToken?: string;
  total?: number;
}

export interface UiPropWithValue extends UiProp {
  value: unknown;
}

export interface IDestinationEnvironmentConfigurationAppUiSettings {
  [key: string]: UiPropWithValue;
}

export interface IDestinationEnvironmentConfigurationAppSettings {
  [key: string]: unknown;
  enterspeedEnvironmentClientApiKey?: string;
}

export enum DestinationEnvironmentConfigurationModal {
  Edit = "Edit",
  Delete = "Delete",
  SetEnabled = "Enable",
  SetDisabled = "Disable"
}

export enum DestinationModal {
  Delete = "Delete"
}

export interface ICreateDestinationConfigurationDto {
  alias: string;
}

export interface ICreateDestinationConfigurationResponseDto {
  destinationConnectionGroupGuid: string;
  idValue: string;
}

export interface ICreateDestinationEnvironmentConfigurationDto {
  alias: string;
  environmentId: string;
  environmentClientId: string | null;
  regions?: string[];
  settings?: IDestinationEnvironmentConfigurationAppSettings;
}

export interface IEditDestinationEnvironmentConfigurationDto {
  alias: string;
  environmentId: string;
  environmentClientId: string | null;
  regions?: string[];
  settings?: IDestinationEnvironmentConfigurationAppSettings;
  enabled: boolean;
}

export interface ICreateDestinationEnvironmentConfigurationResponseDto {
  destinationConnectionGuid: string;
  idValue: string;
}

export interface IDestinationAliasesResponseDto {
  alias: string;
}
