import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HelpIcon from "../../../components/HelpIcon";
import { useCreateTenant, useDemoDataImport } from "../api/createTenant";

const TenantCreateTenantModal = ({
  importDemoData,
  onClose,
  isOpen
}: {
  ml?: number;
  buttonLabel?: string;
  importDemoData?: boolean;
  size?: ResponsiveValue<string>;
  variant?:
    | ResponsiveValue<
        | "link"
        | "outline"
        | "solid"
        | "ghost"
        | "unstyled"
        | "subtle"
        | "primary"
      >
    | undefined;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [name, setName] = useState<string>("");
  const [useDemoData, setUseDemoData] = useState<boolean>(
    importDemoData ?? false
  );
  const navigate = useNavigate();

  const toast = useToast();

  const createTenant = useCreateTenant();
  const importDemoDataMutation = useDemoDataImport();

  const clearState = () => {
    setName("");
    setUseDemoData(importDemoData ?? false);
  };

  const handleAddTenant = async () => {
    if (name) {
      onClose();
      const promise = createTenant.mutateAsync({
        tenant: {
          name: name
        }
      });
      const title = "Creating a new tenant";
      toast.promise(promise, {
        success: { title, description: "Tenant successfully created" },
        error: { title, description: "Error creating tenant" },
        loading: { title, description: "Creating tenant" }
      });
      await promise;
      if (useDemoData) {
        const importTitle = "Importing demo data";

        const importPromise = importDemoDataMutation.mutateAsync();
        toast.promise(importPromise, {
          success: {
            title: importTitle,
            description: "Imported demo data successfully"
          },
          error: {
            title: importTitle,
            description: "Error importing demo data"
          },
          loading: {
            title: importTitle,
            description: "Importing demo data for your new tenant"
          }
        });
      }
      navigate("/");
    }
  };

  useEffect(() => {
    if (importDemoData === null || importDemoData === undefined) {
      return;
    }
    setUseDemoData(importDemoData);
  }, [importDemoData]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={() => clearState()}
      >
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Create new Tenant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel fontSize="sm">Name</FormLabel>
              <Input
                autoFocus
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                bg={useColorModeValue("white", "gray.700")}
              />
            </FormControl>
            <Flex alignItems="center" mt="4" mb="4">
              <Checkbox
                onChange={(e) => setUseDemoData(e.target.checked)}
                colorScheme="brand"
                sx={{
                  ".chakra-checkbox__control:not([data-checked])": {
                    bg: "white"
                  }
                }}
                isChecked={useDemoData}
              >
                Pre-populate with demo data{" "}
              </Checkbox>
              <HelpIcon label="The demo data sets up a simple Enterspeed tenant based on a fictional Umbraco store. This lets you learn how Enterspeed works." />
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="gray"
              bg={useColorModeValue("gray.300", "gray.700")}
              onClick={() => {
                clearState();
                onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              isDisabled={!name}
              onClick={handleAddTenant}
              isLoading={createTenant.isPending}
            >
              Create tenant
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TenantCreateTenantModal;
