import { RefObject } from "react";
import { create } from "zustand";

interface IGeneralStore {
  fullscreen: boolean;
  setFullscreen: (value: boolean) => void;
  fullscreenElement: RefObject<HTMLDivElement> | null;
  setFullscreenElement(value: RefObject<HTMLDivElement>): void;
  sidePanelWidth: number;
  setSidePanelWidth(value: number): void;
  isSideMenuCollapsed: boolean;
  setIsSideMenuCollapsed(value: boolean): void;
}

export const useGeneralStore = create<IGeneralStore>((set) => ({
  fullscreen: false,
  setFullscreen: (value: boolean) => set(() => ({ fullscreen: value })),
  fullscreenElement: null,
  setFullscreenElement: (value) => set({ fullscreenElement: value }),
  sidePanelWidth: 0,
  setSidePanelWidth: (value: number) => set({ sidePanelWidth: value }),
  isSideMenuCollapsed: false,
  setIsSideMenuCollapsed: (value) => set({ isSideMenuCollapsed: value })
}));
