import { create } from "zustand";
import { IAuthState } from "../../auth/types";
import { ITenant, ITenantStore } from "../types";

const tenantIdKey = "enterspeed-tenant-id";
const tenantKey = "enterspeed-tenant";
const previousTenantIdKey = "enterspeed-previous-tenant-id";
export const useTenantStore = create<ITenantStore>((set) => {
  return {
    availableTenants: [],
    preSelectedTenant: null,
    activeTenant: {
      id: "",
      name: "",
      avoidRefresh: true,
      isUsingSourceGroups: true,
      isUsingSchemasBulkDeployment: true,
      javascriptSchemaFormatConfig: { enabled: false },
      plan: undefined
    },
    isImpersonating: false,
    setActiveTenant: (
      user: IAuthState,
      tenant: ITenant,
      avoidRefresh: boolean
    ) =>
      set((state) => {
        if (
          !user.isAdmin &&
          !state.availableTenants.find(
            (x) => x.id.idValue === tenant.id.idValue
          )
        ) {
          const [firstTenant] = state.availableTenants;
          localStorage.setItem(tenantIdKey, firstTenant.id.idValue);
          localStorage.setItem(tenantKey, JSON.stringify(firstTenant));
          return {
            activeTenant: {
              ...firstTenant,
              id: firstTenant.id.idValue,
              avoidRefresh
            },
            isImpersonating: false
          };
        }
        localStorage.setItem(previousTenantIdKey, state.activeTenant.id);
        localStorage.setItem(tenantIdKey, tenant.id.idValue);
        localStorage.setItem(tenantKey, JSON.stringify(tenant));
        return {
          activeTenant: { ...tenant, id: tenant.id.idValue, avoidRefresh },
          isImpersonating:
            user.isAdmin &&
            !state.availableTenants.find(
              (t) => t.id.idValue === tenant.id.idValue
            )
        };
      }),
    setAvailableTenants: (tenants: ITenant[]) =>
      set(() => ({
        availableTenants: tenants.reverse()
      })),
    addTenant: (tenant: ITenant) =>
      set((state) => {
        localStorage.setItem(previousTenantIdKey, state.activeTenant.id);
        localStorage.setItem(tenantIdKey, tenant.id.idValue);
        localStorage.setItem(tenantKey, JSON.stringify(tenant));
        return {
          availableTenants: [...state.availableTenants, tenant],
          activeTenant: {
            ...tenant,
            id: tenant.id.idValue,
            avoidRefresh: true
          },
          isImpersonating: false
        };
      }),
    removeTenant: (tenantId: string) =>
      set((state) => {
        if (tenantId === state.activeTenant.id) {
          const prevTenantId = localStorage.getItem(previousTenantIdKey);
          const prevTenant = state.availableTenants.find(
            (t) => t.id.idValue === prevTenantId
          );
          const currentTenants = state.availableTenants.filter(
            (t) => t.id.idValue !== tenantId
          );
          return {
            activeTenant: prevTenant
              ? { ...prevTenant, id: prevTenant.id.idValue, avoidRefresh: true }
              : {
                  ...currentTenants[0],
                  id: currentTenants[0].id.idValue,
                  avoidRefresh: true
                },
            availableTenants: currentTenants,
            isImpersonating: false
          };
        }
        return {
          availableTenants: state.availableTenants.filter(
            (t) => t.id.idValue !== tenantId
          ),
          activeTenant: state.activeTenant,
          isImpersonating: false
        };
      })
  };
});
