"use client";

import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  useColorModeValue
} from "@chakra-ui/react";
import { useUnconfiguredHostnames } from "../api/getUnconfiguredHostnames";

import { IUnconfiguredHostnamesModalProps } from "../types";

const UnconfiguredHostnamesModal = ({
  isOpen,
  onClose
}: IUnconfiguredHostnamesModalProps) => {
  const { data } = useUnconfiguredHostnames();

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Unconfigured hostnames ({data?.length})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data && data.length > 0 && (
              <UnorderedList>
                {data.map((unconfiguredHostName) => {
                  return (
                    <ListItem key={unconfiguredHostName} fontSize="lg" mb="2">
                      {unconfiguredHostName}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UnconfiguredHostnamesModal;
