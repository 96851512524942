import {
  CloseButton,
  Flex,
  Text,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useGeneralStore } from "../generalStore";

type SidePanelContextType = {
  isOpen: boolean;
  isClosing: boolean;
  setOpen: (isOpen: boolean) => void;
  setTitle: (title: string) => void;
  setContent: (content: React.ReactNode) => void;
  setCustomStartWidth: (customStartWidthPercentage: number) => void;
  setCustomMinWidth: (customMinWithPx: number) => void;
};

export const SidePanelContext = createContext<SidePanelContextType>({
  isOpen: false,
  isClosing: false,
  setOpen: () => {
    //
  },
  setTitle: () => {
    //
  },
  setContent: () => {
    //
  },
  setCustomStartWidth: () => {
    //
  },
  setCustomMinWidth: () => {
    //
  }
});

type SidePanelProviderProps = {
  children: React.ReactNode;
};

export const SidePanelProvider = ({ children }: SidePanelProviderProps) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setFullscreenElement, fullscreen, setSidePanelWidth } =
    useGeneralStore(
      useShallow(({ setFullscreenElement, fullscreen, setSidePanelWidth }) => ({
        setFullscreenElement,
        fullscreen,
        setSidePanelWidth
      }))
    );
  const [isSmallerThan1600] = useMediaQuery("(max-width: 1400px)");

  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [title, setTitle] = useState("");
  const [contentComponent, setContentComponent] =
    useState<React.ReactNode>(null);
  const [customStartWidth, setCustomStartWidth] = useState<number | undefined>(
    undefined
  );

  const [panelMinWidth, setPanelMinWidth] = useState<number>(600);

  const setOpen = useCallback((_isOpen: boolean) => {
    setIsClosing(!_isOpen);
    setIsOpen(_isOpen);
  }, []);

  const setContent = useCallback((content: React.ReactNode) => {
    setContentComponent(content);
  }, []);

  const setCustomMinWidth = useCallback((width: number) => {
    setPanelMinWidth(width);
  }, []);

  const panelStartWidth = 40;

  const [panelWidth, setPanelWidth] = useState(panelStartWidth);

  const [isHandlerDragging, setIsHandlerDragging] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const leftSideRef = useRef<HTMLDivElement>(null);
  const rightSideRef = useRef<HTMLDivElement>(null);

  const boxBackgroundColor = useColorModeValue("white", "gray.900");
  useEffect(() => {
    setPanelWidth(100 - (customStartWidth ?? panelStartWidth));
  }, [customStartWidth]);

  const calculateWidth = useCallback((newX: number) => {
    const left = leftSideRef.current;
    const container = containerRef.current;

    if (left && container) {
      const leftSize = left.clientWidth;
      const dx = newX - leftSize;

      const newLeftWidth = ((leftSize + dx) * 100) / container.clientWidth;
      const widthInBoundary = Math.max(newLeftWidth, 40);
      setPanelWidth(widthInBoundary);
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && isOpen && !fullscreen) {
        setOpen(false);
        setPanelWidth(100);
      }
    },
    [isOpen, setOpen, fullscreen]
  );

  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (e.target === handleRef.current) {
      setIsHandlerDragging(true);
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isHandlerDragging) {
        return;
      }

      calculateWidth(e.clientX);
    },
    [calculateWidth, isHandlerDragging]
  );

  const handleMouseUp = useCallback(() => {
    setIsHandlerDragging(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isOpen, handleKeyDown, handleMouseDown, handleMouseMove, handleMouseUp]);

  const [prevPath, setPrevPath] = useState(pathname);

  useEffect(() => {
    setFullscreenElement(containerRef);
  }, [containerRef, setFullscreenElement]);

  useEffect(() => {
    if (pathname !== prevPath) {
      setPrevPath(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (searchParams?.has("sidePanel")) {
      setOpen(true);

      setPanelWidth(100 - (customStartWidth ?? panelStartWidth));
    } else {
      setOpen(false);

      setPanelWidth(100);
    }
  }, [searchParams, setOpen]);

  useEffect(() => {
    if (!isOpen && searchParams?.has("sidePanel")) {
      const sp = new URLSearchParams(searchParams);
      sp.delete("sidePanel");
      setSearchParams(sp);
    }
  }, [isOpen]);

  useEffect(() => {
    setSidePanelWidth(panelWidth);
  }, [panelWidth, setPanelWidth]);

  // bg color is needed for full screen mode
  const bg = useColorModeValue("gray.100", "gray.800");

  return (
    <SidePanelContext.Provider
      value={{
        isOpen,
        setOpen,
        setTitle,
        setContent,
        isClosing,
        setCustomStartWidth,
        setCustomMinWidth
      }}
    >
      <Flex ref={containerRef} bg={bg}>
        <Flex
          ref={leftSideRef}
          direction={"column"}
          w={`${panelWidth}%`}
          maxH={"100vh"}
          minW={"600px"}
          hidden={isSmallerThan1600 && isOpen}
          overflowY={"auto"}
        >
          {children}
        </Flex>
        {isOpen && (
          <>
            <Flex
              align={"center"}
              ref={handleRef}
              cursor="ew-resize"
              w={"4px"}
              bg={!isHandlerDragging ? "gray.300" : "brand.700"}
              _hover={
                !isHandlerDragging
                  ? {
                      backgroundColor: "brand.100",
                      _after: {
                        backgroundColor: "brand.900",
                        transition: "background-color .2s"
                      }
                    }
                  : {}
              }
            ></Flex>
            <Flex
              ref={rightSideRef}
              flex="1"
              direction={"column"}
              bg={boxBackgroundColor}
              minWidth={isSmallerThan1600 ? "100%" : `${panelMinWidth}px`}
              maxH={"100vh"}
              minH={"100vh"}
              py="6"
              px="4"
              overflowY={"auto"}
            >
              <Flex direction={"row"} alignItems="center">
                <Flex justifyContent="center" grow="1">
                  <Text fontWeight="semibold" fontSize="xl">
                    {title}
                  </Text>
                </Flex>

                <CloseButton
                  size="lg"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Flex>
              <Flex
                direction={"column"}
                minWidth={isSmallerThan1600 ? "100%" : `${panelMinWidth}px`}
                overflowX={"auto"}
                grow={1}
              >
                {contentComponent}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </SidePanelContext.Provider>
  );
};
