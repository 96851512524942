import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { APPS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const destinationQueryFn = (continuationToken?: string) => {
  const destinationsService = container.resolve(DestinationsService);
  return destinationsService.getAllDestinations(continuationToken);
};

const useGetAllDestinations = () =>
  useInfiniteQuery({
    queryFn: ({ pageParam }: { pageParam: string | undefined }) =>
      destinationQueryFn(pageParam),
    queryKey: [APPS],
    getNextPageParam: (lastPage) => lastPage.continuationToken,
    initialPageParam: undefined
  });

export default useGetAllDestinations;
