import { RelativePeriod } from ".";

export interface IRelativePeriodOption {
  label: string;
  value: RelativePeriod;
  subtractMilliSeconds: number;
}

export const relativePeriodOptions: IRelativePeriodOption[] = [
  {
    label: "Last 15 minutes",
    value: "15m",
    subtractMilliSeconds: 15 * 60 * 1000
  },
  {
    label: "Last hour",
    value: "1h",
    subtractMilliSeconds: 60 * 60 * 1000
  },
  {
    label: "Last day",
    value: "1d",
    subtractMilliSeconds: 24 * 60 * 60 * 100
  },
  {
    label: "Last week",
    value: "1w",
    subtractMilliSeconds: 7 * 24 * 60 * 60 * 1000
  },
  {
    label: "Last 30 days",
    value: "30d",
    subtractMilliSeconds: 30 * 24 * 60 * 60 * 1000 - 60000
  }
];
