export type EditorTheme = "vs" | "vs-dark" | "hc-black";
export type EditorThemeSettings = EditorTheme | "follow-app";

export type EditorThemeOption = {
  label: string;
  value: EditorThemeSettings;
};

export const ThemeOptions: EditorThemeOption[] = [
  {
    label: "🔁 Follow theme",
    value: "follow-app"
  },
  { label: "☀️ Light", value: "vs" },
  { label: "🌙 Dark", value: "vs-dark" },
  { label: "👁️ High contrast", value: "hc-black" }
];
