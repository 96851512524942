import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import CreateManagementClientModal from "../../features/management-clients/components/CreateManagementClientModal";
import ManagementClientsTable from "../../features/management-clients/components/ManagementClientsTable";

const ManagementClientsPage = () => {
  const title = "Management clients";
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Management clients are used to interact with the Enterspeed Management API."
        headerRight={<CreateManagementClientModal />}
      >
        <ManagementClientsTable />
      </ContentBox>
    </>
  );
};

export default ManagementClientsPage;
