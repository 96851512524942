import {
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import { Select } from "chakra-react-select";
import {
  HTMLProps,
  Key,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import useReactSelectStyles from "../../styles/react-select-style";
import { DataTableAction, DataTableProps } from "../../types/dataTable";
import ISelectOption from "../../types/selectInput";
import EmptyState from "../EmptyState";

export function DataTable<
  TData extends {
    id: unknown;
  }
>({
  data,
  columns,
  actions,
  loading,
  enableRowSelection,
  enableRowSelectionAll,
  preselectedRowSelection,
  skeletonRows,
  skeletonColumns,
  selectedRowsCallback,
  onRowClickCallback,
  emptyState,
  noItemsText,
  highlightedRow,
  bulkOptions,
  bulkOptionsCallback,
  fontSize = "xs",
  enableSorting = true,
  setRowStyle,
  manualSorting,
  setSorting,
  sorting,
  displayStyle
}: DataTableProps<TData>) {
  const tableHeaderBorderColorMode = useColorModeValue("gray.200", "gray.700");
  const tableRowHoverColorMode = useColorModeValue("gray.50", "gray.700");
  const tableDataCellBorderColor = useColorModeValue("gray.200", "gray.700");
  const tableCellsPadding =
    displayStyle === "compact"
      ? {
          td: {
            px: "0.4em",
            py: "0.4em"
          },
          th: {
            px: "0.4em",
            py: "0.4em"
          }
        }
      : {
          td: {
            px: "0.5em",
            py: "1em"
          },
          th: {
            px: "0.5em",
            py: "1em"
          }
        };
  const [internalSorting, setInternalSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState(
    preselectedRowSelection ? preselectedRowSelection : {}
  );

  const reactSelectStyles = useReactSelectStyles();

  const [selectedBulkOption, setSelectedBulkOption] = useState<ISelectOption>();

  const getRowId = useCallback((row: TData) => {
    return row.id as string;
  }, []);

  const actionsColumn = createColumnHelper<TData>().display({
    id: "actions",

    cell: (props) =>
      actions ? (
        <ActionMenu actions={actions} rowData={props.row.original}></ActionMenu>
      ) : (
        <></>
      )
  });

  const ActionMenu = ({
    actions,
    rowData
  }: {
    actions: DataTableAction<unknown, TData>[];
    rowData: TData;
  }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
      <>
        <Flex justifyContent={"flex-end"}>
          <Menu>
            <Tooltip label="Settings" isOpen={tooltipOpen}>
              <MenuButton
                onClick={(event) => {
                  event.stopPropagation();
                  setTooltipOpen(false);
                }}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                display="block"
                ml="0"
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={FontAwesomeIcon} icon={faEllipsisVertical} />}
                variant="ghost"
              />
            </Tooltip>
            <MenuList>
              {actions?.map((action) => (
                <MenuItem
                  key={action.type as Key}
                  onClick={(event) => {
                    event.stopPropagation();
                    action.onClick(rowData);
                  }}
                  py="3"
                  icon={
                    action.icon && (
                      <Icon
                        as={FontAwesomeIcon}
                        icon={action.icon}
                        color="gray.500"
                      />
                    )
                  }
                  isDisabled={
                    action.isDisabled ? action.isDisabled(rowData) : undefined
                  }
                >
                  {action.type as ReactNode}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </>
    );
  };

  const table = useReactTable({
    columns: actions ? [...columns, actionsColumn] : columns,
    data,
    enableRowSelection: enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: (update) => {
      return enableRowSelection && update ? setRowSelection(update) : undefined;
    },
    onSortingChange: setSorting ? setSorting : setInternalSorting,
    getSortedRowModel: getSortedRowModel(),
    getRowId,
    enableSorting,
    state: enableRowSelection
      ? {
          sorting: sorting ? sorting : internalSorting,
          columnVisibility: {
            id: false
          },
          rowSelection
        }
      : {
          sorting: sorting ? sorting : internalSorting,
          columnVisibility: {
            id: false
          }
        },
    manualSorting,
    enableSortingRemoval: false
  });
  const highlightedColorMode = useColorModeValue("brand.100", "gray.700");

  useEffect(() => {
    if (!rowSelection || !selectedRowsCallback) {
      return;
    }
    selectedRowsCallback(table.getSelectedRowModel());
  }, [rowSelection, table, selectedRowsCallback]);

  if (loading) {
    return (
      <SkeletonTable
        columns={skeletonRows}
        rows={skeletonColumns}
        tableHeaderBorderColorMode={tableHeaderBorderColorMode}
        tableDataCellBorderColor={tableDataCellBorderColor}
      />
    );
  }

  if (data.length === 0) {
    return <EmptyState type={emptyState} customDescription={noItemsText} />;
  }

  return (
    <>
      {bulkOptions && (
        <Flex alignItems="center" mb="4" width={300}>
          <Box mr="4" color="gray" fontSize="sm">
            {table.getSelectedRowModel().rows.length} row
            {table.getSelectedRowModel().rows.length !== 1 && "s"} selected
          </Box>
          <Select
            size="sm"
            useBasicStyles
            colorScheme="blue"
            chakraStyles={reactSelectStyles}
            value={selectedBulkOption}
            onChange={(value) =>
              setSelectedBulkOption(
                value as SetStateAction<ISelectOption | undefined>
              )
            }
            options={bulkOptions}
            placeholder="Select"
          />
          <Button
            ml="4"
            colorScheme="gray"
            pl="5"
            pr="5"
            isDisabled={
              Object.keys(rowSelection).length === 0 || !selectedBulkOption
            }
            onClick={() => {
              const selectedRows = table
                .getSelectedRowModel()
                .rows.map((row) => row.original);

              if (bulkOptionsCallback && selectedBulkOption) {
                bulkOptionsCallback(selectedBulkOption.value, selectedRows);
              }
            }}
          >
            Apply
          </Button>
        </Flex>
      )}
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {enableRowSelection && (
                <Th
                  pl="0"
                  pr="0"
                  width="24px"
                  borderColor={tableHeaderBorderColorMode}
                >
                  {enableRowSelectionAll && (
                    <IndeterminateCheckbox
                      {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler()
                      }}
                    />
                  )}
                </Th>
              )}
              {headerGroup.headers.map((header) => {
                const meta = header.column.columnDef.meta;
                return (
                  <Th
                    px={tableCellsPadding.th.px}
                    py={tableCellsPadding.th.py}
                    _first={{
                      pl: !enableRowSelection && "0"
                    }}
                    borderColor={tableHeaderBorderColorMode}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    fontSize={fontSize}
                    cursor={
                      header.column.columnDef.enableSorting
                        ? "pointer"
                        : undefined
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {header.column.getIsSorted() ? (
                      <chakra.span pl="4">
                        {header.column.getIsSorted() === "desc" ? (
                          <Icon as={FontAwesomeIcon} icon={faChevronDown} />
                        ) : (
                          <Icon as={FontAwesomeIcon} icon={faChevronUp} />
                        )}
                      </chakra.span>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr
                cursor={
                  onRowClickCallback && highlightedRow !== row.id
                    ? "pointer"
                    : undefined
                }
                key={row.id}
                bg={
                  highlightedRow === row.id ? highlightedColorMode : undefined
                }
                style={
                  typeof setRowStyle === "function" && setRowStyle
                    ? setRowStyle(row)
                    : {}
                }
                sx={{
                  ":last-of-type": {
                    td: {
                      borderBottom: "none"
                    }
                  },
                  _hover: {
                    bg:
                      onRowClickCallback &&
                      highlightedRow !== row.id &&
                      tableRowHoverColorMode
                  }
                }}
                onClick={
                  onRowClickCallback && highlightedRow !== row.id
                    ? () => onRowClickCallback(row.original) as unknown
                    : undefined
                }
              >
                {enableRowSelection && (
                  <Td
                    pl="0"
                    pr="0"
                    width="24px"
                    borderColor={tableDataCellBorderColor}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IndeterminateCheckbox
                      {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler()
                      }}
                    />
                  </Td>
                )}
                {row.getVisibleCells().map((cell) => {
                  const meta = cell.column.columnDef.meta;

                  return (
                    <Td
                      width={meta?.width}
                      borderColor={tableDataCellBorderColor}
                      _first={{
                        pl: !enableRowSelection && "0"
                      }}
                      px={tableCellsPadding.td.px}
                      py={tableCellsPadding.td.py}
                      _last={{
                        pr: "0"
                      }}
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      fontSize={fontSize}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

const SkeletonTable = ({
  columns = 4,
  rows = 2,
  tableHeaderBorderColorMode,
  tableDataCellBorderColor
}: {
  rows?: number;
  columns?: number;
  tableHeaderBorderColorMode?: string;
  tableDataCellBorderColor?: string;
}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          {[...Array<unknown>(columns)].map((_, i) => {
            return (
              <Td
                pl="0"
                key={`table-skeleton-column-${i}`}
                borderColor={tableHeaderBorderColorMode}
              >
                <Skeleton height="20px" />
              </Td>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {[...Array<unknown>(rows)].map((_, i) => {
          return (
            <Tr
              key={`table-skeleton-row-${i}`}
              sx={{
                ":last-of-type": {
                  td: {
                    borderBottom: "none"
                  }
                }
              }}
            >
              {[...Array<unknown>(columns)].map((_, i) => {
                return (
                  <Td
                    pl="0"
                    key={`table-skeleton-column-${i}`}
                    borderColor={tableDataCellBorderColor}
                  >
                    <Skeleton height="20px" />
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Flex
      sx={{
        input: {
          width: "1.15rem",
          height: "1.15rem",
          cursor: "pointer"
        }
      }}
    >
      <input type="checkbox" ref={ref} {...rest} />
    </Flex>
  );
}
