import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import AddEntitiesModal from "../features/source-entities/components/AddEntitiesModal";
import SourceEntitiesTable from "../features/source-entities/components/SourceEntitiesTable";
import UnconfiguredHostnames from "../features/source-entities/components/UnconfiguredHostnames";

const SourceEntitiesPage = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const title = "Source entities";
  const flags = useFlags();
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Source entities are all the raw data that has been ingested into Enterspeed from your sources."
        headerRight={
          <Box>
            <Button variant="subtle" onClick={onOpen}>
              Add Entities
            </Button>
            <AddEntitiesModal
              onClose={onClose}
              isOpen={isOpen}
            ></AddEntitiesModal>
          </Box>
        }
      >
        {flags.unconfiguredHostnames && <UnconfiguredHostnames />}
        <SourceEntitiesTable />
      </ContentBox>
    </>
  );
};

export default SourceEntitiesPage;
