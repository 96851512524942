import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { ICreateDestinationConfigurationDto, IDestination } from "../types";

const CreateDestinationConfigurationModal = ({
  isOpen,
  onClose,
  onSubmit,
  destination
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    destinationConfiguration: ICreateDestinationConfigurationDto
  ) => void;
  destination: IDestination;
}) => {
  const [alias, setAlias] = useState<string>("");

  const modalBg = useColorModeValue("gray.100", "gray.800");
  const inputBgColorMode = useColorModeValue("white", "gray.700");

  useEffect(() => {
    if (isOpen) {
      return;
    }
    setAlias("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay></ModalOverlay>
      <ModalContent bg={modalBg}>
        <ModalCloseButton
          onClick={() => {
            onClose();
          }}
        ></ModalCloseButton>
        <ModalHeader>
          <Flex direction="row" gap={"2em"} flex={1}>
            <Box>
              <Image
                boxSize={"90px"}
                objectFit="cover"
                src={destination.icon}
                fallbackSrc="https://via.placeholder.com/150"
              ></Image>
            </Box>
            <Flex direction={"column"} gap={"1em"}>
              <Heading>{`Create a new ${destination.name} destination`}</Heading>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text>
            Type an alias for your destination. The alias is used in the schemas
            and is irreversible after creation.
          </Text>
          <Form>
            <FormControl>
              <FormLabel pt="4">Alias</FormLabel>
              <Input
                value={alias}
                data-1p-ignore
                bg={inputBgColorMode}
                onBlur={(e) => setAlias(e.target.value.trim())}
                onChange={(e) => setAlias(e.target.value)}
                autoFocus
              ></Input>
            </FormControl>
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              isDisabled={!alias}
              onClick={() => {
                onClose();
                if (!alias) {
                  return;
                }
                onSubmit({ alias });
              }}
            >
              Create
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateDestinationConfigurationModal;
