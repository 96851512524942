export const isDevEnv = () => {
  return process.env.NODE_ENV === "development";
};

export const isProdEnv = () => process.env.NODE_ENV === "production";

export const env = (): "development" | "production" | undefined => {
  if (process.env.NODE_ENV === "development") {
    return process.env.NODE_ENV;
  }
  if (process.env.NODE_ENV === "production") {
    return process.env.NODE_ENV;
  }
  return undefined;
};
