import {
  CreateToastFnReturn,
  useToast,
  UseToastOptions
} from "@chakra-ui/toast";
import { AxiosError } from "axios";
import { capitalize } from "lodash";
import { defaultToast } from "../constants/toast";
import { IApiErrorResponse } from "../types/errors";

type PromiseToastOptions = Omit<UseToastOptions, "status">;

type ToasterSubscribeOptions<T> = {
  titles?: string[];
  type?: string;
  actionNames?: [string, string];
  description?: string;
  successFn?: (data: T) => PromiseToastOptions;
  errorFn?: (error: Error) => Omit<UseToastOptions, "status">;
  loadingOptions?: UseToastOptions;
};

class Toaster {
  constructor(private readonly toast: CreateToastFnReturn) {}
  private setupPromiseSubscriptionSuccess<T>({
    successFn,
    titles,
    actionNames,
    description,
    type
  }: ToasterSubscribeOptions<T>) {
    const [successAction] = actionNames ?? [];
    const [successTitle] = titles ?? [];
    return successFn
      ? successFn
      : {
          title: successTitle
            ? successTitle
            : successAction && type
            ? `Successfully ${successAction} ${type}`
            : "Success",
          description,
          ...defaultToast
        };
  }

  private setupPromiseSubscriptionLoading<T>({
    loadingOptions,
    titles,
    actionNames,
    type,
    description
  }: ToasterSubscribeOptions<T>) {
    const [, loadingTitle] = titles ?? [];
    const [, loadingAction] = actionNames ?? [];

    return (
      loadingOptions ?? {
        title: loadingTitle
          ? loadingTitle
          : loadingAction && type
          ? `${capitalize(loadingAction)} ${type}`
          : "Loading",
        description,
        ...defaultToast
      }
    );
  }
  private setupPromiseSubscriptionError<T>({
    errorFn,
    titles,
    actionNames,
    type
  }: ToasterSubscribeOptions<T>) {
    const [, loadingAction] = actionNames ?? [];
    const [, , errorTitle] = titles ?? [];

    return errorFn
      ? errorFn
      : this.defaultErrorFn(errorTitle, loadingAction, type);
  }

  private defaultErrorFn(
    errorTitle: string,
    loadingAction: string | undefined,
    type: string | undefined
  ) {
    return (error: Error) => {
      if (error instanceof AxiosError<IApiErrorResponse>) {
        const { response } = error;
        const data = response?.data as IApiErrorResponse;
        return {
          title: errorTitle
            ? errorTitle
            : loadingAction && type
            ? `Error(s) occurred ${loadingAction} ${type}`
            : `Errors(s) occurred: ${error.message} `,
          description: data.detail,
          ...defaultToast
        };
      }
      return {
        title: errorTitle ?? `Error(s) occurred`,
        description: error.message,
        ...defaultToast
      };
    };
  }

  subscribe<T>(promise: Promise<T>, options: ToasterSubscribeOptions<T>) {
    this.toast.promise(promise, {
      success: this.setupPromiseSubscriptionSuccess<T>(options),
      loading: this.setupPromiseSubscriptionLoading(options),
      error: this.setupPromiseSubscriptionError(options)
    });
    return promise;
  }
}

export const useCustomToast = () => {
  const toast = useToast();
  return new Toaster(toast);
};
