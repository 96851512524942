import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import useActiveNavigation from "../../helpers/getActiveNavigation";
import useAdminFeatures from "../../helpers/useAdminFeatures";
import { HeaderNavigationItem } from "../../types/navigation";

const navItems: HeaderNavigationItem[] = [
  {
    name: "Home",
    href: "/",
    type: "homeNavigation",
    roles: []
  },
  {
    name: "Versions",
    href: "/versions",
    type: "versionsNavigation",
    roles: []
  },
  {
    name: "Settings",
    href: "/settings/data-sources",
    type: "settingsNavigation",
    roles: []
  },
  {
    name: "Admin",
    href: "/admin",
    type: "adminNavigation",
    roles: ["admin"]
  }
];

const Navigation = () => {
  const [isLargerThan48Em] = useMediaQuery("(min-width: 48em)", {
    ssr: true,
    fallback: true
  });
  const adminFeaturesEnabled = useAdminFeatures();
  const navigationItems = navItems.filter(
    (item) =>
      !item.roles.includes("admin") ||
      (item.roles.includes("admin") && adminFeaturesEnabled)
  );

  return isLargerThan48Em ? (
    <DesktopNavigation navItems={navigationItems} />
  ) : (
    <MobileNavigation navItems={navigationItems} />
  );
};

const MobileNavigation = ({
  navItems
}: {
  navItems: HeaderNavigationItem[];
}) => {
  const activeNavigation = useActiveNavigation();

  return (
    <Menu>
      <MenuButton
        variant="ghost"
        as={IconButton}
        icon={<Icon as={FontAwesomeIcon} icon={faBars} size="lg" />}
      />
      <MenuList>
        {navItems.map((item) => {
          const isActive = item.type === activeNavigation;

          return (
            <MenuItem
              as={NavLink}
              key={item.name}
              to={item.href}
              fontWeight="semibold"
              pointerEvents={isActive ? "none" : "auto"}
              color={isActive ? "brand.900" : "gray.500"}
              p="3"
            >
              {item.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

const DesktopNavigation = ({
  navItems
}: {
  navItems: HeaderNavigationItem[];
}) => {
  const colorMode = useColorModeValue("brand.900", "brand.200");
  const activeNavigation = useActiveNavigation();

  return (
    <HStack spacing="4">
      {navItems.map((item) => {
        const isActive = item.type === activeNavigation;

        return (
          <Box
            as={NavLink}
            key={item.name}
            to={item.href}
            fontWeight="semibold"
            color={isActive ? colorMode : "gray.500"}
            borderBottom="3px solid transparent"
            borderColor={isActive ? colorMode : "transparent"}
            _hover={{
              color: colorMode,
              borderColor: colorMode
            }}
          >
            <Box py="12px">{item.name}</Box>
          </Box>
        );
      })}
    </HStack>
  );
};

export default Navigation;
