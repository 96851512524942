import { useMutation } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TenantService } from "../tenants.service";

const deleteTenantFn = () => {
  const tenantService = container.resolve(TenantService);

  return tenantService.deleteActiveTenant();
};

const useDeleteActiveTenant = () => {
  return useMutation({ mutationFn: deleteTenantFn });
};

export default useDeleteActiveTenant;
