import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IBulkDeployResponse, ISchemaRelease } from "./types";

@singleton()
export class DeploymentService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async getDeploymentByEnvironment(environmentId: string) {
    const { data } = await this.managementClient.get<IBulkDeployResponse>(
      `/api/v1/tenant/environments/${environmentId}/deployments`
    );

    return data;
  }

  public async createRelease(environmentId: string, schemas: ISchemaRelease[]) {
    const { data } = await this.managementClient.post(
      "/api/v1/tenant/releases",
      {
        environmentId,
        schemas
      }
    );
    return data;
  }
}
