import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import DevEnvBadge from "./components/DevEnvBadge";
import Footer from "./components/Footer";
import FullScreen from "./components/FullScreen/FullScreen";
import Header from "./components/header/Header";
import MetaTitle from "./components/MetaTitle";
import SideMenu from "./components/SideMenu";
import { SidePanelProvider } from "./context/SidePanelContext";
import { useAuthStore } from "./features/auth/store";
import DeprecatedTenantBlocker from "./features/tenants/components/DeprecatedTenantBlocker";
import { useTenantStore } from "./features/tenants/store";
import { useGeneralStore } from "./generalStore";
import MixpanelProvider from "./mixpanel/MixPanelProvider";

const Layout = () => {
  const { isFullscreen } = useGeneralStore(
    useShallow(({ fullscreen }) => ({
      isFullscreen: fullscreen
    }))
  );

  const { isValid } = useAuthStore();
  const { availableTenants, activeTenant } = useTenantStore();

  return (
    <>
      <MetaTitle></MetaTitle>
      <MixpanelProvider>
        <SidePanelProvider>
          {!isFullscreen && (
            <Header
              enableNavigation={!!availableTenants.length}
              enableCommandbar={!!availableTenants.length && !!activeTenant.id}
              enableDeploy={!!availableTenants.length && !!activeTenant.id}
            ></Header>
          )}

          <Flex
            flexDirection={{
              base: "column",
              md: "row"
            }}
          >
            <DeprecatedTenantBlocker>
              {(!isFullscreen || isValid) && <SideMenu></SideMenu>}
              <Flex direction={"column"} grow={1}>
                <Flex minH={"2em"} justify={"flex-end"}>
                  <FullScreen></FullScreen>
                </Flex>
                <Flex mx={"2em"} grow={1}>
                  <Outlet />
                </Flex>
              </Flex>
            </DeprecatedTenantBlocker>
          </Flex>
          {!isFullscreen && <Footer></Footer>}
          <DevEnvBadge />
        </SidePanelProvider>
      </MixpanelProvider>
    </>
  );
};

export default Layout;
