import { Axios } from "axios";
import { IApiResponse } from "../types/api";

export interface CustomParamsSerializer {
  (params: Record<string, unknown>, options?: ParamsSerializerOptions): string;
}

export interface ParamsSerializerOptions {
  serialize?: CustomParamsSerializer;
}

export interface IHttpOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: Record<string, any>;
  params?: Record<string, unknown>;
  paramsSerializer?: ParamsSerializerOptions | CustomParamsSerializer;
  signal?: AbortSignal;
}

export interface IHttpService {
  get<T>(url: string, options?: IHttpOptions): Promise<IApiResponse<T>>;
  post<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>>;
  put<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>>;
  patch<T, R = unknown>(
    url: string,
    data: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>>;

  delete<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>>;
  options<T>(url: string, options?: IHttpOptions): Promise<IApiResponse<T>>;
}

export class HttpService implements IHttpService {
  constructor(private readonly axios: Axios) {}

  get<T>(url: string, options?: IHttpOptions): Promise<IApiResponse<T>> {
    return this.axios.get<T>(url, options);
  }

  post<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>> {
    return this.axios.post<T>(url, data, options);
  }

  put<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>> {
    return this.axios.put<T>(url, data, options);
  }

  patch<T, R = unknown>(
    url: string,
    data: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>> {
    return this.axios.patch<T>(url, data, options);
  }

  delete<T, R = unknown>(
    url: string,
    data?: R,
    options?: IHttpOptions
  ): Promise<IApiResponse<T>> {
    return this.axios.delete<T>(url, { ...options, data });
  }

  options<T>(url: string, options?: IHttpOptions): Promise<IApiResponse<T>> {
    return this.axios.options<T>(url, options);
  }
}
