import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { IApiErrorResponse } from "../../../types/errors";
import { IRouteDeliveryResponse } from "../../tenants/types";
import { ViewService } from "../view.service";

export const getDeliveryContext = async (routeId?: string) => {
  if (!routeId) {
    return Promise.resolve(null);
  }
  const viewService = container.resolve(ViewService);
  return viewService.getDeliveryContext(routeId);
};

export const useDeliveryContext = ({
  routeIds
}: {
  routeIds: string[];
}): UseQueryResult<IRouteDeliveryResponse, AxiosError<IApiErrorResponse>>[] => {
  return useQueries({
    queries: routeIds.map((routeId) => ({
      queryKey: ["DELIVERY_CONTEXT", routeId],
      queryFn: () => getDeliveryContext(routeId),

      onError: (_: AxiosError<IApiErrorResponse>) => {
        // helping type system
      },
      retry: false
    }))
  });
};
