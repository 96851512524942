import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Switch,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import {
  faArrowRotateForward,
  faFileSlash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CellContext, createColumnHelper, Row } from "@tanstack/react-table";
import { Select } from "chakra-react-select";
import { ChangeEvent, useState } from "react";
import { DataTable } from "../../../components/table/DataTable";
import { IBulkDeploy } from "../types";
import { BulkDeploy } from "../types/BulkDeploy";
import { badgeColorMap } from "../util";

function getChanged(
  schemas: BulkDeploy[],
  info: CellContext<BulkDeploy, unknown>
) {
  return schemas.find((schema) => schema.id === info.row.original.id);
}

const BulkDeployTable = ({
  schemas,
  onChange
}: {
  schemas: BulkDeploy[];
  onChange: (schemas: IBulkDeploy[]) => void;
}) => {
  const [showUnchangedSchemas, setShowUnchangedSchemas] = useState(false);

  const unchangedIconColor = useColorModeValue("blue.500", "gray.500");

  const handleExcludeAllSchemas = (checked: boolean) => {
    schemas
      .filter((schema) => schema.exclude !== checked)
      .forEach((schema) => {
        schema.update({ action: "ToggleExclude", params: { value: checked } });
      });
    onChange(schemas);
  };

  const columnHelper = createColumnHelper<BulkDeploy>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box opacity={props.row.original.exclude ? 0.5 : 1} maxWidth="200px">
          <Box fontSize="sm">{props.row.original.name}</Box>
          <Box fontSize="xs" color="gray.500">
            {props.row.original.alias}
          </Box>
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("currentVersion", {
      cell: (info) => (
        <Box maxWidth="100px" opacity={info.row.original.exclude ? 0.5 : 1}>
          {!info.getValue() ? (
            "N/A"
          ) : (
            <>
              {info.getValue()}
              <Tooltip
                label={"Unpublish schema from environment"}
                placement={"top"}
              >
                <IconButton
                  aria-label="Unpublish schema from environment"
                  bg={"transparent"}
                  _hover={{ background: "transparent" }}
                  cursor={"pointer"}
                  icon={
                    <Icon
                      as={FontAwesomeIcon}
                      icon={faFileSlash}
                      size={"lg"}
                      color={"red.500"}
                    />
                  }
                  onClick={() => {
                    const changed = getChanged(schemas, info);
                    if (changed) {
                      changed.update({ action: "Unpublish" });
                      onChange(schemas);
                    }
                  }}
                ></IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
      header: "Current version"
    }),
    columnHelper.accessor("deployVersion", {
      cell: (props) => {
        return (
          <Box minWidth="90px">
            <Select
              size={"sm"}
              variant="unstyled"
              colorScheme="brand"
              placeholder="Select version"
              options={props.row.original.versionOptions}
              isDisabled={
                props.row.original.exclude ||
                props.row.original.state === "Unchanged"
              }
              onChange={(e) => {
                const changed = getChanged(schemas, props);
                if (changed && e) {
                  changed.update({
                    action: "setNewVersion",
                    params: { version: parseInt(e.value as string) }
                  });
                  onChange(schemas);
                }
              }}
              value={props.row.original.deployVersion}
            />
          </Box>
        );
      },
      header: "Version to deploy"
    }),
    columnHelper.accessor("state", {
      cell: (info) => {
        if (info.getValue() === "Unchanged") {
          return (
            <Box minW={"133px"} opacity={info.row.original.exclude ? 0.5 : 1}>
              <Tooltip
                label={"Republish schema to environment"}
                placement={"top"}
              >
                <Badge
                  p={"4px 8px;"}
                  colorScheme={badgeColorMap[info.getValue()]}
                  role="group"
                  aria-label="Republish schema to environment"
                  onClick={() => {
                    getChanged(schemas, info)?.update({
                      action: "Redeploy"
                    });
                    onChange(schemas);
                  }}
                  cursor={"pointer"}
                >
                  {info.getValue()}{" "}
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faArrowRotateForward}
                    size={"sm"}
                    mx={1}
                    color={unchangedIconColor}
                    transform={"rotate(0deg)"}
                    transitionDuration={"300ms"}
                    _groupHover={{
                      transform: "rotate(180deg)",
                      transitionDuration: "300ms"
                    }}
                  />
                </Badge>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Box minW={"133px"} opacity={info.row.original.exclude ? 0.5 : 1}>
              <Badge
                p={"4px 8px;"}
                colorScheme={badgeColorMap[info.getValue()]}
              >
                {info.getValue()}
              </Badge>
            </Box>
          );
        }
      },
      header: "State"
    }),
    columnHelper.accessor("exclude", {
      cell: (info) => {
        return (
          info.row.original.state !== "Unchanged" && (
            <Switch
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const changed = getChanged(schemas, info);
                if (changed) {
                  changed.update({
                    action: "ToggleExclude",
                    params: { value: e.target.checked }
                  });
                  onChange(schemas);
                }
              }}
              isChecked={info.getValue()}
            />
          )
        );
      },
      header: () => (
        <Flex>
          Exclude
          <Switch
            ml="2"
            size="sm"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleExcludeAllSchemas(e.target.checked)
            }
            isChecked={schemas.every((schema) => schema.exclude)}
          />
        </Flex>
      )
    })
  ];

  const getRowStyles = (row: Row<BulkDeploy>) => {
    const styles: {
      background?: string;
      opacity?: number;
      display?: "none" | "block";
    } = {};
    if (!showUnchangedSchemas && row.original.state === "Unchanged") {
      styles.display = "none";
    }

    return styles;
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={schemas}
        enableSorting={false}
        setRowStyle={getRowStyles}
      />

      <Flex mt="8" justifyContent="center">
        <Button
          variant="outline"
          colorScheme="black"
          onClick={() => {
            setShowUnchangedSchemas(!showUnchangedSchemas);
          }}
        >
          {showUnchangedSchemas
            ? "Hide unchanged schemas"
            : "Show unchanged schemas"}
        </Button>
      </Flex>
    </>
  );
};

export default BulkDeployTable;
