import { ObjectResult } from "../../../../types/codeEditor";
import {
  IEvaluatedSchemaMetadata,
  ISchemaVersionFormatEvaluationTenantConfig,
  SchemaFormat,
  SchemaType
} from "../../types";

export interface ISchemaVisitor {
  canVisit(
    type: SchemaType,
    format: SchemaFormat,
    tenantConfig: ISchemaVersionFormatEvaluationTenantConfig
  ): boolean;
  visit(code: string): ObjectResult<IEvaluatedSchemaMetadata>;
}

export abstract class BaseSchemaVisitor implements ISchemaVisitor {
  abstract get schemaTypes(): SchemaType[];
  abstract get schemaFormat(): SchemaFormat;

  abstract get usingSourceGroup(): boolean;

  canVisit(
    type: SchemaType,
    schemaFormat: SchemaFormat,
    tenantConfig: ISchemaVersionFormatEvaluationTenantConfig
  ): boolean {
    return (
      this.schemaTypes.includes(type) &&
      this.schemaFormat === schemaFormat &&
      tenantConfig.isUsingSourceGroups === this.usingSourceGroup
    );
  }

  abstract visit(code: string): ObjectResult<IEvaluatedSchemaMetadata>;
}
