import { singleton } from "tsyringe";
import { ISchemaVersionFormat, SchemaFormat } from "../types";

@singleton()
export class JsonSchemaVersionFormat implements ISchemaVersionFormat {
  canHandle(schemaFormat: SchemaFormat): boolean {
    return schemaFormat === "json";
  }

  preProcessValue(schemaVersionData: string): string {
    return JSON.stringify(JSON.parse(schemaVersionData), null, "\t");
  }
  postProcessValue(schemaVersionData: string): unknown {
    return JSON.parse(schemaVersionData);
  }
}
