import mixpanel, { Callback, Dict, RequestOptions } from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { APP_VERSION } from "../constants/app";
import { useAuthStore } from "../features/auth/store";
import { useEnvironments } from "../features/environments/api/getEnvironments";
import { useSettings } from "../features/settings/api/getSettings";
import { useTenantStore } from "../features/tenants/store";

let isMixPanelInitialized = false;

const token = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

type DefaultProps = {
  appVersion: string;
  platformVersion?: string;
  tenant: string;
  tenantId: string;
  majorVersion: number;
};

const useMixPanel = () => {
  const { data: settings } = useSettings();

  const user = useAuthStore(({ user }) => user);
  const { activeTenant, availableTenants } = useTenantStore(
    useShallow(({ activeTenant, availableTenants }) => ({
      activeTenant,
      availableTenants
    }))
  );
  const { data: environments } = useEnvironments();

  const majorVersion = parseInt(APP_VERSION.split(".")[0]);

  const [defaultProps, setDefaultProps] = useState<DefaultProps>({
    tenant: activeTenant.name,
    tenantId: activeTenant.id,
    appVersion: APP_VERSION,
    majorVersion
  });

  function init() {
    if (!isMixPanelInitialized && token && user?.id) {
      mixpanel.init(token);

      mixpanel.identify(user.id);
      if (user.firstName && user.lastName) {
        mixpanel.people.set_once({
          $name: `${user.firstName} ${user.lastName}`
        });
      }
      if (user.roles?.includes("admin")) {
        mixpanel.people.set_once({
          $avatar:
            "https://www.enterspeed.com/images/essential/favicon/apple-touch-icon.png",
          "Enterspeed admin": true
        });
      }
      mixpanel.track("Opened Enterspeed", defaultProps);

      isMixPanelInitialized = true;
    }
  }

  init();

  useEffect(() => {
    if (!isMixPanelInitialized) {
      return;
    }
    setDefaultProps((prev) => ({
      ...prev,
      tenant: activeTenant.name,
      tenantId: activeTenant.id,
      platformVersion: settings?.version
    }));
  }, [settings, activeTenant]);

  useEffect(() => {
    if (!isMixPanelInitialized) {
      return;
    }

    mixpanel.people.set({
      "Current Tenant": activeTenant.name,
      "Number of tenants": Object.keys(availableTenants).length
    });
  }, [environments, activeTenant, availableTenants]);

  return {
    user,
    track: (
      event_name: string,
      properties?: Dict | undefined,
      optionsOrCallback?: Callback | RequestOptions | undefined,
      callback?: Callback | undefined
    ): void => {
      mixpanel.track(
        event_name,
        {
          ...properties,
          ...defaultProps,
          platformVersion: settings?.version
        },
        optionsOrCallback,
        callback
      );
    },
    reset: () => {
      mixpanel.reset();
    },
    people: mixpanel.people,
    trackPageView: (properties: Dict | undefined) => {
      if (!isMixPanelInitialized) {
        return;
      }

      mixpanel.track_pageview({ ...defaultProps, ...properties });
    }
  };
};
export default useMixPanel;
