import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SOURCE_GROUPS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SourceGroupService } from "../source-groups.service";
import { ISourceGroupsEditWithSources, ISourceGroupsResponse } from "../types";

const mutationFn = ({
  payload,
  dataSourceBeingEdited
}: {
  payload: ISourceGroupsEditWithSources;
  dataSourceBeingEdited: ISourceGroupsResponse;
}) => {
  const sourceGroupService = container.resolve(SourceGroupService);
  return sourceGroupService.editSourceGroup(payload, dataSourceBeingEdited);
};

export const useEditSourceGroup = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SOURCE_GROUPS] });
      toast({
        title: "Source group updated successfully",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error updating source group",
        status: "error",
        ...defaultToast
      });
    }
  });
};
