import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { ReactNode, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ContentBox from "../../../components/ContentBox";
import NumberCircle from "../../../components/NumberCircle";
import { DataTable } from "../../../components/table/DataTable";
import { DataTableAction } from "../../../types/dataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { getAggregatedLogs } from "../../logs/api/getLogs";
import { createConditionId } from "../../logs/helpers/initQueryConditionFromSearchParams";
import {
  IAggregatedLogLines,
  IAggregatedLogsResponse,
  IConditionEqual
} from "../../logs/types";
import useTenantHealthStore from "./TenantHealthStore";

interface GroupedLogs {
  id: string;
  service: string;
  type: string;
  count: number;
}

enum TenantHealthTileActionOptions {
  OPEN_LOGS = "Go to logs"
}

const TenantHealthTile = ({
  removeTileMenu
}: {
  removeTileMenu?: ReactNode;
}) => {
  const navigate = useNavigate();

  const timeOptions: { label: string; value: number }[] = [
    { label: "15M", value: 0.25 },
    { label: "30M", value: 0.5 },
    { label: "1H", value: 1 },
    { label: "3H", value: 3 },
    { label: "6H", value: 6 },
    { label: "24H", value: 24 },
    { label: "48H", value: 48 }
  ];

  const { setTimeSetting, timeSetting } = useTenantHealthStore();

  const [grouped, setGrouped] = useState<GroupedLogs[]>([]);

  const refetchInterval = 5 * 60 * 1000;
  const hoursInMilliseconds = 1000 * 60 * 60;

  const now = Date.now();
  const to = new Date(now);

  const errorsLastXHoursQuery = useQuery({
    queryKey: ["ERRORS", timeSetting],
    queryFn: () =>
      getAggregatedLogs({
        aggregateFields: ["ServiceName", "TeaserMessageTemplate"],
        condition: {
          $type: "EQUAL",
          value: "Error",
          field: "Level"
        } as IConditionEqual,
        fromDate: new Date(
          now - hoursInMilliseconds * timeSetting
        ).toISOString(),
        toDate: to.toISOString()
      }),
    refetchInterval
  });

  const groupLogs = (aggregatedLogs: IAggregatedLogsResponse) => {
    const groupedLogs: GroupedLogs[] = [];

    Object.entries(aggregatedLogs?.results).forEach(
      ([serviceName, logsByServiceName]) => {
        const aggregatedLogLines = logsByServiceName as IAggregatedLogLines;

        Object.entries(aggregatedLogLines.results).forEach(
          ([teaserMessageTemplate, count]) => {
            groupedLogs.push({
              id: `${serviceName}-${teaserMessageTemplate}`,
              service: serviceName,
              type: teaserMessageTemplate,
              count: count
            });
          }
        );
      }
    );

    return groupedLogs.sort((a, b) => (a.count > b.count ? -1 : 1));
  };

  useEffect(() => {
    const errorsLastXHours = errorsLastXHoursQuery.data;

    if (!errorsLastXHours) {
      return;
    }

    setGrouped(groupLogs(errorsLastXHours));
  }, [errorsLastXHoursQuery.data]);

  const columnHelper = createColumnHelper<GroupedLogs>();

  const columns = [
    columnHelper.accessor("service", {
      cell: (info) => info.getValue(),
      header: "Service"
    }),
    columnHelper.accessor("type", {
      cell: (info) => (
        <Tooltip label={info.getValue()}>
          <Text noOfLines={2}>{info.getValue()}</Text>
        </Tooltip>
      ),
      header: "Error type"
    }),
    columnHelper.accessor("count", {
      cell: (info) => {
        return (
          <Flex>
            <NumberCircle number={info.getValue()} />
          </Flex>
        );
      },
      header: "Count"
    })
  ];

  const actions: DataTableAction<TenantHealthTileActionOptions, GroupedLogs>[] =
    [
      {
        type: TenantHealthTileActionOptions.OPEN_LOGS,
        onClick: (row: GroupedLogs) => {
          navigate({
            pathname: "/settings/logs",
            search: createSearchParams({
              condition: Buffer.from(
                JSON.stringify([
                  {
                    id: createConditionId(),
                    $type: "EQUAL",
                    field: "TeaserMessageTemplate",
                    value: row.type
                  } as IConditionEqual,
                  {
                    id: createConditionId(),
                    $type: "EQUAL",
                    field: "ServiceName",
                    value: row.service
                  } as IConditionEqual
                ]),
                "utf8"
              ).toString("base64"),
              from: new Date(
                now - hoursInMilliseconds * timeSetting
              ).toISOString(),
              to: to.toISOString()
            }).toString()
          });
        }
      }
    ];

  return (
    <Box height={"100%"}>
      <ContentBox
        childrenJustify="space-between"
        title={"Tenant health"}
        subTitle={
          <Flex gap={"0.25rem"} align={"center"}>
            Errors Last
            <ButtonGroup>
              <Menu>
                <MenuButton
                  as={Button}
                  p={"0.5rem"}
                  variant={"ghost"}
                  size={"sm"}
                  rightIcon={
                    <Icon as={FontAwesomeIcon} icon={faChevronDown}></Icon>
                  }
                >
                  {timeOptions.find((t) => t.value === timeSetting)?.label ??
                    timeOptions[0].label}
                </MenuButton>
                <MenuList>
                  {timeOptions.map(({ value, label }) => (
                    <MenuItem key={value} onClick={() => setTimeSetting(value)}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </ButtonGroup>
          </Flex>
        }
        fullHeight={true}
        headerRight={<Flex>{removeTileMenu}</Flex>}
      >
        <DataTable
          columns={columns}
          data={grouped ?? []}
          actions={actions}
          loading={errorsLastXHoursQuery.isLoading}
          emptyState={EmptyStateType.TENANT_HEALTH_NO_ERRORS}
        ></DataTable>
        {(errorsLastXHoursQuery.isLoading ||
          errorsLastXHoursQuery.isRefetching) && (
          <Progress
            size="xs"
            isIndeterminate={true}
            color={"brand.200"}
          ></Progress>
        )}
      </ContentBox>
    </Box>
  );
};

export default TenantHealthTile;
