import {
  ButtonGroup,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip
} from "@chakra-ui/react";
import {
  faChevronDown,
  faDisplay,
  faExpandWide,
  faLaptop,
  faMaximize,
  faMinimize
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useLayoutEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useGeneralStore } from "../../generalStore";
import { DetailedScreen } from "./types";
import { useScreens } from "./useScreens";

const FullScreen = () => {
  const flags = useFlags();

  const { enabled, options } = flags.fullscreen as {
    enabled: boolean;
    options: string[];
  };

  const screenDetails = useScreens();

  const { isFullscreen, setFullscreen, fullscreenElement } = useGeneralStore(
    useShallow(({ fullscreen, setFullscreen, fullscreenElement }) => ({
      isFullscreen: fullscreen,
      setFullscreen,
      fullscreenElement
    }))
  );
  const handleExpandedViewClick = async () => {
    if (!isFullscreen) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
    }
  };

  type FullScreenOptions = {
    screen?: DetailedScreen;
  };

  const onHandleFullscreenClick = async (
    fullscreenOptions?: FullScreenOptions
  ) => {
    if (document.fullscreenEnabled && !document.fullscreenElement) {
      setFullscreen(true);
      const curr = fullscreenElement?.current as {
        requestFullscreen: ({
          screen
        }: {
          screen?: DetailedScreen;
        }) => Promise<void> | undefined;
      };

      try {
        await curr.requestFullscreen({ screen: fullscreenOptions?.screen });
      } catch (error) {
        console.error(error);
      }
    } else {
      setFullscreen(false);
    }
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    return () => {
      if (document.onfullscreenchange) {
        document.onfullscreenchange = null;
      }
    };
  });

  const handleKeyDown = useCallback(() => {
    setFullscreen(false);
  }, [setFullscreen]);

  useEffect(() => {
    if (isFullscreen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullscreen, handleKeyDown]);

  const screenViewOptions = [
    {
      key: "full",
      component: () => (
        <MenuItem
          key={"full"}
          icon={<Icon as={FontAwesomeIcon} icon={faExpandWide}></Icon>}
          onClick={() => onHandleFullscreenClick()}
        >
          Fullscreen
        </MenuItem>
      )
    },
    {
      key: "screens",
      component: () => {
        if (screenDetails?.screens?.length) {
          return screenDetails.screens.map((s) => (
            <MenuItem
              key={s.label}
              onClick={() => onHandleFullscreenClick({ screen: s })}
              icon={
                <Icon
                  as={FontAwesomeIcon}
                  icon={s.isInternal ? faLaptop : faDisplay}
                ></Icon>
              }
            >
              {s.label}
            </MenuItem>
          ));
        }
        return <></>;
      }
    }
  ];

  return enabled ? (
    <ButtonGroup isAttached variant={"ghost"}>
      <Tooltip
        label={!isFullscreen ? "Enter expanded mode" : "Exit expanded mode"}
      >
        <IconButton
          size={"sm"}
          icon={
            <Icon
              as={FontAwesomeIcon}
              icon={!isFullscreen ? faMaximize : faMinimize}
            ></Icon>
          }
          onClick={handleExpandedViewClick}
          aria-label={
            !isFullscreen ? "Enter expanded mode" : "Exit expanded mode"
          }
        >
          Fullscreen
        </IconButton>
      </Tooltip>
      {!!options.length && (
        <Menu>
          <Tooltip label="More view options">
            <MenuButton
              as={IconButton}
              icon={<Icon as={FontAwesomeIcon} icon={faChevronDown}></Icon>}
            ></MenuButton>
          </Tooltip>
          <MenuList>
            {screenViewOptions
              .filter(({ key }) => options.includes(key))
              .map(({ component }) => component())}
          </MenuList>
        </Menu>
      )}
    </ButtonGroup>
  ) : (
    <></>
  );
};

export default FullScreen;
