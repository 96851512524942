import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import BasicJsonCodeEditor from "../../components/CodeEditor/BasicJsonCodeEditor";
import ContentBox from "../../components/ContentBox";
import EmptyState from "../../components/EmptyState";
import TenantMismatch from "../../components/Errors/TenantMismatch";
import LoadingAnimation from "../../components/LoadingAnimation";
import MetaTitle from "../../components/MetaTitle";
import ReloadButton from "../../components/ReloadButton";
import { useEntity } from "../../features/source-entities/api/getEntity";
import formatDateTime from "../../helpers/formatDateTime";
import { IApiErrorResponse } from "../../types/errors";

const SourceEntitiesDetailsPage = () => {
  const title = "Source entity";
  const [isRaw, setIsRaw] = useState(false);
  const params = useParams<{ sourceId: string; originId: string }>();
  const sourceGuid = params?.sourceId;
  const originId = params?.originId;

  const toggleText = isRaw ? "Switch to Placeholders" : "Switch to Raw";

  const { data, refetch, isRefetching, isLoading, isError, error } = useEntity({
    sourceId: sourceGuid,
    originId,
    format: isRaw ? "raw" : "placeholders"
  });

  const json = JSON.stringify(data, null, 2);

  const toggleFormat = () => {
    setIsRaw(!isRaw);
  };

  const ErrorHandler = () => {
    if (error instanceof AxiosError) {
      const response = error.response as AxiosResponse<IApiErrorResponse>;
      if (response.data.code === "TenantMismatch") {
        return (
          <TenantMismatch
            type="source entity"
            tenantId={
              response.data.detail?.includes("gid://Tenant/")
                ? response.data.detail?.substring(
                    response.data.detail.indexOf("'"),
                    response.data.detail.lastIndexOf("'")
                  )
                : undefined
            }
          ></TenantMismatch>
        );
      }
      if (response.status === HttpStatusCode.NotFound) {
        return (
          <EmptyState
            customDescription={
              sourceGuid && originId
                ? `No source entities found with id: "gid://Source/${sourceGuid}/entity/${originId}"`
                : undefined
            }
          ></EmptyState>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      {isLoading ? (
        <LoadingAnimation></LoadingAnimation>
      ) : (
        <Flex direction={"column"} width={"100%"}>
          {isError ? (
            <ErrorHandler></ErrorHandler>
          ) : (
            <ContentBox
              title="Source entity"
              childrenMt={8}
              headerRight={
                <Flex>
                  <Button variant="subtle" onClick={toggleFormat} mr={2}>
                    {toggleText}
                  </Button>
                  <ReloadButton loading={isRefetching} onClick={refetch} />
                </Flex>
              }
            >
              <Box fontSize="sm" color="gray.400">
                <Text>
                  <Text as="strong">Updated: </Text>
                  {formatDateTime(data?.updatedAt)}
                </Text>
                <Text>
                  <Text as="strong">Source ID: </Text>

                  <Link
                    as={RouterLink}
                    color="brand.900"
                    to={`/source-entities?id=${sourceGuid ?? ""}`}
                  >
                    {data?.sourceId}
                  </Link>
                </Text>
                <Text>
                  <Text as="strong">Origin ID: </Text>
                  {data?.originId}
                </Text>
                <Text>
                  <Text as="strong">Type: </Text>
                  {data?.type}
                </Text>
                <Text>
                  <Text as="strong">URL: </Text>
                  {data?.url}
                </Text>
              </Box>
            </ContentBox>
          )}
          {data && (
            <Flex mt="8">
              <BasicJsonCodeEditor
                value={json}
                options={{
                  readOnly: true
                }}
              />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default SourceEntitiesDetailsPage;
