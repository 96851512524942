import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { container } from "tsyringe";
import { SCHEMA } from "../../../constants/queryKey";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";

export const getSchema = async ({
  guid,
  version,
  alias
}: {
  guid?: string;
  version?: number;
  alias?: string;
}) => {
  if (!guid && !alias) {
    return null;
  }
  const schemaService = container.resolve(SchemaService);

  if (guid) {
    if (version) {
      return schemaService.getSchemaVersion(guid, version);
    }
    return schemaService.getSchema(guid);
  }
  if (alias) {
    return schemaService.getSchemaByAlias(alias);
  }
};

export const useSchema = (
  schemaGuid?: string,
  version?: number,
  alias?: string
) => {
  return useQuery({
    queryKey: [SCHEMA, schemaGuid, version],
    queryFn: () => getSchema({ guid: schemaGuid, version, alias }),
    retry(failureCount, error) {
      if (error instanceof AxiosError) {
        const response = error.response as AxiosResponse<IApiErrorResponse>;
        if (
          response?.data.code === "TenantMismatch" ||
          response.status === HttpStatusCode.BadRequest ||
          response.status === HttpStatusCode.NotFound
        ) {
          return false;
        }
      }
      return failureCount < 3 ? true : false;
    }
  });
};
