import { Box } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import LoadingAnimation from "../../../components/LoadingAnimation";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { IUserTableItem } from "../types";
import { UserTenantsModal } from "./UserTenantsModal";

export const UserList = ({
  users,
  isLoading
}: {
  users: IUserTableItem[];
  isLoading: boolean;
}) => {
  const columnHelper = createColumnHelper<IUserTableItem>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.name}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: "Email"
    }),
    columnHelper.accessor("id", {
      cell: (info) => info.getValue(),
      header: "ID"
    }),
    columnHelper.accessor("tenantCount", {
      cell: (info) => (
        <UserTenantsModal
          count={info.getValue()}
          userName={info.row.original.name}
          tenants={info.row.original.tenantsMap}
        ></UserTenantsModal>
      ),
      header: "Number of tenants"
    })
  ];

  if (isLoading) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <DataTable loading={isLoading} columns={columns} data={users} />
    </>
  );
};
