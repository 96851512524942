import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Tooltip
} from "@chakra-ui/react";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { GroupBase, MultiValue, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import EmptyState from "../../../../components/EmptyState";
import EnterspeedIdentifiableSelect from "../../../../components/EnterspeedIdentifiableSelect";
import MultiDropdown from "../../../../components/MultiDropdown";
import Pagination from "../../../../components/Pagination";
import ReloadButton from "../../../../components/ReloadButton";
import { DataTable } from "../../../../components/table/DataTable";
import formatDateTime from "../../../../helpers/formatDateTime";
import {
  getSourceOption,
  getSourceOptions
} from "../../../../helpers/getSourceOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../../styles/react-enterspeed-identifiable-select-style";
import { ISortBy } from "../../../../types/api";
import { EmptyStateType } from "../../../../types/emptyState";
import IEnterspeedIdentifiableSelectOption from "../../../../types/identifiableSelectInput";
import ISelectOption from "../../../../types/selectInput";
import { useAllEntities } from "../../../source-entities/api/getAllEntities";
import { useSourceEntityTypes } from "../../../source-entities/api/getSourceEntityTypes";
import { ISourceEntity } from "../../../source-entities/types";
import { useSourceGroups } from "../../../source-groups/api/getSourceGroups";
import { SchemaType } from "../../types";
import { NO_TRIGGER_TEXT } from "../constants";

const SourceEntitiesTableTestPanel = ({
  selectedSourceEntityCallback,
  triggerTypes,
  schemaType
}: {
  selectedSourceEntityCallback: (
    sourceEntity: ISourceEntity,
    testOnMount?: boolean
  ) => void;
  triggerTypes: string[];
  schemaType: SchemaType;
}) => {
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>([]);

  const [selectedSource, setSelectedSource] =
    useState<IEnterspeedIdentifiableSelectOption>();

  const [selectedTypes, setSelectedTypes] = useState<ISelectOption[]>([]);

  const [typesOptions, setTypesOptions] = useState<ISelectOption[]>([]);

  const [filterTerm, setFilterTerm] = useState(searchParams?.get("term") ?? "");

  const { data: dataSources, isLoading: isLoadingDataSources } =
    useSourceGroups();

  const [debouncedFilterTerm] = useDebounce(filterTerm, 500);

  const { data: sourceEntityTypesData } = useSourceEntityTypes(
    selectedSource?.value as string
  );

  useEffect(() => {
    const sourceGuid = searchParams?.get("id");
    if (!dataSources || !sourceGuid) {
      return;
    }
    const dataSource = getSourceOption(dataSources, sourceGuid);
    setSelectedSource(dataSource);
  }, [dataSources, searchParams]);

  useEffect(() => {
    const term = searchParams?.get("term");
    if (term) {
      setFilterTerm(term);
    }
    const typeSearch = searchParams?.get("type");
    if (typeSearch) {
      const types = typeSearch.split(",");
      setSelectedTypes(
        types.map((type) => ({ label: type, value: type })) ?? []
      );
    } else {
      setSelectedTypes(
        triggerTypes.map((type) => ({ label: type, value: type }))
      );
      setUrlSearchParams(searchParams);
    }
  }, [sourceEntityTypesData, searchParams]);

  useEffect(() => {
    setTypesOptions(
      sourceEntityTypesData
        ?.map((type) => ({ label: type, value: type }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
    );
  }, [sourceEntityTypesData, triggerTypes]);

  const payload = {
    id: selectedSource?.value as string,
    term: debouncedFilterTerm,
    type: (selectedTypes.length !== 0 ? selectedTypes : typesOptions).map(
      ({ value }) => value as string
    ),
    sortBy: sorting.map(
      (s) =>
        ({
          propertyName: s.id,
          direction: s.desc ? "DESC" : "ASC"
        } as ISortBy)
    )
  };

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage
  } = useAllEntities(payload);

  const tableData =
    data?.pages.flatMap((page) =>
      page.results.map((item) => ({
        id: item.id,
        sourceName: item.sourceName,
        originId: item.originId,
        type: item.type,
        url: item.url,
        updatedAt: item.updatedAt,
        sourceId: selectedSource?.value as string
      }))
    ) ?? [];

  const totalEntitiesCount = data?.pages[0].total || 0;

  const sourceOptions = getSourceOptions(dataSources);
  const columnHelper = createColumnHelper<ISourceEntity>();

  const columns = [
    columnHelper.accessor("originId", {
      cell: (info) => (
        <Badge colorScheme="gray" fontWeight="semibold" textTransform="none">
          {info.getValue()}{" "}
        </Badge>
      ),
      header: "Origin Id".toUpperCase(),
      enableSorting: false
    }),
    columnHelper.accessor("type", {
      cell: (info) => info.getValue(),
      header: "Type".toUpperCase(),
      enableSorting: false
    }),
    columnHelper.accessor("url", {
      cell: (info) => info.getValue(),
      header: "Url".toUpperCase(),
      enableSorting: false
    }),
    columnHelper.accessor("updatedAt", {
      cell: (info) => formatDateTime(info.getValue()),
      header: "Updated At".toUpperCase(),
      enableSorting: true
    }),
    columnHelper.display({
      id: "test",
      cell: (props) => (
        <Flex justifyContent="flex-end">
          <Tooltip
            isDisabled={triggerTypes.includes(props.row.original.type)}
            label={NO_TRIGGER_TEXT[schemaType]}
            placement={"bottom-start"}
          >
            <Button
              colorScheme="gray"
              ml="auto"
              onClick={(event) => {
                event.stopPropagation();
                selectedSourceEntityCallback(props.row.original, true);
              }}
              isDisabled={!triggerTypes.includes(props.row.original.type)}
            >
              Test
            </Button>
          </Tooltip>
        </Flex>
      )
    })
  ];

  const handleRowClick = (row: ISourceEntity) => {
    selectedSourceEntityCallback(row);
  };

  const handleFilterSelect = (
    type: string,
    value: ISelectOption | ISelectOption[] | IEnterspeedIdentifiableSelectOption
  ) => {
    if (type === "source" && !Array.isArray(value)) {
      if (value) {
        setSelectedSource(value as IEnterspeedIdentifiableSelectOption);
        setFilterTerm("");
        const newSearch = new URLSearchParams(searchParams);
        newSearch.delete("term");
        newSearch.set("id", value.value as string);
        setUrlSearchParams(newSearch);
      }

      if (!value) {
        setSelectedSource(undefined);
        setFilterTerm("");
      }
    }

    if (type === "type" && Array.isArray(value)) {
      setSelectedTypes(value);
      const newSearch = new URLSearchParams(searchParams);
      newSearch.set("type", value?.map((x) => x.value).join(","));

      setUrlSearchParams(newSearch);

      if (!value || !value.length) {
        const newSearch = new URLSearchParams(searchParams);
        newSearch.delete("type");
        setUrlSearchParams(newSearch);
      }
    }
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 1) {
      setFilterTerm("");
      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.delete("term");

      setUrlSearchParams(newSearch);
    }
    if (e.target.value.length > 1) {
      setFilterTerm(e.target.value);
      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.set("term", e.target.value);

      setUrlSearchParams(newSearch);
    }
  };

  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();

  return (
    <>
      <Box>
        <SimpleGrid spacing={4} columns={3} mb="8">
          <FormControl>
            <FormLabel color="gray.500" fontSize="xs">
              Sources
            </FormLabel>
            <Select<
              IEnterspeedIdentifiableSelectOption,
              true,
              GroupBase<IEnterspeedIdentifiableSelectOption>
            >
              size="sm"
              useBasicStyles
              chakraStyles={reactSelectStyles}
              colorScheme="brand"
              isClearable
              placeholder="Select source"
              options={sourceOptions}
              components={new EnterspeedIdentifiableSelect()}
              value={selectedSource}
              onChange={(value) => {
                return handleFilterSelect(
                  "source",
                  value as unknown as IEnterspeedIdentifiableSelectOption
                );
              }}
            />

            {/* <Select
              size="sm"
              useBasicStyles
              chakraStyles={reactSelectStyles}
              colorScheme="brand"
              isClearable
              value={selectedSource}
              onChange={(value) => {
                return handleFilterSelect("source", value as ISelectOption);
              }}
              options={sourceOptions()}
              placeholder="Select source"
            /> */}
          </FormControl>
          {selectedSource && (
            <>
              <FormControl mr="4">
                <FormLabel color="gray.500" fontSize="xs">
                  Search
                </FormLabel>

                <Input
                  defaultValue={filterTerm}
                  onChange={(e) => handleFilterSearch(e)}
                  placeholder="ID or URL (type at least 2 characters)"
                  size="sm"
                />
              </FormControl>
              <Flex alignItems="flex-end">
                <FormControl>
                  <FormLabel color="gray.500" fontSize="xs">
                    Type
                  </FormLabel>
                  <MultiDropdown
                    options={typesOptions}
                    selectedOptions={selectedTypes}
                    onChange={(value: MultiValue<ISelectOption>) => {
                      return handleFilterSelect(
                        "type",
                        value as ISelectOption[]
                      );
                    }}
                    maxSelectedItemsToShow={2}
                    placeholder="Select"
                  />
                </FormControl>
                <Flex alignItems="flex-end" ml={4}>
                  <ReloadButton loading={isRefetching} onClick={refetch} />
                </Flex>
              </Flex>
            </>
          )}
        </SimpleGrid>
      </Box>
      {!dataSources?.length && isLoadingDataSources ? (
        <EmptyState type={EmptyStateType.NO_SOURCE_ENTITIES}>
          <NavLink to="/settings/data-sources">
            <Button>Go to Data sources</Button>
          </NavLink>
        </EmptyState>
      ) : !selectedSource ? (
        <EmptyState customDescription="Please select a source so we can find some entities for you." />
      ) : (
        <>
          <DataTable
            loading={isLoading || isRefetching}
            columns={columns}
            data={tableData}
            onRowClickCallback={(row: ISourceEntity) => handleRowClick(row)}
            fontSize="xs"
            sorting={sorting}
            setSorting={setSorting}
          />
          {tableData.length > 0 && (
            <Pagination
              disabled={
                tableData.length === totalEntitiesCount ||
                isLoading ||
                isFetchingNextPage
              }
              current={tableData.length}
              total={totalEntitiesCount}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default SourceEntitiesTableTestPanel;
