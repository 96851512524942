import { Button } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SidePanelContext } from "../../../context/SidePanelContext";

import { AxiosError } from "axios";
import {
  IApiErrorResponse,
  IFailedToCreateReleaseErrorResponse
} from "../../../types/errors";
import FailedToCreateReleaseModal from "../../schemas/components/FailedToCreateReleaseModal";
import { BulkDeployPanelContent } from "./BulkDeployPanelContent";

const BulkDeployPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [
    failedToCreateReleaseErrorResponse,
    setFailedToCreateReleaseErrorResponse
  ] = useState<IFailedToCreateReleaseErrorResponse | undefined>(undefined);

  const {
    setOpen,
    setTitle,
    setContent,
    setCustomStartWidth,
    setCustomMinWidth
  } = useContext(SidePanelContext);

  useEffect(() => {
    if (searchParams?.get("sidePanel") === "bulkDeploy") {
      handleOpenSidePanel();
    }
  }, [searchParams]);

  const onError = ({ response }: AxiosError<IApiErrorResponse>) => {
    const data = response?.data;
    if (data?.code === "FailedToCreateRelease") {
      const error = data as unknown as IFailedToCreateReleaseErrorResponse;
      setFailedToCreateReleaseErrorResponse(error);
    }
  };

  const handleOpenSidePanel = () => {
    setTitle("Deploy changes");
    setContent(
      <BulkDeployPanelContent
        onCancel={() => {
          setOpen(false);
        }}
        onError={onError}
      />
    );
    setOpen(true);
    setCustomMinWidth(500);
    setCustomStartWidth(40);
  };

  const onClick = () => {
    setTitle("Deploy changes");
    setContent(
      <BulkDeployPanelContent
        onCancel={() => {
          setOpen(false);
        }}
        onError={onError}
      />
    );
    setOpen(true);
    setCustomStartWidth(40);
    setCustomMinWidth(500);
    const sp = new URLSearchParams(searchParams ?? undefined);
    sp.set("sidePanel", "bulkDeploy");
    if (pathname) {
      navigate(`${pathname}?${sp.toString()}`);
    }
  };

  return (
    <>
      {failedToCreateReleaseErrorResponse && (
        <FailedToCreateReleaseModal
          isOpen={true}
          onClose={() => {
            setFailedToCreateReleaseErrorResponse(undefined);
            setOpen(false);
          }}
          response={failedToCreateReleaseErrorResponse}
        ></FailedToCreateReleaseModal>
      )}

      <Button variant="primary" onClick={onClick}>
        Deploy schemas
      </Button>
    </>
  );
};

export default BulkDeployPanel;
