import { Badge } from "@chakra-ui/react";

const EnvironmentBadge = ({
  name,
  marginRight
}: {
  name: string;
  marginRight?: number;
}) => {
  const getColorIndex = () => {
    if (name) {
      let number = 0;

      for (let i = 0; i < name.length; i++) {
        number = number + name.charCodeAt(i);
      }

      const charIndex = Math.floor(number / name.length);

      if (charIndex < 0) {
        return 0;
      }

      return charIndex % 14;
    } else {
      return 0;
    }
  };

  return (
    <Badge
      fontSize="xs"
      fontWeight="semibold"
      colorScheme="blue"
      borderRadius="md"
      bg={`${colors[getColorIndex()]}33`}
      color={colors[getColorIndex()]}
      marginRight={marginRight}
    >
      {name}
    </Badge>
  );
};

export default EnvironmentBadge;

const colors = [
  "#00B5D8",
  "#00A3C4",
  "#805AD5",
  "#6B46C1",
  "#D53F8C",
  "#B83280",
  "#38A169",
  "#2F855A",
  "#3182CE",
  "#2B6CB0",
  "#D69E2E",
  "#DD6B20",
  "#B7791F",
  "#C05621"
];
