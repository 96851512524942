import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANT_USERS } from "../../../constants/queryKey";
import { TenantService } from "../tenants.service";
import { ITenantGetUsersParams } from "../types";

export const getTenantUsers = async (
  params: ITenantGetUsersParams & { after?: string }
) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.getUserForTenant(params);
};

export const useTenantUsers = (params: ITenantGetUsersParams) => {
  return useInfiniteQuery({
    queryKey: [TENANT_USERS],
    queryFn: ({ pageParam = null }) =>
      getTenantUsers({ ...params, after: pageParam as string }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.pageInfo.hasNextPage) {
        return undefined;
      }

      return lastPage.pageInfo.endCursor;
    },
    initialPageParam: ""
  });
};
