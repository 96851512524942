import { Badge, Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import useProductUpdates from "../../features/product-updates/api/useProductUpdates";
import formatDate from "../../helpers/formatDate";
import ContentBox from "../ContentBox";
import LoadingAnimation from "../LoadingAnimation";

const NewsTile = ({ removeTileMenu }: { removeTileMenu?: ReactNode }) => {
  const { data, isLoading, isError } = useProductUpdates({ count: 5 });

  return (
    <ContentBox
      title="📰 Feature news"
      fullHeight={true}
      headerRight={removeTileMenu}
    >
      {isError ? (
        <Text fontSize={"sm"}>Error loading news...</Text>
      ) : isLoading ? (
        <LoadingAnimation></LoadingAnimation>
      ) : (
        <>
          {data?.map((news, i: number) => (
            <Link
              key={`news-${i}`}
              href={news.url}
              isExternal
              _hover={{ textDecoration: "none" }}
            >
              <Box mb="5">
                <Text fontSize="md" fontWeight="semibold">
                  {news.title}
                </Text>
                <Flex direction={"row"} gap={"0.5em"} justify={"flex-start"}>
                  <Text fontSize={"sm"}>{formatDate(news.publishedAt)}</Text>

                  <Flex gap={"0.5em"} height={"100%"}>
                    {news.tags.map((tag) => (
                      <Badge
                        key={`badge-${i}-${tag.name}`}
                        backgroundColor={`#${tag.color}20`}
                        color={`#${tag.color}`}
                        textTransform="capitalize"
                        mr={2}
                      >
                        {tag.name}
                      </Badge>
                    ))}
                  </Flex>
                </Flex>
                <Text>{news.summary}</Text>
              </Box>
            </Link>
          ))}
          <Link href="https://updates.enterspeed.com/" isExternal>
            <Button variant="subtle">Read all feature news</Button>
          </Link>
        </>
      )}
    </ContentBox>
  );
};

export default NewsTile;
