import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import {
  IDomainCreatePayload,
  IDomainEditPayload,
  IDomainResponse,
  IDomainResponseId
} from "./types";

@singleton()
export class DomainService {
  public constructor(private readonly managementClient: ManagementClient) {}

  public async getAllDomains() {
    const { data } = await this.managementClient.get<IDomainResponse[]>(
      "/api/v1/tenant/domains"
    );
    return data;
  }

  public async createDomain(payload: IDomainCreatePayload) {
    const { data } = await this.managementClient.post(
      "/api/v1/tenant/domains",
      payload
    );
    return data;
  }

  public async deleteDomain(domainId: string) {
    const { data } = await this.managementClient.delete(
      `/api/v1/tenant/domains/${domainId}`
    );
    return data;
  }

  public async editDomain(domainId: string, payload: IDomainEditPayload) {
    const { data } = await this.managementClient.put<IDomainResponseId>(
      `/api/v1/tenant/domains/${domainId}`,
      payload
    );
    return data;
  }
}
