import { Button, Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Field, FieldProps, Formik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { isRequired } from "../../../helpers/fieldIsRequired";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";
import { operators } from "../helpers/conditionOperators";
import {
  IAddOrEditFilterCondition,
  IConditionEqual,
  IConditionIn,
  IConditionNotEqual,
  IFilterableFieldOption,
  IFrontendCondition,
  IMultiValueFrontendCondition,
  IOperatorSelectOption,
  isFieldFrontendCondition,
  isSingleValueFrontendCondition,
  Operator
} from "../types";
import ConditionTypeSwitch from "./ConditionTypeSwitch";

interface IExpandedFrontendCondition extends IFrontendCondition {
  [x: string | number | symbol]: unknown;
}

const AddOrEditFilterCondition = (props: IAddOrEditFilterCondition) => {
  const reactSelectStyles = useReactSelectStyles();

  const filterableFields = (): ISelectOption[] => {
    return props.filterFields.sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  const [selectedField, setSelectedField] =
    useState<IFilterableFieldOption | null>();
  const [selectedType, setSelectedType] = useState<
    IOperatorSelectOption | null | undefined
  >();
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined | null
  >();

  useEffect(() => {
    if (props.condition) {
      // type
      const propsBasedTypeSelection = operators.find(
        (f) => f.value === props.condition?.$type
      );
      if (propsBasedTypeSelection) {
        setSelectedType(propsBasedTypeSelection);
      }

      // field
      const condition = props.condition;
      if (!isFieldFrontendCondition(condition)) {
        console.warn("Only field aware conditions are supported right now");
        return;
      }

      const propBasedFieldSelection = props.filterFields.find(
        (f) => f.value === condition.field
      );
      if (propBasedFieldSelection) {
        setSelectedField(propBasedFieldSelection);
      }

      // value
      setSelectedValue(
        isSingleValueFrontendCondition(condition)
          ? condition.value
          : (condition as IMultiValueFrontendCondition).values
      );
    }
  }, [props]);

  const initialValues: IExpandedFrontendCondition = {
    id: "",
    $type: selectedType?.value as Operator,
    field: selectedField?.value ?? "",
    value: selectedValue ?? null
  };

  const submit = () => {
    let condition: IFrontendCondition | undefined = undefined;
    switch (selectedType?.value) {
      case "EQUAL":
        condition = {
          $type: selectedType?.value,
          id: props.condition?.id,
          field: selectedField?.value,
          value: selectedValue
        } as IConditionEqual;
        break;
      case "NOT_EQUAL":
        condition = {
          $type: selectedType?.value,
          id: props.condition?.id,
          field: selectedField?.value,
          value: selectedValue
        } as IConditionNotEqual;
        break;
      case "IN":
        condition = {
          $type: selectedType?.value,
          id: props.condition?.id,
          field: selectedField?.value,
          values: selectedValue as string[]
        } as IConditionIn;
        break;
      default:
        throw new Error("Something is off");
    }

    props.onSuccess(condition);
  };

  const handleFieldSelection = (value: IFilterableFieldOption | null) => {
    setSelectedField(value);
    setSelectedValue(null);
    setDefaultValueForTypeSelection(selectedType?.isSingleValue);
  };

  const handleValueChange = (value: string | string[] | undefined | null) => {
    setSelectedValue(value);
  };

  const handleTypeSelection = (value: IOperatorSelectOption | null) => {
    const differsInValueType =
      selectedType?.isSingleValue !== value?.isSingleValue;
    setSelectedType(value);
    if (differsInValueType) {
      setDefaultValueForTypeSelection(value?.isSingleValue);
    }
  };

  const setDefaultValueForTypeSelection = (
    isSingleValue: boolean | undefined
  ) => {
    setSelectedValue(isSingleValue ? "" : [""]);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Flex direction={"column"} gap={4}>
              <Field
                name="field"
                validate={() => {
                  isRequired("Field is required");
                }}
              >
                {({ form }: FieldProps) => (
                  <FormControl isInvalid={!!errors.field}>
                    <Select
                      size="sm"
                      useBasicStyles
                      chakraStyles={reactSelectStyles}
                      value={selectedField}
                      placeholder="Select a field"
                      colorScheme="brand"
                      onChange={(selection) => {
                        const selected =
                          selection as IFilterableFieldOption | null;
                        handleFieldSelection(selected);
                        setFieldValue("field", selected?.value).catch(
                          console.error
                        );

                        return selected;
                      }}
                      options={filterableFields()}
                    />
                    <FormErrorMessage>
                      {form.errors?.field?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Select
                size="sm"
                useBasicStyles
                chakraStyles={reactSelectStyles}
                value={selectedType}
                placeholder="Select operator"
                colorScheme="brand"
                onChange={(selection) => {
                  const selected = selection as IOperatorSelectOption | null;
                  handleTypeSelection(selected);
                  setFieldValue("$type", selected?.value).catch(console.error);
                  return selection;
                }}
                options={operators}
              />

              {values.$type && selectedField && (
                <ConditionTypeSwitch
                  type={values.$type}
                  field={selectedField}
                  onChange={handleValueChange}
                  value={selectedValue as string | string[] | undefined}
                />
              )}
            </Flex>

            <Flex pt={8} justifyContent="flex-end">
              <Button
                colorScheme="gray"
                variant="ghost"
                mr={3}
                onClick={props.onClose}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                isDisabled={!!errors.field}
                type="submit"
              >
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddOrEditFilterCondition;
