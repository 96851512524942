import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { container } from "tsyringe";
import {
  DELIVERY_API_METRICS,
  DELIVERY_API_METRICS_RESPONSE_TIME,
  METRICS
} from "../../../constants/queryKey";
import { MetricsService } from "../metrics.service";

const queryFn = () => {
  const service = container.resolve(MetricsService);
  return service.getEnterspeedDeliveryApiResponseTimeMetrics();
};

const useEnterspeedDeliveryApiResponseTimeMetrics = () => {
  const flags = useFlags();

  const { enabled, features } = flags.dashboard as {
    enabled: boolean;
    features: string[];
  };

  return useQuery({
    queryKey: [
      METRICS,
      DELIVERY_API_METRICS,
      DELIVERY_API_METRICS_RESPONSE_TIME
    ],
    queryFn,
    enabled: enabled && features.includes("deliveryApiTile"),
    refetchInterval: 1000 * 60 * 20 // once every twenty minute
  });
};

export default useEnterspeedDeliveryApiResponseTimeMetrics;
