import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IEditNameModalProps } from "../../types/modal";

const EditNameModal = ({
  name,
  type,
  isOpen,
  onClose,
  onSubmit,
  onSuccess
}: IEditNameModalProps) => {
  const [newName, setNewName] = useState<string>(``);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const submit = async () => {
    if (!onSubmit) {
      return onSuccess ? onSuccess() : onClose();
    }

    setIsLoading(true);
    const submissionResult = await onSubmit({
      name: newName
    });
    setIsLoading(false);
    if (submissionResult.valid) {
      return onSuccess ? onSuccess() : onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>
              Edit {name} {type}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text pb="6">
                Enter a new name for the <Text as="b">{name}</Text> {type}.
              </Text>
              <FormLabel>Name</FormLabel>

              <Input
                autoFocus
                value={newName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewName(e.target.value)
                }
                bg={useColorModeValue("white", "gray.700")}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onClose}
                isDisabled={isLoading}
                colorScheme="gray"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => newName && submit()}
                ml={3}
                isDisabled={!newName || isLoading || newName === name}
                isLoading={isLoading}
              >
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default EditNameModal;
