import { Button, useDisclosure } from "@chakra-ui/react";
import { createElement, FunctionComponent } from "react";
import useMixPanel from "../../mixpanel/useMixPanel";

const GettingStartedItemActionModal = ({
  description,
  component
}: {
  description: string;
  component: FunctionComponent<{ isOpen: boolean; onClose: () => void }>;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const mixpanel = useMixPanel();
  return (
    <>
      <Button
        variant={"ghost"}
        onClick={() => {
          mixpanel.track("gettingStartedAction", {
            type: "modal",
            componentName: component.name
          });

          onOpen();
        }}
      >
        {description}
      </Button>
      {createElement(component, { isOpen, onClose })}
    </>
  );
};

export default GettingStartedItemActionModal;
