import { SchemaType } from "../types";

const getSchemaType = (type: SchemaType | null | undefined) => {
  switch (type) {
    case "normal":
      return "Full schema";
    case "partial":
      return "Partial schema";
    case "collection":
      return "Collection schema";
    default:
      return "";
  }
};

export default getSchemaType;
