import { useDisclosure, useToast } from "@chakra-ui/react";
import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { DomainHostnameTableModalType, IDomain, IHostname } from "../types";

import { AxiosError } from "axios";
import EmptyState from "../../../components/EmptyState";
import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import { DataTable } from "../../../components/table/DataTable";
import { defaultToast } from "../../../constants/toast";
import { DataTableAction } from "../../../types/dataTable";
import { IApiErrorResponse } from "../../../types/errors";
import { useEditHostname } from "../api/editHostname";
import { useDomains } from "../api/getDomains";

const DomainHostnamesTable = ({
  domain: { hostnames, name, domainGuid }
}: {
  domain: IDomain;
}) => {
  const { data } = useDomains();
  const [tableData, setTableData] = useState(
    hostnames.map((hostname) => ({
      id: hostname,
      name: hostname
    }))
  );

  const toast = useToast();

  useEffect(() => {
    if (!data) {
      return;
    }
    const domain = data.find((d) => d.id.domainGuid === domainGuid);
    if (!domain) {
      return;
    }
    setTableData(
      domain.hostnames.map((hostname) => ({
        id: hostname,
        name: hostname
      }))
    );
  }, [data]);

  const columnHelper = createColumnHelper<IHostname>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name"
    })
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<DomainHostnameTableModalType>();
  const [rowData, setRowData] = useState<IHostname | null>(null);

  const handleActionClick = (
    row: IHostname,
    type: DomainHostnameTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<DomainHostnameTableModalType, IHostname>[] = [
    {
      type: DomainHostnameTableModalType.EDIT,
      icon: faPencil,
      onClick: (row: IHostname) => {
        handleActionClick(row, DomainHostnameTableModalType.EDIT);
      }
    },
    {
      type: DomainHostnameTableModalType.DELETE,
      icon: faTrashCan,
      onClick: (row: IHostname) => {
        handleActionClick(row, DomainHostnameTableModalType.DELETE);
      }
    }
  ];

  const ActionModal = ({
    type,
    rowData: row,
    isOpen: actionIsOpen,
    onClose: actionOnClose
  }: {
    type: DomainHostnameTableModalType | undefined;
    rowData: IHostname;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const editHostname = useEditHostname();

    const handleEditHostname = async (
      hostname: IHostname,
      currentHostname: string
    ) => {
      const previousHostname = hostnames.find((h) => h === hostname.name);
      if (!previousHostname) {
        return;
      }
      const editedHostnames = hostnames.map((h) => {
        if (h === previousHostname) {
          h = currentHostname;
        }
        return h;
      });
      const editHostnamePromise = editHostname.mutateAsync({
        id: domainGuid,
        payload: {
          name,
          hostnames: editedHostnames
        }
      });
      toast.promise(editHostnamePromise, {
        success: {
          title: "Hostname edited",
          description: `Hostname edited from "${previousHostname}" to "${currentHostname}"`,
          ...defaultToast
        },
        loading: {
          title: "Editing hostname",
          description: `Hostname edited from "${previousHostname}" to "${currentHostname}"`,
          ...defaultToast
        },
        error: ({ response }: AxiosError<IApiErrorResponse>) => ({
          title: response?.data.detail ?? "Error editing hostname",
          status: "error",
          ...defaultToast
        })
      });

      await editHostnamePromise;
    };

    const handleDeleteHostname = async (hostname: IHostname) => {
      const editedHostnames = tableData
        .filter((item) => item.name !== hostname.name)
        .map((item) => item.name);

      const deleteHostnamePromise = editHostname.mutateAsync({
        id: domainGuid,
        payload: {
          name,
          hostnames: editedHostnames
        }
      });
      toast.promise(deleteHostnamePromise, {
        success: {
          title: "Hostname deleted",
          description: `Hostname "${hostname.name}" deleted`,
          ...defaultToast
        },
        loading: {
          title: "Deleted Hostname",
          description: `Deleting hostname "${hostname.name}"`,
          ...defaultToast
        },
        error: ({ response }: AxiosError<IApiErrorResponse>) => ({
          title: response?.data.detail ?? "Error editing hostname",
          status: "error",
          ...defaultToast
        })
      });
      await deleteHostnamePromise;
    };

    switch (type) {
      case DomainHostnameTableModalType.EDIT:
        return (
          <EditNameModal
            type="hostname"
            name={rowData?.name || ""}
            isOpen={actionIsOpen}
            onClose={actionOnClose}
            onSubmit={async ({ name: newName }) => {
              actionOnClose();
              await handleEditHostname(row, newName);
              return { valid: true };
            }}
          />
        );
      case DomainHostnameTableModalType.DELETE:
        return (
          <DeleteModal
            type="hostname"
            name={row?.name || ""}
            isOpen={actionIsOpen}
            onClose={actionOnClose}
            onSuccess={() => {
              actionOnClose();
              return handleDeleteHostname(row);
            }}
          />
        );

      default:
        return null;
    }
  };

  if (tableData.length) {
    return (
      <>
        <DataTable columns={columns} data={tableData} actions={actions} />
        {rowData && (
          <ActionModal
            type={modalType}
            rowData={rowData}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </>
    );
  }
  return <EmptyState />;
};

export default DomainHostnamesTable;
