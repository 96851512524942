import { useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANT_INVITES } from "../../../constants/queryKey";
import { TenantService } from "../tenants.service";
import { ITenantGetInvitesParams } from "../types";

type TenantInviteParamsWithCursor = ITenantGetInvitesParams & {
  after: string | null;
};

export const getTenantSentInvitations = async (
  params: TenantInviteParamsWithCursor
) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.getSentTenantInvitations(params);
};

export const useTenantSentInvitations = (params: ITenantGetInvitesParams) => {
  return useInfiniteQuery({
    queryKey: [TENANT_INVITES],
    queryFn: ({ pageParam = null }) =>
      getTenantSentInvitations({ ...params, after: pageParam as string }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.pageInfo.hasNextPage) {
        return undefined;
      }

      return lastPage.pageInfo.endCursor;
    },
    initialPageParam: ""
  });
};
