import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";

import { AxiosError } from "axios";
import { defaultToast } from "../../../constants/toast";
import { isRequired } from "../../../helpers/fieldIsRequired";
import { IApiErrorResponse } from "../../../types/errors";
import { useCreateEnvironment } from "../api/createEnvironment";

const CreateEnvironmentModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const createEnvironment = useCreateEnvironment();

  const inputBgColorMode = useColorModeValue("white", "gray.700");

  return (
    <>
      <Button variant="subtle" onClick={onOpen}>
        Create
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Create new Environment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">Give your environment a descriptive name.</Text>
            <Formik
              initialValues={{ name: "" }}
              validateOnBlur={false}
              onSubmit={({ name }) => {
                onClose();

                const createEnvironmentPromise = createEnvironment.mutateAsync({
                  name
                });
                toast.promise(createEnvironmentPromise, {
                  success: {
                    title: "Created environment successfully",
                    description: `Created environment "${name}`,
                    ...defaultToast
                  },
                  loading: {
                    title: "Creating environment",
                    description: `Creating environment "${name}`,
                    ...defaultToast
                  },
                  error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                    title:
                      response?.data.detail ?? "Error creating environment",
                    status: "error",
                    ...defaultToast
                  })
                });
              }}
            >
              <Form>
                <Field name="name" validate={isRequired("Name is required")}>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!form.errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        data-1p-ignore
                        bg={inputBgColorMode}
                        autoFocus
                        {...field}
                        placeholder="Your environment name"
                      />
                      <FormErrorMessage>
                        {form.errors.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex pt={8} justifyContent="flex-end">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    mr={3}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    isLoading={createEnvironment.isPending}
                    type="submit"
                  >
                    Create
                  </Button>
                </Flex>
              </Form>
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateEnvironmentModal;
