"use client";

import { Button } from "@chakra-ui/react";
import { IEditorAutoSaveBottomBarButtonProps } from "../types";

const EditorAutoSaveBottomBarButton = ({
  enabled,
  onToggle
}: IEditorAutoSaveBottomBarButtonProps) => {
  return (
    <Button
      variant="unstyled"
      size="xs"
      px="2"
      _hover={{ bg: "brand.200" }}
      fontWeight="normal"
      borderRadius="none"
      onClick={onToggle}
    >
      Autosave: {enabled ? "On" : "Off"}
    </Button>
  );
};

export default EditorAutoSaveBottomBarButton;
