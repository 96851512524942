import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { ENTITIES, UNCONFIGURED_HOSTNAMES } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SourceEntityService } from "../source-entity.service";
import { ISourceEntity } from "../types";

export const deleteEntitiesBulk = ({
  sourceId,
  entities
}: {
  sourceId: string;
  entities: ISourceEntity[];
}) => {
  const sourceEntityService = container.resolve(SourceEntityService);
  return sourceEntityService.deleteByIds(
    sourceId,
    entities.map(({ originId }) => originId)
  );
};

export const useDeleteEntitiesBulk = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: deleteEntitiesBulk,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ENTITIES]
      });
      await queryClient.invalidateQueries({
        queryKey: [UNCONFIGURED_HOSTNAMES]
      });
      toast({
        title: "Entities deleted successfully",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error deleting entities",
        status: "error",
        ...defaultToast
      });
    }
  });
};
