import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import VersionsOverview from "../features/versions/components/VersionsOverview";

const VersionsPage = () => {
  return (
    <>
      <MetaTitle title="Versions"></MetaTitle>
      <ContentBox
        title="Schema versions deployed to environments"
        help="See which schema versions are deployed to each environment. Click on a row to go to schema."
      >
        <VersionsOverview />
      </ContentBox>
    </>
  );
};

export default VersionsPage;
