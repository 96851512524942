import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENTS } from "../../../constants/queryKey";
import { EnvironmentService } from "../environment.service";
import { IEnvironmentCreatePayload } from "../types";

export const createEnvironment = (payload: IEnvironmentCreatePayload) => {
  const environmentService = container.resolve(EnvironmentService);
  return environmentService.createEnvironment(payload);
};

export const useCreateEnvironment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createEnvironment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ENVIRONMENTS] });
    }
  });
};
