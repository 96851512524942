export const JavascriptSchemaRootLevelSnippetCompletions = [
  {
    alias: "actions",
    snippet: `actions: function (sourceEntity, context) {
    // See documentation for actions here: https://docs.enterspeed.com/reference/js/actions
},`
  },
  {
    alias: "triggers",
    snippet: `triggers: function (context) {
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
},`
  },
  {
    alias: "routes",
    snippet: `routes: function (sourceEntity, context) {
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
},`
  },
  {
    alias: "properties",
    snippet: `properties: function (sourceEntity, context) {
    // See documentation for properties here: https://docs.enterspeed.com/reference/js/properties
    return {

    };
},`
  }
];
