import numeral from "numeral";
import { ReactNode } from "react";
import { useShallow } from "zustand/react/shallow";
import { SourceEntitiesMetricsDto } from "../../features/metrics/metrics.service";
import { useTenantStore } from "../../features/tenants/store";
import { ITenant } from "../../features/tenants/types";
import MetricsPlot from "./MetricsPlot";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";
import MetricsToolTip from "./MetricsToolTip";
import useGaugeData from "./useGaugeData";

const StatsDataStorageTile = ({
  sourceEntityMetrics,
  isLoading,
  tenant,
  removeTileMenu
}: {
  sourceEntityMetrics?: SourceEntitiesMetricsDto;
  isLoading?: boolean;
  tenant?: ITenant;
  removeTileMenu?: ReactNode;
}) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );

  const usedTenant = tenant ?? activeTenant;

  const usage = sourceEntityMetrics?.totalSizeInBytes ?? 0;
  const limit = usedTenant.plan?.sourceEntitiesByteLimit;

  const data = useGaugeData(
    { usage, limit },
    {
      valueFormat: ".2~s",
      exponentFormat: "SI",
      suffix: "B",
      tickFormatter: (value) => numeral(value).format("0b")
    }
  );

  const help = `The current data storage usage ${
    limit ? "and the limit based on the tenant's plan" : ""
  } (updated every ~1 minute)`;

  const tooltipMetrics = [
    { label: "Usage", value: usage, format: "0,0", suffix: "B" }
  ];

  if (limit) {
    tooltipMetrics.push({
      label: "Limit",
      value: limit,
      format: "0,0",
      suffix: "B"
    });
  }

  return (
    <MetricsTile
      title="Data storage"
      help={help}
      removeTileMenu={removeTileMenu}
    >
      <MetricsTileBody
        tooltipBody={
          <MetricsToolTip
            title="Total amount of data storage usage"
            metrics={tooltipMetrics}
          ></MetricsToolTip>
        }
      >
        <MetricsPlot data={data} isLoading={isLoading}></MetricsPlot>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default StatsDataStorageTile;
