import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SCHEMAS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";

export const deleteSchema = ({ schemaGuid }: { schemaGuid: string }) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.deleteSchema(schemaGuid);
};

export const useDeleteSchema = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: deleteSchema,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SCHEMAS] });
      toast({
        title: "Schema was deleted",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error deleting schema",
        status: "error",
        ...defaultToast
      });
    }
  });
};
