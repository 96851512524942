import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { EmptyStateType } from "../../../types/emptyState";
import { useEnvironments } from "../../environments/api/getEnvironments";
import useSchemasFromEnvironments from "../api/getSchemasFromEnvironment";
import { IDeployedSchemaVersions } from "../types";

const VersionsOverview = () => {
  const [hoverElement, setHoverElement] = useState<string | null>(null);

  const [isSmallerThan900] = useMediaQuery("(max-width: 899px)");

  const navigate = useNavigate();

  const adminFeaturesEnabled = useAdminFeatures();

  const cellHeight = adminFeaturesEnabled ? "100px" : "65px";

  const {
    data: environments,
    isLoading: isEnvironmentsIsLoading,
    refetch: refetchEnvironments,
    isRefetching: isRefetchingEnvironments
  } = useEnvironments();

  const schemasFromEnv = useSchemasFromEnvironments({
    environmentIds: environments?.map((env) => env.id.idValue)
  });

  const isRefetching =
    isRefetchingEnvironments &&
    schemasFromEnv.some((schemaQuery) => schemaQuery.isRefetching);

  const refetch = async () => {
    await refetchEnvironments();

    await Promise.all(
      schemasFromEnv
        .filter(
          (schemaQuery) => !schemaQuery.isRefetching && !schemaQuery.isLoading
        )
        .map((schemaQuery) => schemaQuery.refetch())
    );
  };

  const goToSchema = (schemaId: string) => {
    navigate(`/schemas/${schemaId}`);
  };

  const schemaVersionsHolder = schemasFromEnv
    .filter((query) => !!query.data)
    .flatMap((query) => query.data)
    .reduce((prev, current) => {
      if (!current) {
        return prev;
      }
      const env = environments?.find(
        (env) => env.id.idValue === current.environmentId
      );
      const schemaIndex = prev.findIndex((s) => s.id === current.id.idValue);
      if (schemaIndex === -1) {
        prev = [
          ...prev,
          {
            name: current.name,
            alias: current.alias,
            id: current.id.idValue,
            guid: current.id.mappingSchemaGuid,
            environments: env
              ? [
                  {
                    environment: env.name,
                    id: env.id.idValue,
                    schemaVersionDeployed: current.version
                  }
                ]
              : []
          }
        ];
        return prev;
      }
      prev[schemaIndex].environments = env
        ? [
            ...prev[schemaIndex].environments,
            {
              environment: env.name,
              id: env.id.idValue,
              schemaVersionDeployed: current.version
            }
          ]
        : prev[schemaIndex].environments;
      return prev;
    }, [] as IDeployedSchemaVersions[])
    .sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

  const HeaderActions = () => (
    <Flex justifyContent="flex-end" mb="4">
      <ReloadButton
        alignRight={false}
        loading={isRefetching}
        onClick={refetch}
      />
    </Flex>
  );

  const TableHeader = () => {
    const evenColors = useColorModeValue("gray.50", "gray.800");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <SimpleGrid
        gridTemplateColumns={`500px repeat(${
          environments?.length ?? 0
        }, 150px)`}
        fontSize="sm"
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Box
          borderBottom="1px solid"
          borderColor="gray.200"
          position="sticky"
          top={0}
          left={0}
          backgroundColor={useColorModeValue("white", "gray.900")}
          boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
        >
          {
            // Intentionally left blank
          }
        </Box>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-header`}
            borderBottom="1px solid"
            borderColor="gray.200"
            _even={{
              backgroundColor: evenColors
            }}
            backgroundColor={bgColors}
            fontWeight="bold"
            padding="3"
            height="100%"
            textAlign="center"
            wordBreak="break-word"
          >
            {environment.name}
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const SchemaNamesColumn = () => {
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <Box
        position="sticky"
        left={0}
        backgroundColor="white"
        boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
      >
        {schemaVersionsHolder.map((schema) => (
          <Box
            key={schema.id}
            height={cellHeight}
            p="3"
            borderBottom="1px solid"
            borderColor="gray.200"
            onMouseEnter={() => setHoverElement(schema.id)}
            onMouseLeave={() => setHoverElement(null)}
            onClick={() => goToSchema(schema.guid)}
            backgroundColor={
              hoverElement === schema.id ? hoverColors : bgColors
            }
            cursor={hoverElement === schema.id ? "pointer" : "initial"}
          >
            <Box
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.name}
            </Box>
            <Box
              fontStyle="italic"
              color="gray.500"
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.alias}
            </Box>
            <AdminTableRowId id={schema.id} />
          </Box>
        ))}
      </Box>
    );
  };

  const EnvironmentSchemaVersionsColumn = () => {
    const bgColors = useColorModeValue("gray.50", "gray.800");
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    return (
      <>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-versions`}
            _even={{
              backgroundColor: bgColors
            }}
            backgroundColor={"initial"}
          >
            {schemaVersionsHolder.map((schema) => {
              const schemaInEnvironment = schema.environments.find(
                (e) => e.id === environment.id.idValue
              );
              return (
                <Box
                  key={`${schema.id}-for-${environment.id.idValue}`}
                  height={cellHeight}
                  p="3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  fontSize="sm"
                  onMouseEnter={() => setHoverElement(schema.id)}
                  onMouseLeave={() => setHoverElement(null)}
                  onClick={() => goToSchema(schema.guid)}
                  backgroundColor={
                    hoverElement === schema.id ? hoverColors : "initial"
                  }
                  cursor={hoverElement === schema.id ? "pointer" : "initial"}
                >
                  {schemaInEnvironment ? (
                    <Tooltip
                      label={
                        <Box textAlign="center">
                          <Box mb="1">
                            <Text as="b">Environment</Text>
                          </Box>
                          <Box fontStyle="italic">{environment.name}</Box>
                        </Box>
                      }
                    >
                      <Box
                        width="32px"
                        height="32px"
                        color="white"
                        backgroundColor="gray.700"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        key={`${schema.id}-for-${environment.id.idValue}`}
                      >
                        {schemaInEnvironment.schemaVersionDeployed}
                      </Box>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
              );
            })}
          </Box>
        ))}
      </>
    );
  };

  const TableBody = () => (
    <SimpleGrid
      gridTemplateColumns={`500px repeat(${environments?.length ?? 0}, 150px)`}
    >
      <SchemaNamesColumn />
      <EnvironmentSchemaVersionsColumn />
    </SimpleGrid>
  );

  if (isSmallerThan900) {
    return <EmptyState type={EmptyStateType.VIEW_NOT_AVAILABLE_ON_MOBILE} />;
  }

  return (
    <>
      <HeaderActions />
      {isEnvironmentsIsLoading ? (
        <LoadingAnimation />
      ) : environments?.length === 0 ? (
        <EmptyState type={EmptyStateType.NO_ENVIRONMENTS_IN_VERSIONS} />
      ) : schemaVersionsHolder.length === 0 ? (
        <EmptyState type={EmptyStateType.NO_SCHEMAS_IN_VERSIONS} />
      ) : (
        <Flex flexDirection="column">
          <TableHeader />
          <TableBody />
        </Flex>
      )}
    </>
  );
};

export default VersionsOverview;
