import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
import {
  IConditionEqual,
  IFrontendCondition,
  SetConditionAction
} from "../types";

export const createConditionId = (): string => {
  return uuidv4().substring(0, 5);
};

export const parseQueryStringCondition = function (
  params: URLSearchParams
): IFrontendCondition | IFrontendCondition[] | null | undefined {
  // Read query params and set initial condition
  const conditionQueryParamBase64 = params.get("condition") ?? undefined;
  const conditionAsString = Buffer.from(
    conditionQueryParamBase64 ?? "",
    "base64"
  ).toString("utf8");
  try {
    const condition: IFrontendCondition[] | IFrontendCondition = JSON.parse(
      conditionAsString
    ) as IFrontendCondition[] | IFrontendCondition;
    return condition;
  } catch {
    /* empty */
    return null;
  }
};

export const searchParamsToFilterCondition = (
  searchParams: URLSearchParams
): SetConditionAction | undefined | null => {
  let action: SetConditionAction | undefined | null = null;

  if (searchParams.get("traceId")) {
    action = {
      type: "set_condition",
      value: {
        $type: "EQUAL",
        id: createConditionId(),
        value: searchParams.get("traceId"),
        field: "TraceId"
      } as IConditionEqual
    };
  }

  // try to re-map transaction id query param into query condition
  if (searchParams.get("transactionId")) {
    action = {
      type: "set_condition",
      value: {
        $type: "EQUAL",
        id: createConditionId(),
        value: searchParams.get("transactionId"),
        field: "TransactionId"
      } as IConditionEqual
    };
  }

  if (searchParams.get("sourceEntityId")) {
    action = {
      type: "set_condition",
      value: {
        $type: "EQUAL",
        id: createConditionId(),
        value: searchParams.get("sourceEntityId"),
        field: "SourceEntityId"
      } as IConditionEqual
    };
  }

  if (searchParams.get("environmentId")) {
    action = {
      type: "set_condition",
      value: {
        $type: "EQUAL",
        id: createConditionId(),
        value: searchParams.get("environmentId"),
        field: "EnvironmentId"
      } as IConditionEqual
    };
  }

  if (searchParams.get("teaserMessageTemplate")) {
    action = {
      type: "set_condition",
      value: {
        $type: "EQUAL",
        id: createConditionId(),
        value: searchParams.get("teaserMessageTemplate"),
        field: "TeaserMessageTemplate"
      } as IConditionEqual
    };
  }

  // parse condition query param
  const defaultValue = parseQueryStringCondition(searchParams);
  if (defaultValue) {
    action = { type: "set_condition", value: defaultValue };
  }

  return action;
};
