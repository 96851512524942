import { Select } from "chakra-react-select";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";

const LogLevelPicker = ({
  value,
  onChange
}: {
  value: string | null;
  onChange(value: string): void;
}) => {
  const reactSelectStyles = useReactSelectStyles();
  const levelsOptions: ISelectOption[] = [
    {
      label: "Information",
      value: "Information"
    },
    {
      label: "Warning",
      value: "Warning"
    },
    {
      label: "Error",
      value: "Error"
    }
  ];
  const selectedLogLevel = value
    ? levelsOptions.find((f) => f.value === value)
    : null;

  return (
    <Select
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select level"
      options={levelsOptions}
      value={selectedLogLevel}
      onChange={(value) => {
        const opt = value as ISelectOption;
        onChange(opt.value as string);
      }}
    />
  );
};

export default LogLevelPicker;
