import _ from "lodash";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { IAuthState } from "../types";

export const useAuthStore = create(
  persist<IAuthState>(
    (set, get) => ({
      adminFeaturesEnabled:
        localStorage.getItem("adminFeaturesEnabled") === "true" &&
        (get()?.isAdmin ?? false),
      user: undefined,
      links: undefined,
      tokens: {},
      isValid: false,
      isAdmin: false,
      addTenant: (tenantId: string) =>
        set((state) => ({
          user: {
            ...state.user,
            tenantIds: _.uniq([...(state.user?.tenantIds ?? []), tenantId])
          }
        })),
      removeTenant: (tenantId: string) =>
        set((state) => ({
          user: {
            ...state.user,
            tenantIds: state.user?.tenantIds.filter((t) => t !== tenantId) ?? []
          }
        })),
      register: (authState: Partial<IAuthState>) =>
        set((state) => ({
          ...state,
          ...authState
        })),
      toggleAdminFeatures: () =>
        set((state) => {
          if (!state.isAdmin) {
            return state;
          }
          localStorage.setItem(
            "adminFeaturesEnabled",
            String(!state.adminFeaturesEnabled)
          );
          return {
            ...state,
            adminFeaturesEnabled: !state.adminFeaturesEnabled
          };
        })
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
