import { useColorModeValue } from "@chakra-ui/react";
import { Data, PlotData } from "plotly.js";
import { getChakraColorAsHex } from "../../constants/colors";

type Options = {
  valueFormat?: string;
  exponentFormat?: "none" | "e" | "E" | "power" | "SI" | "B";
  suffix?: string;
  tickFormatter?: (value: number) => string;
};

const alertZone = [0.9, 1];
const overrunZone = [1, 1.05];

const useGaugeData = (
  {
    usage,
    limit,
    projected
  }: { usage: number; limit?: number; projected?: number },
  options?: Options
): Data[] => {
  const limitExceededColor = useColorModeValue("black", "white");
  const limitExceededBarColor = useColorModeValue(
    getChakraColorAsHex("red.600"),
    getChakraColorAsHex("red.600")
  );

  const orangeBarColor = useColorModeValue(
    getChakraColorAsHex("orange.400"),
    getChakraColorAsHex("orange.300")
  );
  const greenBarColor = useColorModeValue(
    getChakraColorAsHex("brand.500"),
    getChakraColorAsHex("brand.500")
  );
  const bgColor = useColorModeValue(
    getChakraColorAsHex("brand.50"),
    getChakraColorAsHex("gray.700")
  );
  const numberColor = useColorModeValue("black", "white");

  const projectionBarColor = useColorModeValue(
    getChakraColorAsHex("gray.400"),
    getChakraColorAsHex("gray.900")
  );

  const [lowerBoundAlert] = alertZone;

  const [, upperBoundOverrun] = overrunZone;

  const tickValues = getTickValues(usage, limit, projected);
  const projection: Data | undefined = projected
    ? {
        type: "indicator",
        mode: "gauge",
        value: projected,
        gauge: {
          bgcolor: bgColor,
          axis: {
            tickvals: [],
            range: [null, upperBoundOverrun * Math.max(limit ?? 0, projected)],
            tickformat: `.3~s`
          },

          bar: {
            color: `${projectionBarColor}23`,
            line: { color: projectionBarColor, width: 1 }
          }
        }
      }
    : undefined;

  const getBarColor = () => {
    if (!limit || (limit && usage < limit * lowerBoundAlert)) {
      return greenBarColor;
    }
    if (limit && usage < limit) {
      return orangeBarColor;
    }
    return limitExceededBarColor;
  };

  const data: Data[] = [];

  const usageData: Partial<PlotData> = {
    domain: { x: [0, 1], y: [0, 1] },
    value: usage,
    type: "indicator",
    mode: "gauge+number",
    number: {
      font: {
        color: limit && usage > limit ? limitExceededColor : numberColor
      },
      valueformat: options?.valueFormat,
      suffix: options?.suffix
    },
    gauge: {
      bgcolor: projected ? "transparent" : bgColor,
      bar: {
        color: getBarColor(),
        line: { color: getBarColor(), width: 1 }
      },

      axis: {
        range: [
          null,
          Math.max(
            (limit ?? 0) * upperBoundOverrun,
            limit || projected ? usage : usage * 1.3,
            (projected ?? 0) * upperBoundOverrun
          )
        ],
        tickvals: tickValues,

        tickformat: options?.valueFormat,
        exponentformat: options?.exponentFormat,
        ticksuffix: options?.suffix,
        ticktext: options?.tickFormatter
          ? tickValues.map(options.tickFormatter)
          : undefined
      },
      threshold: { value: limit, line: { width: 4, color: numberColor } }
    }
  };

  if (projection) {
    data.push(projection);
  }

  data.push(usageData);

  return data;
};

const getTickValues = (
  usage: number,
  limit?: number,
  projection?: number
): number[] => {
  const stepSize = 1;
  const nTicks = 1 / stepSize;
  const ticks = Array.from({ length: nTicks }, (_, k) => (k + 1) * stepSize);

  return ticks.map((x) => x * Math.max(limit ?? projection ?? 0, usage));
};

export default useGaugeData;
