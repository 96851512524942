import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminTableRowId from "../../../components/table/AdminTableRowId";

type Props = {
  type: "copy" | "link";
  label: string;
  value: string;
  id?: string;
  icon: IconDefinition;
  link?: string;
};

const InputActionBox = ({ label, value, id, icon, type, link }: Props) => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const copy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const inputType = {
    copy: {
      label: copied ? "Copied!" : "Copy",
      action: () => copy()
    },
    link: {
      label: "Go to",
      action: () => {
        if (link) {
          navigate(link);
        }
      }
    }
  };

  return (
    <Box>
      <Flex alignItems="center" mb="2">
        <Icon
          as={FontAwesomeIcon}
          icon={icon}
          color={useColorModeValue("gray.600", "gray.300")}
          size="lg"
        />
        <Text
          fontWeight="semibold"
          ml="2"
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize="sm"
        >
          {label}
        </Text>
      </Flex>

      <InputGroup>
        <Input
          pr="4.5rem"
          value={value}
          readOnly
          bg={useColorModeValue("white", "gray.700")}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={inputType[type].action}
            variant="subtle"
          >
            {inputType[type].label}
          </Button>
        </InputRightElement>
      </InputGroup>
      {id && (
        <Flex mt="2" alignItems="center">
          <AdminTableRowId id={id} />
        </Flex>
      )}
    </Box>
  );
};

export default InputActionBox;
