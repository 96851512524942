import { Button, Heading, Text, useDisclosure } from "@chakra-ui/react";
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentBox from "../../components/ContentBox";
import EmptyState from "../../components/EmptyState";
import LoadingAnimation from "../../components/LoadingAnimation";
import ConfirmModal from "../../components/modals/ConfirmModal";
import DeleteModal from "../../components/modals/DeleteModal";
import useCreateDestinationEnvironmentConfiguration from "../../features/destinations/api/useCreateDestinationConfigurationEnvironmentConfiguration";
import useDeleteDestinationEnvironmentConfiguration from "../../features/destinations/api/useDeleteDestinationConfigurationEnvironmentConfiguration";
import useDestination from "../../features/destinations/api/useDestination";
import useEditDestinationEnvironmentConfiguration from "../../features/destinations/api/useEditDestinationConfigurationEnvironmentConfiguration";
import DestinationConfigurationInfoBox from "../../features/destinations/components/DestinationConfigurationInfoBox";
import DestinationEnvironmentConfigurationCreateModal from "../../features/destinations/components/DestinationEnvironmentConfigurationCreateModal";
import DestinationEnvironmentConfigurationEditModal, {
  DestinationEnvironmentConfigurationEditModalData
} from "../../features/destinations/components/DestinationEnvironmentConfigurationEditModal";
import DestinationEnvironmentConfigurationTable from "../../features/destinations/components/DestinationEnvironmentConfigurationTable";
import DestinationItem from "../../features/destinations/components/DestinationItem";
import {
  DestinationEnvironmentConfigurationModal,
  ICreateDestinationEnvironmentConfigurationDto,
  IDestinationEnvironmentConfiguration,
  IEditDestinationEnvironmentConfigurationDto
} from "../../features/destinations/types";
import { useEnvironmentClients } from "../../features/environment-clients/api/getEnvironmentClients";
import { useEnvironments } from "../../features/environments/api/getEnvironments";
import useMixPanel from "../../mixpanel/useMixPanel";
import { DataTableAction } from "../../types/dataTable";

export const DestinationDetail = () => {
  const createModal = useDisclosure();
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();
  const confirmToggleStateModal = useDisclosure();

  const mixpanel = useMixPanel();

  const { alias } = useParams();
  const { data, isLoading, isSuccess } = useDestination({ alias });
  const { data: environments } = useEnvironments();
  const { data: environmentClients } = useEnvironmentClients();
  const createMutation = useCreateDestinationEnvironmentConfiguration();
  const editMutation = useEditDestinationEnvironmentConfiguration();
  const deleteMutation = useDeleteDestinationEnvironmentConfiguration();

  const [selectedConfig, setSelectedConfig] =
    useState<DestinationEnvironmentConfigurationEditModalData>();

  useEffect(() => {
    if (isSuccess && data) {
      mixpanel.track("Viewed destination", { app: data.app.name });
    }
  }, [isSuccess, data]);

  if (!alias) {
    return <></>;
  }

  if (isLoading) {
    return (
      <ContentBox>
        <LoadingAnimation></LoadingAnimation>
      </ContentBox>
    );
  }

  if (!data) {
    return <EmptyState></EmptyState>;
  }

  const actions: DataTableAction<
    DestinationEnvironmentConfigurationModal,
    IDestinationEnvironmentConfiguration
  >[] = [
    {
      type: DestinationEnvironmentConfigurationModal.Edit,
      icon: faPencil,
      onClick: (row) => {
        const environment = environments?.find(
          (e) => e.id.idValue === row.environmentId
        );
        if (!environment) {
          return;
        }
        setSelectedConfig({
          app: data.app,
          configuration: row,
          environment,
          environmentClientOptions:
            environmentClients?.filter(
              (f) => f.id.environmentGuid === environment.id.environmentGuid
            ) ?? []
        });
        editModal.onOpen();
      }
    },
    {
      type: DestinationEnvironmentConfigurationModal.Delete,
      icon: faTrashCan,
      onClick: (row) => {
        const environment = environments?.find(
          (e) => e.id.idValue === row.environmentId
        );
        if (!environment) {
          return;
        }
        setSelectedConfig({
          app: data.app,
          configuration: row,
          environment,
          environmentClientOptions: []
        });
        deleteModal.onOpen();
      }
    }
  ];

  const onToggleEnabled = (
    configuration: IDestinationEnvironmentConfiguration
  ) => {
    mixpanel.track("Toggle destination state", {
      app: data.app.name,
      enabled: configuration.enabled
    });
    const { enterspeedEnvironmentClientApiKey, ...rest } =
      configuration.settings;
    const reduced = Object.entries(rest).reduce((acc, [key, { value }]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, unknown>);
    confirmToggleStateModal.onClose();
    return editMutation.mutate({
      alias,
      ...configuration,
      settings: {
        enterspeedEnvironmentClientApiKey:
          enterspeedEnvironmentClientApiKey?.value as string,
        ...reduced
      },
      enabled: !configuration.enabled
    });
  };

  const onDelete = (configuration: IDestinationEnvironmentConfiguration) => {
    mixpanel.track("Delete destination environment configuration", {
      app: data.app.name
    });
    deleteModal.onClose();
    return deleteMutation.mutate({
      alias,
      ...configuration
    });
  };

  const onEdit = (
    edit: Omit<IEditDestinationEnvironmentConfigurationDto, "alias">
  ): void => {
    editModal.onClose();
    mixpanel.track("Edit destination environment configuration", {
      app: data.app.name
    });
    return editMutation.mutate({ alias, ...edit });
  };

  const onCreate = (
    create: Omit<ICreateDestinationEnvironmentConfigurationDto, "alias">
  ): void => {
    mixpanel.track("Create destination configuration", { app: data.app.name });
    return createMutation.mutate({ alias, ...create });
  };

  return (
    <ContentBox
      title={<DestinationItem destination={data.app}></DestinationItem>}
      headerRight={
        <Button onClick={createModal.onOpen} variant="subtle">
          Create new environment configuration
        </Button>
      }
    >
      <DestinationConfigurationInfoBox
        destinationConfiguration={data}
      ></DestinationConfigurationInfoBox>
      <Heading size={"sm"}>Environment Configurations</Heading>
      {data.environmentConfigurations && (
        <DestinationEnvironmentConfigurationTable
          destination={data.app}
          configuration={data.environmentConfigurations}
          environments={environments}
          environmentClients={environmentClients}
          actions={actions}
          onToggleEnabled={(row, state) => {
            const environment = environments?.find(
              (e) => e.id.idValue === row.environmentId
            );
            if (!environment) {
              return;
            }
            setSelectedConfig({
              app: data.app,
              configuration: row,
              environment,
              environmentClientOptions: []
            });
            console.log(state);
            confirmToggleStateModal.onOpen();
          }}
        ></DestinationEnvironmentConfigurationTable>
      )}
      {data.app && (
        <DestinationEnvironmentConfigurationCreateModal
          app={data.app}
          environmentOptions={
            environments?.filter(
              (e) =>
                !data.environmentConfigurations?.some(
                  (d) => d.environmentId === e.id.idValue
                )
            ) ?? []
          }
          environmentClientOptions={
            environmentClients?.filter(
              (e) =>
                !data.environmentConfigurations?.some((g) =>
                  g.environmentId.includes(e.id.environmentGuid)
                )
            ) ?? []
          }
          onSubmit={onCreate}
          isOpen={createModal.isOpen}
          onClose={createModal.onClose}
        ></DestinationEnvironmentConfigurationCreateModal>
      )}
      {selectedConfig && (
        <>
          <ConfirmModal
            title={`Are you sure you want to ${
              selectedConfig.configuration.enabled ? "disable" : "enable"
            } destination for environment ${selectedConfig.environment.name}?`}
            onClose={confirmToggleStateModal.onClose}
            isOpen={confirmToggleStateModal.isOpen}
            onSuccess={() => onToggleEnabled(selectedConfig.configuration)}
          >
            {selectedConfig.configuration.enabled ? (
              <Text>
                Disabling the environment for the destination may result in
                dropped messages
              </Text>
            ) : (
              <Text>
                Enabling the environment will start sending messages to the
                destination when views are updated for the environment
              </Text>
            )}
          </ConfirmModal>

          <DestinationEnvironmentConfigurationEditModal
            isOpen={editModal.isOpen}
            onClose={editModal.onClose}
            modalData={selectedConfig}
            onSubmit={onEdit}
          ></DestinationEnvironmentConfigurationEditModal>
          <DeleteModal
            name={`${data.app?.name ?? ""} configuration for environment ${
              selectedConfig.environment.name
            }`}
            type="environment configuration"
            customDescription="This will immediately cause all data for the environment configuration to be deleted and will stop your destination from receiving data from Enterspeed. Deletion cannot be undone, but you can recreate the environment configuration."
            isOpen={deleteModal.isOpen}
            onClose={deleteModal.onClose}
            onSuccess={() => onDelete(selectedConfig.configuration)}
          ></DeleteModal>
        </>
      )}
    </ContentBox>
  );
};

export default DestinationDetail;
