import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SOURCE_GROUPS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SourceGroupService } from "../source-groups.service";
import { ISourceEditPayload } from "../types";

const regenerateAccessKeyDataSourceFn = ({
  id,
  payload
}: {
  id: string;
  payload: ISourceEditPayload;
}) => {
  const sourceGroupService = container.resolve(SourceGroupService);
  return sourceGroupService.regenerateAccessKey(id, payload);
};

export const useRegenerateAccessKeyDataSource = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: regenerateAccessKeyDataSourceFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SOURCE_GROUPS] });
      toast({
        title: "API key was regenerated",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error regenerating API key",
        status: "error",
        ...defaultToast
      });
    }
  });
};
