"use client";

import { ICodeEditorCommonProps } from "../../types/codeEditor";
import BaseCodeEditor from "./BaseCodeEditor";

const BasicJsonCodeEditor = (props: ICodeEditorCommonProps) => {
  return (
    <>
      <BaseCodeEditor
        value={props.value}
        options={{
          ...props.options
        }}
        language="json"
        leftSideBottomBarElements={props.leftSideBottomBarElements}
        rightSideBottomBarElements={props.rightSideBottomBarElements}
        bottomBarActionButtons={props.bottomBarActionButtons}
        diffComparison={props.diffComparison}
        onValueChange={props.onValueChange}
        onEditorMount={props.onEditorMount}
      ></BaseCodeEditor>
    </>
  );
};

export default BasicJsonCodeEditor;
