import { Badge, Code, Flex } from "@chakra-ui/react";
import { ITenant } from "../../tenants/types";

const TenantDetails = ({ tenant }: { tenant: ITenant }) => {
  const { id, name: _name, ...tenantConfig } = tenant;

  return (
    <Flex direction={"column"} gap={"1em"}>
      <Badge color={"brand.200"} fontSize={"x-small"} id={id.idValue}>
        {id.idValue}
      </Badge>
      <Code colorScheme="blue" fontSize={"xx-small"}>
        <pre>{JSON.stringify(tenantConfig, null, 2)}</pre>
      </Code>
    </Flex>
  );
};

export default TenantDetails;
