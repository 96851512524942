import Lottie from "lottie-react";
import loadingAnimation from "./loading-dots.json";

const LoadingAnimation = ({ height }: { height?: number | string }) => {
  const lottieStyle = {
    height: height ?? 300
  };

  return <Lottie animationData={loadingAnimation} style={lottieStyle} loop />;
};

export default LoadingAnimation;
