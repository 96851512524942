import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  CloseButton,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  ToastId,
  Tooltip,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import {
  faCode,
  faFileArrowUp,
  faGearCode,
  faTimes,
  IconDefinition
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupBase, Select } from "chakra-react-select";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import BasicJsonCodeEditor from "../../../components/CodeEditor/BasicJsonCodeEditor";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import LockableField from "../../../components/form/LockableField";
import { getSourceOptions } from "../../../helpers/getSourceOptions";
import useMixPanel from "../../../mixpanel/useMixPanel";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { useAddEntities } from "../api/addEntities";
import { ingestValidatorChain } from "../services/IngestValidator";
import { IAddedSourceEntity, IAddedSourceEntityFile } from "../types";

export enum StepType {
  OVERVIEW = "Overview",
  UPLOAD = "Upload",
  MANUALLY = "Manually"
}

export const StepOverview = ({
  setStepCallback
}: {
  setStepCallback: (step: StepType) => void;
}) => {
  const bg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("brand.100", "gray.700");

  type SingleStep = {
    icon: IconDefinition;
    title: string;
    description: string;
    buttonText: string;
    action: () => void;
  };

  const Steps: SingleStep[] = [
    {
      icon: faGearCode,
      title: "Use the API",
      description: "Ingest entities using the Enterspeed Ingest API (REST).",
      buttonText: "Go to documentation",
      action: () =>
        window.open("https://docs.enterspeed.com/api#tag/Ingest", "_blank")
    },
    {
      icon: faFileArrowUp,
      title: "Upload file(s)",
      description: "Upload one or more JSON-files containing your entities.",
      buttonText: "Select file(s)",
      action: () => setStepCallback(StepType.UPLOAD)
    },
    {
      icon: faCode,
      title: "Add manually",
      description: "Add code into an editor to create an entity.",
      buttonText: "Enter code",
      action: () => setStepCallback(StepType.MANUALLY)
    }
  ];

  return (
    <Box>
      <SimpleGrid columns={3} spacing={4}>
        {Steps.map((step) => {
          return (
            <Flex
              key={step.title}
              bg={bg}
              p="6"
              borderRadius="md"
              border="1px solid"
              borderColor={borderColor}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              onClick={step.action}
              _hover={{ opacity: 0.8 }}
              cursor="pointer"
            >
              <Flex flexDirection="column" alignItems="center" mb="2">
                <Flex
                  width="48px"
                  height="48px"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="brand.100"
                  mb="4"
                >
                  <Icon
                    as={FontAwesomeIcon}
                    icon={step.icon}
                    color="brand.900"
                    size="xl"
                  />
                </Flex>
                <Text fontSize="lg" fontWeight="bold">
                  {step.title}
                </Text>
              </Flex>
              <Text fontSize="sm" mb="4" textAlign="center">
                {step.description}
              </Text>
              <Button variant="primary">{step.buttonText}</Button>
            </Flex>
          );
        })}
      </SimpleGrid>
      <Text mt="8" fontStyle="italic" fontSize="sm" textAlign="center">
        ...Or use one of our integrations to ingest entities from your existing
        data sources.{" "}
        <Link
          color="brand.500"
          href="https://docs.enterspeed.com/integrations"
          isExternal
        >
          View our integrations
        </Link>
        .
      </Text>
    </Box>
  );
};

const IngestAPiRequestBodySchema = () => {
  const requestBodySchema = [
    {
      property: "type",
      required: true,
      type: "string",
      description:
        "The type or alias of the type of entity being send to process. Eg. blogList, blogPage, frontPage, etc."
    },
    {
      property: "url",
      required: false,
      type: "string",
      description:
        "If the entity is routable send the URL. Otherwise leave as null. Allowed as null if entity is not routable. Note: The URL needs to begin with a leading slash."
    },
    {
      property: "originParentId",
      required: false,
      type: "string",
      description: "Allowed as null if no hierarchy exists."
    },
    {
      property: "redirects",
      required: false,
      type: "Array of strings",
      description:
        "URLs that redirects to the current page. Eg. https://enterspeed.com/product-enterspeed-tshirt-old/ redirecting to https://enterspeed.com/product-enterspeed-tshirt/."
    },
    {
      property: "properties",
      required: true,
      type: "object",
      description:
        "Property names  can contain A-Z, a-z letters, digits and underscores (Cannot start with a digit and cannot consist only of underscores)."
    }
  ];

  const rowColor = useColorModeValue("white", "gray.600");
  const oddRowColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box as="details" bg="white" fontSize="sm">
      <Box
        as="summary"
        p="2"
        bg="brandDarkBlue"
        color="white"
        cursor="pointer"
        _hover={{ opacity: 0.8 }}
      >
        View Request Body schema
      </Box>

      <Box>
        {requestBodySchema.map((r) => {
          return (
            <Box
              key={r.property}
              p="4"
              display="grid"
              gridTemplateColumns="1fr 1fr"
              bg={rowColor}
              _odd={{ backgroundColor: oddRowColor }}
            >
              <Box>
                {r.property}

                {r.required && (
                  <Badge fontSize="x-small" colorScheme="red" ml="2">
                    required
                  </Badge>
                )}
                <Box mt="2" fontSize="sm" fontStyle="italic">
                  {r.type}
                </Box>
              </Box>
              <Box>{r.description}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const StepUpload = ({
  sourceEntitiesCallback
}: {
  sourceEntitiesCallback: (
    addedSourceEntities: IAddedSourceEntityFile[]
  ) => void;
}) => {
  const [sourceEntities, setSourceEntities] = useState<
    IAddedSourceEntityFile[]
  >([]);

  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);

  const [hideInvalidFiles, setHideInvalidFiles] = useState<boolean>(true);

  const bg = useColorModeValue("white", "gray.700");

  const borderColor = useColorModeValue("gray.200", "gray.600");

  useEffect(() => {
    sourceEntitiesCallback(sourceEntities);
  }, [sourceEntities]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    setInvalidFiles([]);
    setSourceEntities([]);
    setHideInvalidFiles(false);

    const target = event.target as HTMLInputElement;

    const files = target.files;

    if (!files) {
      return; // No file selected
    }

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      const file = files[i];

      reader.onload = () => {
        try {
          const jsonData = JSON.parse(reader.result as string) as unknown;
          const fileName = file.name.split(".").slice(0, -1).join(".");

          const sourceEntity = {
            fileName: fileName ? fileName : uuid(),
            data: JSON.stringify(jsonData, null, 2),
            originId: fileName,
            originIdLocked: true
          };
          setSourceEntities((prev) => [...prev, sourceEntity]);
        } catch (error: unknown) {
          setInvalidFiles((prev) => [...prev, file.name]);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleOriginIdChange = (fileName: string, value: string) => {
    const updatedSourceEntities = sourceEntities.map((s) => {
      if (s.fileName === fileName) {
        s.originId = value;
      }
      return s;
    });
    setSourceEntities(updatedSourceEntities);
  };

  const removeFile = (fileName: string) => {
    const updatedSourceEntities = sourceEntities.filter(
      (s) => s.fileName !== fileName
    );
    setSourceEntities(updatedSourceEntities);
  };

  return (
    <Box>
      <Box fontSize="sm">
        <Text pb="4">
          The Origin ID must be unique for each entity. Typing an existing
          Origin ID will update the existing entity.
        </Text>
        <Text pb="4">
          <Text as="strong">Note:</Text> The data must be valid JSON and have
          the <Code>type</Code> and <Code>properties</Code> properties. Click on
          the <Text as="i">View Request Body schema</Text> for more info.
        </Text>
      </Box>
      <IngestAPiRequestBodySchema />

      {invalidFiles.length > 0 && !hideInvalidFiles && (
        <Alert status="error" mt="4" mb="4">
          <AlertIcon />
          <AlertTitle mr={2}>The following files are invalid:</AlertTitle>
          <AlertDescription>{invalidFiles.join(", ")}</AlertDescription>
          <CloseButton
            marginLeft="auto"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setHideInvalidFiles(true)}
          />
        </Alert>
      )}

      {!sourceEntities.length ? (
        <Box mt="4" style={{ textAlignLast: "center" }}>
          <Input
            type="file"
            accept=".json"
            multiple
            onChange={(e) => {
              handleFileUpload(e);
            }}
            width="100%"
            height="unset"
            padding="16"
            bg={bg}
            border="1px solid"
            borderColor={borderColor}
            borderRadius="md"
            cursor="pointer"
            _hover={{
              borderColor: "brand.200"
            }}
            sx={{
              "::file-selector-button": {
                borderRadius: "md",
                padding: "6px 12px",
                display: "inline-block",
                outline: 0,
                transition: "background-color 0.5s",
                cursor: "pointer",
                position: "relative",
                borderWidth: "2px",
                borderStyle: "solid",
                fontSize: "12px",
                fontWeight: 500,
                backgroundColor: "brand.700",
                border: "1px solid",
                borderColor: "brand.700",
                color: "white"
              },
              "::file-selector-button:hover, ::file-selector-button:focus": {
                backgroundColor: "brand.600"
              }
            }}
          />
        </Box>
      ) : sourceEntities.length === 1 ? (
        <>
          <BasicJsonCodeEditor
            value={sourceEntities[0].data}
            size="small"
            options={{
              readOnly: false
            }}
          />

          <LockableField
            marginTop={4}
            label="Origin ID"
            valueCallback={(value: string) =>
              handleOriginIdChange(sourceEntities[0].fileName, value)
            }
            initialValue={sourceEntities[0].fileName}
          />
        </>
      ) : (
        <Box mt="4">
          <SimpleGrid
            columns={2}
            spacing={4}
            fontWeight="bold"
            borderBottom="2px solid"
            borderColor="gray.200"
            pb="1"
            mb="4"
          >
            <Box>File name</Box>
            <Box>Origin ID</Box>
          </SimpleGrid>
          {sourceEntities.map((sourceEntity) => {
            return (
              <SimpleGrid
                key={`file-${sourceEntity.fileName}`}
                columns={2}
                spacing={4}
                _notLast={{
                  paddingBottom: 4,
                  marginBottom: 4,
                  borderBottom: "1px solid",
                  borderColor: "gray.200"
                }}
              >
                <Flex
                  alignItems="center"
                  key={`filename-${sourceEntity.fileName}`}
                >
                  {sourceEntity.fileName}
                </Flex>

                <Flex>
                  <LockableField
                    key={`originid-${sourceEntity.fileName}`}
                    label="Origin ID"
                    valueCallback={(value: string) =>
                      handleOriginIdChange(sourceEntity.fileName, value)
                    }
                    hideLabel
                    initialValue={sourceEntity.fileName}
                  />
                  <Flex alignItems="center">
                    <Tooltip label="Remove file" placement="right">
                      <IconButton
                        onClick={() => removeFile(sourceEntity.fileName)}
                        variant="ghost"
                        ml="2"
                        aria-label="Remove file"
                        size="md"
                        icon={<Icon as={FontAwesomeIcon} icon={faTimes} />}
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </SimpleGrid>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export const StepManually = ({
  sourceEntityCallback
}: {
  sourceEntityCallback: (addedSourceEntity: IAddedSourceEntity) => void;
}) => {
  const placeHolderEntity = `{
    "type": "blogPost",
    "url": "/blog/the-perfect-cupcake-recipe/",
    "properties": {
        "name": "The Perfect Cupcake Recipe",
        "slug": "perfect-cupcake-recipe",
        "thumbnail": "https://example.com/cupcake-thumbnail.jpg",
        "article": "Baking the perfect cupcakes requires a balance of ingredients and precise techniques. Here's a tried-and-true recipe to help you create delicious cupcakes every time."
    }
}`;

  const initialAddedSourceEntity = {
    data: placeHolderEntity,
    originId: uuid(),
    originIdLocked: true
  };

  const [sourceEntity, setSourceEntity] = useState<IAddedSourceEntity>(
    initialAddedSourceEntity
  );

  const handleOriginIdChange = (value: string) => {
    setSourceEntity({ ...sourceEntity, originId: value });
  };

  const handleDataChange = (value: string | undefined) => {
    setSourceEntity({ ...sourceEntity, data: value ? value : "" });
  };

  useEffect(() => {
    sourceEntityCallback(sourceEntity);
  }, [sourceEntity]);

  return (
    <Box>
      <Box fontSize="sm">
        <Text pb="4">
          Add entities manually by typing or pasting the JSON data in the text
          editor below.
        </Text>
        <Text pb="4">
          The Origin ID must be unique for each entity. Typing an existing
          Origin ID will update the existing entity.
        </Text>
        <Text pb="4">
          <Text as="strong">Note:</Text> The data must be valid JSON and have
          the <Code>type</Code>, <Code>url</Code>, and <Code>properties</Code>{" "}
          properties. Click on the <Text as="i">View Request Body schema</Text>{" "}
          for more info.
        </Text>
      </Box>
      <IngestAPiRequestBodySchema />
      <>
        <BasicJsonCodeEditor
          value={placeHolderEntity}
          size="small"
          options={{
            readOnly: false
          }}
          onValueChange={(value: string | undefined) => handleDataChange(value)}
        />
        <LockableField
          marginTop={4}
          label="Origin ID"
          valueCallback={(value: string) => handleOriginIdChange(value)}
          initialValue={uuid()}
        />
      </>
    </Box>
  );
};

const AddEntitiesModal = ({
  onClose,
  isOpen
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [step, setStep] = useState(StepType.OVERVIEW);
  const [selectedSource, setSelectedSource] = useState<string>();
  const [sourceEntities, setSourceEntities] = useState<
    IAddedSourceEntity[] | IAddedSourceEntityFile[]
  >([]);

  const [accessKey, setAccessKey] = useState<string>("");

  const toast = useToast();
  const toastIdRef = useRef<ToastId>();
  const mixpanel = useMixPanel();

  const [addButtonIsDisabled, setAddButtonIsDisabled] = useState<boolean>(true);

  const { data } = useSourceGroups();

  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();

  const sourceOptions = getSourceOptions(data ?? []);

  const findAccessKey = () => {
    if (data) {
      const source = data
        .flatMap(({ sources }) => sources.flatMap(({ source }) => source))
        .find(({ id }) => id.sourceGuid === selectedSource);

      if (source?.accessKey) {
        setAccessKey(source.accessKey);
      } else {
        console.error(`source has no accesskey`);
      }
    }
  };

  const addEntities = useAddEntities();

  const handleAddEntity = () => {
    addEntities
      .mutateAsync({
        sourceEntities: sourceEntities,
        apiKey: accessKey,
        toast,
        toastIdRef
      })
      .catch(console.error);
    onClose();
  };

  useEffect(() => {
    checkIfAddButtonShouldBeDisabled();
  }, [sourceEntities]);

  useEffect(() => {
    if (selectedSource) {
      findAccessKey();
    }
    checkIfAddButtonShouldBeDisabled();
  }, [selectedSource]);

  const clearState = () => {
    setStep(StepType.OVERVIEW);
    setSelectedSource(undefined);
    setSourceEntities([]);
    setAddEntitiesError("");
    setAddButtonIsDisabled(true);
    setAccessKey("");
  };

  const [addEntitiesError, setAddEntitiesError] = useState<string>("");

  const checkIfAddButtonShouldBeDisabled = () => {
    const [valid, message] = ingestValidatorChain.validate(
      sourceEntities,
      selectedSource
    );
    if (message) {
      setAddEntitiesError(message);
    }
    setAddButtonIsDisabled(!valid);
  };

  const modalBgColor = useColorModeValue("gray.100", "gray.800");

  return (
    <>
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={() => {
          mixpanel.track("addEntities", { step: "cancel" });
          onClose();
        }}
        closeOnOverlayClick={false}
        onCloseComplete={() => clearState()}
      >
        <ModalOverlay />
        <ModalContent bg={modalBgColor} p="4">
          <ModalHeader>Add Entities</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {step === StepType.OVERVIEW && (
              <StepOverview
                setStepCallback={(step: StepType) => {
                  mixpanel.track(`addEntities`, {
                    step
                  });
                  setStep(step);
                }}
              />
            )}
            {step === StepType.UPLOAD && (
              <StepUpload
                sourceEntitiesCallback={(addedSourceEntities) =>
                  setSourceEntities(addedSourceEntities)
                }
              />
            )}
            {step === StepType.MANUALLY && (
              <StepManually
                sourceEntityCallback={(addedSourceEntity) =>
                  setSourceEntities([addedSourceEntity])
                }
              />
            )}
            {step !== StepType.OVERVIEW && (
              <FormControl mt="4">
                <FormLabel fontSize="sm">Source</FormLabel>

                <Select<
                  IEnterspeedIdentifiableSelectOption,
                  true,
                  GroupBase<IEnterspeedIdentifiableSelectOption>
                >
                  useBasicStyles
                  chakraStyles={reactSelectStyles}
                  colorScheme="brand"
                  isClearable
                  name="flavors"
                  placeholder="Select source"
                  options={sourceOptions}
                  components={new EnterspeedIdentifiableSelect()}
                  onChange={(value: unknown) => {
                    setSelectedSource((value as { value: string })?.value);
                  }}
                />
              </FormControl>
            )}
          </ModalBody>
          {step !== StepType.OVERVIEW && (
            <ModalFooter justifyContent="space-between">
              <Button
                colorScheme="gray"
                bg="gray.300"
                onClick={() => clearState()}
              >
                Go back
              </Button>
              <Tooltip
                isDisabled={!addButtonIsDisabled}
                label={addEntitiesError}
                placement="top"
              >
                <Button
                  variant="primary"
                  isDisabled={addButtonIsDisabled}
                  onClick={() => handleAddEntity()}
                  isLoading={addEntities.isPending}
                >
                  {sourceEntities.length > 1 ? "Add entities" : "Add entity"}
                </Button>
              </Tooltip>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEntitiesModal;
