import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useState } from "react";
import { IDeleteModal } from "../../types/modal";

const DeleteModal = ({
  type,
  name,
  customTitle,
  customDescription,
  isOpen,
  onClose,
  onSubmit,
  onSuccess
}: IDeleteModal) => {
  const [confirmText, setConfirmText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteString = `DELETE ${name.toUpperCase()}`;

  const submit = async () => {
    if (!onSubmit) {
      return onSuccess();
    }

    setIsLoading(true);
    const submissionResult = await onSubmit();
    setIsLoading(false);
    if (submissionResult.valid) {
      onSuccess();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={() => setConfirmText("")}
      >
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>
              {customTitle ? customTitle : type ? `Delete ${type}` : "Delete"}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text pb="4">
                Are you sure you want to delete <Text as="b">{name}?</Text>
              </Text>
              <Text pb="6">
                {customDescription
                  ? customDescription
                  : `
                                This will immediately cause all data ${" "}
                                ${
                                  type ? `for the ${type}` : ""
                                } to be deleted. This action is
                                irreversible!`}
              </Text>
              <FormLabel>Please type "{deleteString}" to confirm</FormLabel>

              <Input
                autoFocus
                value={confirmText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmText(e.target.value)
                }
                bg={useColorModeValue("white", "gray.700")}
                placeholder={deleteString}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                isDisabled={isLoading}
                onClick={onClose}
                colorScheme="gray"
              >
                Cancel
              </Button>
              <Button
                isDisabled={confirmText !== deleteString || isLoading}
                isLoading={isLoading}
                colorScheme="red"
                onClick={submit}
                ml={3}
                autoFocus={true}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default DeleteModal;
