import { parseSourceId } from "../../../helpers/ids";
import { IEnvironmentResponse } from "../../environments/types";
import { ISchemaListResponse } from "../../schemas/types";
import { ISource, ISourceGroupsResponse } from "../../source-groups/types";

export const findEnvironmentByGuid = (
  guid: string,
  environments: IEnvironmentResponse[] | undefined
): IEnvironmentResponse | undefined => {
  return environments?.find((f) => f.id.environmentGuid === guid);
};

export const findMappingSchemaByAlias = (
  alias: string,
  schemas: ISchemaListResponse[] | undefined
): ISchemaListResponse | undefined => {
  return schemas?.find((f) => f.viewHandle === alias);
};

export const findMappingSchemaByGuid = (
  guid: string,
  schemas: ISchemaListResponse[] | undefined
): ISchemaListResponse | undefined => {
  return schemas?.find((f) => f.id.mappingSchemaGuid === guid);
};

export const findSourceGroupBySourceGuid = (
  guid: string,
  sources: ISourceGroupsResponse[] | undefined
): ISourceGroupsResponse | undefined => {
  return sources?.find((s) =>
    s.sources?.find((f) => f.source.id.sourceGuid === guid)
  );
};

export const findSourceGroupByGuid = (
  guid: string,
  sources: ISourceGroupsResponse[] | undefined
): ISourceGroupsResponse | undefined => {
  return sources?.find((s) => s.id.sourceGroupGuid === guid);
};

export const findSourceById = (
  sourceIdAsString: string,
  sources: ISourceGroupsResponse[] | undefined
):
  | {
      source: ISource;
      sourceGroup: ISourceGroupsResponse;
    }
  | undefined => {
  const sourceId = parseSourceId(sourceIdAsString);
  if (!sourceId) {
    return undefined;
  }

  const sourceGroup = findSourceGroupBySourceGuid(sourceId.sourceGuid, sources);
  if (!sourceGroup) {
    return undefined;
  }

  const source = sourceGroup.sources.find(
    (f) => f.source.id.sourceGuid === sourceId.sourceGuid
  );
  if (!source) {
    return undefined;
  }

  return {
    source: source.source,
    sourceGroup: sourceGroup
  };
};
