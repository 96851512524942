import { Flex } from "@chakra-ui/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import LoadingAnimation from "../components/LoadingAnimation";
import { useAuthStore } from "../features/auth/store";
import { useTenantStore } from "../features/tenants/store";

const ActiveTenantRoute = () => {
  const userState = useAuthStore(useShallow((state) => state));
  const { user, isAdmin, isValid } = userState;

  const ldClient = useLDClient();
  const [isLdFlagLoaded, setIsLdFlagsLoaded] = useState(false);

  const [activeTenant, availableTenants] = useTenantStore(
    useShallow(({ activeTenant, availableTenants }) => [
      activeTenant,
      availableTenants
    ])
  );

  useEffect(() => {
    if (!user?.id || !ldClient) {
      return;
    }

    if (user?.tenantIds.length === 0) {
      setIsLdFlagsLoaded(true);
    }

    if (!activeTenant.id) {
      return;
    }
    ldClient
      .identify({
        kind: "multi",
        user: {
          key: user.id,
          name: `${user.firstName ?? "unknown"} ${user.lastName ?? "unknown"}`,
          isAdmin: isAdmin
        },
        tenant: {
          key: activeTenant.id,
          name: activeTenant.name,
          routesConfig: activeTenant.routesConfig,
          javascriptConfig: activeTenant.javascriptSchemaFormatConfig,
          plan: activeTenant.plan
        }
      })
      .then(() => setIsLdFlagsLoaded(true))
      .catch(console.error);
  }, [user, ldClient, activeTenant, isAdmin]);

  if (!isLdFlagLoaded || !isValid) {
    return (
      <Flex flex={1} align={"center"} justify={"center"}>
        <LoadingAnimation></LoadingAnimation>
      </Flex>
    );
  }

  if (!availableTenants?.length) {
    return <Navigate to="/no-tenants"></Navigate>;
  }

  return <Outlet></Outlet>;
};

export default ActiveTenantRoute;
