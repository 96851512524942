import {
  Box,
  Code,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";

import {
  faCopy,
  faInputText,
  faTrashCan
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { ISchemaSearchListResponse } from "../types";
import {
  ISchemaListItemProps,
  ISchemaListMenuProps
} from "../types/schema-list";
import getSchemaFormat from "../utils/getSchemaFormat";
import getSchemaType from "../utils/getSchemaType";
import getSchemaTypeIcon from "../utils/getSchemaTypeIcon";

const SchemaListItem = memo(
  ({
    name,
    schema,
    query,
    onDuplicateSchema,
    onDeleteSchema,
    onEditSchema
  }: ISchemaListItemProps) => {
    const { viewHandle, id, latestFormat, type } = schema;
    const navigate = useNavigate();
    const highlightBackgroundColor = useColorModeValue("#f3f3f3", "#434343");

    const searchSchema = schema as ISchemaSearchListResponse;

    const getHighlightedText = (text: string, searchText: string) => {
      const escapedSearchText = searchText.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      const regex = new RegExp(escapedSearchText, "gi");
      return text.replace(
        regex,
        `<span style="background-color: ${highlightBackgroundColor}">$&</span>`
      );
    };

    return (
      <Box
        key={id.idValue}
        px="2"
        py="2"
        borderRadius="sm"
        alignItems="center"
        cursor="pointer"
        _hover={{
          backgroundColor: useColorModeValue("gray.50", "gray.700")
        }}
        onClick={() => navigate(`/schemas/${id.mappingSchemaGuid}`)}
      >
        <Flex
          key={id.idValue}
          px="2"
          py="3"
          borderRadius="sm"
          alignItems="center"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mr="4"
          >
            <Tooltip placement="left" label={getSchemaType(type)}>
              <Icon
                size="xl"
                color="gray.400"
                as={FontAwesomeIcon}
                icon={getSchemaTypeIcon(type)}
              />
            </Tooltip>
            <Box
              mt="1"
              py="0.5"
              px="1"
              borderRadius="sm"
              fontSize="x-small"
              bg={useColorModeValue("gray.100", "gray.800")}
              textTransform="uppercase"
            >
              {getSchemaFormat(latestFormat)}
            </Box>
          </Flex>
          <Flex flexDirection="column">
            <Box>{name}</Box>
            <Flex alignItems="center">
              <Box fontSize="sm" fontStyle="italic" color="gray.500" mr="2">
                {viewHandle}
              </Box>
              <AdminTableRowId id={id.idValue} />
            </Flex>
          </Flex>
          <Box ml="auto" onClick={(event) => event.stopPropagation()}>
            <SchemaListMenu
              onDuplicateSchema={onDuplicateSchema}
              onDeleteSchema={onDeleteSchema}
              onEditSchema={onEditSchema}
            />
          </Box>
        </Flex>
        {searchSchema?.hits && (
          <Flex flexDirection={"column"} ml={4} color={"gray.500"}>
            {searchSchema.hits.map(function (hit, i) {
              return (
                <Code
                  key={`${searchSchema.id.idValue}-${i}`}
                  colorScheme="lightgray"
                  dangerouslySetInnerHTML={{
                    __html: getHighlightedText(hit, query)
                  }}
                />
              );
            })}
          </Flex>
        )}
      </Box>
    );
  }
);

const SchemaListMenu = memo(
  ({
    onEditSchema,
    onDuplicateSchema,
    onDeleteSchema
  }: ISchemaListMenuProps) => {
    const actions = [
      {
        type: "Duplicate",
        onClick: onDuplicateSchema,
        icon: faCopy
      },
      {
        type: "Rename",
        onClick: onEditSchema,
        icon: faInputText
      },
      {
        type: "Delete",
        onClick: onDeleteSchema,
        icon: faTrashCan
      }
    ];
    return (
      <Menu>
        <Tooltip label="Settings">
          <MenuButton
            display="block"
            ml="auto"
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={FontAwesomeIcon} icon={faEllipsisVertical} />}
            variant="ghost"
          />
        </Tooltip>

        <MenuList>
          {actions?.map((action) => (
            <MenuItem
              key={action.type}
              onClick={() => action.onClick()}
              py="3"
              icon={
                action.icon && (
                  <Icon
                    as={FontAwesomeIcon}
                    icon={action.icon}
                    color="gray.500"
                  />
                )
              }
            >
              {action.type}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }
);

export default SchemaListItem;
