import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import DeleteModal from "../../../components/modals/DeleteModal";
import EditNameModal from "../../../components/modals/EditNameModal";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { useDeleteDomain } from "../api/deleteDomain";
import { useEditDomain } from "../api/editDomain";
import { DomainTableModalType, IDomain } from "../types";

const DomainsActionModal = ({
  type,
  domain,
  isOpen,
  onClose
}: {
  type: DomainTableModalType | undefined;
  domain: IDomain;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const editDomain = useEditDomain();
  const deleteDomain = useDeleteDomain();

  switch (type) {
    case DomainTableModalType.EDIT:
      return (
        <EditNameModal
          type="domain"
          name={domain.name}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={async ({ name }) => {
            onClose();
            const editDomainNamePromise = editDomain.mutateAsync({
              id: domain.domainGuid,
              payload: {
                name: name,
                hostnames: domain.hostnames
              }
            });
            toast.promise(editDomainNamePromise, {
              success: {
                title: "Domain name edited",
                description: `Domain name edited from "${domain.name}" to ${name}`,
                ...defaultToast
              },
              loading: {
                title: "Domain name edited",
                description: `Domain name edited from "${domain.name}" to ${name}`,
                ...defaultToast
              },
              error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                title: response?.data.detail ?? "Error editing domain",
                status: "error",
                ...defaultToast
              })
            });
            await editDomainNamePromise;
            return { valid: true };
          }}
        />
      );
    case DomainTableModalType.DELETE:
      return (
        <DeleteModal
          type="environment"
          name={domain.name}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={() => {
            onClose();

            const deleteDomainPromise = deleteDomain.mutateAsync({
              id: domain.domainGuid
            });
            toast.promise(deleteDomainPromise, {
              success: {
                title: "Domain successfully deleted",
                description: `Deleted domain "${domain.name}"`,
                ...defaultToast
              },
              loading: {
                title: "Deleting domain",
                description: `Deleting domain "${domain.name}"`,
                ...defaultToast
              },
              error: ({ response }: AxiosError<IApiErrorResponse>) => ({
                title: response?.data.detail ?? "Error deleting domain",
                status: "error",
                ...defaultToast
              })
            });
          }}
        />
      );

    default:
      return null;
  }
};

export default DomainsActionModal;
