import { ITreeState, Paths } from "../types/schema-list";

export const DefaultState: ITreeState = {
  nodes: [],
  advancedSearch: false,
  alwaysOpen: false,
  hasFolders: false,
  filterTerm: ``,
  query: ``,
  collapsedPaths: new Paths(),
  expandedPaths: new Paths()
};
