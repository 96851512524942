import {
  Box,
  Button,
  Flex,
  Image,
  Progress,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ContentBox from "../../components/ContentBox";
import EmptyState from "../../components/EmptyState";
import EnvironmentBadge from "../../components/EnvironmentBadge";
import LoadingAnimation from "../../components/LoadingAnimation";
import DeleteModal from "../../components/modals/DeleteModal";
import Pagination from "../../components/Pagination";
import { DataTable } from "../../components/table/DataTable";
import useConfiguredDestinations from "../../features/destinations/api/useConfiguredDestinations";
import useDeleteDestinationConfiguration from "../../features/destinations/api/useDeleteDestinationConfiguration";
import {
  DestinationModal,
  IDestinationConfiguration,
  IDestinationEnvironmentConfiguration
} from "../../features/destinations/types";
import { useEnvironments } from "../../features/environments/api/getEnvironments";
import { useCustomToast } from "../../hooks/useCustomToast";
import useMixPanel from "../../mixpanel/useMixPanel";
import { DataTableAction } from "../../types/dataTable";

export const DestinationsSettings = () => {
  const toast = useCustomToast();
  const mixpanel = useMixPanel();
  const deleteModal = useDisclosure();
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching
  } = useConfiguredDestinations();
  const { data: environments } = useEnvironments();

  const deleteMutation = useDeleteDestinationConfiguration();

  const navigate = useNavigate();

  const [selectedDestination, setSelectedDestination] =
    useState<IDestinationConfiguration>();

  const columnHelper = createColumnHelper<IDestinationConfiguration>();

  const getEnvironmentTags = (
    environmentConfigurations?: IDestinationEnvironmentConfiguration[]
  ) => {
    const environmentTags = environments?.filter(
      (env) =>
        !!environmentConfigurations?.find(
          (configuration) => configuration.environmentId === env.id.idValue
        )
    );
    const maxTagsToShow = 3;
    return environmentTags ? (
      <>
        {environmentTags.slice(0, maxTagsToShow).map((environment) => (
          <EnvironmentBadge
            key={environment.id.idValue}
            name={environment.name}
          />
        ))}
        {environmentTags.length > maxTagsToShow && (
          <Tooltip
            label={environmentTags
              .slice(maxTagsToShow)
              .map((x) => x.name)
              .join(", ")}
          >
            <Text fontWeight={"bold"} marginLeft={"3px"}>
              and {environmentTags.length - maxTagsToShow} more
            </Text>
          </Tooltip>
        )}
      </>
    ) : (
      <></>
    );
  };

  const columns = [
    columnHelper.accessor("app", {
      cell: (col) => (
        <Flex gap={"0.5em"} alignItems={"center"}>
          <Box>
            <Image
              boxSize={"2.5em"}
              objectFit={"cover"}
              src={col.getValue()?.icon}
            ></Image>
          </Box>
          <Text as="b" fontSize={"md"}>
            {col.getValue()?.name}
          </Text>
        </Flex>
      ),
      header: "Destination"
    }),
    columnHelper.accessor("alias", {
      cell: (col) => <Text fontSize={"md"}>{col.getValue()}</Text>
    }),
    columnHelper.accessor("environmentConfigurations", {
      cell: (col) => (
        <Flex gap={"0.5em"} flexWrap={"wrap"}>
          {getEnvironmentTags(col?.getValue())}
        </Flex>
      ),
      header: "Environments"
    })
  ];

  if (isLoading || isRefetching) {
    return (
      <ContentBox>
        <LoadingAnimation></LoadingAnimation>
      </ContentBox>
    );
  }

  if (!data) {
    return <EmptyState></EmptyState>;
  }

  const destinationConfigurations = data.pages.flatMap((page) => page.data);

  if (!destinationConfigurations.length) {
    return <Navigate to="/settings/destinations" />;
  }

  const actions: DataTableAction<
    DestinationModal,
    IDestinationConfiguration
  >[] = [
    {
      type: DestinationModal.Delete,
      icon: faTrashCan,
      onClick: (row) => {
        setSelectedDestination(row);
        deleteModal.onOpen();
      }
    }
  ];

  return (
    <>
      <ContentBox
        title="Destinations settings"
        headerRight={
          <Button
            variant="subtle"
            onClick={() => navigate("/settings/destinations")}
          >
            Create new destination
          </Button>
        }
      >
        <Progress
          hidden={!isRefetching}
          isIndeterminate={true}
          size={"xs"}
        ></Progress>

        <DataTable
          columns={columns}
          actions={actions}
          data={destinationConfigurations}
          onRowClickCallback={(row) =>
            navigate(`/settings/destinations-settings/${row.alias}`)
          }
        ></DataTable>
        <Pagination
          disabled={!hasNextPage}
          current={destinationConfigurations.length}
          total={data.pages[0].total ?? 0}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
        ></Pagination>
      </ContentBox>
      {selectedDestination && (
        <DeleteModal
          name={`${selectedDestination.alias}`}
          type={`destination with alias "${selectedDestination.alias}"`}
          customDescription="Beware if any schema is using this destination, errors will occur if the schema is triggered to be processed."
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          onSuccess={async () => {
            deleteModal.onClose();
            mixpanel.track("Delete destination", {
              app: selectedDestination.app.name
            });

            const promise = deleteMutation.mutateAsync({
              alias: selectedDestination.alias
            });
            await toast.subscribe(promise, {
              titles: [
                `Deleted destination '${selectedDestination.alias}'`,
                `Deleting destination '${selectedDestination.alias}'`
              ]
            });
          }}
        ></DeleteModal>
      )}
    </>
  );
};
