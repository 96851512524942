import { ITenantId } from "../../tenants/types";

export interface IManagementClient {
  name: string;
  accessKey: string;
  id: string;
  managementClientGuid: string;
}

export interface IManagementClientResponse {
  id: IManagementClientResponseId;
  tenantId: ITenantId;
  name: string;
  accessKey: string;
  updatedAt: string;
}

export interface IManagementClientResponseId {
  idValue: string;
  managementClientGuid: string;
}

export interface IManagementClientCreatePayload {
  name: string;
}

export interface IManagementClientEditPayload {
  name: string;
}

export enum ManagementClientTableModalType {
  EDIT = "Edit",
  DELETE = "Delete",
  REGENERATE_API_KEY = "Regenerate API Key"
}
