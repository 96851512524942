import {
  Alert,
  AlertDescription,
  AlertDialogFooter,
  AlertIcon,
  AlertTitle,
  Badge,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { createContext, ReactNode, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import CopyableText from "../../../components/table/CopyableText";
import { DataTable } from "../../../components/table/DataTable";
import { OLD_APP_URL } from "../../../constants/app";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import useTenantSwitcher from "../hooks/useTenantSwitcher";
import { useTenantStore } from "../store";
import { ITenant } from "../types";

export const DeprecatedTenantBlockerContext = createContext({});

const TenantPickerModal = ({
  isOpen,
  onClose,
  onChange,
  onClick,
  tenants,
  onToggleShowDeprecatedTenants,
  showDeprecatedTenants,
  totalTenantCount
}: {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
  onClick: (tenant: ITenant) => void;
  onToggleShowDeprecatedTenants: (checked: boolean) => void;
  tenants: ITenant[];
  showDeprecatedTenants: boolean;
  totalTenantCount: number;
}) => {
  const adminFeatures = useAdminFeatures();
  const columnHelper = createColumnHelper<ITenant>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => {
        const tenant = props.row.original;
        return (
          <Flex direction={"column"}>
            <Flex justify={"space-between"}>
              {props.getValue()}
              {!tenant.isUsingSourceGroups && (
                <Badge
                  color={"gray.300"}
                  textColor={"gray.700"}
                  fontSize={"xx-small"}
                >
                  deprecated
                </Badge>
              )}
            </Flex>
            {adminFeatures && (
              <CopyableText id={tenant.id.idValue}></CopyableText>
            )}
          </Flex>
        );
      },
      header: "Name"
    })
  ];
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="3xl"
    >
      <ModalOverlay />

      <ModalContent p={"1em"}>
        <ModalHeader>Pick another tenant</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl mr="4">
            <FormLabel color="gray.500" fontSize="xs">
              Search
            </FormLabel>

            <Input
              defaultValue={""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              size="sm"
            />
          </FormControl>
          <FormControl mr="4">
            <FormLabel color="gray.500" fontSize="xs">
              Show deprecated tenants
            </FormLabel>

            <Checkbox
              checked={showDeprecatedTenants}
              onChange={(e) => onToggleShowDeprecatedTenants(e.target.checked)}
              size="sm"
            />
          </FormControl>
          <DataTable
            data={tenants}
            columns={columns}
            onRowClickCallback={onClick}
            setRowStyle={(t) => {
              if (!t.original.isUsingSourceGroups) {
                return {
                  opacity: 0.3,
                  disable: true,
                  cursor: "not-allowed"
                };
              }
            }}
          ></DataTable>
          <Flex alignItems="center" flexDirection="column">
            <Text mt="4" color="gray.500" fontSize="sm">
              Showing {tenants.length} of {totalTenantCount}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button
            colorScheme="gray"
            bg={useColorModeValue("gray.300", "gray.700")}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeprecatedTenantBlocker = ({ children }: { children: ReactNode }) => {
  const tenantPickerDisclosure = useDisclosure();
  const [searchTerm, setSearchTerm] = useState("");

  const { availableTenants, activeTenant } = useTenantStore(
    useShallow(({ activeTenant, availableTenants }) => ({
      activeTenant,
      availableTenants
    }))
  );

  const isBlocked = !activeTenant.isUsingSourceGroups;

  const tenantSwitcher = useTenantSwitcher();
  const [showDeprecatedTenants, setShowDeprecatedTenants] = useState(false);

  const reset = () => {
    tenantPickerDisclosure.onClose();
    setSearchTerm("");
  };

  const searchTenants = (searchTerm: string) => {
    return (t: ITenant) => {
      const searchTermLowerCased = searchTerm.toLowerCase();
      const tenantNameLowerCased = t.name.toLowerCase();
      return (
        t.id.idValue.startsWith(searchTermLowerCased) ||
        t.id.idValue.split("/")[3].startsWith(searchTermLowerCased) ||
        tenantNameLowerCased.includes(searchTermLowerCased)
      );
    };
  };

  const tenantSearchResult = [...availableTenants]
    .reverse()
    .filter(searchTenants(searchTerm))
    .filter((t) => !(!showDeprecatedTenants && !t.isUsingSourceGroups))
    .slice(0, 5);

  return (
    <>
      <DeprecatedTenantBlockerContext.Provider value={{}}>
        {children}
        {isBlocked ? (
          <>
            <Modal
              isOpen={true}
              onClose={() => {
                //
              }}
              size="2xl"
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              ></ModalOverlay>
              <ModalContent>
                <Alert
                  status="error"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <AlertTitle>
                    <Flex>
                      <AlertIcon boxSize={"3em"}></AlertIcon>

                      <Heading size={"2xl"}>{"Deprecated tenant"}</Heading>
                    </Flex>
                  </AlertTitle>
                  <AlertDescription>
                    <Text>
                      {
                        "You are using an old tenant that cannot be used with our new app."
                      }
                    </Text>
                    <Text>
                      {
                        "We encourage you to migrate your tenant. Until then you can use our old app."
                      }
                    </Text>
                  </AlertDescription>
                  <AlertDialogFooter justifyContent="space-between" gap={"1em"}>
                    <Button
                      variant={"link"}
                      onClick={() => window.location.replace(OLD_APP_URL)}
                    >
                      Go to old app
                    </Button>
                    <Button
                      onClick={() => {
                        tenantPickerDisclosure.onOpen();
                      }}
                    >
                      Switch Tenant
                    </Button>
                  </AlertDialogFooter>
                </Alert>
                <ModalCloseButton
                  onClick={() =>
                    tenantSwitcher.switchToPreviousAvailableTenant()
                  }
                ></ModalCloseButton>
              </ModalContent>
            </Modal>
            <TenantPickerModal
              isOpen={tenantPickerDisclosure.isOpen}
              onClose={reset}
              onChange={setSearchTerm}
              onClick={tenantSwitcher.switch}
              tenants={tenantSearchResult}
              onToggleShowDeprecatedTenants={setShowDeprecatedTenants}
              showDeprecatedTenants={showDeprecatedTenants}
              totalTenantCount={availableTenants.length}
            ></TenantPickerModal>
          </>
        ) : (
          <></>
        )}
      </DeprecatedTenantBlockerContext.Provider>
    </>
  );
};

export default DeprecatedTenantBlocker;
