import { singleton } from "tsyringe";
import { ObjectResult, Result } from "../../../../types/codeEditor";
import {
  IEvaluatedSchemaMetadata,
  IJsonSourceEntityTypesSchema,
  IJsonSourceGroupTriggerSchema,
  SchemaFormat,
  SchemaType
} from "../../types";
import { BaseSchemaVisitor } from "./base";
import {
  SCHEMA_INVALID,
  SCHEMA_INVALID_CODE,
  SCHEMA_SOURCE_ENTITY_TYPES_INVALID,
  SCHEMA_TRIGGERS_MISSING,
  SCHEMA_TRIGGERS_MUST_BE_AN_OBJECT,
  SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE
} from "./schema-errors";

abstract class BaseJsonFormatSchemaVisitor extends BaseSchemaVisitor {
  visit(code: string): ObjectResult<IEvaluatedSchemaMetadata> {
    try {
      // Parse schema
      const schemaParsingResult = this.parseSchema(code);
      if (!schemaParsingResult.valid) {
        return ObjectResult.fail<IEvaluatedSchemaMetadata>(
          schemaParsingResult.errors
        );
      }

      return this.visitSchema(schemaParsingResult.value);
    } catch {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>([
        { description: SCHEMA_INVALID_CODE }
      ]);
    }
  }

  abstract visitSchema(schema: unknown): ObjectResult<IEvaluatedSchemaMetadata>;

  protected parseSchema(code: string): ObjectResult<unknown> {
    try {
      const schema = JSON.parse(code) as unknown;
      if (!schema) {
        return ObjectResult.fail<unknown>([{ description: SCHEMA_INVALID }]);
      }

      return ObjectResult.ok<unknown>(schema);
    } catch {
      return ObjectResult.fail<unknown>([{ description: SCHEMA_INVALID_CODE }]);
    }
  }
}

@singleton()
export class JsonSourceEntityTypesSchemaVisitor extends BaseJsonFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "json";
  }
  get schemaTypes(): SchemaType[] {
    return ["normal"];
  }

  get usingSourceGroup(): boolean {
    return false;
  }

  visitSchema(
    schema: IJsonSourceEntityTypesSchema
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    // Validate source entity types
    const sourceEntityTypesValidationResult =
      this.validateSourceEntityTypes(schema);
    if (!sourceEntityTypesValidationResult.valid) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>(
        sourceEntityTypesValidationResult.errors
      );
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: this.extractSourceEntityTypes(schema)
    });
  }

  validateSourceEntityTypes(schema: IJsonSourceEntityTypesSchema): Result {
    if (!schema?.sourceEntityTypes?.length) {
      return Result.fail([{ description: SCHEMA_SOURCE_ENTITY_TYPES_INVALID }]);
    }

    return Result.ok();
  }

  extractSourceEntityTypes(schema: IJsonSourceEntityTypesSchema): string[] {
    return schema.sourceEntityTypes;
  }
}

@singleton()
export class JsonSourceGroupTriggerSchemaVisitor extends BaseJsonFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "json";
  }
  get schemaTypes(): SchemaType[] {
    return ["normal", "collection"];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitSchema(
    schema: IJsonSourceGroupTriggerSchema
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    // Validate triggers
    const triggerValidationResult = this.validateTriggers(schema);
    if (!triggerValidationResult.valid) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>(
        triggerValidationResult.errors
      );
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: this.extractSourceEntityTypes(schema)
    });
  }

  validateTriggers(schema: IJsonSourceGroupTriggerSchema): Result {
    if (!schema.triggers) {
      return Result.fail([{ description: SCHEMA_TRIGGERS_MISSING }]);
    }

    if (typeof schema.triggers !== "object" || Array.isArray(schema.triggers)) {
      return Result.fail([{ description: SCHEMA_TRIGGERS_MUST_BE_AN_OBJECT }]);
    }

    if (Object.keys(schema.triggers).length === 0) {
      return Result.fail([
        {
          description:
            SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE
        }
      ]);
    }

    return Result.ok();
  }

  extractSourceEntityTypes(schema: IJsonSourceGroupTriggerSchema): string[] {
    return Array.from(new Set(Object.values(schema.triggers).flat()));
  }
}
