import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DiffEditor, Editor, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { useEffect, useRef, useState } from "react";
import {
  EditorSize,
  FontSizeOptions,
  IBaseCodeEditorProps,
  LocalStorageSettingKeys,
  WordWrap,
  WordWrapOptions
} from "../../types/codeEditor";
import {
  EditorThemeOption,
  EditorThemeSettings,
  ThemeOptions
} from "../../types/ThemeOptions";
import BottomBar from "./BottomBar";

const BaseCodeEditor = ({
  onEditorMount,
  value,
  onValueChange,
  onValidationChange,
  language,
  diffComparison,
  leftSideBottomBarElements,
  rightSideBottomBarElements,
  bottomBarActionButtons,
  size,
  options
}: IBaseCodeEditorProps) => {
  const editorRef = useRef<
    editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor | null
  >(null);
  const monacoRef = useRef<Monaco | null>(null);
  const editorHeight: {
    [K in EditorSize]: string;
  } = {
    default: "700px",
    small: "300px"
  };

  // User settings - Font size
  const [fontSize, setFontSize] = useState<number>(
    parseInt(window.localStorage.getItem("fontSize") ?? "14")
  );

  useEffect(
    () =>
      localStorage.setItem(
        LocalStorageSettingKeys.fontSize,
        fontSize.toString()
      ),
    [fontSize]
  );

  // User settings - Word wrap
  const [wordWrap, setWordWrap] = useState<WordWrap>(
    (window.localStorage.getItem(
      LocalStorageSettingKeys.wordWrap
    ) as WordWrap) ?? "on"
  );

  useEffect(
    () => localStorage.setItem(LocalStorageSettingKeys.wordWrap, wordWrap),
    [wordWrap]
  );

  const { colorMode } = useColorMode();
  const colorModeToTheme = (option: EditorThemeSettings) => {
    if (option === "follow-app") {
      return colorMode === "dark" ? "vs-dark" : "vs";
    }
    return option;
  };

  // User settings - Theme
  const [theme, setTheme] = useState<EditorThemeOption>(
    ThemeOptions.find(
      (t) => t.value === localStorage.getItem(LocalStorageSettingKeys.theme)
    ) ?? { label: "Follow theme", value: "follow-app" }
  );

  useEffect(() => {
    if (!theme) {
      return;
    }
    localStorage.setItem(LocalStorageSettingKeys.theme, theme.value);
  }, [theme]);

  // User settings - Minimap
  const [minimapVisible, setMinimapVisible] = useState<boolean>(
    window.localStorage.getItem(LocalStorageSettingKeys.minimap) === "true"
  );
  useEffect(() => {
    localStorage.setItem(
      LocalStorageSettingKeys.minimap,
      minimapVisible.toString()
    );
  }, [minimapVisible]);

  const addCustomEditorActions = (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor
  ) => {
    // Configure custom actions - word wrap
    WordWrapOptions.forEach((wordWrap) => {
      editorInstance?.addAction?.({
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1.5,
        id: `set-wordwrap-${wordWrap.value}`,
        label: `Set Word Wrap: ${wordWrap.label}`,
        run: () => {
          if (!wordWrap.value) {
            return;
          }
          setWordWrap(wordWrap.value as WordWrap);
        }
      });
    });

    // Configure custom actions - theme
    ThemeOptions.forEach((theme) => {
      editorInstance?.addAction?.({
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1.5,
        id: `set-theme-${theme.value}`,
        label: `Set theme: ${theme.label}`,
        run: () => {
          if (!theme) {
            return;
          }

          setTheme(theme);
        }
      });
    });

    FontSizeOptions.forEach((size) => {
      editorInstance?.addAction({
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1.5,
        id: `set-font-size-${size}`,
        label: `Set Font Size: ${size}`,
        run: () => {
          setFontSize(size);
        }
      });
    });
  };

  const onEditorMounted = (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    editorRef.current = editorInstance;
    monacoRef.current = monaco;

    if (!editorRef.current) {
      return;
    }

    addCustomEditorActions(editorRef.current);
    onEditorMount?.(editorRef.current, monacoRef.current);

    // https://github.com/microsoft/monaco-editor/issues/2206
    const currentValue = value;
    if (currentValue) {
      editorRef.current.updateOptions({
        lineNumbersMinChars: Math.max(
          Math.floor(Math.log10(currentValue.split(/\r\n|\r|\n/g).length)) + 3,
          5
        )
      });
    }
  };

  const currentThemeLabel = () => {
    const themeObj = ThemeOptions.find((t) => t.value === theme.value);
    return themeObj?.label || "";
  };

  const searchCommands = (query?: string) => {
    editorRef?.current?.focus();
    editorRef?.current?.trigger("", "editor.action.quickCommand", "");

    if (!query) {
      return;
    }

    const input = document.querySelector(
      ".quick-input-box .input"
    ) as HTMLInputElement;
    input.value = query;
    input.dispatchEvent(new Event("input", { bubbles: true }));
  };

  const bottomBarBackgroundColor = useColorModeValue("gray.100", "gray.800");

  return (
    <>
      <Flex flexDirection="column" width="0" minWidth="100%">
        <Box position="relative">
          <Tooltip label="Run command (F1)" placement="top">
            <IconButton
              position="absolute"
              size="md"
              top="10px"
              right="10px"
              opacity={0.5}
              _hover={{ opacity: 1 }}
              zIndex={20}
              aria-label="Toggle Commands"
              onClick={() => searchCommands()}
              colorScheme="gray"
              icon={
                <Icon
                  _groupHover={{
                    transform: "rotate(180deg)"
                  }}
                  transition="transform 0.5s"
                  as={FontAwesomeIcon}
                  icon={faPlay}
                />
              }
            />
          </Tooltip>
          {diffComparison ? (
            <DiffEditor
              theme={colorModeToTheme(theme.value)}
              height={editorHeight[size ?? "default"]}
              original={diffComparison.value}
              modified={value}
              originalLanguage={diffComparison.language}
              modifiedLanguage={language}
              options={{
                ...diffComparison.options,
                minimap: {
                  enabled: minimapVisible
                },
                readOnly: true,
                automaticLayout: true,
                scrollBeyondLastLine: false,
                fontSize: fontSize,
                scrollbar: {
                  alwaysConsumeMouseWheel: false
                },
                wordWrap: wordWrap
              }}
              onMount={onEditorMounted}
              loading={<Spinner size="xl" />}
            />
          ) : (
            <Editor
              theme={colorModeToTheme(theme.value)}
              height={editorHeight[size ?? "default"]}
              language={language}
              value={value}
              onMount={onEditorMounted}
              onChange={onValueChange}
              onValidate={onValidationChange}
              options={{
                ...options,
                minimap: {
                  enabled: minimapVisible
                },
                automaticLayout: true,
                scrollBeyondLastLine: false,
                fontSize: fontSize,
                scrollbar: {
                  alwaysConsumeMouseWheel: false
                },
                wordWrap: wordWrap
              }}
              loading={<Spinner size="xl" />}
            />
          )}
        </Box>
        <Box position="sticky" bottom="0" zIndex="20">
          <BottomBar
            onToggleMinimap={() => setMinimapVisible((prevState) => !prevState)}
            theme={currentThemeLabel()}
            wordWrap={wordWrap}
            minimapVisible={minimapVisible}
            onEditorCommandRequest={searchCommands}
            leftChildren={leftSideBottomBarElements}
            rightChildren={rightSideBottomBarElements}
          />

          {bottomBarActionButtons && (
            <Flex
              py="6"
              justifyContent="space-between"
              bg={bottomBarBackgroundColor}
            >
              {bottomBarActionButtons}
            </Flex>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default BaseCodeEditor;
