import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Progress,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import { NavLink } from "react-router-dom";
import CopyableText from "../../../components/table/CopyableText";
import { TenantLimitDto } from "../../bi/types";
import { useTextColors } from "../hooks/colors";

const TenantExceededLimits = ({
  tenantLimits
}: {
  tenantLimits?: TenantLimitDto[];
}) => {
  const cardColor = useColorModeValue("gray.100", "gray.700");
  const color = useTextColors();

  return tenantLimits && tenantLimits.length > 0 ? (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Flex flex={1} align={"center"} justify={"space-between"}>
            <Flex gap={"1em"} align={"center"}>
              <Icon
                as={FontAwesomeIcon}
                icon={faWarning}
                color={"red.300"}
              ></Icon>
              {` ${tenantLimits.length} tenants have exceeded limits`}
            </Flex>
            <AccordionIcon />
          </Flex>
        </AccordionButton>

        <AccordionPanel>
          <Grid gridAutoColumns={"auto"} gap={"1em"}>
            {tenantLimits?.map((tenantLimit) => (
              <GridItem>
                <Card bg={cardColor}>
                  <CardHeader pb="1em">
                    <Flex justify={"space-between"}>
                      <Flex direction={"column"}>
                        <Heading size={"md"}>{tenantLimit.name}</Heading>
                        <CopyableText id={tenantLimit.id}></CopyableText>
                      </Flex>{" "}
                      <Button
                        as={NavLink}
                        to={`./tenants/${tenantLimit.id.split("/")[3]}`}
                      >
                        Go to details page
                      </Button>
                    </Flex>
                  </CardHeader>
                  <CardBody pt={0}>
                    <Grid gap={"2em"} templateColumns="repeat(3, 1fr)">
                      <GridItem>
                        <Heading size={"xxs"}>Source entity bytes</Heading>
                        <Flex
                          gap={"0.5em"}
                          color={
                            tenantLimit.limits.sourceEntityCount.bytes.usage >
                            tenantLimit.limits.sourceEntityCount.bytes.limit
                              ? "red.500"
                              : color.text
                          }
                          fontWeight={
                            tenantLimit.limits.sourceEntityCount.bytes.usage >
                            tenantLimit.limits.sourceEntityCount.bytes.limit
                              ? 500
                              : undefined
                          }
                        >
                          <Text>
                            {numeral(
                              tenantLimit.limits.sourceEntityCount.bytes.usage
                            ).format()}
                          </Text>
                          <Text>/</Text>
                          <Text>
                            {numeral(
                              tenantLimit.limits.sourceEntityCount.bytes.limit
                            ).format()}
                          </Text>
                        </Flex>
                      </GridItem>

                      <GridItem>
                        <Heading size={"xxs"}>Source entity count</Heading>
                        <Flex
                          gap={"0.5em"}
                          color={
                            tenantLimit.limits.sourceEntityCount.count.usage >
                            tenantLimit.limits.sourceEntityCount.count.limit
                              ? "red.500"
                              : color.text
                          }
                          fontWeight={
                            tenantLimit.limits.sourceEntityCount.count.usage >
                            tenantLimit.limits.sourceEntityCount.count.limit
                              ? 500
                              : undefined
                          }
                        >
                          <Text>
                            {numeral(
                              tenantLimit.limits.sourceEntityCount.count.usage
                            ).format()}
                          </Text>
                          <Text>/</Text>
                          <Text>
                            {numeral(
                              tenantLimit.limits.sourceEntityCount.count.limit
                            ).format()}
                          </Text>
                        </Flex>
                      </GridItem>

                      <GridItem>
                        <Heading size={"xxs"}>Delivery requests</Heading>
                        <Flex
                          gap={"0.5em"}
                          color={
                            tenantLimit.limits.deliveryApiRequests.count >
                            tenantLimit.limits.deliveryApiRequests.limit
                              ? "red.500"
                              : color.text
                          }
                          fontWeight={
                            tenantLimit.limits.deliveryApiRequests.count >
                            tenantLimit.limits.deliveryApiRequests.limit
                              ? 500
                              : undefined
                          }
                        >
                          <Text>
                            {numeral(
                              tenantLimit.limits.deliveryApiRequests.count
                            ).format()}
                          </Text>
                          <Text>/</Text>
                          <Text>
                            {numeral(
                              tenantLimit.limits.deliveryApiRequests.limit
                            ).format()}
                          </Text>{" "}
                        </Flex>
                        <Text
                          color={
                            tenantLimit.limits.deliveryApiRequests.usage > 1
                              ? "red.500"
                              : color.text
                          }
                          fontWeight={
                            tenantLimit.limits.deliveryApiRequests.usage > 1
                              ? 500
                              : undefined
                          }
                        >
                          Current usage:{" "}
                          {tenantLimit.limits.deliveryApiRequests.usage}
                        </Text>
                        <Text
                          color={
                            tenantLimit.limits.deliveryApiRequests.projected > 1
                              ? "red.500"
                              : color.text
                          }
                          fontWeight={
                            tenantLimit.limits.deliveryApiRequests.projected > 1
                              ? 500
                              : undefined
                          }
                        >
                          Projected usage:{" "}
                          {tenantLimit.limits.deliveryApiRequests.projected}
                        </Text>
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Progress isIndeterminate={true}></Progress>
  );
};

export default TenantExceededLimits;
