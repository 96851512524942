"use client";

import { Flex, Icon, Text } from "@chakra-ui/react";
import {
  faCheck,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISchemaPendingChangesBottomBarButtonProps } from "../types";

const SchemaPendingChangesBottomBarButton = ({
  hasPendingChanges
}: ISchemaPendingChangesBottomBarButtonProps) => {
  return (
    <Flex px="2" fontSize="xs" alignItems="center">
      <Icon
        as={FontAwesomeIcon}
        icon={hasPendingChanges ? faTriangleExclamation : faCheck}
        mr={1}
      />
      <Text cursor="default">
        {hasPendingChanges
          ? "Your schema has unsaved changes"
          : "All changes saved"}
      </Text>
    </Flex>
  );
};

export default SchemaPendingChangesBottomBarButton;
