import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { MANAGEMENT_CLIENTS } from "../../../constants/queryKey";
import { ManagementClientService } from "../management-client.service";

export const regenerateAccessKeyManagementClient = ({
  managementClientGuid
}: {
  managementClientGuid: string;
}) => {
  const managementClientService = container.resolve(ManagementClientService);
  return managementClientService.regenerateAccessKey(managementClientGuid);
};

export const useRegenerateAccessKeyManagementClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: regenerateAccessKeyManagementClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MANAGEMENT_CLIENTS]
      });
    }
  });
};
