import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { faSwap, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { editor, Monaco } from "monaco-editor";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BasicJsonCodeEditor from "../../components/CodeEditor/BasicJsonCodeEditor";
import CopyableText from "../../components/table/CopyableText";
import StatsDataStorageTile from "../../components/Tiles/StatsDataStorageTile";
import StatsDeliveryApiRequestsTile from "../../components/Tiles/StatsDeliveryApiRequestsTile";
import StatsQueueSizeTile from "../../components/Tiles/StatsQueueSizeTile";
import StatsSourceEntitiesTile from "../../components/Tiles/StatsSourceEntitiesTile";
import { useEnvironments } from "../../features/environments/api/getEnvironments";
import { LogsProvider } from "../../features/logs/contexts/logs";
import useDeliveryRequestsMetrics from "../../features/metrics/api/useDeliveryRequestsMetrics";
import useQueueJobsMetrics from "../../features/metrics/api/useQueuedJobsMetrics";
import useSourceEntitiesMetrics from "../../features/metrics/api/useSourceEntitiesMetrics";
import { useTenants } from "../../features/tenants/api/getTenants";
import useTenant from "../../features/tenants/api/useTenant";
import useUpdateTenantConfig from "../../features/tenants/api/useUpdateTenantConfig";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { ITenant, TenantConfig } from "../../features/tenants/types";
import { daysUntilDeletion } from "../../features/tenants/util";
import NotFound from "../../NotFound";
import LogsPage from "../settings/logs";

const Header = ({ tenant }: { tenant: ITenant }) => {
  const tenantSwitcher = useTenantSwitcher();

  return (
    <Flex gap={"2rem"} align={"center"} justify={"center"} pb={"1rem"}>
      <Flex direction={"column"}>
        {tenant.trashedAt && (
          <Text>
            <Icon
              as={FontAwesomeIcon}
              icon={faWarning}
              color={"yellow.500"}
            ></Icon>{" "}
            Tenant is trashed and will be deleted in{" "}
            {daysUntilDeletion(tenant.trashedAt)} days
          </Text>
        )}
        <Heading>{tenant.name}</Heading>
        <CopyableText id={tenant.id.idValue}></CopyableText>
      </Flex>
      <ButtonGroup>
        <Tooltip
          isDisabled={tenant.isUsingSourceGroups}
          label={"Tenant is deprecated"}
        >
          <Button
            leftIcon={<Icon as={FontAwesomeIcon} icon={faSwap}></Icon>}
            aria-label="switch tenant"
            isDisabled={!tenant.isUsingSourceGroups}
            onClick={() =>
              tenantSwitcher.switch(tenant, {
                redirectToHome: false
              })
            }
          >
            Switch tenant
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};
const EditorActionBar = ({
  onClick,
  isDisabled
}: {
  onClick: () => void;
  isDisabled: boolean;
}) => {
  return (
    <Flex justify={"flex-end"}>
      <ButtonGroup>
        <Button data-es-save-btn isDisabled={isDisabled} onClick={onClick}>
          Save
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

const TenantDetailsPage = () => {
  const { guid } = useParams();
  if (!guid) {
    throw new Error("Should not happen");
  }
  const toast = useToast();
  const tenantId = `gid://Tenant/${guid}`;

  const deliveryStats = useDeliveryRequestsMetrics({
    tenantId
  });
  const queueStats = useQueueJobsMetrics({ tenantId });
  const sourceEntityStats = useSourceEntitiesMetrics({ tenantId });
  const environments = useEnvironments({ tenantId });

  const [editorContent, setEditorContent] = useState<string>("");

  const {
    data: tenant,
    isLoading: tenantIsLoading,
    refetch: refetchTenant,
    isRefetching: isRefetchingTenant
  } = useTenant({ tenantGuid: guid });

  // fetching the mapped tenant dto to get plans and tenant object that work inside app
  const {
    data: tenantDtos,
    isLoading: tenantDtoIsLoading,
    refetch: refetchTenantDto,
    isRefetching: isRefetchingTenantDto
  } = useTenants({
    tenantIds: [guid]
  });

  const isLoading = tenantIsLoading || tenantDtoIsLoading;
  const isRefetching = isRefetchingTenant || isRefetchingTenantDto;

  const refetch = useCallback(() => {
    Promise.all([refetchTenant(), refetchTenantDto()]).catch(console.error);
  }, [refetchTenant, refetchTenantDto]);

  const [tenantConfig, setTenantConfig] = useState<TenantConfig | null>(null);

  const update = useUpdateTenantConfig();

  useEffect(() => {
    if (tenant) {
      const { id: _id, name: _name, trashedAt: _trashedAt, ...config } = tenant;
      setTenantConfig(config);
    }
  }, [tenant]);

  useEffect(() => {
    if (tenantConfig) {
      setEditorContent(JSON.stringify(tenantConfig, null, 4));
    }
  }, [tenantConfig]);

  useEffect(() => {
    if (update.isSuccess) {
      refetch();
    }
  }, [update.isSuccess, refetch]);

  const mutate = () => {
    if (!canSave()) {
      return;
    }
    const promise = update.mutateAsync({
      tenantGuid: guid,
      tenantConfig: JSON.parse(editorContent) as TenantConfig
    });
    toast.promise(promise, {
      success: { title: "Successfully updated tenant config" },
      loading: { title: "Updating tenant config" },
      error: (error) => ({
        title: "Failed updating tenant config",
        description: error.message
      })
    });
  };

  const onEditorMount = (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    // Configure custom editor actions
    editorInstance.addAction({
      id: "save",
      label: "Save",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,
      run: () =>
        // workaround to get state into a method that is set on mount
        (
          document.querySelector("[data-es-save-btn]") as HTMLButtonElement
        ).click()
    });
  };

  if (isLoading) {
    return <Skeleton></Skeleton>;
  }

  if (!tenant || !tenantDtos) {
    return <NotFound></NotFound>;
  }

  const canSave = () => {
    try {
      const json = JSON.parse(editorContent) as TenantConfig;
      return !!editorContent && !_.isEqual(json, tenantConfig);
    } catch (error) {
      return false;
    }
  };

  const [tenantDto] = tenantDtos;

  return (
    <Flex flex={1} direction={"column"} gap={"2em"}>
      <Header tenant={tenant}></Header>
      <Flex gap={"2em"}>
        <Flex direction={"column"} gap={"2em"} flex={20}>
          <StatsDeliveryApiRequestsTile
            deliveryStats={deliveryStats.data}
            tenant={tenantDto}
          ></StatsDeliveryApiRequestsTile>
          <StatsQueueSizeTile
            queuedJobsDto={queueStats.data}
            environments={environments.data}
          ></StatsQueueSizeTile>
        </Flex>
        <Flex gap={"1em"} direction={"column"} flex={60}>
          <BasicJsonCodeEditor
            value={editorContent}
            onValueChange={(value) => setEditorContent(value ?? "")}
            options={{
              readOnly: update.isPending || isLoading || isRefetching
            }}
            onEditorMount={onEditorMount}
          ></BasicJsonCodeEditor>
          <EditorActionBar
            onClick={mutate}
            isDisabled={!canSave()}
          ></EditorActionBar>
        </Flex>
        <Flex direction={"column"} gap={"2em"} flex={20}>
          <StatsSourceEntitiesTile
            sourceEntitiesMetrics={sourceEntityStats.data}
            tenant={tenantDto}
          />
          <StatsDataStorageTile
            sourceEntityMetrics={sourceEntityStats.data}
            tenant={tenantDto}
          ></StatsDataStorageTile>
        </Flex>
      </Flex>
      <LogsProvider>
        {tenant && <LogsPage tenantId={tenant.id.idValue}></LogsPage>}
      </LogsProvider>
    </Flex>
  );
};

export default TenantDetailsPage;
