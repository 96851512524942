import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import HelpIcon from "../../../components/HelpIcon";
import useReactSelectStyles from "../../../styles/react-select-style";
import ISelectOption from "../../../types/selectInput";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { IEnvironmentResponse } from "../../environments/types";
import {
  ICreateDestinationEnvironmentConfigurationDto,
  IDestination
} from "../types";

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const DestinationEnvironmentConfigurationCreateModal = ({
  app,
  environmentOptions,
  environmentClientOptions,
  isOpen,
  onClose,
  onSubmit
}: {
  app: IDestination;
  environmentOptions: IEnvironmentResponse[];
  environmentClientOptions: IEnvironmentClientResponse[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    destinationConfiguration: Omit<
      ICreateDestinationEnvironmentConfigurationDto,
      "alias"
    >
  ) => void;
}) => {
  const reactSelectStyles = useReactSelectStyles();

  const modalBg = useColorModeValue("gray.100", "gray.800");
  const inputBgColorMode = useColorModeValue("white", "gray.700");

  const defaultState: Omit<
    ICreateDestinationEnvironmentConfigurationDto,
    "alias"
  > = {
    environmentId: "",
    environmentClientId: ""
  };

  const [destinationConfiguration, setDestinationConfiguration] =
    useState<
      DeepPartial<Omit<ICreateDestinationEnvironmentConfigurationDto, "alias">>
    >(defaultState);

  const [customData, setCustomData] = useState<Record<string, unknown>>({});
  const [invalidMessage, setInvalidMessage] = useState<string>();

  useEffect(() => {
    if (!destinationConfiguration.environmentId) {
      setInvalidMessage("Environment must be selected");
      return;
    } else if (
      app.ui?.enterspeedEnvironmentClientApiKey &&
      !destinationConfiguration?.environmentClientId
    ) {
      setInvalidMessage("Environment client must be selected");
      return;
    }
    const missingFields = Object.entries(app.ui ?? {})
      .filter(
        ([key, value]) =>
          !value.optional && key !== "enterspeedEnvironmentClientApiKey"
      )
      .filter(([key]) => !customData[key])
      .map(([_, value]) => value);
    if (missingFields.length) {
      setInvalidMessage(`${missingFields[0].label} is required`);
    } else {
      setInvalidMessage(undefined);
    }
  }, [destinationConfiguration, customData, app.ui]);

  useEffect(() => {
    setDestinationConfiguration((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        ...customData
      }
    }));
  }, [customData]);

  const close = () => {
    onClose();
    setCustomData({});
    setDestinationConfiguration({ ...defaultState });
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay></ModalOverlay>
      <ModalContent bg={modalBg}>
        <ModalCloseButton
          onClick={() => {
            setDestinationConfiguration(defaultState);
            close();
          }}
        ></ModalCloseButton>
        <ModalHeader>Create new environment configuration</ModalHeader>
        <ModalBody>
          <Text>
            You can only create one configuration for each environment
          </Text>
          <Form>
            <FormControl>
              <FormLabel pt="4">Select environment</FormLabel>
              <Select
                required
                chakraStyles={reactSelectStyles}
                useBasicStyles
                options={environmentOptions.map((e) => ({
                  label: e.name,
                  value: e.id.idValue
                }))}
                onChange={(change) =>
                  setDestinationConfiguration((prev) => ({
                    ...prev,
                    environmentId: (change as ISelectOption).value as string
                  }))
                }
              ></Select>
            </FormControl>
            {destinationConfiguration.environmentId && (
              <>
                <FormControl>
                  {app.ui?.enterspeedEnvironmentClientApiKey && (
                    <FormControl key={"environmentClient"}>
                      <FormLabel pt="4">Select environment client</FormLabel>
                      <Select
                        required
                        chakraStyles={reactSelectStyles}
                        useBasicStyles
                        options={environmentClientOptions
                          .filter((e) =>
                            destinationConfiguration.environmentId?.includes(
                              e.id.environmentGuid
                            )
                          )
                          .map((e) => ({
                            label: e.name,
                            value: e.id.idValue
                          }))}
                        onChange={(change) =>
                          setDestinationConfiguration((prev) => {
                            const envClient = environmentClientOptions.find(
                              (f) =>
                                f.id.idValue ===
                                ((change as ISelectOption).value as string)
                            );
                            if (!envClient) {
                              return prev;
                            }
                            return {
                              ...prev,
                              environmentClientId: envClient.id.idValue,
                              settings: {
                                ...prev.settings,
                                enterspeedEnvironmentClientApiKey:
                                  envClient.accessKey
                              }
                            };
                          })
                        }
                      ></Select>
                    </FormControl>
                  )}
                  {app.ui ? (
                    Object.entries(app.ui)
                      .filter(
                        ([key]) => key !== "enterspeedEnvironmentClientApiKey"
                      )
                      .map(([key, value]) => {
                        return (
                          <FormControl key={key}>
                            <FormLabel pt="4">
                              {value.label}
                              {value.helpText && (
                                <HelpIcon label={value.helpText}></HelpIcon>
                              )}
                            </FormLabel>
                            <Input
                              bg={inputBgColorMode}
                              value={(customData[key] as string) || ""}
                              onBlur={(e) =>
                                setCustomData((prev) => {
                                  prev[key] = e.target.value.trim();
                                  return { ...prev };
                                })
                              }
                              onChange={(e) =>
                                setCustomData((prev) => {
                                  prev[key] = e.target.value;
                                  return { ...prev };
                                })
                              }
                            ></Input>
                          </FormControl>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </FormControl>
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              onClick={() => {
                close();
                setDestinationConfiguration(defaultState);
              }}
            >
              Cancel
            </Button>
            <Tooltip isDisabled={!invalidMessage} label={invalidMessage}>
              <Button
                isDisabled={!!invalidMessage}
                onClick={() => {
                  close();

                  onSubmit(
                    destinationConfiguration as Omit<
                      ICreateDestinationEnvironmentConfigurationDto,
                      "alias"
                    >
                  );
                  setDestinationConfiguration(defaultState);
                }}
              >
                Create
              </Button>
            </Tooltip>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DestinationEnvironmentConfigurationCreateModal;
