import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { container } from "tsyringe";
import { UNCONFIGURED_HOSTNAMES } from "../../../constants/queryKey";
import { SourceEntityService } from "../source-entity.service";

export const getUnconfiguredHostnames = async () => {
  const sourceEntityService = container.resolve(SourceEntityService);
  return sourceEntityService.getUnconfiguredHostnames();
};

export const useUnconfiguredHostnames = () => {
  const flags = useFlags();
  return useQuery({
    queryKey: [UNCONFIGURED_HOSTNAMES],
    queryFn: () => getUnconfiguredHostnames(),
    enabled: flags.unconfiguredHostnames as boolean
  });
};
