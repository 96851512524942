import { injectAll, singleton } from "tsyringe";
import { ObjectResult } from "../../../types/codeEditor";
import {
  IEvaluatedSchemaMetadata,
  ISchemaEvaluationService,
  ISchemaVersionFormatEvaluationTenantConfig,
  SchemaFormat,
  SchemaType
} from "../types";
import { ISchemaVisitor } from "../utils/validation/base";
import { INJECTION_TOKEN_VALIDATION_VISITORS } from "../utils/validation/validation.module";

@singleton()
export class SchemaEvaluationService implements ISchemaEvaluationService {
  constructor(
    @injectAll(INJECTION_TOKEN_VALIDATION_VISITORS)
    private readonly visitors: ISchemaVisitor[]
  ) {}
  evaluate(
    type: SchemaType,
    schemaFormat: SchemaFormat,
    schemaVersionData: string,
    tenantConfig: ISchemaVersionFormatEvaluationTenantConfig
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    const matchingVisitor = this.visitors.find((f) =>
      f.canVisit(type, schemaFormat, tenantConfig)
    );
    if (matchingVisitor) {
      return matchingVisitor.visit(schemaVersionData);
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: []
    });
  }
}
