import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import {
  faGlobe,
  faKey,
  faPencil,
  faTrashCan
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";

import { Box, Flex, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useEnvironmentClients } from "../api/getEnvironmentClients";
import { EnvironmentClientTableModalType, IEnvironmentClient } from "../types";

import EnvironmentBadge from "../../../components/EnvironmentBadge";
import ReloadButton from "../../../components/ReloadButton";
import SecretKey from "../../../components/SecretKey";
import StringListPopover from "../../../components/StringListPopover";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { DataTableAction } from "../../../types/dataTable";
import { useDomains } from "../../domains/api/getDomains";
import { IDomain } from "../../domains/types";
import EnvironmentClientActionModal from "./EnvironmentClientActionModal";

const EnvironmentClientsTable = () => {
  const { data, isLoading, refetch, isRefetching } = useEnvironmentClients();
  const { data: dataDomains } = useDomains();

  const [accessKeyUpdated, setAccessKeyUpdated] = useState("");

  const handleAccessKeyUpdated = (accessKey: string) => {
    setAccessKeyUpdated(accessKey);
  };

  const domains = dataDomains
    ? dataDomains.map(
        (item) =>
          ({
            name: item.name,
            hostnames: item.hostnames,
            domainGuid: item.id.domainGuid,
            id: item.id.idValue
          } as IDomain)
      )
    : [];

  const tableData = data
    ? data.map(
        (item) =>
          ({
            name: item.name,
            accessKey: item.accessKey,
            environmentName: item.environmentName,
            clientGuid: item.id?.clientGuid,
            environmentGuid: item.id?.environmentGuid,
            id: item.id?.idValue,
            domainIds: item.domainIds,
            domains: getDomainsByListOfIds(item.domainIds)
          } as IEnvironmentClient)
      )
    : [];

  const NoDomainsWarning = () => {
    return (
      <Flex ml="4" alignItems="center">
        <Tooltip label="Environment clients are unusable without at least one domain">
          <Icon
            color="yellow.500"
            size="xl"
            as={FontAwesomeIcon}
            icon={faTriangleExclamation}
          />
        </Tooltip>
      </Flex>
    );
  };

  const columnHelper = createColumnHelper<IEnvironmentClient>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.name}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Name"
    }),
    columnHelper.accessor("environmentName", {
      cell: (info) => <EnvironmentBadge name={info.getValue()} />,
      header: "Environment"
    }),
    columnHelper.accessor("accessKey", {
      cell: (info) => {
        return (
          <SecretKey
            content={info.getValue()}
            updated={accessKeyUpdated === info.getValue()}
          />
        );
      },
      header: "API Key"
    }),
    columnHelper.accessor("domains", {
      cell: (info) => (
        <Flex>
          <StringListPopover list={getDomainNames(info.getValue())} />
          {!info.getValue().length && <NoDomainsWarning />}
        </Flex>
      ),
      header: "Domains"
    })
  ];

  function getDomainsByListOfIds(domainIds: string[]) {
    return domainIds.reduce((items: IDomain[], id: string) => {
      const domain = domains?.find((item) => item.id === id);
      if (domain) {
        items.push(domain);
      }
      return items;
    }, []);
  }

  function getDomainNames(d: IDomain[]) {
    return d.map((domain) => domain.name);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<EnvironmentClientTableModalType>();
  const [rowData, setRowData] = useState<IEnvironmentClient | null>(null);

  const handleActionClick = (
    row: IEnvironmentClient,
    type: EnvironmentClientTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<
    EnvironmentClientTableModalType,
    IEnvironmentClient
  >[] = [
    {
      type: EnvironmentClientTableModalType.EDIT,
      icon: faPencil,
      onClick: (row: IEnvironmentClient) => {
        handleActionClick(row, EnvironmentClientTableModalType.EDIT);
      }
    },
    {
      type: EnvironmentClientTableModalType.EDIT_DOMAINS,
      icon: faGlobe,
      onClick: (row: IEnvironmentClient) => {
        handleActionClick(row, EnvironmentClientTableModalType.EDIT_DOMAINS);
      }
    },
    {
      type: EnvironmentClientTableModalType.REGENERATE_API_KEY,
      icon: faKey,
      onClick: (row: IEnvironmentClient) => {
        handleActionClick(
          row,
          EnvironmentClientTableModalType.REGENERATE_API_KEY
        );
      }
    },
    {
      type: EnvironmentClientTableModalType.DELETE,
      icon: faTrashCan,
      onClick: (row: IEnvironmentClient) => {
        handleActionClick(row, EnvironmentClientTableModalType.DELETE);
      }
    }
  ];

  return (
    <>
      <ReloadButton loading={isRefetching} onClick={refetch} />
      <DataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        actions={actions}
      />
      {rowData && (
        <EnvironmentClientActionModal
          type={modalType}
          environmentClient={rowData}
          isOpen={isOpen}
          onClose={onClose}
          onAccessKeyUpdated={handleAccessKeyUpdated}
        />
      )}
    </>
  );
};

export default EnvironmentClientsTable;
