import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { LOGS } from "../../../constants/queryKey";
import { QueryOptions } from "../../../types/api";
import { useDomains } from "../../domains/api/getDomains";
import { IDomainResponse } from "../../domains/types";
import { useEnvironmentClients } from "../../environment-clients/api/getEnvironmentClients";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { IEnvironmentResponse } from "../../environments/types";
import { useManagementClients } from "../../management-clients/api/getManagementClients";
import { IManagementClientResponse } from "../../management-clients/types";
import { useSchemas } from "../../schemas/api/getSchemas";
import { ISchemaListResponse } from "../../schemas/types";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { ISourceGroupsResponse } from "../../source-groups/types";
import { useTenantUsers } from "../../tenants/api/getTenantUsers";
import { ITenantUser } from "../../tenants/types";
import { LogsService } from "../logs.service";
import { IAggregatedLogsPayload, ILogsPayload, ILogsState } from "../types";

interface ILogsOptions {
  refreshInterval: number;
}

export const getAggregatedLogs = async (
  payload: IAggregatedLogsPayload,
  options?: QueryOptions
) => {
  const logsService = container.resolve(LogsService);
  return logsService.getAggregatedLogs(payload, options);
};

export const getLogs = async (
  payload: ILogsPayload,
  options?: QueryOptions
) => {
  const logsService = container.resolve(LogsService);
  return logsService.getLogs(payload, options);
};

export const getEnhancedLogs = async (
  payload: ILogsPayload,
  schemas: ISchemaListResponse[],
  environments: IEnvironmentResponse[],
  sources: ISourceGroupsResponse[],
  environmentClients: IEnvironmentClientResponse[],
  tenantUsers: ITenantUser[],
  domains: IDomainResponse[],
  managementClients: IManagementClientResponse[],
  options?: QueryOptions
) => {
  const logsService = container.resolve(LogsService);
  return logsService.getEnhancedLogs(
    payload,
    schemas,
    environments,
    sources,
    environmentClients,
    tenantUsers,
    domains,
    managementClients,
    options
  );
};

export const useLogs = (
  payload: ILogsPayload,
  options?: ILogsOptions,
  queryOptions?: QueryOptions
) => {
  // if absolute period is chosen, but has 'to' = 'now', calculate 'to' value
  let toDate: string | undefined | null = payload.toDate;
  if (payload.toDate === "now") {
    toDate = new Date().toISOString();
  }

  return useInfiniteQuery({
    queryKey: [LOGS, JSON.stringify(payload)],
    queryFn: ({
      pageParam: scrollId
    }: QueryFunctionContext<unknown[], string>) =>
      getLogs({ ...payload, scrollId, toDate }, queryOptions),
    getNextPageParam: (lastPage) => lastPage.scrollId,
    initialPageParam: "",
    refetchInterval: options?.refreshInterval
  });
};

export type LogPageOptions = { tenantId?: string };

export const useEnhancedLogs = (
  state: ILogsState,
  options?: ILogsOptions,
  queryOptions?: QueryOptions
) => {
  const { data: dataSources, isLoading: isLoadingSourceGroups } =
    useSourceGroups();
  const { data: dataEnvironments, isLoading: isLoadingEnvironments } =
    useEnvironments();
  const { data: dataSchemas, isLoading: isLoadingSchemas } = useSchemas();
  const {
    data: dataEnvironmentClients,
    isLoading: isLoadingEnvironmentClients
  } = useEnvironmentClients();
  const { data: dataTenantUsers, isLoading: isLoadingTenantUsers } =
    useTenantUsers({ first: 25 });
  const { data: dataDomains, isLoading: isLoadingDomains } = useDomains();
  const { data: dataManagementClients, isLoading: isLoadingManagementClients } =
    useManagementClients();

  const isReady =
    !isLoadingSourceGroups &&
    !isLoadingEnvironments &&
    !isLoadingSchemas &&
    !isLoadingEnvironmentClients &&
    !isLoadingTenantUsers &&
    !isLoadingManagementClients &&
    !isLoadingDomains;
  const payload = {
    condition: state.condition,
    fromDate: state.period?.from,
    toDate: state.period?.to,
    sortOrder: state.sortingDirection,
    types: state.types
  };

  // if absolute period is chosen, but has 'to' = 'now', calculate 'to' value
  let toDate: string | undefined | null = payload.toDate;
  if (payload.toDate === "now") {
    toDate = new Date().toISOString();
  }

  return useInfiniteQuery({
    queryKey: [LOGS, JSON.stringify(payload)],
    queryFn: ({
      pageParam: scrollId
    }: QueryFunctionContext<unknown[], string>) =>
      getEnhancedLogs(
        { ...payload, scrollId, toDate },
        dataSchemas as ISchemaListResponse[],
        dataEnvironments as IEnvironmentResponse[],
        dataSources as ISourceGroupsResponse[],
        dataEnvironmentClients as IEnvironmentClientResponse[],
        dataTenantUsers?.pages?.flatMap((f) => f.results) as ITenantUser[],
        dataDomains as IDomainResponse[],
        dataManagementClients as IManagementClientResponse[],
        queryOptions
      ),
    getNextPageParam: (lastPage) => lastPage.scrollId,
    initialPageParam: "",
    refetchInterval: options?.refreshInterval,
    enabled: isReady
  });
};
