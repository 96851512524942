import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { ENVIRONMENT_CLIENTS } from "../../../constants/queryKey";
import { EnvironmentClientService } from "../environment-client.service";

export const deleteEnvironmentClient = ({
  environmentId,
  clientId
}: {
  environmentId: string;
  clientId: string;
}) => {
  const environmentClientService = container.resolve(EnvironmentClientService);
  return environmentClientService.deleteEnvironmentClient(
    environmentId,
    clientId
  );
};

export const useDeleteEnvironmentClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEnvironmentClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ENVIRONMENT_CLIENTS]
      });
    }
  });
};
