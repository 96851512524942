import { Icon, Tooltip } from "@chakra-ui/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

const HelpIcon = ({
  label,
  ml = 4,
  size
}: {
  label: string | ReactNode;
  ml?: number;
  size?: SizeProp;
}) => {
  return (
    <Tooltip label={label}>
      <Icon
        as={FontAwesomeIcon}
        icon={faQuestionCircle}
        ml={ml}
        color="gray.500"
        size={size}
      />
    </Tooltip>
  );
};

export default HelpIcon;
