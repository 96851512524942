import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";
import { useShallow } from "zustand/react/shallow";
import { DeliveryRequestsMetricsDto } from "../../features/metrics/metrics.service";
import { useTenantStore } from "../../features/tenants/store";
import { ITenant } from "../../features/tenants/types";
import MetricsPlot from "./MetricsPlot";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";
import MetricsToolTip from "./MetricsToolTip";
import useGaugeData from "./useGaugeData";

const StatsDeliveryApiRequestsTile = ({
  deliveryStats,
  isLoading,
  tenant,
  removeTileMenu
}: {
  deliveryStats?: DeliveryRequestsMetricsDto;
  isLoading?: boolean;
  tenant?: ITenant;
  removeTileMenu?: ReactNode;
}) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );
  const flags = useFlags();
  const { enabled, features } = flags.dashboard as {
    enabled: boolean;
    features: string[];
  };

  const usedTenant = tenant ?? activeTenant;

  const limit = usedTenant.plan?.deliveryApiRequestCountLimit;
  const usage = deliveryStats?.deliveryApiRequests ?? 0;

  const projected =
    enabled && features.includes("enableProjections")
      ? getProjection(usage)
      : undefined;

  const data = useGaugeData(
    { usage, limit, projected },
    {
      valueFormat: ".2~s"
    }
  );

  const help = `The current month's total Delivery API requests ${
    limit ? "and the limit based on the tenant's plan" : ""
  } (updated every ~1 minute)`;

  const tooltipMetrics = [{ label: "Usage", value: usage }];

  if (limit) {
    tooltipMetrics.push({ label: "Limit", value: limit });
  }

  if (projected) {
    tooltipMetrics.push({ label: "Projected", value: projected });
  }

  return (
    <MetricsTile
      title="Delivery requests"
      help={help}
      removeTileMenu={removeTileMenu}
    >
      <MetricsTileBody
        tooltipBody={
          <>
            <MetricsToolTip
              title={`Delivery requests for ${new Date(
                Date.now()
              ).toLocaleString("en-US", { month: "long" })}`}
              metrics={tooltipMetrics}
            ></MetricsToolTip>
          </>
        }
      >
        <MetricsPlot data={data} isLoading={isLoading}></MetricsPlot>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default StatsDeliveryApiRequestsTile;

const getProjection = (usage: number) => {
  const now = new Date(Date.now());

  const dayPassedInCurrentMonth = now.getDate();
  const daysInMonth = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();
  return (usage / dayPassedInCurrentMonth) * daysInMonth;
};
