import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useMenuButtonColors } from "../features/tenants/hooks/colors";
import NumberCircle from "./NumberCircle";

const StringListPopover = ({ list }: { list: string[] }) => {
  const { bg, active } = useMenuButtonColors();

  if (!list.length) {
    return <NumberCircle number={0} />;
  }

  const numberOfStrings = list.length;

  return (
    <Menu>
      <MenuButton
        _hover={{
          div: {
            backgroundColor: bg
          }
        }}
        _active={{
          div: {
            backgroundColor: active.bg
          }
        }}
      >
        <NumberCircle number={numberOfStrings} />
      </MenuButton>
      <MenuList>
        {list.map((listItem) => {
          return (
            <MenuItem
              key={listItem}
              py="3"
              pointerEvents="none"
              _focus={{
                bg: "transparent"
              }}
              _active={{
                bg: "transparent"
              }}
            >
              {listItem}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default StringListPopover;
