import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from "@chakra-ui/react";
import { useRef } from "react";
import { IConfirmModalProps } from "../../types/modal";

const ConfirmModal = ({
  title,
  children,
  isOpen,
  onClose,
  onSuccess
}: IConfirmModalProps) => {
  const initialRef = useRef(null);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
      >
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>{children}</ModalBody>

            <ModalFooter>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => onSuccess()}
                ml={3}
                ref={initialRef}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default ConfirmModal;
