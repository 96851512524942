import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SCHEMAS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";
import { ISchemaEditPayload } from "../types";

export const editSchema = ({
  schemaGuid,
  payload
}: {
  schemaGuid: string;
  payload: ISchemaEditPayload;
}) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.editSchema(schemaGuid, payload);
};

export const useEditSchema = (schemaGuid: string) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: editSchema,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SCHEMAS] });
      await queryClient.invalidateQueries({
        queryKey: [`SCHEMA-${schemaGuid}`]
      });

      toast({
        title: "Schema was edited",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error editing schema",
        status: "error",
        ...defaultToast
      });
    }
  });
};
