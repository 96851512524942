import { Box, useColorMode } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const Logo = () => {
  const { colorMode } = useColorMode();

  return (
    <NavLink to="/">
      <Box
        _hover={{ opacity: 0.75 }}
        width={{ base: "150px", lg: "180px" }}
        fill={colorMode === "light" ? "#2c2a2a" : "white"}
      >
        <SVGLogo />
      </Box>
    </NavLink>
  );
};

export const SVGLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 116.3623 20.74316"
      xmlSpace="preserve"
    >
      <path
        d="M10.97852,2.25098c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203H2.82373
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11621v4.36426c0,0.07715,0.03857,0.11621,0.1167,0.11621h5.34326
                  c0.07764,0,0.14355,0.02734,0.19824,0.08203c0.0542,0.05469,0.08154,0.12012,0.08154,0.19824V8.9834
                  c0,0.07715-0.02734,0.14355-0.08154,0.19824C8.31055,9.23633,8.24463,9.2627,8.16699,9.2627H2.82373
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11719v4.50293C2.70703,13.96094,2.74561,14,2.82373,14h7.95654
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.77344
                  c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-10.5
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203C0.02734,16.19727,0,16.13086,0,16.05273V0.2793
                  c0-0.07715,0.02734-0.14355,0.08203-0.19824C0.13623,0.02734,0.20215,0,0.28027,0h10.5
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.77344
                  C11.06006,2.13086,11.03271,2.19727,10.97852,2.25098z"
      />
      <path
        d="M22.29492,5.34277c0.75439,0.74707,1.13184,1.76562,1.13184,3.05664v7.65332
                  c0,0.07812-0.02734,0.14453-0.08203,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203H21
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08203-0.12012-0.08203-0.19824V8.95996
                  c0-0.71582-0.20215-1.29492-0.60645-1.73828c-0.40479-0.44336-0.94141-0.66504-1.60986-0.66504
                  c-0.66943,0-1.20996,0.21777-1.62207,0.65332s-0.61816,1.01074-0.61816,1.72656v7.11621
                  c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-2.14697
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08154-0.12012-0.08154-0.19824V4.68945
                  c0-0.07715,0.02686-0.14355,0.08154-0.19824c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h2.14697
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v0.74707
                  c0,0.04688,0.01514,0.07812,0.04688,0.09277c0.03076,0.01562,0.0542,0,0.06982-0.0459
                  c0.6377-0.84082,1.60205-1.26074,2.89355-1.26074C20.5332,4.22266,21.54004,4.59668,22.29492,5.34277z"
      />
      <path
        d="M32.4917,6.52148c-0.05469,0.05469-0.12061,0.08105-0.19824,0.08105h-2.38037
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11719v5.62305c0,0.5918,0.12842,1.01953,0.38525,1.2832
                  c0.25635,0.26465,0.66504,0.39746,1.2251,0.39746h0.69971c0.07764,0,0.14355,0.02734,0.19824,0.08105
                  c0.05469,0.05469,0.08203,0.12109,0.08203,0.19824v1.75c0,0.17188-0.09326,0.27246-0.28027,0.30371l-1.21338,0.02344
                  c-1.229,0-2.14648-0.20996-2.75342-0.62988c-0.60645-0.41992-0.91797-1.21387-0.93311-2.37988V6.71973
                  c0-0.07812-0.03906-0.11719-0.1167-0.11719h-1.33008c-0.07812,0-0.14404-0.02637-0.19824-0.08105
                  c-0.05469-0.05469-0.08154-0.12012-0.08154-0.19824V4.68945c0-0.07715,0.02686-0.14355,0.08154-0.19824
                  c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h1.33008c0.07764,0,0.1167-0.03906,0.1167-0.11719V1.56348
                  c0-0.07812,0.02686-0.14453,0.08154-0.19922c0.05469-0.05371,0.12061-0.08105,0.19824-0.08105h2.03027
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19922v2.72949
                  c0,0.07812,0.03857,0.11719,0.1167,0.11719h2.38037c0.07764,0,0.14355,0.02734,0.19824,0.08105
                  c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.63379C32.57324,6.40137,32.5459,6.4668,32.4917,6.52148z"
      />
      <path
        d="M45.12646,10.33594l-0.02344,0.72363c0,0.18652-0.09326,0.28027-0.27979,0.28027h-7.30322
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11621c0.04639,0.51367,0.1167,0.86328,0.20996,1.0498
                  c0.37305,1.12012,1.2832,1.68848,2.72998,1.7041c1.05762,0,1.89746-0.43555,2.52002-1.30664
                  c0.06201-0.09375,0.13965-0.14062,0.2334-0.14062c0.06152,0,0.11621,0.02344,0.16309,0.07031l1.44678,1.19043
                  c0.14014,0.1084,0.16309,0.23242,0.06982,0.37305c-0.48242,0.68457-1.12402,1.21289-1.9248,1.58691
                  c-0.80127,0.37305-1.69189,0.55957-2.67188,0.55957c-1.21338,0-2.24414-0.27148-3.0918-0.81641s-1.46631-1.30664-1.85498-2.28711
                  c-0.35791-0.83984-0.53662-2.00684-0.53662-3.5c0-0.90137,0.09326-1.63281,0.28027-2.19336
                  c0.31104-1.08887,0.89844-1.94824,1.76172-2.57812s1.90137-0.94531,3.11475-0.94531c3.06445,0,4.79102,1.60254,5.18018,4.80664
                  C45.09521,9.41895,45.12646,9.85449,45.12646,10.33594z M38.41797,6.98828c-0.38135,0.28809-0.64941,0.68848-0.80469,1.20117
                  C37.52002,8.4541,37.45752,8.7959,37.42627,9.2168c-0.03125,0.07715,0,0.11621,0.09375,0.11621h4.73633
                  c0.07764,0,0.1167-0.03906,0.1167-0.11621c-0.03125-0.38867-0.07812-0.68457-0.14014-0.88672
                  c-0.13965-0.56055-0.41602-0.99609-0.82812-1.30664c-0.41211-0.31152-0.92969-0.4668-1.55176-0.4668
                  C39.27734,6.55664,38.79932,6.7002,38.41797,6.98828z"
      />
      <path
        d="M54.45947,4.57324c0.12451,0.0625,0.1709,0.17871,0.14014,0.34961l-0.39697,2.10059
                  c-0.01562,0.18652-0.12451,0.24121-0.32666,0.16309c-0.23291-0.07812-0.50537-0.11719-0.81641-0.11719
                  c-0.20264,0-0.35791,0.00879-0.4668,0.02344c-0.65332,0.03125-1.19775,0.26074-1.6333,0.68848s-0.65332,0.98438-0.65332,1.66797
                  v6.60352c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-2.14697
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08154-0.12012-0.08154-0.19824V4.68945
                  c0-0.07715,0.02686-0.14355,0.08154-0.19824c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h2.14697
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.0498
                  c0,0.0625,0.01172,0.09766,0.03516,0.10547c0.02295,0.00781,0.05029-0.01172,0.08154-0.05859
                  c0.65332-1.01074,1.56348-1.5166,2.72998-1.5166C53.65039,4.26953,54.08594,4.37109,54.45947,4.57324z"
      />
      <path
        d="M63.5835,10.06836c-0.56006-0.25684-1.25244-0.50977-2.07666-0.75879l-0.51318-0.13965
              c-0.45581-0.12427-0.83691-0.24261-1.15363-0.35583l-0.83746,2.3009c0.30066,0.1084,0.61548,0.21503,0.96423,0.31763
              l0.88672,0.28027c0.71533,0.20215,1.23242,0.39355,1.55176,0.57227c0.31836,0.17871,0.47803,0.44727,0.47803,0.80469
              c0,0.38867-0.19434,0.71191-0.58301,0.96777c-0.38916,0.25684-0.89502,0.38574-1.51709,0.38574
              c-0.65332,0-1.19385-0.14355-1.62158-0.43164s-0.6416-0.62598-0.6416-1.01562v-0.04688c0-0.07715-0.02734-0.14355-0.08154-0.19824
              c-0.00751-0.00739-0.01935-0.00476-0.02728-0.01117l-1.04205,2.86298c0.25714,0.16522,0.53943,0.31396,0.85938,0.43823
              c0.73877,0.28809,1.59814,0.43164,2.57812,0.43164c1.44678,0,2.60547-0.31445,3.47656-0.94434s1.30713-1.49707,1.30713-2.60156
              c0-0.73145-0.19482-1.32617-0.5835-1.78516C64.61768,10.68262,64.14355,10.32422,63.5835,10.06836z M62.8833,7.81641
              c0,0.07812,0.02734,0.14355,0.08203,0.19824c0.0542,0.05469,0.12012,0.08203,0.19824,0.08203h1.9834
              c0.07764,0,0.14355-0.02734,0.19824-0.08203c0.0542-0.05469,0.08154-0.12012,0.08154-0.19824
              c0-0.68457-0.19482-1.29492-0.5835-1.83203c-0.38867-0.53613-0.94141-0.95605-1.65674-1.25977
              c-0.50903-0.21613-1.0802-0.35138-1.70752-0.41357l-0.75977,2.08734c0.65668,0.00757,1.18365,0.13867,1.56934,0.40338
              C62.68506,7.07324,62.8833,7.41211,62.8833,7.81641z M85.88965,4.22266c-1.21338,0-2.25146,0.31543-3.11475,0.94531
              s-1.45068,1.48926-1.76172,2.57812c-0.18701,0.56055-0.28027,1.29199-0.28027,2.19336c0,1.49316,0.17871,2.66016,0.53662,3.5
              c0.38867,0.98047,1.00732,1.74219,1.85498,2.28711s1.87842,0.81641,3.0918,0.81641c0.97998,0,1.87061-0.18652,2.67188-0.55957
              c0.80078-0.37402,1.44238-0.90234,1.9248-1.58691c0.09326-0.14062,0.07031-0.26465-0.06982-0.37305l-1.44678-1.19043
              c-0.04688-0.04688-0.10156-0.07031-0.16309-0.07031c-0.09375,0-0.17139,0.04688-0.2334,0.14062
              c-0.62256,0.87109-1.4624,1.30664-2.52002,1.30664c-1.44678-0.01562-2.35693-0.58398-2.72998-1.7041
              c-0.09326-0.18652-0.16357-0.53613-0.20996-1.0498c0-0.07715,0.03857-0.11621,0.1167-0.11621h7.30322
              c0.18652,0,0.27979-0.09375,0.27979-0.28027l0.02344-0.72363c0-0.48145-0.03125-0.91699-0.09326-1.30664
              C90.68066,5.8252,88.9541,4.22266,85.88965,4.22266z M88.29297,9.33301h-4.73633c-0.09375,0-0.125-0.03906-0.09375-0.11621
              c0.03125-0.4209,0.09375-0.7627,0.18701-1.02734c0.15527-0.5127,0.42334-0.91309,0.80469-1.20117s0.85938-0.43164,1.43506-0.43164
              c0.62207,0,1.13965,0.15527,1.55176,0.4668c0.41211,0.31055,0.68848,0.74609,0.82812,1.30664
              c0.06201,0.20215,0.10889,0.49805,0.14014,0.88672C88.40967,9.29395,88.37061,9.33301,88.29297,9.33301z M76.32373,5.08594
              c-0.7627-0.5752-1.6958-0.86328-2.80029-0.86328s-1.99902,0.40527-2.68311,1.21387
              c-0.03174,0.04688-0.0625,0.0625-0.09326,0.04688c-0.03174-0.01562-0.04688-0.04688-0.04688-0.09375v-0.7002
              c0-0.07715-0.02734-0.14355-0.08154-0.19824c-0.05469-0.05371-0.12061-0.08105-0.19824-0.08105h-2.14697
              c-0.07812,0-0.14404,0.02734-0.19824,0.08105c-0.05469,0.05469-0.08154,0.12109-0.08154,0.19824v15.77344
              c0,0.07812,0.02686,0.14355,0.08154,0.19824c0.0542,0.05469,0.12012,0.08203,0.19824,0.08203h2.14697
              c0.07764,0,0.14355-0.02734,0.19824-0.08203c0.0542-0.05469,0.08154-0.12012,0.08154-0.19824v-5.06348
              c0-0.04688,0.01514-0.07715,0.04688-0.09277c0.03076-0.01562,0.06152-0.00781,0.09326,0.02344
              c0.66846,0.79297,1.55518,1.18945,2.65967,1.18945c1.15137,0,2.104-0.28711,2.8584-0.86328
              c0.75439-0.5752,1.30273-1.37695,1.64502-2.40332c0.26465-0.79297,0.39697-1.74219,0.39697-2.84668
              c0-1.15137-0.14014-2.12305-0.42041-2.91699C77.6377,6.46289,77.08545,5.66211,76.32373,5.08594z M74.94678,13.20605
              c-0.41992,0.65332-1.04248,0.98047-1.8667,0.98047c-0.76221,0-1.3457-0.3418-1.75-1.02637
              c-0.38867-0.6377-0.58301-1.57129-0.58301-2.80078c0-1.19727,0.17822-2.09961,0.53662-2.70605
              c0.4043-0.74707,1.01074-1.12012,1.81982-1.12012c0.88672,0,1.54785,0.38086,1.9834,1.14355
              c0.37305,0.65332,0.56006,1.54785,0.56006,2.68262C75.64697,11.54199,75.41357,12.49121,74.94678,13.20605z M116.28076,0.08105
              C116.22607,0.02734,116.16016,0,116.08252,0h-2.14648c-0.07812,0-0.14404,0.02734-0.19873,0.08105
              c-0.05469,0.05469-0.08154,0.12109-0.08154,0.19824v5.06348c0,0.04688-0.01562,0.07422-0.04639,0.08203
              c-0.03174,0.00781-0.0625-0.00391-0.09375-0.03516c-0.68457-0.77734-1.56299-1.16699-2.63623-1.16699
              c-1.15137,0-2.1084,0.28809-2.87012,0.86328c-0.7627,0.57617-1.31494,1.37695-1.65674,2.40332
              c-0.26465,0.79395-0.39648,1.74316-0.39648,2.84668c0,1.07422,0.13965,2.0459,0.41992,2.91699
              c0.3418,1.02637,0.89404,1.82812,1.65674,2.40332c0.76172,0.57617,1.69531,0.86328,2.7998,0.86328s1.99854-0.4043,2.68311-1.21289
              c0.03125-0.04688,0.06201-0.0625,0.09375-0.04688c0.03076,0.01562,0.04639,0.04688,0.04639,0.09277v0.7002
              c0,0.07812,0.02686,0.14453,0.08154,0.19824c0.05469,0.05469,0.12061,0.08203,0.19873,0.08203h2.14648
              c0.07764,0,0.14355-0.02734,0.19824-0.08203c0.0542-0.05371,0.08154-0.12012,0.08154-0.19824V0.2793
              C116.3623,0.20215,116.33496,0.13574,116.28076,0.08105z M113.0957,13.06641c-0.43555,0.74707-1.0498,1.12012-1.84326,1.12012
              c-0.88672,0-1.54785-0.37305-1.9834-1.12012c-0.37305-0.7002-0.56006-1.59473-0.56006-2.68359
              c0-1.21289,0.2334-2.16211,0.7002-2.84668c0.45117-0.65332,1.07324-0.97949,1.8667-0.97949
              c0.76172,0,1.35352,0.3418,1.77344,1.02637c0.38867,0.6377,0.58301,1.56348,0.58301,2.77637
              C113.63232,11.52637,113.45361,12.42871,113.0957,13.06641z M98.5127,4.22266c-1.21338,0-2.25146,0.31543-3.11475,0.94531
              s-1.45068,1.48926-1.76172,2.57812c-0.18701,0.56055-0.28027,1.29199-0.28027,2.19336c0,1.49316,0.17871,2.66016,0.53662,3.5
              c0.38867,0.98047,1.00732,1.74219,1.85498,2.28711s1.87842,0.81641,3.0918,0.81641c0.97998,0,1.87061-0.18652,2.67188-0.55957
              c0.80078-0.37402,1.44238-0.90234,1.9248-1.58691c0.09326-0.14062,0.07031-0.26465-0.06982-0.37305l-1.44678-1.19043
              c-0.04688-0.04688-0.10156-0.07031-0.16309-0.07031c-0.09375,0-0.17139,0.04688-0.2334,0.14062
              c-0.62256,0.87109-1.4624,1.30664-2.52002,1.30664c-1.44678-0.01562-2.35693-0.58398-2.72998-1.7041
              c-0.09326-0.18652-0.16357-0.53613-0.20996-1.0498c0-0.07715,0.03857-0.11621,0.1167-0.11621h7.30322
              c0.18652,0,0.27979-0.09375,0.27979-0.28027l0.02344-0.72363c0-0.48145-0.03125-0.91699-0.09326-1.30664
              C103.30371,5.8252,101.57715,4.22266,98.5127,4.22266z M100.91602,9.33301h-4.73633c-0.09375,0-0.125-0.03906-0.09375-0.11621
              c0.03125-0.4209,0.09375-0.7627,0.18701-1.02734c0.15527-0.5127,0.42334-0.91309,0.80469-1.20117s0.85938-0.43164,1.43506-0.43164
              c0.62207,0,1.13965,0.15527,1.55176,0.4668c0.41211,0.31055,0.68848,0.74609,0.82812,1.30664
              c0.06201,0.20215,0.10889,0.49805,0.14014,0.88672C101.03271,9.29395,100.99365,9.33301,100.91602,9.33301z"
      />
      <path
        d="M10.97852,2.25098c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203H2.82373
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11621v4.36426c0,0.07715,0.03857,0.11621,0.1167,0.11621h5.34326
                  c0.07764,0,0.14355,0.02734,0.19824,0.08203c0.0542,0.05469,0.08154,0.12012,0.08154,0.19824V8.9834
                  c0,0.07715-0.02734,0.14355-0.08154,0.19824C8.31055,9.23633,8.24463,9.2627,8.16699,9.2627H2.82373
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11719v4.50293C2.70703,13.96094,2.74561,14,2.82373,14h7.95654
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.77344
                  c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-10.5
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203C0.02734,16.19727,0,16.13086,0,16.05273V0.2793
                  c0-0.07715,0.02734-0.14355,0.08203-0.19824C0.13623,0.02734,0.20215,0,0.28027,0h10.5
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.77344
                  C11.06006,2.13086,11.03271,2.19727,10.97852,2.25098z"
      />
      <path
        d="M22.29492,5.34277c0.75439,0.74707,1.13184,1.76562,1.13184,3.05664v7.65332
                  c0,0.07812-0.02734,0.14453-0.08203,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203H21
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08203-0.12012-0.08203-0.19824V8.95996
                  c0-0.71582-0.20215-1.29492-0.60645-1.73828c-0.40479-0.44336-0.94141-0.66504-1.60986-0.66504
                  c-0.66943,0-1.20996,0.21777-1.62207,0.65332s-0.61816,1.01074-0.61816,1.72656v7.11621
                  c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-2.14697
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08154-0.12012-0.08154-0.19824V4.68945
                  c0-0.07715,0.02686-0.14355,0.08154-0.19824c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h2.14697
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v0.74707
                  c0,0.04688,0.01514,0.07812,0.04688,0.09277c0.03076,0.01562,0.0542,0,0.06982-0.0459
                  c0.6377-0.84082,1.60205-1.26074,2.89355-1.26074C20.5332,4.22266,21.54004,4.59668,22.29492,5.34277z"
      />
      <path
        d="M32.4917,6.52148c-0.05469,0.05469-0.12061,0.08105-0.19824,0.08105h-2.38037
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11719v5.62305c0,0.5918,0.12842,1.01953,0.38525,1.2832
                  c0.25635,0.26465,0.66504,0.39746,1.2251,0.39746h0.69971c0.07764,0,0.14355,0.02734,0.19824,0.08105
                  c0.05469,0.05469,0.08203,0.12109,0.08203,0.19824v1.75c0,0.17188-0.09326,0.27246-0.28027,0.30371l-1.21338,0.02344
                  c-1.229,0-2.14648-0.20996-2.75342-0.62988c-0.60645-0.41992-0.91797-1.21387-0.93311-2.37988V6.71973
                  c0-0.07812-0.03906-0.11719-0.1167-0.11719h-1.33008c-0.07812,0-0.14404-0.02637-0.19824-0.08105
                  c-0.05469-0.05469-0.08154-0.12012-0.08154-0.19824V4.68945c0-0.07715,0.02686-0.14355,0.08154-0.19824
                  c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h1.33008c0.07764,0,0.1167-0.03906,0.1167-0.11719V1.56348
                  c0-0.07812,0.02686-0.14453,0.08154-0.19922c0.05469-0.05371,0.12061-0.08105,0.19824-0.08105h2.03027
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19922v2.72949
                  c0,0.07812,0.03857,0.11719,0.1167,0.11719h2.38037c0.07764,0,0.14355,0.02734,0.19824,0.08105
                  c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.63379C32.57324,6.40137,32.5459,6.4668,32.4917,6.52148z"
      />
      <path
        d="M45.12646,10.33594l-0.02344,0.72363c0,0.18652-0.09326,0.28027-0.27979,0.28027h-7.30322
                  c-0.07812,0-0.1167,0.03906-0.1167,0.11621c0.04639,0.51367,0.1167,0.86328,0.20996,1.0498
                  c0.37305,1.12012,1.2832,1.68848,2.72998,1.7041c1.05762,0,1.89746-0.43555,2.52002-1.30664
                  c0.06201-0.09375,0.13965-0.14062,0.2334-0.14062c0.06152,0,0.11621,0.02344,0.16309,0.07031l1.44678,1.19043
                  c0.14014,0.1084,0.16309,0.23242,0.06982,0.37305c-0.48242,0.68457-1.12402,1.21289-1.9248,1.58691
                  c-0.80127,0.37305-1.69189,0.55957-2.67188,0.55957c-1.21338,0-2.24414-0.27148-3.0918-0.81641s-1.46631-1.30664-1.85498-2.28711
                  c-0.35791-0.83984-0.53662-2.00684-0.53662-3.5c0-0.90137,0.09326-1.63281,0.28027-2.19336
                  c0.31104-1.08887,0.89844-1.94824,1.76172-2.57812s1.90137-0.94531,3.11475-0.94531c3.06445,0,4.79102,1.60254,5.18018,4.80664
                  C45.09521,9.41895,45.12646,9.85449,45.12646,10.33594z M38.41797,6.98828c-0.38135,0.28809-0.64941,0.68848-0.80469,1.20117
                  C37.52002,8.4541,37.45752,8.7959,37.42627,9.2168c-0.03125,0.07715,0,0.11621,0.09375,0.11621h4.73633
                  c0.07764,0,0.1167-0.03906,0.1167-0.11621c-0.03125-0.38867-0.07812-0.68457-0.14014-0.88672
                  c-0.13965-0.56055-0.41602-0.99609-0.82812-1.30664c-0.41211-0.31152-0.92969-0.4668-1.55176-0.4668
                  C39.27734,6.55664,38.79932,6.7002,38.41797,6.98828z"
      />
      <path
        d="M54.45947,4.57324c0.12451,0.0625,0.1709,0.17871,0.14014,0.34961l-0.39697,2.10059
                  c-0.01562,0.18652-0.12451,0.24121-0.32666,0.16309c-0.23291-0.07812-0.50537-0.11719-0.81641-0.11719
                  c-0.20264,0-0.35791,0.00879-0.4668,0.02344c-0.65332,0.03125-1.19775,0.26074-1.6333,0.68848s-0.65332,0.98438-0.65332,1.66797
                  v6.60352c0,0.07812-0.02734,0.14453-0.08154,0.19824c-0.05469,0.05469-0.12061,0.08203-0.19824,0.08203h-2.14697
                  c-0.07812,0-0.14404-0.02734-0.19824-0.08203c-0.05469-0.05371-0.08154-0.12012-0.08154-0.19824V4.68945
                  c0-0.07715,0.02686-0.14355,0.08154-0.19824c0.0542-0.05371,0.12012-0.08105,0.19824-0.08105h2.14697
                  c0.07764,0,0.14355,0.02734,0.19824,0.08105c0.0542,0.05469,0.08154,0.12109,0.08154,0.19824v1.0498
                  c0,0.0625,0.01172,0.09766,0.03516,0.10547c0.02295,0.00781,0.05029-0.01172,0.08154-0.05859
                  c0.65332-1.01074,1.56348-1.5166,2.72998-1.5166C53.65039,4.26953,54.08594,4.37109,54.45947,4.57324z"
      />
    </svg>
  );
};

export default Logo;
