import { useEffect } from "react";

import { init } from "commandbar";
import { useShallow } from "zustand/react/shallow";
import { useAuthStore } from "../../auth/store";

const COMMAND_BAR_ORG = "93cf06d2";

function useCommandBar() {
  const { user } = useAuthStore(useShallow(({ user }) => ({ user })));

  useEffect(() => {
    if (window.CommandBar) {
      return;
    }
    init(COMMAND_BAR_ORG);
  }, []);

  useEffect(() => {
    if (!user?.id || !window.CommandBar) {
      return;
    }
    window.CommandBar.boot(user.id, { user }).catch((err) =>
      console.error(err)
    );
  }, [user]);

  return window.CommandBar;
}

export default useCommandBar;
