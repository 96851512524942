export interface IDomain {
  name: string;
  hostnames: string[];
  domainGuid: string;
  id: string;
}

export interface ISelectableDomain extends IDomain {
  selected?: boolean; // Temp optional - remove
}

export interface IDomainResponse {
  name: string;
  hostnames: string[];
  id: IDomainResponseId;
}

export interface IDomainResponseId {
  idValue: string;
  domainGuid: string;
}

export interface IDomainCreatePayload {
  name: string;
  hostnames?: string[] | null;
}

export interface IDomainEditPayload extends IDomainCreatePayload {
  hostnames: string[];
}

export interface IDomainTable extends IDomain {
  useRelativeUrls: boolean;
}

export interface IDomainHostnamesModalProps {
  domain: IDomain;
  isOpen: boolean;
  onClose: () => void;
}

export enum DomainTableModalType {
  EDIT = "Edit",
  EDIT_HOSTNAMES = "Edit Hostnames",
  DELETE = "Delete"
}

export enum DomainHostnameTableModalType {
  EDIT = "Edit",
  DELETE = "Delete"
}

export interface IHostname {
  id: string;
  name: string;
}
