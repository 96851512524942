"use client";

import { useDisclosure } from "@chakra-ui/react";

import { useState } from "react";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import DeleteModal from "../../../components/modals/DeleteModal";
import ReloadButton from "../../../components/ReloadButton";
import { useDeleteSourceGroup } from "../api/deleteSourceGroup";
import { useSourceGroups } from "../api/getSourceGroups";
import { ISourceGroupsResponse, SourceGroupModalType } from "../types";
import CreateSourceGroupModal from "./CreateSourceGroupModal";
import DataSource from "./DataSource";
import SourceGroup from "./SourceGroup";

const SourceGroups = () => {
  const { data, isLoading, refetch, isRefetching } = useSourceGroups();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<SourceGroupModalType>();
  const [sourceGroupData, setSourceGroupData] =
    useState<ISourceGroupsResponse>();

  const handleCallbackAction = (
    modal: SourceGroupModalType,
    sourceGroup: ISourceGroupsResponse
  ) => {
    setSourceGroupData(sourceGroup);
    setModalType(modal);
    onOpen();
  };

  const ActionModal = ({
    type,
    sourceGroup,
    isOpen,
    onClose
  }: {
    type: SourceGroupModalType | undefined;
    sourceGroup: ISourceGroupsResponse | undefined;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const deleteSourceGroup = useDeleteSourceGroup();
    if (!sourceGroup) {
      return null;
    }

    switch (type) {
      case SourceGroupModalType.DELETE_SOURCE_GROUP:
        return (
          <DeleteModal
            type="source group"
            name={sourceGroup?.name || ""}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={() => {
              deleteSourceGroup.mutate({
                id: sourceGroup.id.sourceGroupGuid
              });

              onClose();
            }}
          />
        );
      case SourceGroupModalType.EDIT_SOURCE_GROUP:
        return (
          <CreateSourceGroupModal
            isOpen={isOpen}
            onClose={onClose}
            dataSourceBeingEdited={sourceGroup}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (data?.length === 0 || !data) {
    return <EmptyState />;
  }

  return (
    <>
      <ReloadButton loading={isRefetching} onClick={refetch} marginBottom={4} />
      {data.map((sourceGroup, i) => (
        <SourceGroup
          openModalCallback={(modal) =>
            handleCallbackAction(modal, sourceGroup)
          }
          key={sourceGroup.id.sourceGroupGuid}
          gid={sourceGroup.id.idValue}
          alias={sourceGroup.alias}
          name={sourceGroup.name}
          numberOfSources={sourceGroup.sources.length}
          type={sourceGroup.type}
          marginBottom={i === data.length - 1 ? 0 : 6}
        >
          {sourceGroup.sources.map((s) => (
            <DataSource
              key={s.source.id.sourceGuid}
              accessKey={s.source.accessKey}
              name={s.source.name}
              numberOfSourceEntities={s.entitiesInSource}
              environments={s.environments}
              type={s.source.type}
              sourceId={s.source.id}
            />
          ))}
        </SourceGroup>
      ))}
      <ActionModal
        type={modalType}
        sourceGroup={sourceGroupData}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
export default SourceGroups;
