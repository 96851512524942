export type RouteKind =
  | "URL"
  | "IMPLICIT_REDIRECT"
  | "EXPLICIT_REDIRECT"
  | "LEGACY_IMPLICIT_REDIRECT"
  | "HANDLE";

export const getRouteKindDisplayText = (kind: RouteKind) => {
  switch (kind) {
    case "HANDLE":
      return "Handle";
    case "URL":
      return "Url";
    case "LEGACY_IMPLICIT_REDIRECT":
      return "Source entity based redirect";
    case "EXPLICIT_REDIRECT":
      return "Explicit redirect";
    case "IMPLICIT_REDIRECT":
      return "Implicit redirect";
  }
};

export const isRouteKindViewAware = (kind: RouteKind) => {
  return ["URL", "HANDLE", "EXPLICIT_REDIRECT", "IMPLICIT_REDIRECT"].includes(
    kind
  );
};
