import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { SOURCE_GROUPS } from "../../../constants/queryKey";
import { defaultToast } from "../../../constants/toast";
import { IApiErrorResponse } from "../../../types/errors";
import { SourceGroupService } from "../source-groups.service";
import { ISourceGroupsCreateWithSources } from "../types";

const createSourceGroupFn = (payload: ISourceGroupsCreateWithSources) => {
  const sourceGroupService = container.resolve(SourceGroupService);
  return sourceGroupService.createSourceGroup(payload);
};

export const useCreateSourceGroup = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: createSourceGroupFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SOURCE_GROUPS] });
      toast({
        title: "Source group created successfully",
        status: "success",
        ...defaultToast
      });
    },
    onError({ response }: AxiosError<IApiErrorResponse>) {
      toast({
        title: response?.data.detail ?? "Error creating source group",
        status: "error",
        ...defaultToast
      });
    }
  });
};
