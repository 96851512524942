import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { AuthService } from "../auth.service";
import { useAuthStore } from "../store";

export const useSignOut = () => {
  const authService = container.resolve(AuthService);
  const [signOutUrl] = useAuthStore(
    useShallow((state) => [state.links?.signOutUrl, state.register])
  );

  return {
    signOut: async () => {
      try {
        await authService.signOut();
        if (signOutUrl) {
          window.location.replace(signOutUrl);
        }
      } catch (error) {
        return error;
      } finally {
        clearAuthStoreState();
      }
    }
  };
};

export const signOut = async () => {
  try {
    const authService = container.resolve(AuthService);
    await authService.signOut();
  } catch (error) {
    return error;
  } finally {
    const signOutUrl = useAuthStore.getState().links?.signOutUrl;
    clearAuthStoreState();
    if (signOutUrl) {
      window.location.replace(signOutUrl);
    }
  }
};

const clearAuthStoreState = () => {
  useAuthStore.setState({
    user: undefined,
    links: undefined,
    tokens: undefined,
    isValid: false
  });
};
