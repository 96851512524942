import { create } from "zustand";

interface ITenantHealthStore {
  timeSetting: number;
  setTimeSetting: (timeSetting: number) => void;
}

const key = "TenantHealthTimeSetting";
const useTenantHealthStore = create<ITenantHealthStore>((set) => ({
  timeSetting: Number(localStorage.getItem(key) ?? 0.25),
  setTimeSetting(timeSetting) {
    set(() => ({ timeSetting }));
    localStorage.setItem(key, timeSetting.toString());
  }
}));

export default useTenantHealthStore;
