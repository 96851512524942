import {
  AssignmentExpression,
  Expression,
  ExpressionStatement,
  Identifier,
  ObjectProperty,
  Statement
} from "@babel/types";
import { ErrorDescriptor } from "../../../../types/codeEditor";
import { FunctionValidator } from "./function-base.validator";
import { BaseFunctionExpression } from "./javascript";

const expressionFilter = (x: Statement): x is ExpressionStatement =>
  x.type === "ExpressionStatement";

const mapExpression = (x: ExpressionStatement): Expression => x.expression;

const assignmentFilter = (x: Expression): x is AssignmentExpression =>
  x.type === "AssignmentExpression";

export class SourceEntityReassignmentValidator implements FunctionValidator {
  validate(property: ObjectProperty): ErrorDescriptor[] {
    const baseFunction = property.value as BaseFunctionExpression;
    if (baseFunction.body.type === "BlockStatement") {
      const reassignments = baseFunction.body.body
        .filter(expressionFilter)
        .map(mapExpression)
        .filter(assignmentFilter)
        .filter(
          (b) =>
            (b.left as Identifier).name ===
            (baseFunction.params[0] as Identifier).name
        );
      if (reassignments.length) {
        return reassignments.map((reassignment) => ({
          description: "Do not reassign source entity input param",
          location: reassignment.loc
        }));
      }
    }

    return [];
  }
}
