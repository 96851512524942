import qs from "qs";
import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IPaginated } from "../../types/api";
import {
  ITenant,
  ITenantCreateInvitePayload,
  ITenantGetInvitesParams,
  ITenantGetUsersParams,
  ITenantId,
  ITenantInvite,
  ITenantPayload,
  ITenantResponse,
  ITenantUser,
  TenantConfig
} from "./types";

type TenantInviteParamsWithCursor = ITenantGetInvitesParams & {
  after: string | null;
};
@singleton()
export class TenantService {
  public constructor(private readonly http: ManagementClient) {}

  public async getById(guid: string): Promise<ITenant> {
    const { data } = await this.http.get<ITenantResponse>(
      `/api/v1/tenants/${guid}`
    );

    return {
      id: { tenantGuid: data.id.split("/")[3], idValue: data.id },
      name: data.name,
      trashedAt: data.trashedAt ? new Date(data.trashedAt) : undefined,
      ...data.config
    };
  }

  public async getByIds(ids: string[]) {
    const { data } = await this.http.get<ITenant[]>("/api/v1/tenants", {
      params: { ids },
      paramsSerializer: (params: Record<string, unknown>) =>
        qs.stringify(params, { arrayFormat: "repeat" })
    });

    return data;
  }

  public async createTenant(tenant: ITenantPayload) {
    const { data } = await this.http.post<ITenantId>("/api/v1/tenants", tenant);
    return data;
  }

  public async updateTenantConfig(tenantGuid: string, config: TenantConfig) {
    await this.http.put(`/api/v1/tenants/${tenantGuid}`, config);
  }

  public async createTenantInvite(payload: ITenantCreateInvitePayload) {
    return this.http.post("/api/v1/tenant/invites", payload);
  }

  public async cancelTenantInvite(inviteId: string) {
    return this.http.put(`/api/v1/tenant/invites/${inviteId}/_cancel`);
  }

  public async getSentTenantInvitations(params: TenantInviteParamsWithCursor) {
    const { data } = await this.http.get<IPaginated<ITenantInvite>>(
      "/api/v1/tenant/invites",
      {
        params: { ...params }
      }
    );

    return data;
  }

  public async getUserForTenant(params: ITenantGetUsersParams) {
    const { data } = await this.http.get<IPaginated<ITenantUser>>(
      "/api/v1/tenant/users",
      {
        params: { ...params }
      }
    );

    return data;
  }

  public async removeUserFromTenant(userId: string) {
    return this.http.put(`/api/v1/tenant/users/${userId}`, {
      tenantRoles: []
    });
  }

  public async resendTenantInvitation(inviteId: string) {
    const { data } = await this.http.put(
      `/api/v1/tenant/invites/${inviteId}/_resend`
    );
    return data;
  }

  public deleteActiveTenant() {
    return this.http.delete(`/api/v1/tenants/`);
  }
}
