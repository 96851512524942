import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip
} from "@chakra-ui/react";
import { faCode, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";

const HelpMenu = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const MenuItems = [
    {
      icon: faCode,
      label: "Enterspeed docs",
      href: "https://docs.enterspeed.com/",
      isExternal: true
    }
  ];

  return (
    <Menu>
      <Tooltip label="Open help menu" isOpen={tooltipOpen}>
        <MenuButton
          onClick={() => setTooltipOpen(false)}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          as={IconButton}
          aria-label="Open help menu"
          _hover={{ opacity: 0.75 }}
          variant="ghost"
          icon={
            <Icon
              as={FontAwesomeIcon}
              icon={faQuestionCircle}
              size="xl"
              color="gray.500"
              cursor="pointer"
            />
          }
        />
      </Tooltip>

      <MenuList>
        {MenuItems.map((item) => (
          <Link
            key={item.label}
            to={item.href}
            target={item.isExternal ? "_blank" : ""}
          >
            <MenuItem
              p="3"
              icon={
                <Icon color="gray.500" as={FontAwesomeIcon} icon={item.icon} />
              }
            >
              {item.label}
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </Menu>
  );
};

export default HelpMenu;
