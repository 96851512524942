import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { SETTINGS } from "../../../constants/queryKey";
import { SettingsService } from "../settings.service";

export const getSettings = async () => {
  const settingService = container.resolve(SettingsService);
  return settingService.getSettings();
};

export const useSettings = () => {
  return useQuery({
    queryKey: [SETTINGS],
    queryFn: getSettings
  });
};
