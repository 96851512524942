import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import useTenantLimits from "../../features/bi/useTenantLimits";
import useTenantsWithErrors from "../../features/bi/useTenantWithErrors";
import TenantExceededLimits from "../../features/tenants/components/TenantExceededLimit";
import TenantsWithErrorsList from "../../features/tenants/components/TenantsWithErrors";

const AdminDashboard = () => {
  const tenantLimits = useTenantLimits();
  const tenantsWithErrors = useTenantsWithErrors();

  return (
    <>
      <MetaTitle title="Admin dashboard"></MetaTitle>
      <ContentBox title="Admin Dashboard">
        <TenantsWithErrorsList
          tenantsWithErrors={tenantsWithErrors.data}
        ></TenantsWithErrorsList>
        <TenantExceededLimits
          tenantLimits={tenantLimits.data}
        ></TenantExceededLimits>
      </ContentBox>
    </>
  );
};

export default AdminDashboard;
