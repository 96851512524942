import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANTS } from "../../../constants/queryKey";
import { TenantService } from "../tenants.service";

const queryFn = (guid: string) => {
  const tenantService = container.resolve(TenantService);
  return tenantService.getById(guid);
};

const useTenant = ({ tenantGuid }: { tenantGuid: string }) => {
  return useQuery({
    queryKey: [TENANTS, tenantGuid],
    queryFn: () => queryFn(tenantGuid)
  });
};

export default useTenant;
