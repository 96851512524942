import Axios from "axios";
import { GraphQLClient } from "graphql-request";
import { injectable, registry } from "tsyringe";
import { API_URL, INGEST_API_ENDPOINT_V2 } from "../constants/api";
import { HttpService } from "./http.service";
import { managementAxios } from "./management-axios";

@injectable()
@registry([
  {
    token: "managementHttpService",
    useValue: new HttpService(managementAxios)
  },
  {
    token: "ingestHttpService",
    useValue: new HttpService(
      Axios.create({
        baseURL: INGEST_API_ENDPOINT_V2
      })
    )
  },
  {
    token: "javascriptSchemaTypesHttpService",
    useValue: new HttpService(
      Axios.create({
        baseURL: "https://unpkg.com"
      })
    )
  },
  {
    token: "managementGraphQlClient",
    useValue: new GraphQLClient(`${API_URL}graphql`)
  }
])
export class HttpModule {}
