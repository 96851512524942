import { ISource } from "../../source-groups/types";
import { ITenantId } from "../../tenants/types";

export interface IEnvironment {
  name: string;
  environmentGuid: string;
  id: string;
  sources: ISource[];
}

export enum EnvironmentTableModalType {
  DELETE = "Delete",
  EDIT = "Edit"
}

// export interface IEnvironmentOption {
//   label: string;
//   subOptions: IEnvironmentSubOption[];
//   value: string;
// }

// export interface IEnvironmentSubOption {
//   value: string;
//   label: string;
// }
// export interface IEnvironmentRow extends IEnvironment {
//   version?: number;
// }

export interface IEnvironmentResponse {
  name: string;
  id: IEnvironmentId;
  tenantId?: ITenantId;
}

export interface IEnvironmentId {
  idValue: string;
  environmentGuid: string;
}

export interface IEnvironmentBase {
  id: IEnvironmentId;
  name: string;
  tenantId: ITenantId;
}

export interface IEnvironmentCreatePayload {
  name: string;
}

export interface IEnvironmentEditPayload {
  name: string;
}
