import {
  Box,
  GridItem,
  Icon,
  Kbd,
  Text,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createElement,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import { container } from "tsyringe";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { SidePanelContext } from "../../../context/SidePanelContext";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { AdminService } from "../../admin/admin.service";
import { useAuthStore } from "../../auth/store";
import { BulkDeployPanelContent } from "../../deployment/components/BulkDeployPanelContent";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { IEnvironmentResponse } from "../../environments/types";
import { useCreateSchema } from "../../schemas/api/createSchema";
import { useSchemas } from "../../schemas/api/getSchemas";
import CreateSchemaModal from "../../schemas/components/CreateSchemaModal";
import {
  CreateSchemaModalRequest,
  ISchemaListResponse,
  ISchemaResponseId
} from "../../schemas/types";
import { getAllEntities } from "../../source-entities/api/getAllEntities";
import AddEntitiesModal from "../../source-entities/components/AddEntitiesModal";
import { ISourceEntity } from "../../source-entities/types";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { ISource } from "../../source-groups/types";
import useTenantSwitcher from "../../tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../tenants/store";
import { ITenant } from "../../tenants/types";
import { iconPicker } from "../constants";
import useCommandBar from "../hooks/useCommandBar";
import { ISchema } from "../types";
import SchemaRecordPreview from "./SchemaRecordPreview";
import TenantDetails from "./TenantDetails";

const CommandBar = () => {
  const mixpanel = useMixPanel();
  const { isAdmin, toggleAdminFeatures } = useAuthStore();
  const adminFeaturesEnabled = useAdminFeatures();
  const { availableTenants, activeTenant: currentTenantProperties } =
    useTenantStore();
  const [confirmModalData, setConfirmModalData] = useState<{
    title: string;
    content: FunctionComponent;
    onConfirm: () => void | Promise<void>;
  }>({
    title: "",
    content: () => <></>,
    onConfirm: () => {
      console.error("confirm modal must be overwritten");
    }
  });

  const loadTenantAdmin = async ({ term }: { term: string }) => {
    const page = await container
      .resolve(AdminService)
      .getTenantsPage({ term, first: 5 });
    return page.results;
  };

  const [sourceId, setSourceId] = useState<string | null>(null);
  const [createdSchema, setCreatedSchema] = useState<ISchemaResponseId>();

  const createSchema = useCreateSchema();
  const { data: environments } = useEnvironments();
  const { data: sourceGroups } = useSourceGroups();

  const confirmModalDisclosure = useDisclosure();
  const addEntitiesModalDisclosure = useDisclosure();
  const createSchemaDisclosure = useDisclosure();

  const { setOpen, setTitle, setContent, setCustomStartWidth } =
    useContext(SidePanelContext);

  const handleOpenSidePanel = (environment?: IEnvironmentResponse) => {
    setTitle("Deploy changes");
    setContent(
      <BulkDeployPanelContent
        preSelectedEnvironment={environment}
        onCancel={() => setOpen(false)}
      />
    );
    setOpen(true);
    setCustomStartWidth(40);
  };

  const commandBar = useCommandBar();
  const navigate = useNavigate();
  const { data: schemas } = useSchemas();

  const tenantSwitcher = useTenantSwitcher();

  const { colorMode, toggleColorMode } = useColorMode();

  commandBar?.setTheme(colorMode);

  commandBar
    ?.addCommand({
      name: "toggleColorMode",
      text: "Toggle color mode",
      icon:
        colorMode === "dark"
          ? `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z"/></svg>`,
      template: { type: "callback", value: "toggleColorMode" }
    })
    .catch(console.error);

  commandBar?.addCallback("toggleColorMode", toggleColorMode);

  if (isAdmin) {
    commandBar
      ?.addCommand({
        name: "toggleAdminFeatures",
        text: "Toggle admin features",
        template: { type: "callback", value: "toggleAdminFeatures" }
      })
      .catch(console.error);

    commandBar?.addCallback("toggleAdminFeatures", toggleAdminFeatures);
  }

  if (adminFeaturesEnabled) {
    commandBar?.addComponent("tenantDetails", "Tenants", {
      mount: (elem) => ({
        render(data, _metadata) {
          if (!data) {
            return;
          }
          render(
            <TenantDetails tenant={data as ITenant}></TenantDetails>,
            elem
          );
        },
        unmount() {
          //
        }
      })
    });
    commandBar?.addRecords("Tenants", [...availableTenants], {
      onInputChange: (term) => loadTenantAdmin({ term }),
      labelKey: "name",
      descriptionKey: "id.idValue",
      detail: { type: "component", value: "tenantDetails" }
    });
  } else {
    commandBar?.removeComponent("tenantDetails");
    commandBar?.addRecords("Tenants", () => [...availableTenants], {
      labelKey: "name"
    });
  }

  useEffect(() => {
    if (!schemas) {
      return;
    }
    commandBar.addComponent("preview", "Schema", {
      mount: (elem) => ({
        render: (data, _metadata) => {
          if (!data) {
            return;
          }
          render(<SchemaRecordPreview data={data as ISchema} />, elem);
        },
        unmount: () => {
          //
        }
      })
    });

    const mapToSchemaRecords = ({
      name,
      viewHandle,
      id: { idValue: id, mappingSchemaGuid },
      type,
      latestVersion: version,
      latestFormat: format
    }: ISchemaListResponse): ISchema & {
      description: string;
      icon: string | undefined;
    } => ({
      name,
      viewHandle,
      mappingSchemaGuid,
      id,
      type,
      version,
      format: format ?? "json",
      description: `viewHandle: ${viewHandle}, type: ${type}`,
      icon: format ? iconPicker[format] : iconPicker.json
    });
    commandBar?.addRecords(
      "normalSchemas",
      (schemas ?? [])
        .filter((s) => s.type === "normal")
        .map(mapToSchemaRecords),
      {
        labelKey: "name",
        descriptionKey: "description",
        detail: { type: "component", value: "preview" }
      }
    );
    const schemaUrl = "/schemas/{{record.mappingSchemaGuid}}";
    commandBar
      ?.addRecordAction("normalSchemas", {
        text: "Normal Schemas",
        name: "normalSchemas",
        template: {
          type: "link",
          value: schemaUrl,
          operation: "router"
        }
      })
      .catch((err) => console.error(err));
    commandBar?.addRecords(
      "partialSchemas",
      (schemas ?? [])
        .filter((s) => s.type === "partial")
        .map(mapToSchemaRecords),
      {
        labelKey: "name",
        descriptionKey: "description",
        detail: { type: "component", value: "preview" }
      }
    );
    commandBar
      ?.addRecordAction("partialSchemas", {
        text: "Partial Schemas",
        name: "partialSchemas",
        template: {
          type: "link",
          value: schemaUrl,
          operation: "router"
        }
      })
      .catch((err) => console.error(err));

    commandBar?.addRecords(
      "collectionSchema",
      (schemas ?? [])
        .filter((s) => s.type === "collection")
        .map(mapToSchemaRecords),
      {
        labelKey: "name",
        descriptionKey: "description",
        detail: { type: "component", value: "preview" }
      }
    );
    commandBar
      ?.addRecordAction("collectionSchema", {
        text: "Collection Schemas",
        name: "collectionSchema",
        template: {
          type: "link",
          value: schemaUrl,
          operation: "router"
        }
      })
      .catch((err) => console.error(err));
  }, [schemas]);

  useEffect(() => {
    if (!sourceId) {
      return;
    }
    commandBar
      ?.addCommand({
        name: "pickEntity",
        text: "Go to source entity",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"/></svg>`,
        template: {
          type: "callback",
          value: "pickEntity"
        },
        arguments: {
          entities: {
            type: "context",
            value: "entities",
            order_key: 1
          }
        }
      })
      .then(() => {
        commandBar?.execute("pickEntity");
        commandBar.removeCommand("pickEntity");
      })
      .catch((err) => {
        console.error(err);
        commandBar.removeCommand("pickEntity");
      });
  }, [sourceId]);

  commandBar?.addCallback<{ Switch: ITenant }, null>(
    "switchTenant",
    ({ Switch: tenant }) => {
      if (tenant.id.idValue === currentTenantProperties.id) {
        return false;
      }
      setConfirmModalData({
        title: "Confirm switch tenant",
        content: () => (
          <Text>
            {"Are you sure you want to switch to "}
            <Text as="b">{tenant?.name}</Text>?
          </Text>
        ),
        onConfirm: () => {
          confirmModalDisclosure.onClose();
          tenantSwitcher.switch(tenant);
        }
      });
      confirmModalDisclosure.onOpen();
      return true;
    }
  );

  commandBar
    ?.addCommand({
      name: "createSourceEntity",
      text: "Create source entity",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM192 240c13.3 0 24 10.7 24 24v48h48c13.3 0 24 10.7 24 24s-10.7 24-24 24H216v48c0 13.3-10.7 24-24 24s-24-10.7-24-24V360H120c-13.3 0-24-10.7-24-24s10.7-24 24-24h48V264c0-13.3 10.7-24 24-24z"/></svg>`,
      template: { type: "callback", value: "createSourceEntity" }
    })
    .catch(console.error);

  commandBar?.addCallback("createSourceEntity", () => {
    mixpanel.track("CommandBar AddEntities");
    addEntitiesModalDisclosure.onOpen();
  });

  commandBar
    ?.addCommand({
      name: "createSchema",
      text: "Create schema",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>`,
      template: { type: "callback", value: "createSchema" }
    })
    .catch(console.error);

  commandBar?.addCallback("createSchema", () => {
    mixpanel.track("CommandBar Create Schema");
    createSchemaDisclosure.onOpen();
  });

  commandBar
    ?.addCommand({
      name: "deploySchemas",
      text: "Deploy schemas",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>`,
      template: { type: "callback", value: "deploySchemas" },
      arguments: {
        environment: {
          type: "context",
          value: "environment",
          order_key: 1
        }
      }
    })
    .catch(console.error);

  commandBar
    ?.addCommand({
      name: "goToSourceEntity",
      text: "Go to source entity",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>`,
      template: { type: "callback", value: "goToSourceEntity" },
      arguments: {
        sources: {
          type: "context",
          value: "sources",
          order_key: 1
        }
      }
    })
    .catch(console.error);

  commandBar?.addCallback<{ sources: ISource }, Record<string, unknown>>(
    "goToSourceEntity",
    ({ sources }) => {
      setSourceId(sources.id.sourceGuid);
    }
  );

  commandBar?.addCallback<
    { environment: IEnvironmentResponse },
    Record<string, unknown>
  >("deploySchemas", ({ environment }) => {
    mixpanel.track("CommandBar Deploy Schema");
    handleOpenSidePanel(environment);
  });

  commandBar?.addCallback<{ entities: ISourceEntity }, Record<string, unknown>>(
    "pickEntity",
    ({ entities }) => {
      const url =
        sourceId && entities.originId
          ? `/source-entities/${sourceId}/${entities.originId}`
          : null;
      setSourceId("");
      if (url) {
        navigate(url);
      }
    }
  );

  commandBar?.addArgumentChoices("entities", [], {
    labelKey: "originId",
    descriptionKey: "url",
    onInputChange: async (query) => {
      const { results } = await getAllEntities({
        id: sourceId,
        term: query,
        type: []
      });
      return results;
    }
  });

  commandBar?.addArgumentChoices("environment", environments ?? [], {
    labelKey: "name"
  });

  commandBar?.addArgumentChoices(
    "sources",
    sourceGroups?.flatMap((sg) => sg.sources.map((s) => s.source)) ?? [],
    {
      labelKey: "name"
    }
  );

  const routerFn = (url: string) => {
    mixpanel.track("CommandBar routing", { url });
    navigate(url);
  };
  commandBar?.addRouter(routerFn);
  const isMac = navigator.userAgent.includes("Mac OS X");

  const onSubmitCreateSchema = async (
    request: CreateSchemaModalRequest
  ): Promise<{ valid: true }> => {
    const result = await createSchema.mutateAsync({
      payload: request
    });
    setCreatedSchema(result);
    return { valid: true };
  };
  useEffect(() => {
    if (!createdSchema) {
      return;
    }
    navigate(`/schemas/${createdSchema.mappingSchemaGuid}`);
  }, [createdSchema]);

  const onSuccessCreateSchema = () => {
    createSchemaDisclosure.onClose();
  };
  return (
    <GridItem
      display={{ base: "none", lg: "GridItem" }}
      onClick={() => window.CommandBar.open()}
      border="1px solid"
      borderColor="gray.300"
      py="1"
      px="2"
      borderRadius="sm"
      color="gray.400"
      alignItems="center"
      userSelect="none"
      cursor="pointer"
      _hover={{
        opacity: 0.75
      }}
    >
      <Icon as={FontAwesomeIcon} icon={faMagnifyingGlass} size="sm" />
      <Box mx="2">Find anything</Box>
      <Kbd size="xs" mr="1">
        {isMac ? "⌘" : "Ctrl"}
      </Kbd>
      <Kbd>K</Kbd>
      <ConfirmModal
        title={confirmModalData.title}
        onClose={confirmModalDisclosure.onClose}
        isOpen={confirmModalDisclosure.isOpen}
        onSuccess={confirmModalData.onConfirm}
      >
        {createElement(confirmModalData.content)}
      </ConfirmModal>
      <AddEntitiesModal
        onClose={addEntitiesModalDisclosure.onClose}
        isOpen={addEntitiesModalDisclosure.isOpen}
      ></AddEntitiesModal>
      <CreateSchemaModal
        isOpen={createSchemaDisclosure.isOpen}
        onClose={createSchemaDisclosure.onClose}
        onSubmit={onSubmitCreateSchema}
        onSuccess={onSuccessCreateSchema}
      ></CreateSchemaModal>
    </GridItem>
  );
};

export default CommandBar;
