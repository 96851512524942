import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { MANAGEMENT_CLIENTS } from "../../../constants/queryKey";
import { ManagementClientService } from "../management-client.service";
import { IManagementClientEditPayload } from "../types";

export const editManagementClient = ({
  managementClientGuid,
  payload
}: {
  managementClientGuid: string;
  payload: IManagementClientEditPayload;
}) => {
  const managementClientService = container.resolve(ManagementClientService);
  return managementClientService.editManagementClient(
    managementClientGuid,
    payload
  );
};

export const useEditManagementClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editManagementClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MANAGEMENT_CLIENTS]
      });
    }
  });
};
