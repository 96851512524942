import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { IApiErrorResponse } from "../../../types/errors";
import { SchemaService } from "../schema.service";
import {
  DryRunInputFormat,
  ISchemaDryRunSourceEntityDto
} from "../testing-schemas/types";

export const testSchema = ({
  dryRunDto,
  format
}: {
  dryRunDto: ISchemaDryRunSourceEntityDto;
  format: DryRunInputFormat;
}) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.test(dryRunDto, format);
};

export const useTestSchema = () => {
  return useMutation({
    mutationFn: testSchema,
    onError: (_: AxiosError<IApiErrorResponse>) => {
      // left blank to help types of error when using mutation
    }
  });
};
