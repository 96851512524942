"use client";

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import {
  faFolder,
  faFolderOpen,
  faPlus
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISchemaListFolderProps } from "../types/schema-list";

const SchemaListFolder = ({
  children,
  name,
  isOpen,
  onCreateSchema,
  onClick
}: ISchemaListFolderProps) => {
  return (
    <Box borderBottom="1px solid" borderColor="gray.200">
      <Flex
        as="summary"
        onClick={onClick}
        justifyContent="space-between"
        alignItems="center"
        px="2"
        py="3"
        borderRadius="sm"
        cursor="pointer"
        _hover={{
          backgroundColor: useColorModeValue("gray.50", "gray.700")
        }}
      >
        <Flex alignItems="center" justifyContent="center" mr="4">
          <Icon
            size="xl"
            color="brand.300"
            as={FontAwesomeIcon}
            icon={isOpen ? faFolderOpen : faFolder}
            mr="4"
          />
          <Box>{name}</Box>
        </Flex>
        <Box onClick={(e) => e.stopPropagation()}>
          <Tooltip
            placement="left"
            label={`Create a schema inside the "${name}"-folder`}
          >
            <IconButton
              aria-label={`Create a schema inside the "${name}"-folder`}
              onClick={onCreateSchema}
              icon={<Icon as={FontAwesomeIcon} icon={faPlus} />}
              variant="ghost"
            />
          </Tooltip>
        </Box>
      </Flex>
      <Box mb="2" borderLeft="1px solid" borderColor="gray.100" ml="4" pl="2">
        {children}
      </Box>
    </Box>
  );
};

export default SchemaListFolder;
