import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import {
  faCogs,
  faDatabase,
  faEye,
  faShuffle
} from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { GroupBase, Select } from "chakra-react-select";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import LoadingAnimation from "../../../components/LoadingAnimation";
import Pagination from "../../../components/Pagination";
import { INSPECTED_ROUTES } from "../../../constants/queryKey";
import { formatTimeWithSec, timeFromNow } from "../../../helpers/dateHelper";
import {
  getEnvironmentClientOption,
  getEnvironmentClientOptions
} from "../../../helpers/getEnvironmentClientOptions";
import {
  getRouteTypeOption,
  getRouteTypeOptions
} from "../../../helpers/getRouteTypeOptions";
import { environmentClientIdDisplayFormat } from "../../../helpers/idFormatters";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import useReactSelectStyles from "../../../styles/react-select-style";
import { EmptyStateType } from "../../../types/emptyState";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import ISelectOption from "../../../types/selectInput";
import { useEnvironmentClients } from "../../environment-clients/api/getEnvironmentClients";
import { useInspectedRoutes } from "../api/getInspectedRoutes";
import { IRoute } from "../types";
import InputActionBox from "./InputActionBox";

const RouteInspector = () => {
  const flags = useFlags();

  const usingNewRoutes = flags.newRoutes as boolean;

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetchQuery, setFetchQuery] = useState(false);

  const { data: environmentClients, isLoading: environmentClientsIsLoading } =
    useEnvironmentClients();

  const environmentClientOptions =
    getEnvironmentClientOptions(environmentClients);
  const routeInspectTypeOptions = getRouteTypeOptions();

  const [selectedEnvironmentClient, setSelectedEnvironmentClient] =
    useState<IEnterspeedIdentifiableSelectOption>();

  const [selectedRouteType, setSelectedRouteType] = useState<ISelectOption>(
    getRouteTypeOption(routeInspectTypeOptions, searchParams?.get("routeType"))
  );

  const [route, setRoute] = useState<string>(searchParams?.get("route") ?? "");

  useEffect(() => {
    queryClient.removeQueries({ queryKey: [INSPECTED_ROUTES], exact: true });
  }, []);

  useEffect(() => {
    if (!environmentClients) {
      return;
    }

    const envClientParam = searchParams?.get("envClient");
    const clientOption = envClientParam
      ? getEnvironmentClientOption(environmentClients, envClientParam)
      : undefined;
    setSelectedEnvironmentClient(clientOption);

    if (
      clientOption &&
      selectedRouteType &&
      isRouteValid(selectedRouteType, route)
    ) {
      setFetchQuery(true);
    }
  }, [environmentClients]);

  const headerBackgrounColor = useColorModeValue("brandDarkBlue", "gray.700");
  const headerActiveBackgrounColor = useColorModeValue(
    "green.500",
    "green.700"
  );

  const payload = {
    environmentClientId: selectedEnvironmentClient?.id as string,
    url: route,
    type: selectedRouteType?.value as string
  };

  const {
    data,
    isLoading: inspectedRoutesIsLoading,
    isRefetching: inspectedRoutesIsRefetching,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = useInspectedRoutes(payload, fetchQuery);

  const inspectedRoutes = data?.pages.flatMap((x) => x.routes);
  const totalRoutesCount = data ? data.pages[0].total ?? 0 : 0;
  const routesDomain = data ? data.pages[0].domain : null;

  const getType = (route: IRoute) => {
    switch (route.kind) {
      case "HANDLE":
        return "Handle";
      case "URL":
        return "Url";
      case "LEGACY_IMPLICIT_REDIRECT":
        return "Source entity based redirect";
      case "EXPLICIT_REDIRECT":
        return "Explicit redirect";
      case "IMPLICIT_REDIRECT":
        return "Implicit redirect";
    }
  };

  const isViewAware = (route: IRoute) => {
    return ["URL", "HANDLE", "EXPLICIT_REDIRECT", "IMPLICIT_REDIRECT"].includes(
      route.kind
    );
  };

  const isRouteValid = (selectedRouteType: ISelectOption, route: string) => {
    if (!route) {
      return false;
    }

    if (selectedRouteType?.value !== "Url") {
      return true;
    }

    return (
      route.startsWith("http://") ||
      route.startsWith("https://") ||
      route.startsWith("/")
    );
  };

  const handleSearch = async () => {
    if (
      selectedEnvironmentClient &&
      selectedRouteType &&
      isRouteValid(selectedRouteType, route)
    ) {
      setFetchQuery(true);

      const newQuery = new URLSearchParams(searchParams ?? undefined);
      newQuery.set("envClient", selectedEnvironmentClient.value as string);
      newQuery.set("routeType", selectedRouteType.value as string);
      newQuery.set("route", route);
      setSearchParams(newQuery, { replace: true });

      if (inspectedRoutes) {
        await refetch();
      }
    }
  };

  const getSourceEntityId = (sourceEntityId: string): string => {
    if (!sourceEntityId) {
      return "";
    }
    const entityIndex = sourceEntityId.lastIndexOf("/Entity/");
    if (entityIndex !== -1) {
      return sourceEntityId.slice(entityIndex + "/Entity/".length);
    }
    return "";
  };

  const getSchemaAlias = (viewId: string): string => {
    if (!viewId) {
      return "";
    }
    const entityIndex = viewId.lastIndexOf("/View/");
    if (entityIndex !== -1) {
      return viewId.slice(entityIndex + "/View/".length);
    }
    return "";
  };

  const getSourceEntityLink = (link: string): string => {
    if (!link) {
      return "";
    }
    const withoutGidSource = link.replace("gid://Source/", "");

    const withoutEntity = withoutGidSource.replace("/Entity", "");

    return `/source-entities/${withoutEntity}`;
  };

  const getSchemaLink = (viewId: string): string => {
    if (!viewId) {
      return "";
    }

    return `/schemas/${getSchemaAlias(viewId)}`;
  };

  const getViewLink = (viewId: string): string => {
    if (!viewId) {
      return "";
    }

    const regex = /gid:\/\/Environment\/([a-fA-F0-9-]+)\/.*/;
    const environment = viewId.match(regex);

    if (!environment) {
      return "";
    }

    return `/views?env=${environment[1]}&id=${viewId}&sidePanel=viewsPanel`;
  };

  const getViewId = (viewId: string): string => {
    if (!viewId) {
      return "";
    }

    const entityIndex = viewId.indexOf("/Entity/");
    if (entityIndex !== -1) {
      return viewId.substring(entityIndex + "/Entity/".length);
    }
    return "";
  };

  const gridBgColors = useColorModeValue("gray.50", "gray.800");
  const reactSelectStyles = useReactSelectStyles();
  const reactEnterspeedIdentifiableSelectStyles =
    useReactEnterspeedIdentifiableSelectStyles();

  return (
    <>
      {environmentClients?.length === 0 && !environmentClientsIsLoading ? (
        <EmptyState
          type={EmptyStateType.ROUTE_INSPECTOR_NO_ENVIRONMENT_CLIENTS}
        />
      ) : (
        <Box>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 2fr"
            }}
            gap={10}
          >
            <FormControl>
              <FormLabel color="gray.500" fontSize="xs">
                Environment client
              </FormLabel>
              <Select<
                IEnterspeedIdentifiableSelectOption,
                true,
                GroupBase<IEnterspeedIdentifiableSelectOption>
              >
                size="sm"
                useBasicStyles
                chakraStyles={reactEnterspeedIdentifiableSelectStyles}
                colorScheme="brand"
                placeholder="Select an environment client"
                options={environmentClientOptions}
                components={
                  new EnterspeedIdentifiableSelect(
                    environmentClientIdDisplayFormat
                  )
                }
                value={selectedEnvironmentClient}
                onChange={(value) => {
                  return setSelectedEnvironmentClient(
                    value as unknown as IEnterspeedIdentifiableSelectOption
                  );
                }}
              />
            </FormControl>
            {selectedEnvironmentClient && (
              <Flex alignItems="flex-end">
                <FormControl>
                  <FormLabel color="gray.500" fontSize="xs">
                    Route type
                  </FormLabel>
                  <Select
                    size="sm"
                    useBasicStyles
                    chakraStyles={reactSelectStyles}
                    colorScheme="blue"
                    value={selectedRouteType}
                    onChange={(value) => {
                      setSelectedRouteType(value as ISelectOption);
                    }}
                    options={routeInspectTypeOptions}
                    placeholder="Select route type"
                  />
                </FormControl>
              </Flex>
            )}
            {selectedEnvironmentClient && selectedRouteType && (
              <Flex
                alignItems="flex-end"
                gridColumn={{
                  base: "span 1",
                  md: "span 2",
                  xl: "span 1"
                }}
              >
                <FormControl mr="2">
                  <FormLabel color="gray.500" fontSize="xs">
                    {selectedRouteType.value === "Url"
                      ? "Route URL"
                      : "Route handle"}
                  </FormLabel>
                  <Input
                    onChange={(e) => {
                      setRoute(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        return handleSearch();
                      }
                    }}
                    placeholder={
                      selectedRouteType.value === "Url"
                        ? "Enter the URL you want to inspect (must be exact match)"
                        : "Enter the handle you want to inspect (must be exact match)"
                    }
                    value={route}
                    size="sm"
                  />
                </FormControl>
                <Tooltip
                  label={
                    !isRouteValid(selectedRouteType, route)
                      ? selectedRouteType?.value === "Url"
                        ? "Url route is not valid. It must start with (http://), (https://) or (/)"
                        : "Handle route is not valid. It must have a value"
                      : ""
                  }
                  placement="bottom-start"
                >
                  <Button
                    isDisabled={
                      !isRouteValid(selectedRouteType, route) ||
                      !selectedRouteType ||
                      !selectedEnvironmentClient
                    }
                    onClick={() => handleSearch()}
                    variant="primary"
                  >
                    Search
                  </Button>
                </Tooltip>
              </Flex>
            )}
          </Grid>
          <Box>
            {(inspectedRoutesIsLoading || inspectedRoutesIsRefetching) && (
              <LoadingAnimation />
            )}

            {(selectedEnvironmentClient === undefined || !inspectedRoutes) &&
              !(inspectedRoutesIsLoading || inspectedRoutesIsRefetching) && (
                <EmptyState
                  type={EmptyStateType.ROUTE_INSPECTOR_ENTER_VALUES_TO_SEARCH}
                />
              )}
            {selectedEnvironmentClient &&
              inspectedRoutes?.length === 0 &&
              !(inspectedRoutesIsLoading || inspectedRoutesIsRefetching) && (
                <EmptyState
                  type={EmptyStateType.ROUTE_INSPECTOR_NO_ROUTES_FOUND}
                  customDescription={
                    selectedRouteType.value === "Url" && !routesDomain
                      ? "We couldn't find any domain and therefore routes for this value. Make sure the route is correct and that you have selected the correct environment client and route type."
                      : ""
                  }
                />
              )}
            {selectedEnvironmentClient &&
              !(inspectedRoutesIsLoading || inspectedRoutesIsRefetching) &&
              inspectedRoutes?.map((route) => {
                return (
                  <Box key={route.routeId} borderTopRadius="sm" mt="8">
                    <Flex
                      justifyContent="left"
                      borderTopRadius="sm"
                      bg={
                        usingNewRoutes && route.active
                          ? headerActiveBackgrounColor
                          : headerBackgrounColor
                      }
                      color="white"
                      py="2"
                      px="4"
                      alignItems="center"
                      fontSize="sm"
                    >
                      <Text fontWeight={"700"}>
                        {getType(route)?.toUpperCase()}{" "}
                      </Text>
                      {usingNewRoutes && route.active && (
                        <Tooltip label="Marks the view with the active route. The active route is based on the source entity with the latest `updatedAt`">
                          <Badge ml={4} colorScheme={"green"}>
                            ACTIVE
                          </Badge>
                        </Tooltip>
                      )}
                      {routesDomain && (
                        <Box ml="auto">
                          Found by domain:{" "}
                          <Text as="strong">{routesDomain?.name}</Text>
                        </Box>
                      )}
                      <Box ml={routesDomain ? "4" : "auto"}>
                        Last updated:{" "}
                        <Text as="strong">
                          {formatTimeWithSec(route.originUpdatedAt)}{" "}
                          <small>({timeFromNow(route.originUpdatedAt)})</small>
                        </Text>
                      </Box>
                    </Flex>
                    <SimpleGrid
                      columns={{ base: 1, xl: 3 }}
                      spacing={8}
                      px="4"
                      py="8"
                      bgColor={gridBgColors}
                    >
                      {route.sourceEntityId && isViewAware(route) && (
                        <InputActionBox
                          label="Generated by source entity"
                          value={getSourceEntityId(route.sourceEntityId)}
                          id={route.sourceEntityId}
                          icon={faDatabase}
                          link={getSourceEntityLink(route.sourceEntityId)}
                          type="link"
                        />
                      )}
                      {route.redirectSourceEntityId && !isViewAware(route) && (
                        <InputActionBox
                          label="Generated by source entity"
                          value={getSourceEntityId(
                            route.redirectSourceEntityId
                          )}
                          id={route.redirectSourceEntityId}
                          icon={faDatabase}
                          link={getSourceEntityLink(
                            route.redirectSourceEntityId
                          )}
                          type="link"
                        />
                      )}
                      {route.viewId && isViewAware(route) && (
                        <InputActionBox
                          label="Generated by schema"
                          value={getSchemaAlias(route.viewId)}
                          id={getSchemaAlias(route.viewId)}
                          icon={faCogs}
                          link={getSchemaLink(route.viewId)}
                          type="link"
                        />
                      )}
                      {route.viewId && (
                        <InputActionBox
                          label="View ID"
                          value={getViewId(route.viewId)}
                          link={getViewLink(route.viewId)}
                          id={route.viewId}
                          icon={faEye}
                          type="link"
                        />
                      )}
                      {route.redirectUrl && route.redirectSourceEntityId && (
                        <InputActionBox
                          label="Redirect URL"
                          value={route.redirectUrl}
                          icon={faShuffle}
                          type="copy"
                        />
                      )}
                    </SimpleGrid>
                  </Box>
                );
              })}
            {selectedEnvironmentClient &&
              !(inspectedRoutesIsLoading || inspectedRoutesIsRefetching) &&
              inspectedRoutes &&
              inspectedRoutes.length > 0 && (
                <Pagination
                  disabled={!hasNextPage}
                  current={inspectedRoutes.length}
                  total={totalRoutesCount}
                  loadMore={fetchNextPage}
                  loading={isFetchingNextPage}
                />
              )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default RouteInspector;
