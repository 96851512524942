import { CancelToken } from "axios";
import { ISortBy } from "../../../types/api";

export interface ISourceEntity {
  id: string;
  type: string;
  url: string;
  sourceId: string;
  originId: string;
  sourceName: string;
  updatedAt?: string;
  test?: string;
}

export interface ISourceEntityId {
  idValue: string;
  sourceGuid: string;
  originId: string;
}

export interface ISourceEntityFilters {
  term?: string;
  type?: string[];
  sortBy?: ISortBy[];
}

export interface ISourceEntitiesApiGetPayload extends ISourceEntityFilters {
  id: string;
}

export interface ISourceEntitiesGetPayload extends ISourceEntityFilters {
  id: string;
  next?: boolean;
  cancelToken?: CancelToken;
}

export interface ISourceEntityPaginationResponse {
  total?: number;
  results: ISourceEntity[];
}

export enum SourceEntityTableModalType {
  DELETE = "Delete",
  BULK_DELETE = "Bulk delete",
  REPROCESS_ENTITY = "Reprocess entity"
}

export interface ISourceReprocessEntity {
  sourceEntityIds: string[];
}

export interface IAddedSourceEntity {
  data: string;
  originId: string;
  originIdLocked: boolean;
}

export interface IAddedSourceEntityFile extends IAddedSourceEntity {
  fileName: string;
}

export interface IUnconfiguredHostnamesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface IngestResponse {
  status: number;
  message: string;
  originId: string;
}
