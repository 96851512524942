import { Button, useDisclosure } from "@chakra-ui/react";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import CreateSourceGroupModal from "../../features/source-groups/components/CreateSourceGroupModal";
import SourceGroups from "../../features/source-groups/components/SourceGroups";

const DataSourcesPage = () => {
  const title = "Data sources";
  const {
    isOpen: isOpenSourceGroup,
    onOpen: onOpenSourceGroup,
    onClose: onCloseSourceGroup
  } = useDisclosure();

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Data sources are where a connection is created to your data source. This can be a CMS, a PIM-system, or perhaps a development instance of your CMS."
        headerRight={
          <Button variant="subtle" onClick={onOpenSourceGroup}>
            Create
          </Button>
        }
      >
        {<SourceGroups />}
      </ContentBox>

      <CreateSourceGroupModal
        isOpen={isOpenSourceGroup}
        onClose={onCloseSourceGroup}
      />
    </>
  );
};

export default DataSourcesPage;
