import { Data } from "plotly.js";
import { ReactNode } from "react";
import { IEnvironmentResponse } from "../../features/environments/types";
import { QueuedJobsDto } from "../../features/metrics/metrics.service";
import MetricsPlot from "./MetricsPlot";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";
import MetricsToolTip from "./MetricsToolTip";

const StatsQueueSizeTile = ({
  queuedJobsDto,
  environments,
  isLoading,
  removeTileMenu
}: {
  queuedJobsDto?: QueuedJobsDto;
  environments?: IEnvironmentResponse[];
  isLoading?: boolean;
  removeTileMenu?: ReactNode;
}) => {
  const usage = queuedJobsDto?.totalJobsInBacklog ?? 0;

  const data: Data[] = [
    {
      domain: { x: [0, 1], y: [0, 1] },
      value: usage,
      type: "indicator",
      mode: "number"
    }
  ];

  const help = `The current number of jobs in the queue. The number indicates pending jobs and not those currently processing. (updated every ~1 minute)`;
  const tooltipMetrics: { label: string; value: number }[] = [];

  for (const key in queuedJobsDto?.backlogPerEnvironment) {
    const environment = environments?.find((x) => x.id.idValue === key);

    tooltipMetrics.push({
      label: environment?.name ?? "- Ingest jobs",
      value: queuedJobsDto?.backlogPerEnvironment[key] ?? 0
    });
  }

  return (
    <MetricsTile
      title="Queued jobs"
      help={help}
      removeTileMenu={removeTileMenu}
    >
      <MetricsTileBody
        tooltipBody={
          <MetricsToolTip
            title="Queued jobs per environment"
            metrics={tooltipMetrics}
          ></MetricsToolTip>
        }
      >
        <MetricsPlot data={data} isLoading={isLoading}></MetricsPlot>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default StatsQueueSizeTile;
