import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { Reducer } from "../reducers/logs";
import { DefaultActivityState } from "../states/activityLogs";
import { ILogsState, LogsAction } from "../types";

export const ActivityLogsContext = createContext<{
  state: ILogsState;
  dispatch: React.Dispatch<LogsAction>;
}>({
  state: DefaultActivityState,
  dispatch: () => null
});

export const ActivityLogsProvider = ({
  children
}: {
  children?: ReactNode;
}) => {
  const [state, dispatch] = useReducer(Reducer, DefaultActivityState);
  return (
    <ActivityLogsContext.Provider value={{ state, dispatch }}>
      {children}
    </ActivityLogsContext.Provider>
  );
};

export const useActivityLogsState = () => {
  return useContext(ActivityLogsContext);
};
