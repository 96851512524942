import { Icon } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faCircleMinus,
  faLoader,
  faTrophy
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as CSS from "csstype";
import { GettingStartedStepStatus } from "./types";

export const GettingStartedItemStatus = ({
  status
}: {
  status: GettingStartedStepStatus;
}) => {
  const iconSelector: {
    [key in GettingStartedStepStatus]: {
      icon: IconProp;
      color: CSS.Property.Color;
      className: string;
    };
  } = {
    complete: { icon: faCheckCircle, color: "green.500", className: "" },
    idle: { icon: faCircleMinus, color: "gray.200", className: "fa-beat" },
    loading: { icon: faLoader, color: "gray.200", className: "fa-spin" },
    trophy: { icon: faTrophy, color: "green.500", className: "fa-beat" }
  };
  return (
    <>
      <Icon
        className={iconSelector[status].className}
        size="2xl"
        as={FontAwesomeIcon}
        icon={iconSelector[status].icon}
        color={iconSelector[status].color}
      ></Icon>
    </>
  );
};
