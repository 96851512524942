import { Box, Divider, Flex, Tooltip } from "@chakra-ui/react";
import { createElement } from "react";
import { GettingStartedItemActionExternalLink } from "./GettingStartedItemActionExternalLink";
import GettingStartedItemActionLink from "./GettingStartedItemActionLink";
import GettingStartedItemActionModal from "./GettingStartedItemActionModal";

import { GettingStartedItemStatus } from "./GettingStartedItemStatus";
import {
  IGettingStartedAction,
  IGettingStartedActionExternalLink,
  IGettingStartedActionLink,
  IGettingStartedActionModal,
  IGettingStartedStep
} from "./types";

function actionFactory(action: IGettingStartedAction) {
  if (isLinkAction(action)) {
    return createElement(GettingStartedItemActionLink, {
      ...action
    });
  }
  if (isModalAction(action)) {
    return createElement(GettingStartedItemActionModal, {
      ...action
    });
  }
  if (isExternalLinkAction(action)) {
    return createElement(GettingStartedItemActionExternalLink, {
      ...action
    });
  }
  return <></>;
}

export const GettingStartedItem = ({
  step: { title, status, description, actions }
}: {
  step: IGettingStartedStep;
}) => {
  return (
    <>
      <Flex direction={"column"}>
        <Flex direction={"row"}>
          <Flex
            alignItems="center"
            justify-content="center"
            minW="32px"
            minH="32px"
            marginRight={"16px"}
          >
            <GettingStartedItemStatus
              status={status}
            ></GettingStartedItemStatus>
          </Flex>
          <Flex direction={"column"}>
            <Tooltip label={description}>
              <Box fontWeight="500">{title}</Box>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex direction={"row"}>
          {actions.map((action, idx) => (
            <Box key={idx} mr={"1em"}>
              {actionFactory(action)}
            </Box>
          ))}
        </Flex>
        <Divider size={"md"} margin={"16px 0 16px 0"} opacity="1"></Divider>
      </Flex>
    </>
  );
};
function isExternalLinkAction(
  action: IGettingStartedAction
): action is IGettingStartedActionExternalLink {
  return action.type === "external";
}

function isModalAction(
  action: IGettingStartedAction
): action is IGettingStartedActionModal {
  return action.type === "modal";
}

function isLinkAction(
  action: IGettingStartedAction
): action is IGettingStartedActionLink {
  return action.type === "link";
}
