import {
  Box,
  Button,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  ToastId,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Buffer } from "buffer";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { container } from "tsyringe";
import BasicJsonCodeEditor from "../../../../components/CodeEditor/BasicJsonCodeEditor";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { IApiErrorResponse } from "../../../../types/errors";
import { useEntity } from "../../../source-entities/api/getEntity";
import { ISourceEntity } from "../../../source-entities/types";
import { useTenantStore } from "../../../tenants/store";
import { useTestSchema } from "../../api/testSchema";
import { SchemaEvaluationService } from "../../formats/SchemaEvaluationService";
import { ISchemaVersion, SchemaType } from "../../types";
import SourceEntitiesTableTestPanel from "./SourceEntitiesTableTestPanel";

import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NO_TRIGGER_TEXT } from "../constants";
import {
  ISchemaDryRunResultDto,
  ISchemaDryRunSourceEntityDto,
  TestInputSourceEntity
} from "../types";
import TestConsoleEntries from "./TestConsoleEntries";
import TestErrors from "./TestErrors";

export const TestSourceEntity = ({
  schema,
  schemaAlias,
  schemaType
}: {
  schema: ISchemaVersion;
  schemaType: SchemaType;
  schemaAlias: string;
}) => {
  const evaluationService = container.resolve(SchemaEvaluationService);
  const activeTenant = useTenantStore(({ activeTenant }) => activeTenant);

  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useToast();
  const toastIdRef = useRef<ToastId>();

  const [selectedSourceEntity, setSelectedSourceEntity] = useState<Pick<
    ISourceEntity,
    "originId" | "sourceId"
  > | null>();
  const [testOnMount, setTestOnMount] = useState(false);
  const [isRaw, setIsRaw] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");

  const [testResult, setTestResult] = useState<
    ISchemaDryRunResultDto | IApiErrorResponse
  >();
  const [tabIndex, setTabIndex] = useState(0);
  const [schemaHasChanged, setSchemaHasChanged] = useState<boolean>(false);

  const [sourceEntityTypes, setSourceEntityTypes] = useState<string[]>([]);

  useEffect(() => {
    const { valid, value } = evaluationService.evaluate(
      schemaType,
      schema.format,
      schema.data,
      { isUsingSourceGroups: activeTenant.isUsingSourceGroups }
    );
    setSchemaHasChanged(true);
    if (valid) {
      setSourceEntityTypes(value?.sourceEntityTypes ?? []);
      setInvalidMessage("");
    } else {
      setInvalidMessage("Schema is not valid");
    }
  }, [schema, schemaType]);

  useEffect(() => {
    const originId = searchParams?.get("originId");
    const sourceId = searchParams?.get("id");
    if (originId && sourceId) {
      setSelectedSourceEntity({
        originId,
        sourceId
      });
    }
  }, []);

  const {
    mutateAsync,
    isPending: isLoadingTestResult,
    isSuccess,
    isError,
    error
  } = useTestSchema();

  const isSuccessResponse = (
    testResult: ISchemaDryRunResultDto | IApiErrorResponse
  ): testResult is ISchemaDryRunResultDto => isSuccess;

  const getSourceEntity = () => {
    return JSON.stringify(data, null, 2);
  };

  const { data, isLoading } = useEntity({
    sourceId: selectedSourceEntity?.sourceId,
    originId: selectedSourceEntity?.originId,
    format: isRaw ? "raw" : "placeholders"
  });

  const test = async (entity: TestInputSourceEntity) => {
    const dryRunDto: ISchemaDryRunSourceEntityDto = {
      input: entity,
      schemaType,
      schemaAlias,
      schemaVersionData: Buffer.from(schema.data, "utf8").toString("base64"),
      schemaVersionFormat: schema.format,
      sourceId: entity.sourceId
    };

    try {
      setSchemaHasChanged(false);
      const testPromise = mutateAsync({
        dryRunDto,
        format: "simple"
      });
      const result = await testPromise;
      setTestResult(result);
    } catch (error) {
      const err = error as AxiosError<IApiErrorResponse>;
      setTestResult(err.response?.data);
    } finally {
      setTabIndex(1);
    }
  };

  useEffect(() => {
    if (!data || !testOnMount) {
      return;
    }
    test(data as TestInputSourceEntity).catch(console.error);
  }, [data, testOnMount]);

  useEffect(() => {
    if (!data || !sourceEntityTypes) {
      return;
    }
    if (!sourceEntityTypes.includes(data.type)) {
      setInvalidMessage(NO_TRIGGER_TEXT[schemaType]);
    }
  }, [data, sourceEntityTypes]);

  useEffect(() => {
    const title = "Testing schema";
    if (isLoadingTestResult) {
      toastIdRef.current = toast({
        status: "loading",
        title
      });
    }

    if (
      isSuccess &&
      toastIdRef.current &&
      testResult &&
      isSuccessResponse(testResult)
    ) {
      toast.update(toastIdRef.current, {
        status: testResult.succeeded ? "success" : "error",
        description: testResult.succeeded
          ? "Test ran successfully"
          : `Test had ${testResult.errors?.length ?? 0} error(s)`,
        title
      });
    }

    if (isError && toastIdRef.current && error) {
      toast.update(toastIdRef.current, {
        status: "error",
        title,
        description: error.response?.data.detail
      });
    }
  }, [testResult, isLoadingTestResult]);

  return (
    <>
      {!selectedSourceEntity ? (
        <SourceEntitiesTableTestPanel
          selectedSourceEntityCallback={(value, testOnMount) => {
            setSelectedSourceEntity(value);
            setTestOnMount(testOnMount ?? false);
            const newSearch = new URLSearchParams(searchParams);
            newSearch.set("originId", value.originId);

            setSearchParams(newSearch);
          }}
          triggerTypes={sourceEntityTypes}
          schemaType={schemaType}
        />
      ) : isLoading ? (
        <LoadingAnimation></LoadingAnimation>
      ) : (
        selectedSourceEntity &&
        data && (
          <>
            <Flex justifyContent="space-between" alignItems="center" py="4">
              <Button
                variant="subtle"
                onClick={() => {
                  setSelectedSourceEntity(null);
                  setTabIndex(0);
                  setTestResult(undefined);
                  setSchemaHasChanged(false);
                }}
              >
                Back
              </Button>
              <Flex>
                <Button
                  colorScheme="gray"
                  mr="2"
                  onClick={() => setIsRaw(!isRaw)}
                >
                  {isRaw ? "Switch to Placeholders" : "Switch to Raw"}
                </Button>
                <Tooltip
                  isDisabled={!invalidMessage}
                  label={invalidMessage}
                  placement="bottom-start"
                >
                  <Button
                    colorScheme="gray"
                    onClick={() => test(data as TestInputSourceEntity)}
                    isDisabled={!!invalidMessage}
                    isLoading={isLoadingTestResult}
                  >
                    Test
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>

            {testResult && isSuccessResponse(testResult) && (
              <>
                <TestErrors testResult={testResult} />
                <TestConsoleEntries testResult={testResult} />
              </>
            )}

            <Tabs
              variant="default"
              isFitted
              index={tabIndex}
              onChange={setTabIndex}
            >
              <TabList>
                <Tab>Input</Tab>
                <Tooltip
                  placement="bottom-start"
                  label={
                    !testResult
                      ? "No output produced. Click test to produce output"
                      : schemaHasChanged && testResult
                      ? "The schema has changed since last test, test again to test the updated schema"
                      : ""
                  }
                >
                  <Tab isDisabled={!testResult}>
                    {schemaHasChanged && testResult ? (
                      <>
                        <Icon
                          as={FontAwesomeIcon}
                          icon={faTriangleExclamation}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    Output
                  </Tab>
                </Tooltip>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box key={data.id}>
                    {data && (
                      <BasicJsonCodeEditor
                        value={getSourceEntity()}
                        options={{
                          readOnly: true
                        }}
                      />
                    )}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <BasicJsonCodeEditor
                    value={JSON.stringify(testResult, null, 2)}
                    options={{ readOnly: true }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )
      )}
    </>
  );
};
