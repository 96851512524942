import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Text
} from "@chakra-ui/react";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import CopyableText from "../../../components/table/CopyableText";
import { TenantWithErrors } from "../../bi/types";
import { useTenants } from "../api/getTenants";

const TenantsWithErrorsList = ({
  tenantsWithErrors
}: {
  tenantsWithErrors?: TenantWithErrors[];
}) => {
  const tenants = useTenants({
    tenantIds: tenantsWithErrors?.map((tenant) => tenant.tenantId ?? [])
  });
  return tenantsWithErrors ? (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Flex flex={1} align={"center"} justify={"space-between"}>
            <Flex gap={"1em"} align={"center"}>
              <Icon
                as={FontAwesomeIcon}
                icon={faWarning}
                color={"red.300"}
              ></Icon>
              {` ${tenantsWithErrors.length} tenants with errors`}
            </Flex>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {tenantsWithErrors?.map((withErrors) => (
            <Card>
              <CardHeader>
                <Flex justify={"space-between"}>
                  <Flex direction={"column"}>
                    {tenants.isLoading ? (
                      <Skeleton size={"md"}></Skeleton>
                    ) : (
                      <Heading size={"md"}>
                        {tenants.data?.find(
                          (t) => t.id.idValue === withErrors.tenantId
                        )?.name ?? "unknown"}
                      </Heading>
                    )}
                    <CopyableText id={withErrors.tenantId}></CopyableText>
                  </Flex>
                  <Button
                    as={NavLink}
                    to={`./tenants/${withErrors.tenantId.split("/")[3]}`}
                  >
                    Go to details page
                  </Button>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text>Error count: {withErrors.errorCount}</Text>
              </CardBody>
            </Card>
          )) ?? <></>}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <></>
  );
};

export default TenantsWithErrorsList;
