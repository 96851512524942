import { inject, singleton } from "tsyringe";
import { APP_VERSION } from "../constants/app";
import { IngestResponse } from "../features/source-entities/types";
import { HttpService } from "./http.service";

@singleton()
export class IngestClient {
  constructor(
    @inject("ingestHttpService") private readonly http: HttpService
  ) {}

  async create(
    originId: string,
    sourceEntityDataString: string,
    apiKey: string
  ) {
    const { data } = await this.http.post<IngestResponse>(
      `/${originId}`,
      sourceEntityDataString,
      {
        headers: {
          "X-Api-Key": apiKey,
          "X-Enterspeed-System": `management-app/${APP_VERSION}`
        }
      }
    );
    return data;
  }
  async delete(originId: string, apiKey: string) {
    const { data } = await this.http.delete(`/${originId}`, {
      headers: {
        "X-Api-Key": apiKey,
        "X-Enterspeed-System": `management-app/${APP_VERSION}`
      }
    });
    return data;
  }
}
