import { Box, Flex, Text } from "@chakra-ui/react";
import { IEnhancedLog } from "../types";
import InterpolatedText from "./InterpolatedText";

const LogErrors = ({ log }: { log: IEnhancedLog }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let errors: unknown[] = log.properties?.Errors ?? [];
  if (!errors.length && !!log.properties?.Error) {
    errors = [log.properties?.Error];
  }

  if (!errors.length) {
    return <></>;
  }

  const tryParseJsonObject = function (str: string): unknown {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  return (
    <Box>
      <Text mb="2">
        <Text as="b">Error{errors.length > 1 ? "s" : ""}: </Text>
      </Text>
      <Flex
        direction={"column"}
        gap={2}
        borderLeftColor={"red.300"}
        borderLeftWidth={"3px"}
        borderLeftStyle={"solid"}
        paddingX={"10px"}
        paddingY={"5px"}
      >
        {errors.map((error, index) => {
          const obj = tryParseJsonObject(error as string);
          const isObject = typeof obj === "object" && obj !== null;
          if (!isObject) {
            return <Text key={index}>{error?.toString()}</Text>;
          }

          const errorObjectKeys = Object.keys(obj);
          return (
            <Flex key={index} direction={"column"} gap={1.5}>
              <Text as="p">
                {errorObjectKeys.map((key, index) => {
                  const capitalizedFirstWordDisplayKey =
                    key.charAt(0).toUpperCase() + key.slice(1);
                  return (
                    <Box as="span" key={key}>
                      <Text
                        as="span"
                        fontWeight={"medium"}
                        marginRight={0.5}
                        marginLeft={index > 0 ? 1 : 0}
                      >
                        {capitalizedFirstWordDisplayKey}:
                      </Text>
                      <InterpolatedText
                        text={(obj as never)[key]}
                        properties={log.properties}
                        enhancements={log.enhancements}
                        displayType={"ID_VALUE_LINK"}
                      ></InterpolatedText>
                      {}
                    </Box>
                  );
                })}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default LogErrors;
