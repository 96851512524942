import { Box, keyframes } from "@chakra-ui/react";
import { useState } from "react";
import { isDevEnv } from "../helpers/env";

const DevEnvBadge = () => {
  const [hideBadge, setHideBadge] = useState(false);

  const pulse = keyframes`
    0% {
        background-color: #48BB78;
      }
  
      50% {
        background-color: #2F855A;
      }
  
      100% {
        background-color: #48BB78;
      }
    `;

  return (
    <>
      {!hideBadge && isDevEnv() && (
        <Box
          onClick={() => setHideBadge(true)}
          title="Click to hide badge"
          fontSize="md"
          borderRadius="md"
          fontWeight="bold"
          backgroundColor="green.500"
          color="white"
          position="fixed"
          bottom="10px"
          right="10px"
          cursor="pointer"
          p="2"
          textTransform="uppercase"
          _hover={{ backgroundColor: "green.600" }}
          animation={`${pulse} 2s infinite`}
          userSelect="none"
          zIndex={20}
        >
          Development Env
        </Box>
      )}
    </>
  );
};

export default DevEnvBadge;
