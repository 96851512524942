import Axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { API_URL } from "../constants/api";
import { APP_VERSION } from "../constants/app";
import { refreshToken } from "../features/auth/api/authenticate";
import { signOut } from "../features/auth/api/signOut";
import { useAuthStore } from "../features/auth/store";
import { useTenantStore } from "../features/tenants/store";
import { ProblemDetailsDto } from "../types/api";

export const managementAxios = Axios.create({
  baseURL: API_URL,
  headers: {
    "X-Enterspeed-System": `management-app/${APP_VERSION}`
  }
});

type RetryCallback = (accessToken: string) => void;
class SubscriptionHandler {
  private static instance: SubscriptionHandler;

  private constructor() {
    // intentionally left empty
  }

  public static getInstance(): SubscriptionHandler {
    if (!SubscriptionHandler.instance) {
      SubscriptionHandler.instance = new SubscriptionHandler();
    }

    return SubscriptionHandler.instance;
  }

  private subscribers: RetryCallback[] = [];

  addSubscriber(callback: RetryCallback) {
    this.subscribers.push(callback);
  }
  async retryQueuedRequests(token: string) {
    await Promise.all(this.subscribers.map((callback) => callback(token)));
    this.emptyQueue();
  }
  emptyQueue() {
    this.subscribers = [];
  }
}

let isRefreshing = false;

managementAxios.interceptors.request.use(
  (requestConfig: InternalAxiosRequestConfig) => {
    const {
      tokens: { accessToken }
    } = useAuthStore.getState();

    const {
      activeTenant: { id: currentId }
    } = useTenantStore.getState();

    if (accessToken) {
      requestConfig.headers["authorization"] = `Bearer ${accessToken}`;
    }
    if (currentId && !requestConfig.headers["X-Tenant-Id"]) {
      requestConfig.headers["X-Tenant-Id"] = currentId;
    }
    return requestConfig;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

const handle401Refresh = async (request: InternalAxiosRequestConfig) => {
  const subscriptionHandler = SubscriptionHandler.getInstance();
  const retryOriginalRequest = new Promise((resolve) => {
    subscriptionHandler.addSubscriber((accessToken: string) => {
      request.headers.Authorization = `Bearer ${accessToken}`;
      resolve(managementAxios(request));
    });
  });

  if (!isRefreshing) {
    isRefreshing = true;
    try {
      const { token: refreshedToken } = await refreshToken();
      if (!refreshedToken) {
        return Promise.reject("token could not be refreshed");
      }

      if (validateRefreshedToken(request, refreshedToken?.accessToken)) {
        await subscriptionHandler.retryQueuedRequests(
          refreshedToken.accessToken
        );
      }
      isRefreshing = false;
    } catch (error) {
      isRefreshing = false;
      await emptyQueueAndSignOut();
      return Promise.reject(request);
    }
  }
  return retryOriginalRequest;
};

const emptyQueueAndSignOut = async () => {
  SubscriptionHandler.getInstance().emptyQueue();
  await signOut();
};

const handleFailedToRefreshToken = async (
  request: InternalAxiosRequestConfig
) => {
  await emptyQueueAndSignOut();
  return Promise.reject(request);
};

managementAxios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<ProblemDetailsDto>) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    const {
      response: { status, config: originalRequest }
    } = error;

    const refreshTokenFromStore = useAuthStore.getState().tokens?.refreshToken;

    try {
      if (
        status !== 401 ||
        !refreshTokenFromStore ||
        isSignOutEndpointRequest(originalRequest)
      ) {
        return Promise.reject(error);
      }

      // if refresh_token is the one that failed, sign out.
      if (isRefreshTokenRequest(error.config)) {
        return handleFailedToRefreshToken(originalRequest);
      }

      return handle401Refresh(originalRequest);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const validateRefreshedToken = (
  request: InternalAxiosRequestConfig,
  accessToken?: string
): accessToken is string =>
  request.headers.Authorization?.toString().slice(7) !== accessToken;

const isSignOutEndpointRequest = (request: InternalAxiosRequestConfig) =>
  request.url?.includes("/api/v1/identity/signout") ?? false;

const isRefreshTokenRequest = (request?: InternalAxiosRequestConfig) =>
  (request?.data as string | undefined | null)
    ?.toString()
    ?.includes("refresh_token") ?? false;
