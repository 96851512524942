import { IDomain } from "../../domains/types";

export interface IEnvironmentClient {
  name: string;
  accessKey: string;
  environmentName: string;
  clientGuid: string;
  id: string;
  environmentGuid: string;
  domainIds: string[];
  domains: IDomain[];
}

export interface IEnvironmentClientResponse {
  id: IEnvironmentClientResponseId;
  idValue: string;
  name: string;
  accessKey: string;
  environmentName: string;
  domainIds: string[];
}

export interface IEnvironmentClientResponseId {
  environmentGuid: string;
  clientGuid: string;
  idValue: string;
}

export interface IEnvironmentClientCreatePayload {
  name: string;
}

export interface IEnvironmentClientEditPayload {
  name: string;
  allowedDomains?: string[];
  regenerateAccessKey: boolean;
}

export enum EnvironmentClientTableModalType {
  EDIT = "Edit",
  DELETE = "Delete",
  EDIT_DOMAINS = "Edit Domains",
  REGENERATE_API_KEY = "Regenerate API Key"
}

export interface IEnvironmentClientDomainsModalProps {
  name: string;
  envClientDomains?: IDomain[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (domains: string[]) => void;
}
