import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../features/auth/store";

const ProtectedAdminRoute = () => {
  const isAdmin = useAuthStore((state) => state.isAdmin);
  if (!isAdmin) {
    return <Navigate to={"../"} replace></Navigate>;
  }
  return <Outlet />;
};

export default ProtectedAdminRoute;
