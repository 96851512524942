import {
  DomainId,
  EnvironmentClientId,
  EnvironmentId,
  MappingSchemaId,
  MappingSchemaVersionId,
  RouteId,
  SourceEntityId,
  SourceGroupId,
  SourceId,
  ViewId
} from "../types/ids";

export const parseSourceEntityId = (id: string): SourceEntityId | undefined => {
  const regex =
    /^gid:\/\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid
  };
};

export const parseUrlRouteId = (id: string): RouteId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Domain\/(?<DomainGuid>[a-fA-F0-9-]+)\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)\/Schema\/(?<SchemaAlias>.+)\/Route\/Url(?<RelativeUrl>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    kind: "URL",
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid,
    environmentGuid: groups.EnvironmentGuid,
    schemaAlias: groups.SchemaAlias,
    relativeUrl: groups.RelativeUrl,
    domainGuid: groups.DomainGuid
  };
};

export const parseHandleRouteId = (id: string): RouteId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)\/Schema\/(?<SchemaAlias>.+)\/Route\/Handle\/(?<Handle>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    kind: "HANDLE",
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid,
    environmentGuid: groups.EnvironmentGuid,
    schemaAlias: groups.SchemaAlias,
    handle: groups.Handle
  };
};

export const parseImplicitRedirectRouteId = (
  id: string
): RouteId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Domain\/(?<DomainGuid>[a-fA-F0-9-]+)\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)\/Schema\/(?<SchemaAlias>.+)\/Route\/ImplicitRedirect\/Url(?<RelativeUrl>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    kind: "IMPLICIT_REDIRECT",
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid,
    environmentGuid: groups.EnvironmentGuid,
    schemaAlias: groups.SchemaAlias,
    relativeUrl: groups.RelativeUrl,
    domainGuid: groups.DomainGuid
  };
};

export const parseExplicitRedirectRouteId = (
  id: string
): RouteId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Domain\/(?<DomainGuid>[a-fA-F0-9-]+)\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)\/Schema\/(?<SchemaAlias>.+)\/Route\/ExplicitRedirect\/Url(?<RelativeUrl>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    kind: "EXPLICIT_REDIRECT",
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid,
    environmentGuid: groups.EnvironmentGuid,
    schemaAlias: groups.SchemaAlias,
    relativeUrl: groups.RelativeUrl,
    domainGuid: groups.DomainGuid
  };
};

export const parseViewId = (id: string): ViewId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)\/Entity\/(?<OriginId>.+)\/View\/(?<SchemaAlias>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    originId: groups.OriginId,
    sourceGuid: groups.SourceGuid,
    environmentGuid: groups.EnvironmentGuid,
    viewHandle: groups.SchemaAlias
  };
};

export const parseEnvironmentClientId = (
  id: string
): EnvironmentClientId | undefined => {
  const regex =
    /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)\/Client\/(?<ClientGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    environmentGuid: groups.EnvironmentGuid,
    clientGuid: groups.clientGuid
  };
};

export const parseMappingSchemaId = (
  id: string
): MappingSchemaId | undefined => {
  const regex = /^gid:\/\/MappingSchema\/(?<SchemaGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    mappingSchemaGuid: groups.SchemaGuid
  };
};

export const parseMappingSchemaVersionId = (
  id: string
): MappingSchemaVersionId | undefined => {
  const regex =
    /^gid:\/\/MappingSchema\/(?<SchemaGuid>[a-fA-F0-9-]+)\/Version\/(?<Version>.+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    mappingSchemaGuid: groups.SchemaGuid,
    version: parseInt(groups.Version)
  };
};

export const parseEnvironmentId = (id: string): EnvironmentId | undefined => {
  const regex = /^gid:\/\/Environment\/(?<EnvironmentGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    environmentGuid: groups.EnvironmentGuid
  };
};

export const parseSourceId = (id: string): SourceId | undefined => {
  const regex = /^gid:\/\/Source\/(?<SourceGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    sourceGuid: groups.SourceGuid
  };
};

export const parseDomainId = (id: string): DomainId | undefined => {
  const regex = /^gid:\/\/Domain\/(?<DomainGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    domainGuid: groups.DomainGuid
  };
};

export const parseSourceGroupId = (id: string): SourceGroupId | undefined => {
  const regex = /^gid:\/\/SourceGroup\/(?<SourceGroupGuid>[a-fA-F0-9-]+)$/;
  const groups = id?.match(regex)?.groups;
  if (!groups) {
    return undefined;
  }
  return {
    sourceGroupGuid: groups.SourceGroupGuid
  };
};
