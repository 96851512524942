import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";

import { TenantTable } from "../../tenants/components/TenantTable";
import useTenantSwitcher from "../../tenants/hooks/useTenantSwitcher";
import { ITenant } from "../../tenants/types";

export const UserTenantsModal = ({
  count,
  userName,
  tenants
}: {
  count: number;
  userName: string;
  tenants: ITenant[];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantSwitcher = useTenantSwitcher();

  const handleTenantSwitch = (tenant: ITenant) => {
    onClose();
    tenantSwitcher.switch(tenant, { redirectToHome: false });
  };

  return (
    <>
      <Button
        size="xs"
        variant="subtle"
        onClick={onOpen}
        isDisabled={count === 0}
      >
        {count}
      </Button>
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
          <ModalHeader>Tenants for {userName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TenantTable
              tenants={tenants}
              onTenantRowClick={handleTenantSwitch}
            ></TenantTable>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="gray"
              bg={useColorModeValue("gray.300", "gray.700")}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
