import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";
import { IEditDestinationEnvironmentConfigurationDto } from "../types";

const editDestinationEnvironmentConfigurationFn = (
  dto: IEditDestinationEnvironmentConfigurationDto
) => {
  const destinationService = container.resolve(DestinationsService);

  return destinationService.editEnvironmentConfigurationToDestinationConfiguration(
    dto
  );
};

const useEditDestinationEnvironmentConfiguration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editDestinationEnvironmentConfigurationFn,
    onSuccess: (_data, { alias }) =>
      queryClient.invalidateQueries({ queryKey: [DESTINATIONS, alias] })
  });
};

export default useEditDestinationEnvironmentConfiguration;
