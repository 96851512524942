import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { DESTINATIONS } from "../../../constants/queryKey";
import { DestinationsService } from "../destinations.service";

const createDestinationConfigurationFn = ({
  alias,
  appId
}: {
  alias: string;
  appId: string;
}) => {
  const destinationService = container.resolve(DestinationsService);

  return destinationService.createDestinationConfiguration(alias, appId);
};

const useCreateDestinationConfiguration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDestinationConfigurationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DESTINATIONS] })
  });
};

export default useCreateDestinationConfiguration;
