import { Badge, Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { IDestination } from "../types";

const DestinationItem = ({ destination }: { destination: IDestination }) => {
  return (
    <Flex direction="row" gap={"2em"} flex={1}>
      <Box flexShrink={0}>
        <Image
          boxSize={"90px"}
          objectFit="cover"
          src={destination.icon}
          fallbackSrc="https://via.placeholder.com/150"
        ></Image>
      </Box>
      <Flex direction={"column"} gap={"1em"}>
        <Box>
          <Box h={"1.5em"}>
            {destination.accessibility !== "public" && (
              <Badge colorScheme={"red"}>{destination.accessibility}</Badge>
            )}
          </Box>
          <Heading>{destination.name}</Heading>
        </Box>
        <Text>{destination.description}</Text>
      </Flex>
    </Flex>
  );
};

export default DestinationItem;
