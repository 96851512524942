import { SourceLocation } from "@babel/types";
import {
  Monaco,
  MonacoEditorContribution,
  MonacoMarker,
  MonacoModelContentChangedEvent,
  MonacoStandaloneCodeEditor,
  MonacoStandaloneDiffEditor,
  MonacoStandaloneDiffEditorConstructionOptions,
  MonacoStandaloneEditorConstructionOptions
} from "monaco-editor";
import { ReactNode } from "react";
export type EditorLanguage = "json" | "javascript";
export type WordWrap = "off" | "on" | "wordWrapColumn" | "bounded";
export type EditorSize = "small" | "default";
export const FontSizeOptions = [10, 12, 14, 16, 18, 20];
export const WordWrapOptions = [
  {
    value: "off",
    label: "Off"
  },
  {
    value: "on",
    label: "On"
  },
  {
    value: "wordWrapColumn",
    label: "Word wrap column"
  },
  {
    value: "bounded",
    label: "Bounded"
  }
];

export const LocalStorageSettingKeys = {
  autoSave: "autosave",
  wordWrap: "wordWrap",
  theme: "theme",
  minimap: "minimap",
  fontSize: "fontSize"
};

export interface ICodeEditorCommonProps {
  value: string;
  options: MonacoStandaloneEditorConstructionOptions;
  diffComparison?: IDiffComparison;

  size?: EditorSize;
  leftSideBottomBarElements?: ReactNode;
  rightSideBottomBarElements?: ReactNode;
  bottomBarActionButtons?: ReactNode;

  onValueChange?: (
    value: string | undefined,
    event: MonacoModelContentChangedEvent
  ) => void;
  onEditorMount?: (
    editor: MonacoStandaloneCodeEditor | MonacoStandaloneDiffEditor,
    monaco: Monaco
  ) => void;
}

export interface IBaseCodeEditorProps extends ICodeEditorCommonProps {
  language: EditorLanguage;
  onValidationChange?: (markers: MonacoMarker[]) => void;
}

export interface IDiffComparison {
  value: string;
  language: EditorLanguage;
  options: MonacoStandaloneDiffEditorConstructionOptions;
}

export class ObjectResult<T> implements IResult {
  value: T | undefined;
  valid: boolean;
  errors: ErrorDescriptor[];

  private constructor(
    valid: boolean,
    errors: ErrorDescriptor[] | undefined,
    value?: T
  ) {
    this.valid = valid;
    this.errors = errors ?? [];
    this.value = value;
  }

  static ok<T>(value: T): ObjectResult<T> {
    return new ObjectResult(true, undefined, value);
  }

  static fail<T>(errors: ErrorDescriptor[]): ObjectResult<T> {
    return new ObjectResult(false, errors);
  }
}

export interface IResult {
  valid: boolean;
  errors: ErrorDescriptor[];
}

export interface ErrorDescriptor {
  description: string;
  location?: Partial<SourceLocation> | null;
  column?: number;
}

export class Result implements IResult {
  valid: boolean;
  errors: ErrorDescriptor[];

  private constructor(valid: boolean, errors: ErrorDescriptor[] | undefined) {
    this.valid = valid;
    this.errors = errors ?? [];
  }

  static ok(): Result {
    return new Result(true, undefined);
  }

  static fail(errors: ErrorDescriptor[]): Result {
    return new Result(false, errors);
  }
}

export interface ICodeEditorBottomBarProps {
  theme: string;
  wordWrap: WordWrap;
  minimapVisible: boolean;

  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  onToggleMinimap?: () => void;
  onEditorCommandRequest?: (query?: string) => void;
}

export enum ICodeEditorBottomBarActionType {
  AUTO_SAVE = "autoSave",
  WORD_WRAP = "wordWrap",
  THEME = "theme"
}

export enum MarkerSeverity {
  Hint = 1,
  Info = 2,
  Warning = 4,
  Error = 8
}

export enum CompletionItemKind {
  Method = 0,
  Function = 1,
  Constructor = 2,
  Field = 3,
  Variable = 4,
  Class = 5,
  Struct = 6,
  Interface = 7,
  Module = 8,
  Property = 9,
  Event = 10,
  Operator = 11,
  Unit = 12,
  Value = 13,
  Constant = 14,
  Enum = 15,
  EnumMember = 16,
  Keyword = 17,
  Text = 18,
  Color = 19,
  File = 20,
  Reference = 21,
  Customcolor = 22,
  Folder = 23,
  TypeParameter = 24,
  User = 25,
  Issue = 26,
  Snippet = 27
}

export interface EditorContribSuggestController
  extends MonacoEditorContribution {
  widget: {
    value: {
      _setDetailsVisible(value: boolean): void;
      onDidFocus(event: unknown): void;
    };
  };
  dispose(): void;
}

export interface EditorSuggestionWidgetOnDidFocusEvent {
  item: EditorSuggestionWidgetOnDidFocusEventItem;
  model: {
    _items: [
      {
        textLabel: string;
        completion: {
          insertText: string | null;
          kind: CompletionItemKind;
          documentation: { value: string };
        };
      }
    ];
  };
}
export type EditorSuggestionWidgetOnDidFocusEventItem = {
  _resolveCache: Promise<void>;
};
export type Suggestion = {
  label: string;
  kind: CompletionItemKind;
  insertText: string;
};
export type SuggestionConfiguration = {
  patterns: string[];
  suggestions: Suggestion[];
};
export interface CompletionItem {
  label: string;
  kind: CompletionItemKind;
  insertText: string;
  range: IRange | CompletionItemRanges;
}
export interface IRange {
  readonly startLineNumber: number;
  readonly startColumn: number;
  readonly endLineNumber: number;
  readonly endColumn: number;
}
export interface CompletionItemRanges {
  insert: IRange;
  replace: IRange;
}
