import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { MANAGEMENT_CLIENTS } from "../../../constants/queryKey";
import { ManagementClientService } from "../management-client.service";

export const deleteManagementClient = ({
  managementClientGuid
}: {
  managementClientGuid: string;
}) => {
  const managementClientService = container.resolve(ManagementClientService);
  return managementClientService.deleteManagementClient(managementClientGuid);
};

export const useDeleteManagementClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteManagementClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MANAGEMENT_CLIENTS]
      });
    }
  });
};
