import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from "@chakra-ui/react";
import {
  faLightbulbOn,
  faTriangleExclamation
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ISchemaDryRunResultDto } from "../types";

const TestErrors = ({ testResult }: { testResult: ISchemaDryRunResultDto }) => {
  return (
    <>
      {testResult.errors && testResult.errors.length > 0 && (
        <Accordion
          allowToggle
          width="100%"
          color="black"
          backgroundColor={testResult.succeeded ? "yellow.100" : "red.100"}
          fontSize="sm"
        >
          <AccordionItem>
            <AccordionButton
              backgroundColor={testResult.succeeded ? "yellow.300" : "red.300"}
            >
              <Box as="span" flex="1" textAlign="left">
                <Icon as={FontAwesomeIcon} icon={faTriangleExclamation} />{" "}
                <Text as="span" fontWeight="semibold">
                  The test threw the following error:
                </Text>{" "}
                Click to view details.
              </Box>
              <AccordionIcon />
            </AccordionButton>

            {testResult.errors?.map((error, i) => {
              return (
                <AccordionPanel
                  p={0}
                  mt={i === 0 ? "3" : ""}
                  mb="3"
                  ml="3"
                  mr="3"
                  key={`error-${i}`}
                  backgroundColor={
                    testResult.succeeded ? "yellow.200" : "red.200"
                  }
                >
                  <Flex>
                    <Flex grow={1}>
                      <Box p="4">
                        <Text fontWeight="bold">{error.schemaProperty}</Text>
                        <Text>{error.message}</Text>
                      </Box>
                    </Flex>
                    {Object.keys(error.errors).length > 0 && (
                      <Flex pt="4" pr="4">
                        {Object.keys(error.errors).length}{" "}
                        {`issue${
                          Object.keys(error.errors).length > 1 ? "s" : ""
                        }`}
                      </Flex>
                    )}
                  </Flex>
                  {Object.keys(error.errors).length > 0 && (
                    <Box backgroundColor="white" p={4}>
                      {Object.entries(error.errors).map(([key, value]) => {
                        return (
                          <>
                            <Box key={`error-issue-${key}`}>
                              <Text fontWeight="bold">
                                {key ? key : "Issue"}:
                              </Text>
                              <Box ml="8">
                                <ul>
                                  {value.map((issue, y) => {
                                    return <li key={`issue-${y}`}>{issue}</li>;
                                  })}
                                </ul>
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  )}
                </AccordionPanel>
              );
            })}
          </AccordionItem>
        </Accordion>
      )}
      {testResult.warnings.length > 0 && (
        <Flex
          width="100%"
          color="black"
          backgroundColor="yellow.300"
          p="2"
          pb="2"
          pl="4"
          pr="4"
          mt="2"
        >
          <Box as="span" flex="1" textAlign="left">
            <Icon as={FontAwesomeIcon} icon={faTriangleExclamation} />{" "}
            <Text as="span" fontWeight="semibold">
              The test threw the following warnings:
            </Text>{" "}
            <Box mt="2" ml="8" fontSize="sm">
              <ul>
                {testResult.warnings?.map((warning, i) => {
                  return (
                    <>
                      <li key={`warning-${i}`}>
                        {warning.path && (
                          <Text as="span" fontWeight="semibold">
                            {warning.path}:
                          </Text>
                        )}
                        {warning.message}
                        {warning.tip && (
                          <Box ml="8">
                            <ul>
                              <li>
                                <Icon
                                  as={FontAwesomeIcon}
                                  icon={faLightbulbOn}
                                />{" "}
                                {warning.tip}
                              </li>
                            </ul>
                          </Box>
                        )}
                      </li>
                    </>
                  );
                })}
              </ul>
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
};
export default TestErrors;
