import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { IContentBox } from "../types/contentBox";
import HelpIcon from "./HelpIcon";

const ContentBox = ({
  title,
  subTitle,
  help,
  headerRight,
  headerLeft,
  fullHeight,
  children,
  childrenMt,
  childrenJustify,
  childrenAlign,
  id
}: IContentBox) => {
  return (
    <Flex
      id={id}
      p="24px"
      bg={useColorModeValue("white", "gray.900")}
      border="1px solid"
      borderColor={useColorModeValue("gray.200", "gray.900")}
      borderRadius="sm"
      height={{ lg: fullHeight ? "100%" : "auto" }}
      width="100%"
      direction={"column"}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Flex alignItems="center">
            <Flex direction={"column"}>
              <Flex align={"center"}>
                <Box fontSize="24px" fontWeight="semibold">
                  {title}
                </Box>
                {help && <HelpIcon label={help} />}
              </Flex>
              {subTitle && (
                <Box fontSize="14px" fontWeight="semibold">
                  {subTitle}
                </Box>
              )}
            </Flex>
            {headerLeft && <Box ml="4">{headerLeft}</Box>}
          </Flex>
        </Flex>

        {headerRight && <Box>{headerRight}</Box>}
      </Flex>
      <Flex
        mt={
          childrenMt !== null && childrenMt !== undefined
            ? `${childrenMt}px`
            : "36px"
        }
        flex="1"
        direction={"column"}
        justify={childrenJustify}
        align={childrenAlign}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default ContentBox;
