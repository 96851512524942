import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { ISettingsResponse } from "./types";

@singleton()
export class SettingsService {
  constructor(private readonly managementClient: ManagementClient) {}
  public async getSettings() {
    const { data } = await this.managementClient.get<ISettingsResponse>(
      "/api/settings"
    );
    return data;
  }
}
