import { ISchemaListResponse } from "../types";
import {
  SchemaItemTreeNode,
  TreeNode,
  VirtualFolderTreeNode
} from "../types/schema-list";
import schemaHasValidFolderName from "./schemaHasValidFolderName";

const createVirtualFolderTreeNode = (
  name: string,
  path: string,
  hidden: boolean,
  isExpanded: boolean,
  children: TreeNode[]
): VirtualFolderTreeNode => {
  return {
    name,
    hidden,
    children,
    path,
    isExpanded,
    key: path,
    type: "folder"
  };
};

const createSchemaItemTreeNode = (
  name: string,
  hidden: boolean,
  data: ISchemaListResponse
): SchemaItemTreeNode => {
  return {
    name,
    hidden,
    key: data.id.idValue,
    type: "schema",
    data: data
  };
};

const createNode = (
  priorPath: string[],
  nextPath: string[],
  schema: ISchemaListResponse
): TreeNode => {
  const name = nextPath[0];
  if (nextPath.length <= 1) {
    return createSchemaItemTreeNode(name, false, schema);
  }

  const priorPathUpdated = [...priorPath, name];
  return createVirtualFolderTreeNode(
    name,
    priorPathUpdated.join("/"),
    false,
    false,
    [createNode(priorPathUpdated, nextPath.slice(1), schema)]
  );
};

const mergeNodes = (input: TreeNode[]): TreeNode[] => {
  const nodesGroupedByName = input.reduce(
    (group: { [name: string]: TreeNode[] }, node) => {
      group[node.name] = group[node.name] ?? [];
      group[node.name].push(node);
      return group;
    },
    {}
  );

  const result = [];
  for (const [name, nodes] of Object.entries(nodesGroupedByName)) {
    const directoryNodes = nodes.filter(
      (f) => f.type === "folder"
    ) as VirtualFolderTreeNode[];
    const childrenNodes = directoryNodes.map((m) => m.children).flat();
    const childrenNodesGrouped = mergeNodes(childrenNodes);
    const schemaNodes = nodes.filter((f) => f.type === "schema");
    result.push(...schemaNodes);

    if (childrenNodesGrouped.length > 0) {
      result.push(
        createVirtualFolderTreeNode(
          name,
          directoryNodes[0].path,
          false,
          false,
          childrenNodesGrouped
        )
      );
    }
  }

  return result.sort(sortByName).sort(sortFoldersOverSchemas);
};

const sortByName = (a: TreeNode, b: TreeNode) => {
  return a.name.localeCompare(b.name);
};

const sortFoldersOverSchemas = (a: TreeNode, b: TreeNode) => {
  if (a.type === "schema") {
    return 1;
  }

  if (b.type === "schema") {
    return -1;
  }

  return sortByName(a, b);
};

export const buildNodes = (schemas: ISchemaListResponse[]): TreeNode[] => {
  const nodes = schemas.map((schema) => {
    if (!schemaHasValidFolderName(schema.name)) {
      return createSchemaItemTreeNode(schema.name, false, schema);
    }

    const path = schema.name.split("/");
    const name = path[0];
    const priorPath = name;
    return createVirtualFolderTreeNode(name, priorPath, false, false, [
      createNode([priorPath], path.slice(1), schema)
    ]);
  });
  return mergeNodes(nodes);
};
